import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { POSMenuStructure } from 'menuStructures';
import { Loader, ConfirmationModal } from 'components'
import { ToastContainer } from "react-toastify";

@inject('store')
@observer
export default class TillList extends React.Component {


  @observable addNewTillModalVisible;
  @action setAddNewTillModalVisible(bool) {
    this.addNewTillModalVisible = bool;
  }

  @observable addingNewTill;
  @action setAddingNewTill(addingNewTill) {
    this.addingNewTill = addingNewTill;
  }

  @observable showConfirmationModal;
  @action setShowConfirmationModal(showConfirmationModal) {
    this.showConfirmationModal = showConfirmationModal;
  }

  @observable tillToArchive;
  @action setTillToArchive(tillToArchive) {
    this.tillToArchive = tillToArchive;
  }

  cancelArchive = () => {
    this.setTillToArchive(null);
    this.setShowConfirmationModal(false);
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    document.title = 'Point of Sale Tills | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.props.store.TillStore.rehydrateTills();
  }

  disableTill(id) {
    this.props.store.TillStore.disableTill(id)
      .then(() => {
        this.props.store.TillStore.rehydrateTills();
        this.props.store.MainStore.burntToast("Till has been disabled");
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      })
  }

  showArchiveTillModal(till) {
    this.setShowConfirmationModal(true);
    this.setTillToArchive(till);
  }

  archiveTill = () => {
    this.props.store.TillStore.archiveTill(this.tillToArchive.id)
      .then(() => {
        this.props.store.TillStore.rehydrateTills();
        this.props.store.MainStore.toast(this.tillToArchive.name + " has been archived");
        this.cancelArchive();
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      })
  }

  enableTill(id) {
    this.props.store.TillStore.enableTill(id)
      .then(() => {
        this.props.store.TillStore.rehydrateTills();
        this.props.store.MainStore.toast("Till has been enabled");
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      })
  }

  addNewTill(e) {
    e.preventDefault()
    this.setAddingNewTill(true);

    const elm = this.form.current.elements;
    let toAdd = {
      name: elm.tillName.value,
      description: elm.tillDescription.value,
    }

    this.props.store.TillStore.addNewTill(toAdd)
      .then(() => {
        this.props.store.TillStore.rehydrateTills();
        this.setAddNewTillModalVisible(false);
        this.setAddingNewTill(false);
      }).catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.pos ?
          <React.Fragment>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            {this.props.store.TillStore.tills ?
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon red"><i className="fas fa-users"></i></span>
                    <span className="header-text">Tills / Registers</span>
                    {/* <a className="button is-primary rightAlign" onClick={() => this.props.store.TillStore.rehydrateTills()}><i className="fas fa-sync-alt"></i>Refresh</a> */}
                    <a className="button is-primary rightAlign" onClick={() => this.setAddNewTillModalVisible(true)}>Add new Till / Register</a>
                  </h2>
                </div>
                <div>
                  <div className="advanced_list tiled events">
                    <p className="tillInfo">Feel free to add as many tills as required for your stores needs. Each till becomes usable with our POS system and has its own sales ledger.</p>
                    <div className="row">
                      {this.props.store.TillStore.tills.map((till, i) => {
                        return (
                          <div className="col-lg-4 col-md-6 col-sm-12 has_avatar" key={i}>
                            <div className={till.active ? "panel panel-default tillHeight" : "panel panel-default tillHeight notActive"}>
                              <div className="topBlock">
                                <div className="tillImage">
                                  <i className="far fa-cash-register"></i>
                                </div>
                                <div className="titleDiv">
                                  <span className="eventTitle">{till.name}</span>
                                  {till.active ? null :
                                    <React.Fragment>
                                      <p className="currentlyDisabled"> - Disabled</p>
                                    </React.Fragment>
                                  }
                                </div>
                              </div>
                              <hr />
                              <div className="panel-body">
                                <div className="eventInfo">
                                  <div className="types">
                                    <em>Till Description:</em>{till.description}
                                  </div>
                                  <div className="types">
                                    <em>Till ID:</em>{till.id}
                                  </div>
                                </div>
                                <div className="editInfo">
                                  <span className="edit">
                                    {till.active ?
                                      <a className="userActions" data-balloon-length="medium" data-balloon="Click to disable" data-balloon-pos="up" onClick={() => this.disableTill(till.id)}><i className="fas fa-eye pause"></i>Till is enabled</a>
                                      :
                                      <a className="userActions" data-balloon-length="medium" data-balloon="Click to enable" data-balloon-pos="up" onClick={() => this.enableTill(till.id)}><i className="fas fa-eye-slash green"></i>Till is disabled</a>
                                    }
                                  </span>
                                  <span className="deleteEvent">
                                    <React.Fragment>
                                      <a data-balloon-length="large" data-balloon="Permanently remove this till" data-balloon-pos="up" onClick={() => this.showArchiveTillModal(till)}>Archive <i className="fas fa-archive"></i></a>
                                    </React.Fragment>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                {this.showArchiveTillModal && this.tillToArchive ?
                  <ConfirmationModal
                    title="Archive till?"
                    text={"Are you sure you want to archive till: " + this.tillToArchive.name}
                    cancelAction={this.cancelArchive}
                    confirmAction={this.archiveTill} />
                  :
                  null
                }
                {this.addNewTillModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <form ref={this.form} onSubmit={e => this.addNewTill(e)} noValidate>
                        <header className="modal-card-head">
                          <p className="modal-card-title">Add a new Till / Register</p>
                          <button className="delete" aria-label="close" onClick={() => this.setAddNewTillModalVisible(false)}></button>
                        </header>
                        <section className="modal-card-body">
                          <div className="field">
                            <label className="label">Till / Register name</label>
                            <div className="control">
                              <input id="tillName" className="input" type="text" required placeholder="Register 1" />
                            </div>
                          </div>
                          <div className="field">
                            <label className="label">Till / Register description</label>
                            <div className="control">
                              <textarea id="tillDescription" className="input" placeholder="My awesome front of house register"></textarea>
                            </div>
                          </div>
                        </section>
                        <footer className="modal-card-foot">
                          <button className={`button half is-info ${this.addingNewTill ? 'is-loading disabled' : ''}`}>Add</button>
                          <button type="button" className="button half is-text" onClick={() => this.setAddNewTillModalVisible(false)}>Cancel</button>
                        </footer>
                      </form>
                    </div>
                  </div>
                  :
                  null
                }
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
