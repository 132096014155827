import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, action } from "mobx";
import { ConfirmationModal } from 'components'

@inject("store")
@observer
class BulkUpdateModal extends Component {

  @observable isLoading;
  @action setIsLoading(loading) {
    this.isLoading = loading;
  }

  @observable showConfirmationModal = false;
  @action setShowConfirmationModal(bool) {
    this.showConfirmationModal = bool;
  }

  @observable requestFinished;
  @action setRequestFinished(requestFinished) {
    this.setRequestFinished = requestFinished;
  }

  @observable successMessage;
  @action setSuccessMessage(successMessage) {
    this.successMessage = successMessage;
  }

  @observable updatePriceOverride;
  @action setUpdatePriceOverride(bool) {
    this.updatePriceOverride = bool;
  }

  @observable updateCashBuyPrice;
  @action setUpdateCashBuyPrice(bool) {
    this.updateCashBuyPrice = bool;
  }

  @observable updateCashBuyPercentage;
  @action setUpdateCashBuyPercentage(bool) {
    this.updateCashBuyPercentage = bool;
  }

  @observable updateCreditBuyPrice;
  @action setUpdateCreditBuyPrice(bool) {
    this.updateCreditBuyPrice = bool;
  }

  @observable updateCreditBuyPercentage;
  @action setUpdateCreditBuyPercentage(bool) {
    this.updateCreditBuyPercentage = bool;
  }

  @observable updateBuyLimit;
  @action setUpdateBuyLimit(bool) {
    this.updateBuyLimit = bool;
  }

  @observable updateStock;
  @action setUpdateStock(bool) {
    this.updateStock = bool;
  }

  @observable updateMaxInstockBuyPrice;
  @action setUpdateMaxInstockBuyPrice(bool) {
    this.updateMaxInstockBuyPrice = bool;
  }

  @observable updateMaxInstockBuyPercentage;
  @action setUpdateMaxInstockBuyPercentage(bool) {
    this.updateMaxInstockBuyPercentage = bool;
  }

  @observable updateMaxInstockCreditBuyPrice;
  @action setUpdateMaxInstockCreditBuyPrice(bool) {
    this.updateMaxInstockCreditBuyPrice = bool;
  }

  @observable updateMaxInstockCreditBuyPercentage;
  @action setUpdateMaxInstockCreditBuyPercentage(bool) {
    this.updateMaxInstockCreditBuyPercentage = bool;
  }

  @observable bulkInventoryUpdate;
  @action setBulkInventoryUpdate(bulkInventoryUpdate) {
    this.bulkInventoryUpdate = bulkInventoryUpdate;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  buildFieldsUpdate(fieldsUpdated) {
    var fieldsUpdatedString = "";
    if (fieldsUpdated.stock || fieldsUpdated.stock == 0) {
      fieldsUpdatedString += (this.capitalizeFirstLetter(this.updateType.current.options[this.updateType.current.selectedIndex].value))
      fieldsUpdatedString += " Stock: " + fieldsUpdated.stock + ", ";
    }
    if (fieldsUpdated.priceOverride || fieldsUpdated.priceOverride == 0) {
      fieldsUpdatedString += "Price update: " + this.props.store.MainStore.currencyBuilder(parseFloat(fieldsUpdated.priceOverride)) + ", ";
    }
    if (fieldsUpdated.cashBuyPrice || fieldsUpdated.cashBuyPrice == 0) {
      fieldsUpdatedString += "Cash buy price: " + this.props.store.MainStore.currencyBuilder(parseFloat(fieldsUpdated.cashBuyPrice)) + ", ";
    }
    if (fieldsUpdated.cashBuyPercentage || fieldsUpdated.cashBuyPercentage == 0) {
      fieldsUpdatedString += "Cash buy percent: " + fieldsUpdated.cashBuyPercentage + "%, ";
    }
    if (fieldsUpdated.creditBuyPrice || fieldsUpdated.creditBuyPrice == 0) {
      fieldsUpdatedString += "Credit buy price: " + this.props.store.MainStore.currencyBuilder(parseFloat(fieldsUpdated.creditBuyPrice)) + ", ";
    }
    if (fieldsUpdated.creditBuyPercentage || fieldsUpdated.creditBuyPercentage == 0) {
      fieldsUpdatedString += "Credit buy percent: " + fieldsUpdated.creditBuyPercentage + "%, ";
    }
    if (fieldsUpdated.buyLimit || fieldsUpdated.buyLimit == 0) {
      fieldsUpdatedString += "Buy limit: " + fieldsUpdated.buyLimit + ", ";
    }
    if (fieldsUpdated.maxInstockBuyPrice || fieldsUpdated.maxInstockBuyPrice == 0) {
      fieldsUpdatedString += "Over-limit Cash buy price: " + this.props.store.MainStore.currencyBuilder(parseFloat(fieldsUpdated.maxInstockBuyPrice)) + ", ";
    }
    if (fieldsUpdated.maxInstockBuyPercentage || fieldsUpdated.maxInstockBuyPercentage == 0) {
      fieldsUpdatedString += "Over-limit Cash buy percent: " + fieldsUpdated.maxInstockBuyPercentage + "%, ";
    }
    if (fieldsUpdated.maxInstockCreditBuyPrice || fieldsUpdated.maxInstockCreditBuyPrice == 0) {
      fieldsUpdatedString += "Over-limit Credit buy price: " + this.props.store.MainStore.currencyBuilder(parseFloat(fieldsUpdated.maxInstockCreditBuyPrice)) + ", ";
    }
    if (fieldsUpdated.maxInstockCreditBuyPercentage || fieldsUpdated.maxInstockCreditBuyPercentage == 0) {
      fieldsUpdatedString += "Over-limit Credit buy percent: " + fieldsUpdated.maxInstockCreditBuyPercentage + "%, ";
    }
    return fieldsUpdatedString.substring(0, fieldsUpdatedString.length - 2);
  }

  priceOverride() {
    this.setUpdatePriceOverride(this.form.current.elements.priceOverrideCheckbox.checked);
  }

  stockUpdate() {
    this.setUpdateStock(this.form.current.elements.stockUpdateCheckbox.checked);
  }

  cashBuyPrice() {
    this.setUpdateCashBuyPrice(this.form.current.elements.updateCashBuyPriceCheckbox.checked);
  }

  cashBuyPercentage() {
    this.setUpdateCashBuyPercentage(this.form.current.elements.updateCashBuyPercentageCheckbox.checked);
  }

  creditBuyPrice() {
    this.setUpdateCreditBuyPrice(this.form.current.elements.updateCreditBuyPriceCheckbox.checked);
  }

  creditBuyPercentage() {
    this.setUpdateCreditBuyPercentage(this.form.current.elements.updateCreditBuyPercentageCheckbox.checked);
  }

  buyLimit() {
    this.setUpdateBuyLimit(this.form.current.elements.updateBuyLimitCheckbox.checked);
  }

  overstockedCashBuyPrice() {
    this.setUpdateMaxInstockBuyPrice(this.form.current.elements.updateOverstockedCashBuyPriceCheckbox.checked);
  }

  overstockedCashBuyPercentage() {
    this.setUpdateMaxInstockBuyPercentage(this.form.current.elements.updateOverstockedCashBuyPercentageCheckbox.checked);
  }

  overstockedCreditBuyPrice() {
    this.setUpdateMaxInstockCreditBuyPrice(this.form.current.elements.updateOverstockedCreditBuyPriceCheckbox.checked);
  }

  overstockedCreditBuyPercentage() {
    this.setUpdateMaxInstockCreditBuyPercentage(this.form.current.elements.updateOverstockedCreditBuyPercentageCheckbox.checked);
  }


  cancel = () => {
    this.props.cancelAction();
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.updateType = React.createRef();
  }

  submitBulkUpdate() {
    this.setIsLoading(true);

    var fieldToUpdate = {};
    if (this.updatePriceOverride) {
      fieldToUpdate.updatePriceOverride = this.updatePriceOverride;
      fieldToUpdate.priceOverride = this.form.current.elements.priceOverride.value;
    }
    if (this.updateStock) {
      fieldToUpdate.stockUpdateType = this.updateType.current.options[this.updateType.current.selectedIndex].value;
      fieldToUpdate.updateStock = this.updateStock;
      fieldToUpdate.stock = this.form.current.elements.stockUpdate.value;
    }
    if (this.updateCashBuyPrice) {
      fieldToUpdate.updateCashBuyPrice = this.updateCashBuyPrice;
      fieldToUpdate.cashBuyPrice = this.form.current.elements.cashBuyPrice.value;
    }
    if (this.updateCashBuyPercentage) {
      fieldToUpdate.updateCashBuyPercentage = this.updateCashBuyPercentage;
      fieldToUpdate.cashBuyPercentage = this.form.current.elements.cashBuyPercentage.value;
    }
    if (this.updateCreditBuyPrice) {
      fieldToUpdate.updateCreditBuyPrice = this.updateCreditBuyPrice;
      fieldToUpdate.creditBuyPrice = this.form.current.elements.creditBuyPrice.value;
    }
    if (this.updateCreditBuyPercentage) {
      fieldToUpdate.updateCreditBuyPercentage = this.updateCreditBuyPercentage;
      fieldToUpdate.creditBuyPercentage = this.form.current.elements.creditBuyPercentage.value;
    }
    if (this.updateBuyLimit) {
      fieldToUpdate.updateBuyLimit = this.updateBuyLimit;
      fieldToUpdate.buyLimit = this.form.current.elements.buyLimit.value;
    }
    if (this.updateMaxInstockBuyPrice) {
      fieldToUpdate.updateMaxInstockBuyPrice = this.updateMaxInstockBuyPrice;
      fieldToUpdate.maxInstockBuyPrice = this.form.current.elements.overstockedCashBuyPrice.value;
    }
    if (this.updateMaxInstockBuyPercentage) {
      fieldToUpdate.updateMaxInstockBuyPercentage = this.updateMaxInstockBuyPercentage;
      fieldToUpdate.maxInstockBuyPercentage = this.form.current.elements.overstockedCashBuyPercentage.value;
    }
    if (this.updateMaxInstockCreditBuyPrice) {
      fieldToUpdate.updateMaxInstockCreditBuyPrice = this.updateMaxInstockCreditBuyPrice;
      fieldToUpdate.maxInstockCreditBuyPrice = this.form.current.elements.overstockedCreditBuyPrice.value;
    }
    if (this.updateMaxInstockCreditBuyPercentage) {
      fieldToUpdate.updateMaxInstockCreditBuyPercentage = this.updateMaxInstockCreditBuyPercentage;
      fieldToUpdate.maxInstockCreditBuyPercentage = this.form.current.elements.overstockedCreditBuyPercentage.value;
    }

    let bulkInventoryUpdate = {
      fieldsToUpdate: fieldToUpdate,
      searchToUpdate: this.props.search,
    };

    this.setBulkInventoryUpdate(bulkInventoryUpdate);
    this.setShowConfirmationModal(true);
  }

  pushBulkUpdate = () => {
    this.props.store.ProductsStore.bulkUpdate(this.bulkInventoryUpdate)
      .then((result) => {
        let info = {
          title: "Bulk update queued!",
          message: result.message,
        };
        this.props.store.MainStore.setInfo(info);
        this.cancel();
      }).catch(err => {
        this.cancel();
        this.props.store.MainStore.setError(err);
      })
  }

  cancelBulkUpdate = () => {
    this.setShowConfirmationModal(false);
  }

  buildHtmlForSearch() {
    return {
      __html: `<div>Are you sure you want to bulk update the following across ${this.props.productCount} products?</div>
      <br />
    <div>${this.buildFieldsUpdate(this.bulkInventoryUpdate.fieldsToUpdate)}</div>`
    };
  }

  render() {
    const fC = this.props.store.MainStore.currencyBuilder;
    return (
      <React.Fragment>
        {!this.showConfirmationModal ?


          <div className={"modal is-active batch"}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                {this.props.submitType == "bulk" ?
                  <p className="modal-card-title">Bulk update {this.props.productCount} records</p>
                  :
                  <p className="modal-card-title">Update {this.props.variants.length} variants</p>
                }
                {!this.isLoading ?
                  <React.Fragment>
                    {this.props.cancelAction ?
                      <button className="closeModal" aria-label="close" onClick={() => this.cancel()} ><i className='fal fa-times'></i></button>
                      :
                      null
                    }
                  </React.Fragment>
                  :
                  null
                }
              </header>
              <section className="modal-card-body">
                <form ref={this.form} onSubmit={() => { return false }} noValidate>
                  <div className="field">
                    Select the fields below that you would like to update and apply to {this.props.submitType == "bulk" ? (this.props.productCount + " products") : (this.props.variants.length + " variants")} found in this search
              </div>
                  <div className="field">
                    <label className="checkbox">
                      <input id="priceOverrideCheckbox" type="checkbox" onChange={this.priceOverride.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Price override
                </label>
                  </div>
                  {this.updatePriceOverride ?
                    <div className="field">
                      <label className="label">Set to</label>
                      <div className="control">
                        <input id="priceOverride" className="input" type="number" min="0.01" placeholder="E.g. 29.95" step=".01" />
                      </div>
                    </div>
                    :
                    null
                  }
                  <div className="field">
                    <label className="checkbox">
                      <input id="stockUpdateCheckbox" type="checkbox" onChange={this.stockUpdate.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Stock quantity
                </label>
                  </div>
                  {this.updateStock ?
                    <div className="input-group margin-bottom">
                      <div className="input-group-prepend">
                        <select ref={this.updateType} className="custom-select" defaultValue={this.props.getStockUpdateType()}>
                          <option value="set">Set to</option>
                          <option value="add">Add</option>
                          <option value="remove">Remove</option>
                        </select>
                      </div>
                      <input id="stockUpdate" className="form-control unset-modal-input" type="number" placeholder="E.g. 10" step="1" />
                    </div>
                    :
                    null
                  }
                  <div className="field">
                    <label className="checkbox">
                      <input id="updateCashBuyPriceCheckbox" type="checkbox" onChange={this.cashBuyPrice.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Fixed buy price / cash
                </label>
                  </div>
                  {this.updateCashBuyPrice ?
                    <div className="field">
                      <label className="label">Set to</label>
                      <div className="control">
                        <input id="cashBuyPrice" className="input" type="number" min="0.01" placeholder="E.g. 29.95" step=".01" />
                      </div>
                    </div>
                    :
                    null
                  }
                  <div className="field">
                    <label className="checkbox">
                      <input id="updateCashBuyPercentageCheckbox" type="checkbox" onChange={this.cashBuyPercentage.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Percentage buy price / cash
                </label>
                  </div>
                  {this.updateCashBuyPercentage ?
                    <div className="field">
                      <label className="label">Set to</label>
                      <div className="control">
                        <input id="cashBuyPercentage" className="input" type="number" min="0.01" placeholder="E.g. 30" step=".01" />
                      </div>
                    </div>
                    :
                    null
                  }
                  <div className="field">
                    <label className="checkbox">
                      <input id="updateCreditBuyPriceCheckbox" type="checkbox" onChange={this.creditBuyPrice.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Fixed buy price / credit
                </label>
                  </div>
                  {this.updateCreditBuyPrice ?
                    <div className="field">
                      <label className="label">Set to</label>
                      <div className="control">
                        <input id="creditBuyPrice" className="input" type="number" min="0.01" placeholder="E.g. 29.95" step=".01" />
                      </div>
                    </div>
                    :
                    null
                  }
                  <div className="field">
                    <label className="checkbox">
                      <input id="updateCreditBuyPercentageCheckbox" type="checkbox" onChange={this.creditBuyPercentage.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Percentage buy price / Credit
                </label>
                  </div>
                  {this.updateCreditBuyPercentage ?
                    <div className="field">
                      <label className="label">Set to</label>
                      <div className="control">
                        <input id="creditBuyPercentage" className="input" type="number" min="0.01" placeholder="E.g. 30" step=".01" />
                      </div>
                    </div>
                    :
                    null
                  }
                  <div className="field">
                    <label className="checkbox">
                      <input id="updateBuyLimitCheckbox" type="checkbox" onChange={this.buyLimit.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Update buy limit
                </label>
                  </div>
                  {this.updateBuyLimit ?
                    <div className="field">
                      <label className="label">Set to</label>
                      <div className="control">
                        <input id="buyLimit" className="input" type="number" placeholder="E.g. 8" step="1" />
                      </div>
                      <p>When the buy limit is reached, if the overstocked prices are setup, the prices will change accordingly or the buylist will stop purchasing them altogether. </p>

                    </div>
                    :
                    null
                  }
                  <div className="field">
                    <label className="checkbox">
                      <input id="updateOverstockedCashBuyPriceCheckbox" type="checkbox" onChange={this.overstockedCashBuyPrice.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Over-limit buy price / cash
                </label>
                  </div>
                  {this.updateMaxInstockBuyPrice ?
                    <div className="field">
                      <label className="label">Set to</label>
                      <div className="control">
                        <input id="overstockedCashBuyPrice" className="input" type="number" min="0.01" placeholder="E.g. 29.95" step=".01" />
                      </div>
                    </div>
                    :
                    null
                  }
                  <div className="field">
                    <label className="checkbox">
                      <input id="updateOverstockedCashBuyPercentageCheckbox" type="checkbox" onChange={this.overstockedCashBuyPercentage.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Over-limit % price / cash
                </label>
                  </div>
                  {this.updateMaxInstockBuyPercentage ?
                    <div className="field">
                      <label className="label">Set to</label>
                      <div className="control">
                        <input id="overstockedCashBuyPercentage" className="input" type="number" min="0.01" placeholder="E.g. 30" step=".01" />
                      </div>
                    </div>
                    :
                    null
                  }
                  <div className="field">
                    <label className="checkbox">
                      <input id="updateOverstockedCreditBuyPriceCheckbox" type="checkbox" onChange={this.overstockedCreditBuyPrice.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Over-limit buy price / credit
                </label>
                  </div>
                  {this.updateMaxInstockCreditBuyPrice ?
                    <div className="field">
                      <label className="label">Set to</label>
                      <div className="control">
                        <input id="overstockedCreditBuyPrice" className="input" type="number" min="0.01" placeholder="E.g. 29.95" step=".01" />
                      </div>
                    </div>
                    :
                    null
                  }
                  <div className="field">
                    <label className="checkbox">
                      <input id="updateOverstockedCreditBuyPercentageCheckbox" type="checkbox" onChange={this.overstockedCreditBuyPercentage.bind(this)} />
                      <span className="checkmark"></span>
                      &nbsp; Over-limit % price / credit
                </label>
                  </div>
                  {this.updateMaxInstockCreditBuyPercentage ?
                    <div className="field">
                      <label className="label">Set to</label>
                      <div className="control">
                        <input id="overstockedCreditBuyPercentage" className="input" type="number" min="0.01" placeholder="E.g. 30" step=".01" />
                      </div>
                    </div>
                    :
                    null
                  }
                </form>
              </section>
              <footer className="modal-card-foot">
                {this.requestFinished ?
                  <button type="button" className={"button is-info"} onClick={() => this.cancel()}>Close</button>
                  :
                  <React.Fragment>
                    {this.props.submitType == "bulk" ?
                      <button className={"button is-info" + (this.isLoading ? ' is-loading disabled' : '')} onClick={() => this.submitBulkUpdate()}>Push bulk update</button>
                      :
                      <button className={"button is-info" + (this.isLoading ? ' is-loading disabled' : '')} onClick={() => this.submitBatchUpdate()}>Push update</button>
                    }
                  </React.Fragment>
                }
              </footer>
            </div>
          </div>
          :
          <ConfirmationModal type="confirm" title={"Are you sure?"} isHtml={true} text={this.buildHtmlForSearch()} cancelAction={() => this.cancel()} confirmAction={this.pushBulkUpdate} />
        }
      </React.Fragment>
    );
  }
}

export default BulkUpdateModal;
