import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { UserMenuStructure } from 'menuStructures';
import { Loader, ConfirmationModal } from 'components'
import { Link } from "react-router-dom";

@inject('store')
@observer
export default class Users extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = 'Users | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(UserMenuStructure);
    this.props.store.UsersStore.rehydrateUsers();
  }

  enableUser(id) {
    this.props.store.UsersStore.enableUser(id);
    this.props.store.UsersStore.rehydrateUsers();
  }

  disableUser(id) {
    this.props.store.UsersStore.disableUser(id);
    this.props.store.UsersStore.rehydrateUsers();
  }

  @observable showPasswordResetConfirmation;
  @action setShowPassowrdResetConfirmation(showPasswordResetConfirmation) {
    this.showPasswordResetConfirmation = showPasswordResetConfirmation
  }

  @observable userToResetPassword;
  @action setUserToResetPassword(userToResetPassword) {
    this.userToResetPassword = userToResetPassword;
  }

  showResetPassword(user) {
    this.setUserToResetPassword(user);
    this.setShowPassowrdResetConfirmation(true);
  }

  cancelPasswordReset = () => {
    this.setUserToResetPassword(null);
    this.setShowPassowrdResetConfirmation(false);
  }

  sendPasswordReset = () => {
    this.props.store.UsersStore.sendPasswordReset(this.userToResetPassword.id);
    this.setUserToResetPassword(null);
    this.setShowPassowrdResetConfirmation(false);
    this.props.store.UsersStore.rehydrateUsers();
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.UsersStore.users ?
          <React.Fragment>
            <div className="app-header">
              <h2 className="title is-2">
                <span className="app-icon purple"><i className="fas fa-users"></i></span>
                <span className="header-text">Users</span>
              </h2>
            </div>
            <div className="customer_list">
              <table>
                <thead>
                  <tr>
                    <th colSpan="2" className="col1">Email</th>
                    <th className="col1">Name</th>
                    <th className="col2">Status</th>
                    <th className="col3"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.store.UsersStore.users.map((user, i) => {
                    return (
                      <tr className="list_item" key={i}>
                        <td className="initials"><div>{user.firstName && user.firstName.length > 0 ?
                          <React.Fragment>

                            {user.firstName.charAt(0)}
                            {user.lastName && user.lastName.length > 0 ?
                              <React.Fragment>
                                {user.lastName.charAt(0)}
                              </React.Fragment>
                              :
                              null
                            }
                          </React.Fragment>
                          :
                          <React.Fragment>
                            {user.email && user.email.length > 0 ?
                              <React.Fragment>
                                {user.email.charAt(0)}
                              </React.Fragment>
                              :
                              null
                            }
                          </React.Fragment>
                        }
                        </div>
                        </td>
                        <td className="name">{user.email}</td>
                        <td>{user.firstName} {user.lastName}</td>
                        <td>{user.enabled ? <div>Enabled</div> : <div>Disabled</div>}</td>
                        <td>
                          <Link to={"/users/update/" + user.id} className="userActions" data-balloon-length="medium" data-balloon="Edit user details." data-balloon-pos="up" ><i className="fal fa-user-edit"></i></Link>
                          {!user.me ?
                            <React.Fragment>
                              {user.enabled ? <a data-balloon-length="medium" data-balloon="Disable this user." data-balloon-pos="up" className="userActions" onClick={() => this.disableUser(user.id)}><i className="fal fa-lock-alt"></i></a> : <a className="userActions" data-balloon-length="medium" data-balloon="Enable this user." data-balloon-pos="up" onClick={() => this.enableUser(user.id)}><i className="fal fa-lock-open-alt"></i></a>}
                            </React.Fragment>
                            :
                            null
                          }
                          <a data-balloon-length="large" data-balloon="Send a password reset." data-balloon-pos="up" className="userActions" onClick={() => this.showResetPassword(user)}><i className="fal fa-paper-plane"></i></a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {this.showPasswordResetConfirmation ?
                <ConfirmationModal type="confirm" title="Send password reset?" text={"Do you want to send a password reset email to " + this.userToResetPassword.email} cancelAction={this.cancelPasswordReset} confirmAction={this.sendPasswordReset} />
                :
                null
              }
            </div>
          </React.Fragment>
          :
          <Loader />
        }
      </React.Fragment>
    );
  }
}
