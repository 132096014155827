import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { SettingsMenuStructure } from 'menuStructures';
import { Loader } from 'components'
import { ToastContainer } from "react-toastify";

@inject('store')
@observer
export default class TaxSettings extends React.Component {
    @observable updatingTax = false;
    @action setUpdatingTax(updatingTax) {
        this.updatingTax = updatingTax;
    }

    @observable taxRate;
    @action setTaxRate(taxRate) {
        this.taxRate = taxRate;
    }

    @observable taxIncluded;
    @action setTaxIncluded(taxIncluded) {
        this.taxIncluded = taxIncluded;
    }

    @observable taxNumber;
    @action setTaxNumber(taxNumber) {
        this.taxNumber = taxNumber;
    }

    @observable tradeInTax;
    @action setTradeInTax(tradeInTax) {
        this.tradeInTax = tradeInTax;
    }

    @observable taxWording;
    @action setTaxWording(taxWording) {
        this.taxWording = taxWording;
    }

    @observable savingSettings;
    @action setSavingSettings(savingSettings) {
        this.savingSettings = savingSettings;
    }

    constructor(props) {
        super(props);
        this.taxRateRef = React.createRef();
        this.taxIncludedRef = React.createRef();
        this.taxNumberRef = React.createRef();
        this.tradeInTaxRef = React.createRef();
        this.taxWordingRef = React.createRef();

    }

    componentDidMount() {
        document.title = 'Tax Settings | BinderPOS';
        this.props.store.MenuStore.setSideMenuToDisplay(SettingsMenuStructure);
        this.fetchTaxSettings();
    }

    fetchTaxSettings() {
        this.props.store.SettingsStore.fetchCustomerSettingForType('taxRate')
            .then(result => {
                this.setTaxRate(result);
            }).catch(error => {
                this.props.store.MainStore.setError(error);
            });

        this.props.store.SettingsStore.fetchCustomerSettingForType('taxIncluded')
            .then(result => {
                this.setTaxIncluded(result);
            }).catch(error => {
                this.props.store.MainStore.setError(error);
            });

        this.props.store.SettingsStore.fetchCustomerSettingForType('taxNumber')
            .then(result => {
                this.setTaxNumber(result);
            }).catch(error => {
                this.props.store.MainStore.setError(error);
            });

        this.props.store.SettingsStore.fetchCustomerSettingForType('tradeInTax')
            .then(result => {
                this.setTradeInTax(result);
            }).catch(error => {
                this.props.store.MainStore.setError(error);
            })

        this.props.store.SettingsStore.fetchCustomerSettingForType('taxWording')
            .then(result => {
                this.setTaxWording(result);
            }).catch(error => {
                this.props.store.MainStore.setError(error);
            })
    }

    saveTaxSettings() {
        this.setSavingSettings(true);
        console.log(this.taxRateRef);
        console.log(this.taxIncludedRef);
        let newTaxRate = this.taxRateRef.current.value;
        let newTaxIncluded = this.taxIncludedRef.current.checked;
        let newTaxNumber = this.taxNumberRef.current.value;
        let newTradeInTax = this.tradeInTaxRef.current.checked;
        let newTaxWording = this.taxWordingRef.current.value;

        var taxRateSettings = {
            settingName: "taxRate",
            settingValue: `${newTaxRate}`,
        }

        var taxIncludedSettings = {
            settingName: "taxIncluded",
            settingValue: `${newTaxIncluded}`
        }

        var taxNumberSettings = {
            settingName: "taxNumber",
            settingValue: `${newTaxNumber}`
        }

        var tradeInTaxSettings = {
            settingName: "tradeInTax",
            settingValue: `${newTradeInTax}`
        }

        var taxWordingSettings = {
            settingName: "taxWording",
            settingValue: `${newTaxWording}`
        }

        var promises = [];
        promises.push(this.props.store.SettingsStore.updateSetting(taxRateSettings));
        promises.push(this.props.store.SettingsStore.updateSetting(taxIncludedSettings));
        promises.push(this.props.store.SettingsStore.updateSetting(taxNumberSettings));
        promises.push(this.props.store.SettingsStore.updateSetting(tradeInTaxSettings));
        promises.push(this.props.store.SettingsStore.updateSetting(taxWordingSettings));

        Promise.all(promises)
            .then(() => {
                this.props.store.MainStore.toast("Tax settings updated!");
                this.setUpdatingTax(false);
                this.setSavingSettings(true);
                this.fetchTaxSettings();
            }).catch((error) => {
                this.props.store.MainStore.setError(error);
                this.setSavingSettings(true);
            })
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                {this.taxRate ?
                    <React.Fragment>
                        <div className="app-header">
                            <h2 className="title is-2">
                                <span className="app-icon purple"><i className="fas fa-users"></i></span>
                                <span className="header-text">Tax Settings</span>
                            </h2>
                        </div>
                        <div className="EventListing">
                            <div className="col-md-12 content">
                                {this.updatingTax ?
                                    <div style={{ paddingLeft: "20px", paddingTop: "10px", paddingBottom: "20px" }}>
                                        <button className="button settingsCancel is-link" onClick={() => this.setUpdatingTax(false)}>Cancel</button>
                                        <div>
                                            <div className="row">
                                                <div className="col-md-6 field">
                                                    <label className="label">Your tax rate</label>
                                                    <div className="control">
                                                        <input ref={this.taxRateRef} id="taxRate" className="input" type="number" min="0" required placeholder="For example 10%" step="0.01" defaultValue={this.taxRate && this.taxRate.settingValue ? this.taxRate.settingValue : ""} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 field">
                                                    <label className="label">Your tax number</label>
                                                    <div className="control">
                                                        <input ref={this.taxNumberRef} id="taxNumber" className="input" type="text" placeholder="For example 123-345-678" defaultValue={this.taxNumber && this.taxNumber.settingValue ? this.taxNumber.settingValue : ""} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 field">
                                                    <label className="checkbox">
                                                        <input ref={this.taxIncludedRef} id="taxIncluded" type="checkbox" defaultChecked={this.taxIncluded && this.taxIncluded.settingValue && this.taxIncluded.settingValue == "true" ? true : false} />
                                                        <span className="checkmark"></span> Prices include tax?
                                                    </label>
                                                </div>
                                                <div className="col-md-6 field">
                                                    <label className="checkbox">
                                                        <input ref={this.tradeInTaxRef} id="tradeInTax" type="checkbox" defaultChecked={this.tradeInTax && this.tradeInTax.settingValue && this.tradeInTax.settingValue == "true" ? true : false} />
                                                        <span className="checkmark"></span> Trade ins include tax?
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 field">
                                                    <label className="label">Tax Wording for Receipt</label>
                                                    <div className="control">
                                                        <input ref={this.taxWordingRef} id="taxWording" className="input" type="text" placeholder="For example GST" defaultValue={this.taxWording && this.taxWording.settingValue && this.taxWording.settingValue.length > 0 ? this.taxWording.settingValue : ""} />
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="button is-link" style={{ marginRight: "10px" }} onClick={() => this.saveTaxSettings()}>Save tax settings</button>

                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <button className="button settingsUpdate is-link" onClick={() => this.setUpdatingTax(true)}>Update settings</button>
                                        <div>
                                            <div className="row">
                                                <div className="col-md-6 field">
                                                    <label className="label">Your tax rate</label>
                                                    <div className="control">
                                                        <input disabled ref={this.taxRateRef} id="taxRate" className="input" type="text" min="0" placeholder="For example 10%" defaultValue={this.taxRate && this.taxRate.settingValue && this.taxRate.settingValue.length > 0 ? this.taxRate.settingValue : ""} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 field">
                                                    <label className="label">Your tax number</label>
                                                    <div className="control">
                                                        <input disabled ref={this.taxNumberRef} id="taxNumber" className="input" type="text" placeholder="For example 123-345-678" defaultValue={this.taxNumber && this.taxNumber.settingValue && this.taxNumber.settingValue.length > 0 ? this.taxNumber.settingValue : ""} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 field">
                                                    <label className="checkbox">
                                                        <input disabled ref={this.taxIncludedRef} id="taxIncluded" type="checkbox" defaultChecked={this.taxIncluded && this.taxIncluded.settingValue && this.taxIncluded.settingValue == "true" ? true : false} />
                                                        <span className="checkmark"></span> Prices include tax?
                                                    </label>
                                                </div>
                                                <div className="col-md-6 field">
                                                    <label className="checkbox">
                                                        <input disabled ref={this.tradeInTaxRef} id="tradeInTax" type="checkbox" defaultChecked={this.tradeInTax && this.tradeInTax.settingValue && this.tradeInTax.settingValue == "true" ? true : false} />
                                                        <span className="checkmark"></span> Trade ins include tax?
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 field">
                                                    <label className="label">Tax Wording for Receipt</label>
                                                    <div className="control">
                                                        <input disabled ref={this.taxWordingRef} id="taxWording" className="input" type="text" placeholder="For example GST" defaultValue={this.taxWording && this.taxWording.settingValue && this.taxWording.settingValue.length > 0 ? this.taxWording.settingValue : ""} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <Loader />
                }
            </React.Fragment>
        );
    }
}
