import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Loader, QueueLine } from 'components'
import { Link } from 'react-router-dom'

@inject('store')
@observer
export default class JobQueueList extends React.Component {

  componentDidMount() {
    this.props.store.MainStore.getCurrency();
    this.props.store.ProductsStore.setQueuedJobs(null);
    document.title = 'Queued jobs | BinderPOS';
    this.props.store.MainStore.getCurrency();
    this.props.store.ProductsStore.fetchQueuedJobs("bulkUpdate", 10, 0)
      .then((results) => {
        this.props.store.ProductsStore.setQueuedJobs(results);
        this.canProgress();
        this.canGoBack();
      })
  }

  @observable listOfQueues = [
    { title: "Bulk queue", type: "bulkUpdate", offset: 0, limit: 10 },
    { title: "Batch query queue", type: "batchQueryUpdate", offset: 0, limit: 15 },
    { title: "Price queue", type: "priceUpdate", offset: 0, limit: 25 },
    { title: "Stock queue", type: "stockUpdate", offset: 0, limit: 25 },
    { title: "Other queue", type: "variantUpdate", offset: 0, limit: 100 }
  ]
  @action setListOfQueues(list) {
    this.listOfQueues = list;
  }

  @observable selectedTab = "bulkUpdate";
  @action setSelectedTab(selectedTab) {
    this.selectedTab = selectedTab;
  }

  @observable canGoPrev = false;
  @action setCanGoPrev(bool) {
    this.canGoPrev = bool;
  }

  @observable canGoNext = false;
  @action setCanGoNext(bool) {
    this.canGoNext = bool;
  }

  updateSelectedType(type) {
    this.setSelectedTab(type);
    this.props.store.ProductsStore.setQueuedJobs(null);
    this.listOfQueues.forEach(item => {
      if (item.type == type) {
        this.props.store.ProductsStore.fetchQueuedJobs(item.type, item.limit, item.offset)
          .then((results) => {
            this.props.store.ProductsStore.setQueuedJobs(results);
            this.canProgress();
            this.canGoBack();
          })
      }
    });
  }

  canProgress() {
    this.setCanGoNext(false);
    this.listOfQueues.forEach(item => {
      if (item.type == this.selectedTab) {
        if (this.props.store.ProductsStore.queuedJobs.length >= item.limit) {
          this.setCanGoNext(true);
        }
      }
    });
  }

  canGoBack() {
    this.setCanGoPrev(false);
    this.listOfQueues.forEach(item => {
      if (item.type == this.selectedTab) {
        if (item.offset > 0) {
          console.log(item.offset);
          this.setCanGoPrev(true);
        }
      }
    });
  }

  @action
  getNextProducts() {
    this.props.store.ProductsStore.setQueuedJobs(null);
    this.listOfQueues.forEach(item => {
      if (item.type == this.selectedTab) {
        var theNewOffset = (item.offset + item.limit);
        this.props.store.ProductsStore.fetchQueuedJobs(item.type, item.limit, theNewOffset)
          .then((result) => {
            item.offset = theNewOffset;
            this.props.store.ProductsStore.setQueuedJobs(result);
            this.canProgress();
            this.canGoBack();
          })
          .catch(err => {
            this.props.store.MainStore.setError(err);
          })
      }
    })
  }

  @action
  getPrevProducts() {
    this.props.store.ProductsStore.setQueuedJobs(null);
    this.listOfQueues.forEach(item => {
      if (item.type == this.selectedTab) {
        var theNewOffset = (item.offset - item.limit);
        if (theNewOffset < 0) {
          theNewOffset = 0;
        }
        this.props.store.ProductsStore.fetchQueuedJobs(item.type, item.limit, theNewOffset)
          .then((result) => {
            item.offset = theNewOffset;
            this.props.store.ProductsStore.setQueuedJobs(result);
            this.canProgress();
            this.canGoBack();
          })
          .catch(err => {
            this.props.store.MainStore.setError(err);
          })
      }
    });
  }

  render() {
    const self = this;
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.inventoryManagement ?
          <React.Fragment>
            <div className="app-header">
              <h2 className="title is-2">
                <span className="app-icon purple"><i className="fal fa-inventory"></i></span>
                <span className="header-text">My Queued Jobs</span>
                <Link to="/products"><button className={`button rightAlign is-link`}>Back to Inventory Management</button></Link>
              </h2>
            </div>
            <div className="filterTabs">
              {this.listOfQueues.map((item, index) => {
                return (
                  <span key={index} className={(!self.selectedTab || self.selectedTab == item.type) ? "active" : ""} onClick={() => self.updateSelectedType(item.type)}>{item.title}</span>
                )
              })}
            </div>
            {this.props.store.ProductsStore.queuedJobs ?
              <div className="container-fluid resultsPanel padding-top">
                {this.props.store.ProductsStore.queuedJobs.map((queuedJob, index) => {
                  return (
                    <QueueLine key={index} jobQueueItem={queuedJob} />
                  )
                })}

              </div>
              :
              <Loader text="Loading products..." />
            }
            <div className="paging-nav">
              <button className="button is-info paging-nav-right-margin"
                disabled={!this.canGoPrev ? true : false}
                onClick={() => this.getPrevProducts()}>Prev</button>
              <button
                className="button is-info"
                disabled={!this.canGoNext ? true : false}
                onClick={() => this.getNextProducts()}>Next</button>
            </div>
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
