import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { LineItem } from "../../pos/ItemStore";
import { action, computed, observable } from "mobx";
import { TimeUtils } from 'utils'

@inject("posStore")
@observer
class GridItem extends Component {
  @action
  addItem = () => {
    this.setShowAdditionalInfoModal(false);
    if (this.props.store.buyMode && this.props.item.selectedBuyVariant > -1) {
      this.props.item.variants[this.props.item.selectedBuyVariant].quantity++;
    }
    if (!this.props.store.buyMode && this.props.item.selectedVariant > -1) {
      this.props.item.variants[this.props.item.selectedVariant].quantity--;
    }

    this.props.store.addToCart(
      new LineItem(
        this.props.item,
        this.props.store.buyMode,
        this.props.store.cashPrice,
        this.props.store.allTax
      )
    );
  };

  @observable showAdditionalInfoModal = false;
  @action setShowAdditionalInfoModal(showAdditionalInfoModal) {
    this.showAdditionalInfoModal = showAdditionalInfoModal;
  }

  @action addItemWithAdditionalInfo = (e) => {
    e.preventDefault()
    this.setShowAdditionalInfoModal(false);
    var lineItemValues = [];
    this.props.item.event.additionalInfo.forEach(additionalInfo => {
      console.log(JSON.stringify(additionalInfo));
      var additionalInfoItem = {
        name: additionalInfo.id + "",
        value: this.form.current.elements["additionalInfo" + additionalInfo.id].value
      }
      lineItemValues.push(additionalInfoItem);
    })

    console.log(lineItemValues);

    this.props.store.addToCart(
      new LineItem(
        this.props.item,
        this.props.store.buyMode,
        this.props.store.cashPrice,
        this.props.store.allTax,
        lineItemValues
      )
    );
  }

  showEventAdditionalInfo = () => {
    this.setShowAdditionalInfoModal(true);
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }


  @action
  updatePrice = event => {
    if (this.props.store.buyMode) {
      this.props.item.selectedBuyVariant = event.target.value;
    } else {
      this.props.item.selectedVariant = event.target.value;
    }
  };

  @action
  checkStock = () => {
    if (!this.props.store.stockLimit) {
      this.props.item.selectedBuyVariant = 0;
      this.props.item.selectedVariant = 0;
    }
  };

  @computed get totalInStock() {
    var total = 0;
    this.props.item.variants.forEach(variant => {
      total += variant.quantity;
    });
    return total;
  }
  @action
  getStockedProduct() {
    var revArr = this.props.item.variants.reverse();
    revArr.forEach((variant, index) => {
      if (variant.quantity > 0) {
        this.props.item.selectedVariant =
          this.props.item.variants.length - index - 1;
      }
    });
  }

  componentDidMount() {
    this.checkStock(0);
    this.getStockedProduct();
  }

  render() {
    const item = this.props.item;
    const store = this.props.store;
    const fC = this.props.posStore.fCurr;
    var selectedQuantity = store.buyMode
      ? item.variants[item.selectedBuyVariant] ?.quantity
        : item.variants[item.selectedVariant] ?.quantity;
    return (
      <React.Fragment>
        {this.showAdditionalInfoModal ?
          <div className="modal is-active additionalInfoModal">
            <div className="modal-background" />
            <div className="modal-card">

              <header className="modal-card-head">
                <p className="modal-card-title">Event additional information</p>
                <button className="delete" type="button" aria-label="close" onClick={this.addItem} />
              </header>
              <form className="additionalInfoForm"
                ref={this.form}
                onSubmit={e => this.addItemWithAdditionalInfo(e)}
                noValidate
              >
                <section className="modal-card-body">
                  <div className="field">
                    <p>Attach additional information to events, these will only be applied if a customer is selected.</p>
                  </div>
                  {item.event.additionalInfo.map((additionalInfo, index) => {
                    return (
                      <div className="field" key={index}>
                        <label>{additionalInfo.header}</label>
                        {additionalInfo.description && additionalInfo.description.length > 0 ?
                          <p>{additionalInfo.description}</p>
                          :
                          null
                        }
                        <input className="input" type="text" id={"additionalInfo" + additionalInfo.id} />
                      </div>
                    )
                  })}
                </section>
                <footer className="modal-card-foot">
                  <button className={"button is-info half"} type="submit">Add info</button>
                  <button className={"button is-text half"} onClick={this.addItem}>Skip</button>
                </footer>
              </form>
            </div>
          </div>
          :
          null
        }
        <div className={"cardpos " + (this.totalInStock >= 1 ? this.totalInStock == 1 ? "stockLowStock" : "stockAvailable" : "stockOutofStock")} key={item.id}>
          <span className="stock">Stock: {this.totalInStock} </span>
          <div className="cardpos-container">
            <img src={item.img} alt="" />
          </div>
          <React.Fragment>
            <div className="productDetails">
              <span className="title" title={item.title + ' ' + (item.event && item.event.date ? [TimeUtils.convertDateToHumanReadableNoTime(item.event.date)] : '')}>
                {item.title + ' ' + (item.event && item.event.date ? [TimeUtils.convertDateToHumanReadableNoTime(item.event.date)] : '')}
              </span>
              <span className="variant">
                <select value={this.props.store.buyMode ? item.selectedBuyVariant : item.selectedVariant} onChange={this.updatePrice} >
                  {item.variants.map((variant, index) => (
                    <option className={variant.quantity >= 0 ? variant.quantity == 0 ? "blackClass" : "greenClass" : "redClass"}
                      disabled={this.props.store.buyMode ? variant.quantity <= "0" && store.stockLimit ? true : null : variant.quantity <= "0" && store.stockLimit ? true : null}
                      value={index}
                      key={index} >
                      {variant.title + "\tx" + (this.props.store.buyMode ? variant.quantity : variant.quantity)}
                    </option>
                  ))}
                  <option key="-1">-</option>
                </select>
              </span>
            </div>
            {!this.props.store.buyMode ? (
              this.props.item.selectedVariant > -1 ? (
                <span className="price">
                  {this.props.store.currency + " "} {item.variants[item.selectedVariant] ?.price ?.toFixed(2)}
                </span>
              ) : (
                  <span className="price noStock">Out of Stock</span>
                )
            ) : this.props.item.selectedBuyVariant > -1 ? (
              <span className="price">
                {this.props.store.currency + " "}
                {this.props.store.cashPrice
                  ? item.variants[item.selectedBuyVariant] ?.cashBuyPrice ?.toFixed(
                    2
                  )
                    : item.variants[
                      item.selectedBuyVariant
                    ] ?.storeCreditBuyPrice ?.toFixed(2)}
              </span>
            ) : (
                  <span className="price">N/A</span>
                )}
            <span
              className="addToCart"
              style={
                store.cartLoading
                  ? { pointerEvents: "none", backgroundColor: "#606968" }
                  : {}
              }
              onClick={item.event && item.event.additionalInfo && item.event.additionalInfo.length > 0 ? this.showEventAdditionalInfo : this.addItem}
            >
              + Add
          </span>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

export default GridItem;
