import React, { Component } from "react";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class Loader extends Component {

  componentWillMount() {
    //this.props.store.MainStore.rehydrateRandomCards();
  }

  onErrorChangeImage(e, number) {
    console.log(e);
    e.target.src = "/dist/img/loader" + number + ".jpg";
  }

  render() {
    return (
      <div className="custom-loader">
        <div className="dice">
          <div className="face first-face">
            <img src={this.props.store.MainStore.randomCards && this.props.store.MainStore.randomCards.length > 0 ?
              this.props.store.MainStore.randomCards[0] : "/dist/img/loader1.jpg"} onError={(e) => this.onErrorChangeImage(e, 1)} className="card-img-loader first" />
          </div>
          <div className="face first-face">
            <img src={this.props.store.MainStore.randomCards && this.props.store.MainStore.randomCards.length > 0 ?
              this.props.store.MainStore.randomCards[1] : "/dist/img/loader2.jpg"} onError={(e) => this.onErrorChangeImage(e, 2)} className="card-img-loader second" />
          </div>
          <div className="face first-face">
            <img src={this.props.store.MainStore.randomCards && this.props.store.MainStore.randomCards.length > 0 ?
              this.props.store.MainStore.randomCards[2] : "/dist/img/loader3.jpg"} onError={(e) => this.onErrorChangeImage(e, 3)} className="card-img-loader third" />
          </div>
          <div className="face first-face">
            <img src={this.props.store.MainStore.randomCards && this.props.store.MainStore.randomCards.length > 0 ?
              this.props.store.MainStore.randomCards[3] : "/dist/img/loader4.jpg"} onError={(e) => this.onErrorChangeImage(e, 4)} className="card-img-loader fourth" />
          </div>
          <div className="face first-face">
            <img src={this.props.store.MainStore.randomCards && this.props.store.MainStore.randomCards.length > 0 ?
              this.props.store.MainStore.randomCards[4] : "/dist/img/loader5.jpg"} onError={(e) => this.onErrorChangeImage(e, 5)} className="card-img-loader fifth" />
          </div>
        </div>
        <p>{this.props.text && this.props.text.length > 0 ? this.props.text : "Loading... Please wait"}</p>
      </div>
    );
  }
}

export default Loader;
