import { observable, action, configure } from "mobx";
import firebase, { app } from "firebase/app";
import { toast } from "react-toastify";
import { runInThisContext } from "vm";
import { getFirebaseConfig } from "../utils/ConfigUtils"

configure({ enforceActions: "observed" });

const config = getFirebaseConfig();

firebase.initializeApp(config);

const project = window.location.hostname === "portal.binderpos.com" ||
  window.location.hostname === "betaportal.binderpos.com" ||
  window.location.hostname === "beta.binderpos.com" ||
  window.location.hostname === "kube.binderpos.com" ||
  window.location.hostname == "localhost" ||
  window.location.hostname == "binderpos.au.ngrok.io" ||
  window.location.hostname === "devportal.binderpos.com" ||
  window.location.hostname === "app.binderpos.com" ||
  window.location.hostname === "appbeta.binderpos.com" ||
  window.location.hostname === "legacy.binderpos.com"
  ? "hobby-pos"
  : "binderpos-infrastructure"

let currentToken;

/**
 * Wrapper around 'fetch' for GET/POST, schema validation and error handling.
 */
async function _fetch({ method, endpoint, payload }, isRetry) {
  if (!currentToken || currentToken.length == 0 || isRetry) {
    currentToken = await getUserToken();
  }

  try {
    let response = await fetch(endpoint, {
      credentials: "same-origin",
      method: method,
      body: payload ? JSON.stringify(payload) : null,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentToken
      })
    });

    if (!response.ok && response.status != 201) {
      if (response.status == 401 && !isRetry) {
        return _fetchWithFormData({ method, endpoint, payload }, true);
      } else {
        let error = await response.json();
        throw error;
      }
    }

    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

async function _fetchWithFile({ endpoint, payload }, isRetry) {
  if (!currentToken || currentToken.length == 0 || isRetry) {
    currentToken = await getUserToken();
  }

  try {
    let response = await fetch(endpoint, {
      credentials: "same-origin",
      method: "POST",
      body: payload,
      headers: new Headers({
        Authorization: "Bearer " + currentToken
      })
    });

    if (!response.ok && response.status != 201) {
      if (response.status == 401 && !isRetry) {
        return _fetchWithFile({ endpoint, payload }, true);
      } else {
        let error = await response.json();
        throw error;
      }
    }

    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

async function _fetchWithFormData({ method, endpoint, payload }, isRetry) {
  if (!currentToken || currentToken.length == 0 || isRetry) {
    currentToken = await getUserToken();
  }

  try {
    let response = await fetch(endpoint, {
      credentials: "same-origin",
      method: method,
      body: payload ? payload : null,
      enctype: "multipart/form-data",
      headers: new Headers({
        Authorization: "Bearer " + currentToken
      })
    });

    if (!response.ok && response.status != 201) {
      if (response.status == 401 && !isRetry) {
        return _fetchWithFormData({ method, endpoint, payload }, true);
      } else {
        let error = await response.json();
        throw error;
      }
    }

    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

async function getUserToken() {
  return Store.AuthStore.user.getIdToken(true);
}

class TillStore {
  @observable tills;
  @action setTills(tills) {
    this.tills = tills;
  }

  constructor(root) {
    this.root = root;
  }

  rehydrateTills() {
    this.setTills(null);
    this.fetchTills()
      .then(result => {
        this.setTills(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  async fetchTills() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/pos/tills?showDisabled=true`
    });
  }

  async addNewTill(till) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/pos/tills`,
      payload: till
    });
  }

  async disableTill(tillId) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/pos/tills/byId/${tillId}/disable`
    });
  }

  async archiveTill(tillId) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/pos/tills/byId/${tillId}/archive`
    });
  }

  async enableTill(tillId) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/pos/tills/byId/${tillId}/enable`
    });
  }
}

class ReportStore {
  constructor(root) {
    this.root = root;
  }

  async fetchTestData() {
    return await _fetch({ method: 'GET', endpoint: `https://us-central1-${project}.cloudfunctions.net/apiReports/getLedger` });
  }
}

class EventParticipantsStore {
  constructor(root) {
    this.root = root;
  }

  async fetchEventParticipants(eventId) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/events/${eventId}/participants`
    });
  }

  async fetchEventAdditionalInfoDetails(eventId) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/events/${eventId}/additionalInfo`
    });
  }
}

class EventsStore {
  @observable event;
  @observable events;
  @observable eventWithChildern;
  @observable eventGames;
  @observable eventTypes;
  @observable eventCategories;
  @observable failedEvents;
  @observable failedToCreateEvents;
  @observable failedToDeleteEvents;

  @action setEvents(events) {
    this.events = events;
  }
  @action setEventWithChildern(eventWithChildern) {
    this.eventWithChildern = eventWithChildern;
  }
  @action setEventCategories(eventCategories) {
    if (eventCategories) {
      this.eventGames = eventCategories.filter(category => {
        return category.categoryType == "game";
      });
      this.eventTypes = eventCategories.filter(category => {
        return category.categoryType == "eventType";
      });
    }
    this.eventCategories = eventCategories;
  }

  @action setFailedToCreateEvents(failedToCreateEvents) {
    this.failedToCreateEvents = failedToCreateEvents;
  }

  @action setFailedToDeleteEvents(failedToDeleteEvents) {
    this.failedToDeleteEvents = failedToDeleteEvents;
  }

  @action setFailedEvents(failedEvents) {
    this.failedEvents = failedEvents;
  }

  @action setEvent(event) {
    this.event = event;
  }

  constructor(root) {
    this.root = root;
  }

  rehydrateChildEvents(eventId) {
    this.setEventWithChildern(null);
    this.fetchEventInfo(eventId)
      .then(result => {
        this.setEventWithChildern(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  rehydrate() {
    this.setEvents(null);
    this.fetchEvents()
      .then(result => {
        this.setEvents(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  rehydrateFailedToCreate(offset, limit) {
    this.setFailedToCreateEvents(null);
    this.fetchFailedToCreateEvents(offset, limit)
      .then(result => {
        this.setFailedToCreateEvents(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  rehydrateFailedToDelete(offset, limit) {
    this.setFailedToDeleteEvents(null);
    this.fetchFailedToDeleteEvents(offset, limit)
      .then(result => {
        this.setFailedToDeleteEvents(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  async fetchEventCategories() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/events/categories`
    });
  }

  async fetchEvents() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/events`
    });
  }

  async fetchEventInfo(eventId) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/events/${eventId}`
    });
  }

  async fetchFailedToCreateEvents(offset, limit) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/events/failedToCreate?offset=${offset}&limit=${limit}`
    });
  }

  async repushFailedEvent(id) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/events/${id}/repush`
    });
  }

  async removeFailedEventFromList(id) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/events/${id}/failedToCreate/remove`
    });
  }

  async fetchFailedToDeleteEvents(offset, limit) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/events/failedToDelete?offset=${offset}&limit=${limit}`
    });
  }

  async removeFailedEventDeleteFromList(id) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/events/${id}/failedToDelete/remove`
    });
  }

  async retryFailedToDelete(id) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/events/${id}/retry/failedToDelete`
    });
  }

  async addEvent(event) {
    return await _fetchWithFormData({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/events/add`,
      payload: event
    });
  }

  async enableEvent(eventId) {
    return await _fetchWithFormData({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/events/${eventId}/enable`
    });
  }

  async disableEvent(eventId) {
    return await _fetchWithFormData({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/events/${eventId}/disable`
    });
  }

  async updateEvent(id, event) {
    return await _fetchWithFormData({
      method: "PUT",
      endpoint: `https://devportal.binderpos.com/api/events/${id}/update`,
      payload: event
    });
  }

  async deleteEvent(eventId) {
    return await _fetch({
      method: "DELETE",
      endpoint: `https://devportal.binderpos.com/api/events/${eventId}`
    });
  }
}

class SettingsStore {
  @observable currencySymbol;
  @action setCurrencySymbol(currencySymbol) {
    this.currencySymbol = currencySymbol;
  }

  @observable storeSettings;
  @action setStoreSettings(storeSettings) {
    this.storeSettings = storeSettings;
  }

  @observable settings;
  @action setSettings(settings) {
    this.settings = settings;
  }

  @observable customerSettings;
  @action setCustomerSettings(settings) {
    this.customerSettings = settings;
  }

  @observable customerTimezone;
  @action setCustomerTimezone(timezone) {
    this.customerTimezone = timezone;
  }

  @observable timezones;
  @action setTimezones(timezones) {
    this.timezones = timezones;
  }

  constructor(root) {
    this.root = root;
  }

  getTimezones() {
    this.fetchTimezones()
      .then((result) => {
        this.setTimezones(result);
      })
      .catch(err => {
        this.root.MainStore.setError(err);
      })
  }

  getCustomerTimezone() {
    this.fetchCustomerTimezone()
      .then((result) => {
        this.setCustomerTimezone(result.timezone);
      })
      .catch(err => {
        this.root.MainStore.setError(err);
      })
  }

  async fetchTimezones() {
    return await _fetch({ method: 'GET', endpoint: `https://devportal.binderpos.com/api/timezones` });
  }

  async fetchCustomerTimezone() {
    return await _fetch({ method: "GET", endpoint: `https://devportal.binderpos.com/api/settings/timezone` });
  }

  async updateCustomerTimezone(timezone) {
    return await _fetch({ method: 'POST', endpoint: `https://devportal.binderpos.com/api/settings/timezone`, payload: timezone });
  }

  async fetchStoreSettings() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/settings/store`
    });
  }

  async updateStoreSettings(storeSettingsToSave) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/settings/store`,
      payload: storeSettingsToSave
    });
  }

  async fetchSettings() {
    return await _fetch({ method: 'GET', endpoint: `https://us-central1-${project}.cloudfunctions.net/apiCustomers/settings` });
  }

  async fetchCustomerSettings() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/settings/forMe`
    });
  }

  async fetchCustomerSettingForType(type) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/settings/${type}/forMe`
    });
  }

  async updateSetting(setting) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/settings/save`,
      payload: setting
    });
  }

  async fetchCustomerCurrencySymbol() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/settings/currencySymbol`
    });
  }
}

/**
 * Products Store
 */
class ProductsStore {
  @observable productTypes;
  @action setProductTypes(productTypes) {
    this.productTypes = productTypes;
  }

  @observable tags;
  @action setTags(tags) {
    this.tags = tags;
  }

  @observable vendors;
  @action setVendors(vendors) {
    this.vendors = vendors;
  }

  @observable products;
  @action setProducts(products) {
    this.products = products;
  }

  @observable savedSearches;
  @action setSavedSearches(savedSearches) {
    this.savedSearches = savedSearches;
  }

  @observable queuedJobs;
  @action setQueuedJobs(queuedJobs) {
    this.queuedJobs = queuedJobs;
  }

  constructor(root) {
    this.root = root;
  }

  getSavedSearches() {
    this.fetchSavedSearches().then(result => {
      this.setSavedSearches(result);
    });
  }

  rehydrateSavedSearches() {
    this.setSavedSearches(null);
    this.getSavedSearches();
  }

  getQueuedJobs(type, limit, offset) {
    if (!type) {
      type == "bulkUpdate";
    }
    if (!limit) {
      limit = 20;
    }
    if (!offset) {
      offset = 0;
    }
    this.fetchQueuedJobs(type, limit, offset)
      .then(results => {
        this.setQueuedJobs(results);
        return Promise.resolve();
      })
      .catch(error => {
        this.root.MainStore.setError(error);
        return Promise.reject(error);
      });
  }

  getProductTypes() {
    this.fetchProductTypes()
      .then(results => {
        console.log("PRODUCT RESULT:", results);
        this.setProductTypes(results);
        return Promise.resolve();
      })
      .catch(error => {
        this.root.MainStore.setError(error);
        return Promise.reject(error);
      });
  }

  getTags() {
    this.fetchTags()
      .then(result => {
        this.setTags(result);
        return Promise.resolve();
      })
      .catch(error => {
        this.root.MainStore.setError(error);
        return Promise.reject(error);
      });
  }

  getVendors() {
    this.fetchVendors()
      .then(result => {
        this.setVendors(result);
        return Promise.resolve();
      })
      .catch(error => {
        this.root.MainStore.setError(error);
        return Promise.reject(error);
      });
  }

  buildGenericFilters() {
    let promises = [];
    promises.push(this.getProductTypes());
    promises.push(this.getTags());
    promises.push(this.getVendors());
    return Promise.all(promises);
  }

  async fetchProducts(offset, limit) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/products?offset=${offset}&limit=${limit}&includeBuyprice=true`
    });
  }

  async fetchProductTypes() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/products/productTypes`
    });
  }

  async fetchTags() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/products/tags`
    });
  }

  async fetchVendors() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/products/vendors`
    });
  }

  async advancedSearch(searchFilters) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/products/advancedSearch`,
      payload: searchFilters
    });
  }

  async updateVariant(variant) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/products/variant/update`,
      payload: variant
    });
  }

  async batchUpdateVariants(variants) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/products/batchUpdate`,
      payload: variants
    });
  }

  async bulkUpdate(bulkUpdateRequest) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/products/bulkUpdate`,
      payload: bulkUpdateRequest
    });
  }

  async saveSearch(searchToSave) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/products/advancedSearch/save`,
      payload: searchToSave
    });
  }

  async fetchSavedSearches() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/products/advancedSearch/saved`
    });
  }

  async deleteSavedSearchFilter(id) {
    return await _fetch({
      method: "DELETE",
      endpoint: `https://devportal.binderpos.com/api/products/advancedSearch/saved/${id}`
    });
  }

  async fetchQueuedJobs(type, limit, offset) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/products/queuedJobs?type=${type}&offset=${offset}&limit=${limit}`
    });
  }

  async fetchQueuedJobStatus(queuedJobId, type) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/products/queuedJobs/${queuedJobId}/type/${type}`
    });
  }
}

class CSVStore {
  constructor(root) {
    this.root = root;
  }

  async postCustomerCSV(file) {
    return await _fetchWithFile({
      endpoint: `/csv/upload/customers`,
      payload: file
    });
  }
}

class POSBuylistRulesStore {
  @observable posFailedToSyncList;
  @action setPOSFailedToSyncList(posFailedToSyncList) {
    this.posFailedToSyncList = posFailedToSyncList;
  }

  rehydratePOSFailedToSync(offset, limit) {
    this.setPOSFailedToSyncList(null);
    this.fetchPOSFailedToSyncProducts(offset, limit)
      .then(result => {
        this.setPOSFailedToSyncList(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  async fetchPOSFailedToSyncProducts(offset, limit) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/pos/failedProducts?offset=${offset}&limit=${limit}`
    });
  }

  async processPOSFailedProduct(buylistItemToProcess) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/pos/pushFailedProduct`,
      payload: buylistItemToProcess
    });
  }

  async removePOSFailedProduct(buylistItemToProcess) {
    return await _fetch({
      method: "DELETE",
      endpoint: `https://devportal.binderpos.com/api/pos/removeFailedProduct/byId/${buylistItemToProcess.id}`
    });
  }
}

/**
 * Buylist Rules Store
 */
class BuylistRulesStore {
  @observable pendingBuylists;
  @action setPendingBuylists(pendingBuylists) {
    this.pendingBuylists = pendingBuylists;
  }

  @observable approvedBuylists;
  @action setApprovedBuylists(approvedBuylists) {
    this.approvedBuylists = approvedBuylists;
  }

  @observable completedBuylists;
  @action setCompletedBuylists(completedBuylists) {
    this.completedBuylists = completedBuylists;
  }

  @observable buylistRule;
  @action setBuylistRule(buylistRule) {
    this.buylistRule = buylistRule;
  }

  @observable buylistRules;
  @action setBuylistRules(buylistRules) {
    this.buylistRules = buylistRules;
  }

  @observable buylistDetails;
  @action setBuylistDetails(buylistDetails) {
    this.buylistDetails = buylistDetails;
  }

  @observable failedToSyncList;
  @action setFailedToSyncList(failedToSyncList) {
    this.failedToSyncList = failedToSyncList;
  }

  rehydrate() {
    this.setBuylistRules(null);
    this.fetchBuylistRules()
      .then(result => {
        this.setBuylistRules(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  rehydratePendingBuylists(offset, limit, keyword) {
    if (!limit) {
      limit = 20;
    }
    if (!offset) {
      offset = 0;
    }
    this.setPendingBuylists(null);
    this.fetchBuylists("pending", offset, limit, keyword)
      .then(result => {
        this.setPendingBuylists(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  rehydrateApprovedBuylists(offset, limit, keyword) {
    if (!limit) {
      limit = 20;
    }
    if (!offset) {
      offset = 0;
    }
    this.setApprovedBuylists(null);
    this.fetchBuylists("approved", offset, limit, keyword)
      .then(result => {
        this.setApprovedBuylists(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  rehydrateCompletedBuylists(offset, limit, keyword) {
    if (!limit) {
      limit = 20;
    }
    if (!offset) {
      offset = 0;
    }
    this.setCompletedBuylists(null);
    this.fetchBuylists("completed", offset, limit, keyword)
      .then(result => {
        this.setCompletedBuylists(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  rehydrateFailedToSync(offset, limit) {
    this.setFailedToSyncList(null);
    this.fetchFailedToSyncProducts(offset, limit)
      .then(result => {
        this.setFailedToSyncList(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  hydrateBuylistDetails(id) {
    this.setBuylistDetails(null);
    this.fetchBuylistDetails(id)
      .then(result => {
        this.setBuylistDetails(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  constructor(root) {
    this.root = root;
  }

  async fetchBuylists(buylistType, offset, limit, keyword) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/buylist/${buylistType}?offset=${offset}&limit=${limit}` + (keyword ? "&keyword=" + keyword : "")

    });
  }

  async fetchBuylistDetails(buylistDetailsId) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/buylist/byId/${buylistDetailsId}/details`
    });
  }

  async fetchCompletedBuylists(offset, limit, keyword) {
    return await _fetch({
      method: "GET",
      endpoint:
        `https://devportal.binderpos.com/api/buylist/completed?offset=${offset}&limit=${limit}` +
        (keyword ? "&keyword=" + keyword : "")
    });
  }

  async fetchFailedToSyncProducts(offset, limit) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/buylist/failedProducts?offset=${offset}&limit=${limit}`
    });
  }

  async processFailedProduct(buylistItemToProcess) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/buylist/pushFailedProduct`,
      payload: buylistItemToProcess
    });
  }

  async removeFailedProduct(buylistItemToProcess) {
    return await _fetch({
      method: "DELETE",
      endpoint: `https://devportal.binderpos.com/api/buylist/removeFailedProduct/byId/${buylistItemToProcess.id}`
    });
  }

  async fetchBuylistRules() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/buylist/rule/list`
    });
  }

  async declineBuylist(buylist) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/buylist/decline`,
      payload: buylist
    });
  }

  async approveBuylist(buylist) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/buylist/approve`,
      payload: buylist
    });
  }

  async completeBuylist(buylist) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/buylist/complete`,
      payload: buylist
    });
  }

  async saveBuylist(buylist) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/buylist/save`,
      payload: buylist
    });
  }

  async fetchBuylistRule(id) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/buylist/rule/byId/${id}`
    });
  }

  async addBuylistRule(buylistRule) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/buylist/rule/add`,
      payload: buylistRule
    });
  }

  async updateBuylistRule(buylistRule) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/buylist/rule/update`,
      payload: buylistRule
    });
  }

  async deleteBuylistRule(buylistRuleId) {
    return await _fetch({
      method: "DELETE",
      endpoint: `https://devportal.binderpos.com/api/buylist/rule/byId/${buylistRuleId}`
    });
  }

  async deleteBuylistRules(buylistObj) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/buylist/rules/delete`,
      payload: buylistObj
    });
  }

  async fetchCardBuylistDetails(game, setName, keyword) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/buylist/cards?game=${game}&setName=${setName}&keyword=${keyword}`
    });
  }
}

class CardStore {
  constructor(root) {
    this.root = root;
  }

  @observable cardVariants;
  @action setCardVariants(cardVariants) {
    this.cardVariants = cardVariants;
  }

  @observable searchResults;
  @action setSearchResults(results) {
    this.searchResults = results;
  }

  @observable rarities;
  @action setRarities(results) {
    this.rarities = results;
  }

  @observable games;
  @action setGames(results) {
    this.games = results;
  }

  @observable sets;
  @action setSets(results) {
    this.sets = results;
  }

  @observable cardTypes;
  @action setCardTypes(cardTypes) {
    this.cardTypes = cardTypes;
  }

  @observable colors;
  @action setColors(colors) {
    this.colors = colors;
  }

  @observable monsterTypes;
  @action setMonsterTypes(monsterTypes) {
    this.monsterTypes = monsterTypes;
  }

  @observable cards;
  @action setCards(results) {
    this.cards = results;
  }

  buildGameTypeData(game) {
    var promises = [];
    promises.push(this.getRarities(game));
    promises.push(this.getCardTypes(game));
    promises.push(this.getCardColors(game));
    promises.push(this.getCardMonsterTypes(game));
    promises.push(this.getCardSets(game));
    promises.push(this.getCardVariants(game));
    return Promise.all(promises);
  }

  clearGameTypeData() {
    this.setRarities(null);
    this.setCardTypes(null);
    this.setColors(null);
    this.setMonsterTypes(null);
    this.setSets(null);
    this.setCardVariants(null);
  }

  getRarities(game) {
    this.fetchCardRarities(game)
      .then(result => {
        this.setRarities(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  getCardTypes(game) {
    this.fetchCardTypes(game)
      .then(result => {
        this.setCardTypes(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  getCardColors(game) {
    this.fetchCardColors(game)
      .then(result => {
        this.setColors(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  getCardMonsterTypes(game) {
    this.fetchCardMonsterTypes(game)
      .then(result => {
        this.setMonsterTypes(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  getCardSets(game) {
    this.fetchCardSetNames(game)
      .then(result => {
        this.setSets(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  getCardVariants(game) {
    this.fetchCardVariants(game)
      .then(result => {
        this.setCardVariants(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  async fetchCardSetNames(type, searchTerm) {
    return await _fetch({
      method: "GET",
      endpoint:
        `https://devportal.binderpos.com/api/cards/${type}/sets` +
        (searchTerm ? "?keyword=" + searchTerm : "")
    });
  }

  async fetchCardRarities(type) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/cards/${type}/rarities`
    });
  }

  async fetchCardGames() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/cards/games/supported`
    });
  }

  async fetchCardTypes(type) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/cards/${type}/types`
    });
  }

  async fetchCardColors(type) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/cards/${type}/colors`
    });
  }

  async fetchCardMonsterTypes(type) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/cards/${type}/monsterTypes`
    });
  }

  async fetchCardVariants(type) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/variants/byGame/${type}/types`
    });
  }

  async fetchCardsWithinSet(type, setName) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/cards/${type}/set/${setName}`
    });
  }
}

/**
 * Customers Store
 */
class CustomersStore {
  @observable customers;
  @action setCustomers(customers) {
    this.customers = customers;
  }

  @observable customer;
  @action setCustomer(customer) {
    this.customer = customer;
  }

  @observable customerVariants;
  @action setCustomerVariants(variants) {
    this.customerVariants = variants;
  }

  constructor(root) {
    this.root = root;
  }

  rehydrate(offset, limit) {
    if (!offset) {
      offset = 0;
    }
    if (!limit) {
      limit = 100;
    }
    this.setCustomers(null);
    this.fetchCustomers(offset, limit)
      .then(result => {
        this.setCustomers(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  async fetchCustomerById(id) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/customers/byId/${id}`
    });
  }

  async fetchCustomers(offset, limit, keyword) {
    return await _fetch({
      method: "GET",
      endpoint:
        `https://devportal.binderpos.com/api/customers?offset=${offset}&limit=${limit}` +
        (keyword ? "&keyword=" + keyword : "")
    });
  }

  async refreshCustomers(offset, limit) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/customers?offset=${offset}&limit=${limit}&refresh=true`
    });
  }

  async addCustomer(customerToAdd) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/customers/add`,
      payload: customerToAdd
    });
  }

  async updateCustomer(customerToUpdate) {
    return await _fetch({
      method: "PUT",
      endpoint: `https://devportal.binderpos.com/api/customers/update`,
      payload: customerToUpdate
    });
  }

  async getCustomerCreditHistory(customerId) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/customers/byId/${customerId}/creditHistory`
    });
  }

  async adjustCustomerCredit(toUpdate) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/customers/adjustStoreCredit`,
      payload: toUpdate
    });
  }

  async getCustomerVariants() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/variants`
    });
  }
}

class CartStore {
  constructor(root) {
    this.root = root;
  }

  @observable carts;
  @action setCarts(carts) {
    this.carts = carts;
  }

  @observable selectedCart;
  @action setSelectedCart(cart) {
    this.selectedCart = cart;
  }

  rehydrateAllCarts(offset, limit) {
    if (!offset) {
      offset = 0;
    }
    if (!limit) {
      limit = 50;
    }
    this.setCarts(null);
    this.fetchAllCarts(offset, limit)
      .then(result => {
        this.setCarts(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  rehydrateAllCartsForTill(tillId, offset, limit) {
    if (!offset) {
      offset = 0;
    }
    if (!limit) {
      limit = 50;
    }
    this.setCarts(null);
    this.fetchAllCartsForTill(tillId, offset, limit)
      .then(result => {
        this.setCarts(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  async fetchAllCarts(offset, limit, startDate, endDate) {
    if (startDate && endDate) {
      return await _fetch({
        method: "GET",
        endpoint: `https://devportal.binderpos.com/api/pos/carts/all?limit=${limit}&offset=${offset}&submitted=true&startDate=${startDate}&endDate=${endDate}`
      });
    }
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/pos/carts/all?limit=${limit}&offset=${offset}&submitted=true`
    });
  }

  async fetchAllCartsForTill(tillId, offset, limit, startDate, endDate) {
    if (startDate && endDate) {
      return await _fetch({
        method: "GET",
        endpoint: `https://devportal.binderpos.com/api/pos/carts/forTill/${tillId}?limit=${limit}&offset=${offset}&submitted=true&startDate=${startDate}&endDate=${endDate}`
      });
    }
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/pos/carts/forTill/${tillId}?limit=${limit}&offset=${offset}&submitted=true`
    });
  }

  async fetchCartById(id) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/pos/carts/byId/${id}`
    });
  }
}

class UsersStore {
  constructor(root) {
    this.root = root;
  }

  @observable users;
  @action setUsers(users) {
    this.users = users;
  }

  @observable user;
  @action setUser(user) {
    this.user = user;
  }

  fetchAndSetUser(id) {
    this.setUser(null);
    this.fetchUser(id)
      .then(result => {
        this.setUser(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  rehydrateUsers() {
    this.setUsers(null);
    this.fetchUsers()
      .then(result => {
        this.setUsers(result);
      })
      .catch(error => {
        this.root.MainStore.setError(error);
      });
  }

  async fetchUser(id) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/users/byId/${id}`
    });
  }

  async fetchUsers() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/users/list`
    });
  }

  async createUser(user) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/users/create`,
      payload: user
    });
  }

  async updateUser(user) {
    return await _fetch({
      method: "PUT",
      endpoint: `https://devportal.binderpos.com/api/users/update`,
      payload: user
    });
  }

  async disableUser(id) {
    return await _fetch({
      method: "PUT",
      endpoint: `https://devportal.binderpos.com/api/users/${id}/disable`
    });
  }

  async enableUser(id) {
    return await _fetch({
      method: "PUT",
      endpoint: `https://devportal.binderpos.com/api/users/${id}/enable`
    });
  }

  async sendPasswordReset(id) {
    return await _fetch({
      method: "POST",
      endpoint: `https://devportal.binderpos.com/api/users/${id}/passwordReset`
    });
  }
}

class AuthStore {
  @observable hasLoaded = false;
  @action setHasLoaded(hasLoaded) {
    this.hasLoaded = hasLoaded;
  }

  @observable user = null;
  @action setUser(user) {
    this.user = user;
  }

  @observable screenSettings;
  @action setScreenSettings(screenSettings) {
    this.screenSettings = screenSettings;
  }

  async fetchScreenSettings() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/screens/forMe`
    });
  }

  constructor(root) {
    this.root = root;
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setUser(user);
        if (window.fcWidget && window.fcWidget.user) {
          window.fcWidget.user.setEmail(user ? user.email : null);
        }
        this.root.SettingsStore.getCustomerTimezone();
        this.fetchScreenSettings()
          .then(result => {
            this.setScreenSettings(result);
          })
          .catch(err => {
            console.error("Error: ", err);
            this.setScreenSettings("No account");
          });
        this.root.SettingsStore.fetchSettings()
          .then(result => {
            if (result) {
              this.root.SettingsStore.setStoreSettings(result);
            }
          })
          .catch(error => {
            this.root.MainStore.setError(error);
          });
      }
      this.setHasLoaded(true);
    });
  }

  logUserOut() {
    this.setUser(null);
    Store.SettingsStore.setSettings(null);
    Store.EventsStore.setEvents(null);
  }
}

class MenuStore {
  @observable sideMenuToDisplay;
  @action setSideMenuToDisplay(sideMenuToSet) {
    this.sideMenuToDisplay = sideMenuToSet;
  }

  constructor(root) {
    this.root = root;
  }
}

class MainStore {
  @observable showSupportModal = false;
  @action setShowSupportModal(value) {
    this.showSupportModal = value;
  }

  @observable error;
  @action setError(error) {
    this.error = error;
  }

  @observable info;
  @action setInfo(info) {
    this.info = info;
  }

  @observable posSalesData;
  @action setPosSalesData(posSalesData) {
    this.posSalesData = posSalesData;
  }

  @observable randomCards;
  @action setRandomCards(randomCards) {
    this.randomCards = randomCards;
  }

  @observable appUpdate;
  @action setAppUpdate(appUpdate) {
    this.appUpdate = appUpdate;
  }

  @observable currency;
  @action setCurrency(currency) {
    this.currency = currency;
  }

  @observable storeInfo;
  @action setStoreInfo(storeInfo) {
    this.storeInfo = storeInfo;
  }

  @observable taxWording;
  @action setTaxWording(taxWording) {
    this.taxWording = taxWording;
  }

  @observable taxNumber;
  @action setTaxNumber(taxNumber) {
    this.taxNumber = taxNumber;
  }

  @observable taxRate;
  @action setTaxRate(taxRate) {
    this.taxRate = taxRate;
  }

  @observable storeInfo;
  @action setStoreInfo(storeInfo) {
    this.storeInfo = storeInfo;
  }

  constructor(root) {
    this.root = root;
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getAppUpdate() {
    this.fetchAppUpdate()
      .then(result => {
        this.setAppUpdate(result);
      })
      .catch(error => {
        this.setAppUpdate(null);
      });
  }

  rehydrateRandomCards() {
    let randomNumber = this.getRandomInt(0, 2);
    let type = "mtg";
    // if (randomNumber == 0) {
    //   type = "pokemon";
    // } else if (randomNumber == 1) {
    //   type = "yugioh";
    // }
    this.fetchRandomCards(type)
      .then(urls => {
        this.setRandomCards(urls);
      })
      .catch(error => {
        //Don't throw error here, roll back to saved cards
        this.setRandomCards([]);
      });
  }

  toast(msg) {
    toast.info(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }

  burntToast(msg) {
    toast.warn(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }

  buildReceiptData() {
    this.getStoreInfo();
    this.getTaxWording();
    this.getTaxNumber();
    this.getCurrency();
    this.getTaxRate();
  }

  getPosSalesData() {
    this.fetchPOSSaleData()
      .then(data => {
        this.setPosSalesData(data);
        return Promise.resolve(data);
      })
      .catch(error => {
        this.setError(error);
      });
  }

  getStoreInfo() {
    this.fetchStoreInfo()
      .then(storeInfo => {
        this.setStoreInfo(storeInfo);
      })
      .catch(error => {
        this.setError(error);
      });
  }

  getTaxWording() {
    this.fetchTaxWording()
      .then(taxWording => {
        this.setTaxWording(taxWording.settingValue);
      })
      .catch(error => {
        this.setError(error);
      });
  }

  getTaxNumber() {
    this.fetchTaxNumber()
      .then(taxNumber => {
        this.setTaxNumber(taxNumber.settingValue);
      })
      .catch(error => {
        this.setError(error);
      });
  }

  getCurrency() {
    this.fetchCurrency()
      .then(currencySymbol => {
        this.setCurrency(currencySymbol.settingValue);
      })
      .catch(error => {
        this.setError(error);
      });
  }

  getTaxRate() {
    this.fetchTaxRate()
      .then(taxRate => {
        this.setTaxRate(taxRate.settingValue);
      })
      .catch(error => {
        this.setError(error);
      });
  }

  currencyBuilder = amount => {
    if (amount) {
      if (amount >= 0) {
        return this.currency + amount.toFixed(2);
      } else {
        return "-" + this.currency + Math.abs(amount).toFixed(2);
      }
    }
    return amount;
  };

  async fetchRandomCards(type) {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/cards/${type}/img/random`
    });
  }

  async fetchAppUpdate() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/binderpos/latestUpdate`
    });
  }

  async fetchCurrency() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/settings/currencySymbol`
    });
  }

  async fetchStoreInfo() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/settings/store`
    });
  }

  async fetchTaxWording() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/settings/taxWording/forMe`
    });
  }

  async fetchTaxNumber() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/settings/taxNumber/forMe`
    });
  }

  async fetchTaxRate() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/settings/taxRate/forMe`
    });
  }

  async fetchPriceRuleSettings() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/settings/priceRulesForGames`
    });
  }

  async fetchDefaultPriceRuleSettings() {
    return await _fetch({
      method: "GET",
      endpoint: "https://devportal.binderpos.com/api/settings/priceRulesForGames/default"
    });
  }

  async updatePriceRuleSettings(priceRuleSettings) {
    return await _fetch({
      method: "POST",
      endpoint: "https://devportal.binderpos.com/api/settings/priceRulesForGames/update",
      payload: priceRuleSettings
    });
  }

  async fetchPOSSaleData() {
    return await _fetch({
      method: "GET",
      endpoint: `https://devportal.binderpos.com/api/reports/pos/sales`
    });
  }
}

/**
 * Root
 **/
class RootStore {
  constructor() {
    this.MainStore = new MainStore(this);
    this.AuthStore = new AuthStore(this);
    this.CustomersStore = new CustomersStore(this);
    this.ProductsStore = new ProductsStore(this);
    this.CSVStore = new CSVStore(this);
    this.EventsStore = new EventsStore(this);
    this.SettingsStore = new SettingsStore(this);
    this.EventParticipantsStore = new EventParticipantsStore(this);
    this.BuylistRulesStore = new BuylistRulesStore(this);
    this.POSBuylistRulesStore = new POSBuylistRulesStore(this);
    this.CardStore = new CardStore(this);
    this.TillStore = new TillStore(this);
    this.ReportStore = new ReportStore(this);
    this.MenuStore = new MenuStore(this);
    this.CartStore = new CartStore(this);
    this.UsersStore = new UsersStore(this);
  }

  clearState() {
    this.MainStore = new MainStore(this);
    this.AuthStore = new AuthStore(this);
    this.CustomersStore = new CustomersStore(this);
    this.ProductsStore = new ProductsStore(this);
    this.CSVStore = new CSVStore(this);
    this.EventsStore = new EventsStore(this);
    this.SettingsStore = new SettingsStore(this);
    this.EventParticipantsStore = new EventParticipantsStore(this);
    this.BuylistRulesStore = new BuylistRulesStore(this);
    this.POSBuylistRulesStore = new POSBuylistRulesStore(this);
    this.CardStore = new CardStore(this);
    this.TillStore = new TillStore(this);
    this.ReportStore = new ReportStore(this);
    this.MenuStore = new MenuStore(this);
    this.CartStore = new CartStore(this);
    this.UsersStore = new UsersStore(this);
  }
}

const Store = new RootStore();
export default Store;
