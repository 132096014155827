import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";

class SideMenu extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.sideMenu ? (
          <div className="sidemenuclass">
            <div>
              <h2
                className={
                  this.props.sideMenu.activeClass
                    ? this.props.sideMenu.activeClass
                    : ""
                }
              >
                {this.props.sideMenu.title}
              </h2>
            </div>
            <div>
              {this.props.sideMenu.links.map((link, index) => {
                return (
                  <div key={index}>
                    <NavLink
                      className={
                        this.props.sideMenu.activeClass
                          ? this.props.sideMenu.activeClass
                          : ""
                      }
                      activeClassName="active"
                      to={`/${link.href}`}
                    >
                      <span>{link.title}</span>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(SideMenu);
