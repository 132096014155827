import React, { Component } from "react";
import { SearchBar, ResultsGrid, Cart, Navbar, FloatModal, ReceiptModal, CartModal, POSErrorModal, CloseTillWarning } from 'components'
import { observable } from "mobx";
import { inject } from "mobx-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



@inject("store")
export default class Pos extends Component {
  @observable fetchingProducts = false;

  componentDidMount() {
    document.title = 'Point of Sale | BinderPOS';
    var mainElement = document.querySelector("main");
    mainElement.id = "binderpos-pos";

    var body = document.querySelector("body");
    body.className = "pos";
  }

  componentWillUnmount() {
    var mainElement = document.querySelector("main");
    mainElement.id = "binderpos-app";

    var body = document.querySelector("body");
    body.className = "";
  }

  toast(msg) {
    toast.info(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.pos ? (

          <React.Fragment>
            <div className="App">
              <Navbar />
              <div className="body">
                <div className="product-search">
                  <SearchBar
                    store={posStore}
                    updateFetchingProds={this.updateFetchingProds}
                  />
                  <ResultsGrid store={posStore} />
                </div>
                <Cart store={posStore} />
              </div>
            </div>
            <FloatModal />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <POSErrorModal />
            <ReceiptModal />
            <CloseTillWarning />
            <CartModal />
          </React.Fragment>
        ) : (
            <div>
              <p>Please contact BinderPOS to enable this screen.</p>
            </div>
          )}
      </React.Fragment>
    );
  }
}


