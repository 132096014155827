import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { EventMenuStructure } from 'menuStructures';
import { Loader } from 'components';

@inject('store')
@observer
export default class EventParticipants extends React.Component {

  @observable eventAdditionalInfo;
  @observable eventParticipants;

  @action setEventAdditionalInfo(eventAdditionalInfo) { this.eventAdditionalInfo = eventAdditionalInfo };
  @action setEventParticipants(eventParticipants) { this.eventParticipants = eventParticipants };

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentWillMount() {
    this.props.store.MenuStore.setSideMenuToDisplay(EventMenuStructure);
    const id = (this.props.match ?.params ?.eventId || null);
    console.log(id);
    if (id) {
      document.title = 'Event Participants | BinderPOS';
      this.props.store.EventParticipantsStore.fetchEventAdditionalInfoDetails(id)
        .then(result => {
          this.setEventAdditionalInfo(result);
        })
        .catch(error => {
          this.props.store.MainStore.setError(error);
        });
      this.props.store.EventParticipantsStore.fetchEventParticipants(id)
        .then(result => {
          this.setEventParticipants(result);
        })
        .catch(error => {
          this.props.store.MainStore.setError(error);
        });
    }
  }

  downloadCSV(csv, filename) {
    var csvFile = new Blob([csv], { type: "text/csv" })
    var downloadLink;

    // Download link
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  exporTableToCsv() {
    var csv = [];
    var rows = this.tableRef.current.rows;
    for (var i = 0; i < rows.length; i++) {
      var row = [], cols = rows[i].children;
      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }
      csv.push(row.join(","));
    }
    this.downloadCSV(csv.join("\n"), "eventParticipants.csv");
  }


  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.events ?
          <React.Fragment>
            <div className="app-header">
              <h2 className="title is-2">
                <span className="app-icon yellow"><i className="fas fa-calendar-alt"></i></span>
                <span className="header-text">Event Participants</span>
                <button className="button rightAlign is-primary" onClick={this.exporTableToCsv.bind(this)}>Download participant list</button>
              </h2>
            </div>
            <br />
            {this.eventParticipants && this.eventAdditionalInfo ?
              <React.Fragment>
                <div>
                  <br />
                  <table ref={this.tableRef} className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        {this.eventAdditionalInfo.map((additionalInfo, i) => {
                          return (
                            <th key={i}>{additionalInfo.header}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.eventParticipants.map((eventParticipant, i) => {
                        return (
                          <tr key={i}>
                            <td>{eventParticipant.participantName}</td>
                            <td>{eventParticipant.participantEmail}</td>
                            {eventParticipant.additionalInfo.map((info, j) => {
                              return (
                                <td key={j}>{info.value}</td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
