import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { action } from "mobx";
import LineDiscount from "./LineDiscount";

@inject("posStore")
@observer
class CartLine extends Component {
  constructor(props) {
    super(props);
    this.state = { discountModal: false };
  }
  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(
      ([key, val]) =>
        prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    );
    Object.entries(this.state).forEach(
      ([key, val]) =>
        prevState[key] !== val && console.log(`State '${key}' changed`)
    );
  }

  @action.bound
  updateQty(e) {
    this.props.item.qty = e.target.value;
    this.props.posStore.validateCartNoRefresh();
  }

  @action
  changeQty(increase) {
    clearTimeout(this.props.posStore.timer);
    if (increase) {
      this.props.item.qty = this.props.item.qty + 1;
    } else {
      if (this.props.item.qty - 1 <= 0) {
        this.deleteLine();
      } else {
        this.props.item.qty = this.props.item.qty - 1;
      }
    }
    let localTimer = setTimeout(() => {
      this.props.posStore.validateCartNoRefresh();
    }, 650);
    this.props.posStore.setTimer(localTimer);
  }

  @action
  deleteLine = () => {
    this.props.posStore.removeFromCart(this.props.item);
  };

  @action
  toggleTax = () => {
    if (this.props.item.taxDisabledUI === false) {
      this.props.item.taxDisabledUI = true;
    } else {
      this.props.item.taxDisabledUI = false;
    }
    this.props.posStore.validateCartNoRefresh();
  };

  openDiscountModal = () => {
    this.setState({ discountModal: !this.state.discountModal });

    //focus inputbox and select all
  };

  closeDiscountModal = () => {
    this.setState({ discountModal: false });
    this.props.posStore.validateCartNoRefresh();
  };

  @action
  updatePrice = e => {
    this.props.item.actualPrice = parseFloat(e.target.value);
    if (isNaN(this.props.item.actualPrice)) {
      this.props.item.actualPrice = 0.0;
    }
    if (this.props.item.buyItem && this.props.item.actualPrice > 0) {
      this.props.item.actualPrice = -1 * this.props.item.actualPrice;
    }
    console.log("updated");
    this.props.posStore.validateCartNoRefresh();
  };

  @action
  updateTotal = e => {
    this.props.item.actualPrice = e.target.value / this.props.item.qty;
    if (isNaN(this.props.item.actualPrice)) {
      this.props.item.actualPrice = 0.0;
    }
    if (this.props.item.buyItem && this.props.item.actualPrice > 0) {
      this.props.item.actualPrice = -1 * this.props.item.actualPrice;
    }
    console.log("updated");
    this.props.posStore.validateCartNoRefresh();
  };

  blurOnEnter = e => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  render() {
    console.log(this.props);
    var item = this.props.item;
    const fC = this.props.posStore.fCurr;
    const roundCents = this.props.posStore.roundCents;
    return (
      <React.Fragment>
        <div
          className={
            (!item.buyItem ? "cartItem buy" : "cartItem sell") +
            (this.props.posStore.disableLineItems ? " disable" : "") +
            (this.props.posStore.isDeletingCartItem ? " disable" : "")
          }
          item={item}
          key={Math.random()}
        >
          <i
            className="fal fa-trash-alt"
            onClick={this.deleteLine}
            style={
              this.props.posStore.cartLoading
                ? { pointerEvents: "none", color: "#606968 !important" }
                : {}
            }
          />
          <img
            className="img"
            src={item.imageUrl ? item.imageUrl : "/dist/img/noimage.png"}
          />
          <span className="itemDetails">{item.title}</span>
          <span className="price">
            <p>
              {item.variantTitle != "-" ? " - [" + item.variantTitle + "]" : ""}
              {!item.variantId ? "[Custom Item]" : ""}
            </p>
            {item.discountValue ? (
              <span className="ItemDiscount" onClick={this.openDiscountModal}>
                {item.discountType == "percentage"
                  ? item.discountAmount + "% "
                  : ""}
                {"Discount: " + fC(roundCents(item.discountValue))}
              </span>
            ) : (
              <span className="ItemDiscount" onClick={this.openDiscountModal}>
                {" "}
                No Discount
              </span>
            )}

            <input
              type="number"
              onBlur={this.updatePrice}
              onKeyPress={this.blurOnEnter}
              defaultValue={parseFloat(item.tax.displayPrice).toFixed(2)}
            />
            <a className="cartView">{item.tax.displayPrice}</a>
            <input
              className="lineTotal"
              type="number"
              onBlur={this.updateTotal}
              onChange={this.formatPrice}
              onKeyPress={this.blurOnEnter}
              defaultValue={item.displayTotal.toFixed(2)}
            />
            {!item.buyItem ? (
              <p onClick={this.toggleTax}>
                {item.taxDisabledShopify
                  ? "Tax Free"
                  : item.taxDisabledUI
                  ? "Tax Disabled"
                  : item.specialTax
                  ? item.specialTax + " Tax"
                  : "Standard Tax"}
                {}
              </p>
            ) : (
              ""
            )}

            <a className="cartView">{item.displayTotal}</a>
          </span>
          <span className="qty">
            <span
              className="minus"
              onClick={() => this.changeQty(false)}
              style={
                this.props.posStore.cartLoading
                  ? { pointerEvents: "none", backgroundColor: "#606968" }
                  : {}
              }
            >
              -
            </span>
            <input
              type="number"
              step="1"
              disabled={this.props.posStore.cartLoading}
              defaultValue={item.qty}
              onBlur={this.updateQty}
              onKeyPress={this.blurOnEnter}
              ref={input => {
                this.nameInput = input;
              }}
            />
            <span
              className="plus"
              onClick={() => this.changeQty(true)}
              style={
                this.props.posStore.cartLoading
                  ? { pointerEvents: "none", backgroundColor: "#606968" }
                  : {}
              }
            >
              +
            </span>
            <a className="cartView">{item.qty}</a>
          </span>
        </div>
        {this.state.discountModal ? (
          <LineDiscount item={item} close={this.closeDiscountModal} />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default CartLine;
