import React, { Component } from "react";
import { action } from "mobx";
import { inject, observer } from "mobx-react";

@inject("posStore")
@observer
class CartModal extends Component {
  @action closeModal = () => {
    this.props.posStore.cartModal = false;
  };
  render() {
    const store = this.props.posStore;
    var cartsWithItems = store.cartList.filter(cart => cart ?.cartItems.length > 0);
    return (
      <div className={store.cartModal ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.closeModal} />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Load a cart</p>
            <i onClick={this.closeModal} className="fal fa-times"></i>
          </header>
          <section className="modal-card-body">
            {cartsWithItems.length > 0 ?
              <table>
                <thead>
                  <tr>
                    <th className="iconImg"></th>
                    <th className="cartID">Cart Id</th>
                    <th className="customer">Customer</th>
                    <th className="lineItem">Line Items</th>
                    <th className="autosave"></th>
                  </tr>
                </thead>
                <tbody>
                  {cartsWithItems
                    .map(cart => (
                      <tr key={cart.id}
                        className={cart ?.savedCart ? "Saved" : ""}
                        onClick={e => store.getCartById(e, cart.id)}
                      >
                        <td><i className="fas fa-save"></i></td>
                        <td className="cartID">#{cart.id}</td>
                        <td> {cart ?.customer ?.firstName ? "" : "No Customer"}
                          {cart ?.customer ?.firstName} {cart ?.customer ?.lastName}</td>
                        <td>{cart.cartItems.length} Items</td>
                        <td>{cart ?.savedName}{" "}{cart ?.savedCart ? "" : "(Autosave)"}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              :
              <p className="noCart">
                {store.cartList.filter(cart => cart ?.cartItems.length == 0) ? "No saved carts" : ""}
              </p>

            }


          </section>
          <footer className="modal-card-foot">
            <button className={"button is-info"} onClick={this.closeModal}>
              Load cart
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default CartModal;
