import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, action } from "mobx";

@inject("store")
@observer
class ErrorModal extends Component {

  @observable showModal = true;
  @action setShowModal(value) {
    this.showModal = value;
  }

  clearErrorMessage = () => {
    this.props.store.MainStore.setError(null);
    this.setShowModal(false);
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      try {
        var value = JSON.parse(JSON.stringify(str));
        if (Object.entries(value).length === 0 && value.constructor === Object) {
          return false;
        }
      } catch (e) {
        return false;
      }
    }
    return true;
  }

  render() {
    console.log(this.props.store.MainStore.error);
    var isValidJson = this.IsJsonString(this.props.store.MainStore.error);
    console.log(isValidJson);
    return (
      <div className={this.showModal ? "modal is-active" : "modal"}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{this.props.store.MainStore.error && isValidJson ? this.props.store.MainStore.error.error : "Unknown error"}</p>
            <button className="delete" aria-label="close" onClick={this.clearError} />
          </header>
          <section className="modal-card-body">
            {this.props.store.MainStore.error && isValidJson ?
              <React.Fragment>
                <div className="field">{this.props.store.MainStore.error.error == "Not Found" ? "Unable to find the resource you are looking for." : this.props.store.MainStore.error.detailedMessage}</div>
                <div className="field">
                  {this.props.store.MainStore.error.traceId ? "Trace ID: " + this.props.store.MainStore.error.traceId : ""}
                </div>
              </React.Fragment>
              :
              <div className="field">
                An unknown error has occurred, please go back and try again. If the problem persists, contact BinderPOS support.
              </div>
            }
          </section>
          <footer className="modal-card-foot">
            <button className={"button is-info"} onClick={() => this.clearErrorMessage()}>
              OK
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default ErrorModal;
