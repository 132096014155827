import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { BuylistMenuStructure } from 'menuStructures';
import { Loader } from "components";

@inject('store')
@observer
export default class BuylistRuleList extends React.Component {

  @observable selectedBuylistRules = [];
  @action setSelectedBuylistRules(selectedBuylistRules) {
    this.selectedBuylistRules = selectedBuylistRules;
  }

  @observable buylistRule;
  @action setBuylistRule(buylistRule) {
    this.buylistRule = buylistRule;
  }

  @observable modalVisible = false;
  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable deleteModalVisible = false;
  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteSelectedModalVisible = false;
  @action setDeleteSelectedModalVisible(bool) {
    this.deleteSelectedModalVisible = bool;
  }

  @observable deleteModalBuylistRule;
  @action setDeleteModalBuylistRule(buylistRule) {
    this.deleteModalBuylistRule = buylistRule;
  }

  @observable deleteModalLoading;
  @action setDeleteModalLoading(loading) {
    this.deleteModalLoading = loading;
  }

  @observable deleteSelectedModalLoading;
  @action setDeleteSelectedModalLoading(loading) {
    this.deleteSelectedModalLoading = loading;
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = 'Buylist Rules | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);

    this.props.store.BuylistRulesStore.fetchBuylistRules()
      .then(result => {
        this.props.store.BuylistRulesStore.setBuylistRules(result)
      }).catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  setBuylistRuleInStore(buylistRule) {
    this.props.store.BuylistRulesStore.setBuylistRule(buylistRule);
  }

  @action
  pushToBuylistRulesList(id) {
    let newList = this.selectedBuylistRules.filter(item => item !== id);
    newList.push(id);
    this.setSelectedBuylistRules(newList);
  }

  @action
  removeFromBuylistRulesList(id) {
    let newList = this.selectedBuylistRules.filter(item => item !== id);
    this.setSelectedBuylistRules(newList);
  }

  handleOnChangeForCheckbox(index, buylistId) {
    var checkbox = document.querySelector("#additionalInfoRequired" + index);
    if (checkbox.checked) {
      this.pushToBuylistRulesList(buylistId);
    } else {
      this.removeFromBuylistRulesList(buylistId);
    }
  }

  showDeleteModalForSelectedBuylistRules() {
    this.setDeleteSelectedModalVisible(true);
  }

  deleteSelectedBuylistRules() {
    this.setDeleteSelectedModalLoading(true);
    var buylistsRulesToDelete = {
      buylistIds: this.selectedBuylistRules
    };
    this.props.store.BuylistRulesStore.deleteBuylistRules(buylistsRulesToDelete)
      .then(() => {
        this.setDeleteSelectedModalVisible(false);
        this.setDeleteSelectedModalLoading(false);
        this.setSelectedBuylistRules([]);
        this.props.store.BuylistRulesStore.rehydrate();
      }).catch(error => {
        this.setDeleteSelectedModalVisible(false);
        this.setDeleteSelectedModalLoading(false);
        this.props.store.MainStore.setError(error);
      })
  }

  showViewingModal(buylistRule) {
    this.setBuylistRule(buylistRule);
    this.setModalVisible(true);
  }

  deleteBuylistRule(buylistRuleId) {
    this.setDeleteModalLoading(true);
    this.props.store.BuylistRulesStore.deleteBuylistRule(buylistRuleId)
      .then(() => {
        this.setDeleteModalVisible(false);
        this.setDeleteModalBuylistRule(null);
        this.setDeleteModalLoading(false);
        this.props.store.BuylistRulesStore.rehydrate();
      }).catch(error => {
        this.setDeleteModalVisible(false);
        this.setDeleteModalBuylistRule(null);
        this.setDeleteModalLoading(false);
        this.props.store.MainStore.setError(error);
      })
  }

  showDeleteModalFor(buylistRule) {
    this.setDeleteModalVisible(true);
    this.setDeleteModalBuylistRule(buylistRule);
  }

  generateBuylistRuleDescription(buylistRule) {
    var ruleWording = "Equal to";
    if (buylistRule.priceRule == "lessThanOrEqualTo") {
      ruleWording = "Less than or Equal to";
    } else if (buylistRule.priceRule == "lessThan") {
      ruleWording = "Less than";
    } else if (buylistRule.priceRule == "greaterThanOrEqualTo") {
      ruleWording = "Greater than or Equal to";
    } else if (buylistRule.priceRule == "greaterThan") {
      ruleWording = "Greater than";
    }
    if (buylistRule.game && buylistRule.game != "all") {
      if (buylistRule.cardName) {
        if (buylistRule.price && buylistRule.price > 0) {
          return "Applied to card '" + buylistRule.cardName + "' for '" + buylistRule.gameName + "' with a price " + ruleWording + " $" + buylistRule.price;
        } else {
          return "Applied to card '" + buylistRule.cardName + "' for '" + buylistRule.gameName + "'";
        }
      } else if (buylistRule.setName) {
        if (buylistRule.price && buylistRule.price > 0) {
          return "Applied across cards within '" + buylistRule.setName + "' for '" + buylistRule.gameName + "' with a price " + ruleWording + " $" + buylistRule.price;
        } else {
          return "Applied across cards within '" + buylistRule.setName + "' for '" + buylistRule.gameName + "'";
        }
      } else {
        if (buylistRule.price && buylistRule.price > 0) {
          return "Applied to all '" + buylistRule.gameName + "' cards with a price " + ruleWording + " $" + buylistRule.price;
        } else {
          return "Applied to all '" + buylistRule.gameName + "' cards";
        }
      }
    }
    if (buylistRule.price && buylistRule.price > 0) {
      return "Applied across all cards with a price " + ruleWording + " $" + buylistRule.price;
    } else {
      return "Applied across all cards";
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.buylist ?
          <React.Fragment>
            {this.props.store.BuylistRulesStore.buylistRules ?
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon orange"><i className="fas fa-users"></i></span>
                    <span className="header-text">My Buylist Rules</span>
                    <Link to="/buylists/rules/add" className="button is-primary rightAlign" onClick={this.setBuylistRuleInStore.bind(this, null)}><i className="fas fa-plus-square"></i>New Rule</Link>
                  </h2>
                </div>
                <div className="customer_list">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="2" className="col1 ruleName">{this.selectedBuylistRules && this.selectedBuylistRules.length > 0 ? <span className="multipleSelect" onClick={() => this.showDeleteModalForSelectedBuylistRules()}>
                          <label className="checkbox inline">
                            <input type="checkbox" checked />
                            <span className="checkmark"></span>
                          </label>
                          {this.selectedBuylistRules.length} selected <i className="far fa-trash"></i></span> : null}</th>
                        <th>Description</th>
                        <th className="col2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.store.BuylistRulesStore.buylistRules.map((buylistRule, i) => {
                        return (
                          <tr className="list_item" key={i}>
                            <td><label className="checkbox">
                              <input id={"additionalInfoRequired" + i} type="checkbox" onChange={() => this.handleOnChangeForCheckbox(i, buylistRule.id)} />
                              <span className="checkmark"></span>
                            </label></td>
                            <td className="bold">{buylistRule.name}</td>
                            <td>{this.generateBuylistRuleDescription(buylistRule)}</td>
                            <td>
                              <a className="userActions" onClick={() => this.showViewingModal(buylistRule)}><i className="far fa-eye"></i></a>
                              <Link className="userActions" to={`/buylists/rules/update/${buylistRule.id}`}><i className="far fa-edit"></i></Link>
                              <a className="userActions" onClick={() => this.showDeleteModalFor(buylistRule)}><i className="far fa-trash"></i></a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                {this.modalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card modal-card-large">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{`Viewing: ${this.buylistRule.name}`}</p>
                        <button className="delete" aria-label="close" onClick={() => this.setModalVisible(false)}></button>
                      </header>
                      <section className="modal-card-body">
                        <h5 className="title1 is-5">Rule description</h5>
                        <p>{this.generateBuylistRuleDescription(this.buylistRule)}</p>
                        {this.buylistRule.buylistVariants.map((buylistVariant, i) => {
                          return (
                            <div key={i}>
                              <br />
                              <h5 className="title1 is-5">{buylistVariant.variantName}</h5>
                              <table className="table is-fullwidth condition buylist-rule-table-rarities">
                                <thead>
                                  <tr>
                                    <td style={{ 'textAlign': 'right', 'width': '264px' }}><strong>Rarities:</strong></td>
                                    <td><strong>Default</strong></td>
                                    {buylistVariant && buylistVariant.buylistVariantRarityRules.length > 0 ?
                                      <React.Fragment>
                                        {buylistVariant.buylistVariantRarityRules.map((buylistVariantRarityRule, j) => {
                                          return (
                                            <td key={`${j}-rarity`}><strong>{buylistVariantRarityRule.rarity}</strong></td>
                                          )
                                        })}
                                      </React.Fragment>
                                      :
                                      null
                                    }
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td><strong>Allows purchase</strong></td>
                                    <td className="info">{buylistVariant && buylistVariant.allowPurchase ? "Yes" : "No"}</td>
                                    {buylistVariant && buylistVariant.buylistVariantRarityRules.length > 0 ?
                                      <React.Fragment>
                                        {buylistVariant.buylistVariantRarityRules.map((buylistVariantRarityRule, j) => {
                                          return (
                                            <td className="info" key={`${j}-allowPurchase`}>{buylistVariantRarityRule && buylistVariantRarityRule.allowPurchase ? "Yes" : "No"}</td>
                                          )
                                        })}
                                      </React.Fragment>
                                      :
                                      null
                                    }
                                  </tr>
                                  <tr>
                                    <td><strong>Cash percent</strong></td>
                                    <td className="info">{buylistVariant && buylistVariant.cashPercent ? `${buylistVariant.cashPercent}%` : "N/A"}</td>
                                    {buylistVariant && buylistVariant.buylistVariantRarityRules.length > 0 ?
                                      <React.Fragment>
                                        {buylistVariant.buylistVariantRarityRules.map((buylistVariantRarityRule, j) => {
                                          return (
                                            <td className="info" key={`${j}-cashPercent`}>{buylistVariantRarityRule && buylistVariantRarityRule.cashPercent ? `${buylistVariantRarityRule.cashPercent}%` : "N/A"}</td>
                                          )
                                        })}
                                      </React.Fragment>
                                      :
                                      null
                                    }
                                  </tr>
                                  <tr>
                                    <td><strong>Store credit percent</strong></td>
                                    <td className="info">{buylistVariant && buylistVariant.storeCreditPercent ? `${buylistVariant.storeCreditPercent}%` : "N/A"}</td>
                                    {buylistVariant && buylistVariant.buylistVariantRarityRules.length > 0 ?
                                      <React.Fragment>
                                        {buylistVariant.buylistVariantRarityRules.map((buylistVariantRarityRule, j) => {
                                          return (
                                            <td className="info" key={`${j}-storeCreditPercent`}>{buylistVariantRarityRule && buylistVariantRarityRule && buylistVariantRarityRule.storeCreditPercent ? `${buylistVariantRarityRule.storeCreditPercent}%` : "N/A"}</td>
                                          )
                                        })}
                                      </React.Fragment>
                                      :
                                      null
                                    }
                                  </tr>
                                  <tr>
                                    <td><strong>Overstock quantity</strong></td>
                                    <td className="info">{buylistVariant && buylistVariant.quantity ? buylistVariant.quantity : "N/A"}</td>
                                    {buylistVariant && buylistVariant.buylistVariantRarityRules.length > 0 ?
                                      <React.Fragment>
                                        {buylistVariant.buylistVariantRarityRules.map((buylistVariantRarityRule, j) => {
                                          return (
                                            <td className="info" key={`${j}-quantity`}>{buylistVariantRarityRule && buylistVariantRarityRule.quantity ? buylistVariantRarityRule.quantity : "N/A"}</td>
                                          )
                                        })}
                                      </React.Fragment>
                                      :
                                      null
                                    }
                                  </tr>
                                  <tr>
                                    <td><strong>Allows overstock purchase</strong></td>
                                    <td className="info">{buylistVariant && buylistVariant.overQuantityAllowPurchase ? "Yes" : "No"}</td>
                                    {buylistVariant && buylistVariant.buylistVariantRarityRules.length > 0 ?
                                      <React.Fragment>
                                        {buylistVariant.buylistVariantRarityRules.map((buylistVariantRarityRule, j) => {
                                          return (
                                            <td className="info" key={`${j}-overQuantityAllowPurchase`}>{buylistVariantRarityRule && buylistVariantRarityRule.overQuantityAllowPurchase ? "Yes" : "No"}</td>
                                          )
                                        })}
                                      </React.Fragment>
                                      :
                                      null
                                    }
                                  </tr>
                                  <tr>
                                    <td><strong>Overstock cash percent</strong></td>
                                    <td className="info">{buylistVariant && buylistVariant.overQuantityCashPercent ? `${buylistVariant.overQuantityCashPercent}%` : "N/A"}</td>
                                    {buylistVariant && buylistVariant.buylistVariantRarityRules.length > 0 ?
                                      <React.Fragment>
                                        {buylistVariant.buylistVariantRarityRules.map((buylistVariantRarityRule, j) => {
                                          return (
                                            <td className="info" key={`${j}-overQuantityCashPercent`}>{buylistVariantRarityRule && buylistVariantRarityRule.overQuantityCashPercent ? `${buylistVariant.overQuantityCashPercent}%` : "N/A"}</td>
                                          )
                                        })}
                                      </React.Fragment>
                                      :
                                      null
                                    }
                                  </tr>
                                  <tr>
                                    <td><strong>Overstock store credit percent</strong></td>
                                    <td className="info">{buylistVariant && buylistVariant.overQuantityStoreCreditPercent ? `${buylistVariant.overQuantityStoreCreditPercent}%` : "N/A"}</td>
                                    {buylistVariant && buylistVariant.buylistVariantRarityRules.length > 0 ?
                                      <React.Fragment>
                                        {buylistVariant.buylistVariantRarityRules.map((buylistVariantRarityRule, j) => {
                                          return (
                                            <td className="info" key={`${j}-overQuantityStoreCreditPercent`}>{buylistVariantRarityRule && buylistVariantRarityRule.overQuantityStoreCreditPercent ? `${buylistVariant.overQuantityStoreCreditPercent}%` : "N/A"}</td>
                                          )
                                        })}
                                      </React.Fragment>
                                      :
                                      null
                                    }
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )
                        })}
                      </section>
                      <footer className="modal-card-foot">
                        <button type="button" className="button is-text" onClick={() => this.setModalVisible(false)}>Close</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
                {this.deleteModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Are you sure?</p>
                        <button className="delete" aria-label="close" onClick={() => this.setDeleteModalVisible(false)}></button>
                      </header>
                      <section className="modal-card-body">
                        <p>If you proceed with deleting your buylist rule: <strong>{this.deleteModalBuylistRule.name}</strong>, you will not be able to restore it.</p>
                        <br />
                        <p>Are you sure you want to delete the buylist rule?</p>
                      </section>
                      <footer className="modal-card-foot">
                        <button className={`button half is-danger ${this.deleteModalLoading ? 'is-loading disabled' : ''}`} onClick={() => this.deleteBuylistRule(this.deleteModalBuylistRule.id)}>Delete</button>
                        <button className="button  half is-text" onClick={() => this.setDeleteModalVisible(false)}>Cancel</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
                {this.deleteSelectedModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Are you sure?</p>
                        <button className="delete" aria-label="close" onClick={() => this.setDeleteSelectedModalVisible(false)}></button>
                      </header>
                      <section className="modal-card-body">
                        <p>If you proceed with deleting your buylist rules, you will not be able to restore them.</p>
                        <br />
                        <p>Are you sure you want to delete the selected buylist rules?</p>
                      </section>
                      <footer className="modal-card-foot">
                        <button className={`button half is-danger ${this.deleteSelectedModalLoading ? 'is-loading disabled' : ''}`} onClick={() => this.deleteSelectedBuylistRules()}>Delete</button>
                        <button className="button half is-text" onClick={() => this.setDeleteSelectedModalVisible(false)}>Cancel</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
