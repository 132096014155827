import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { action } from "mobx";

@inject("posStore")
@observer
class Navbar extends Component {
  @action
  toggleFloatModal = () => {
    this.props.posStore.floatModal = true;
    this.props.posStore.fetchFloat();
  };

  @action
  toggleCartModal = () => {
    this.props.posStore.floatModal = true;
    this.props.posStore.fetchFloat();
  };

  createNewCart() {
    this.props.posStore.newCart();
    this.props.posStore.toast("You have created a new cart!");
  }

  saveCart() {
    if (this.props.posStore.cart.length > 0) {
      this.props.posStore.saveCart();
      this.props.posStore.toast(
        "Cart #" + this.props.posStore.cartId + " has been saved!"
      );
    } else {
      this.props.posStore.toast("Your cart appears to be empty...");
    }
  }

  toggleSingles() {
    this.props.posStore.toggleSingle();
    this.props.posStore.toast(
      this.props.posStore.includeSingles
        ? "Your searches now include singles"
        : "Your searches will now exclude singles"
    );
  }

  render() {
    return (
      <div className="navbar">
        <div className="link-icon-pos left" onClick={this.toggleFloatModal}>
          <i className="far fa-power-off" /> Open/Close Till
        </div>
        <div className="posMenu">
          <div className="link-icon-pos">Cart #{this.props.posStore.cartId}</div>
          <div
            className={
              "link-icon-pos cashSwap" + (this.props.posStore.buyMode ? "" : " hidden")
            }
            onClick={() => this.props.posStore.toggleCashPrice()}
          >
            <i
              className={
                this.props.posStore.cashPrice
                  ? "fas fa-money-bill"
                  : "fas fa-credit-card"
              }
              aria-hidden="true"
            />{" "}
            {this.props.posStore.cashPrice ? "Cash Price" : "Credit Price"}
          </div>
          <div className="link-icon-pos productSwap" onClick={() => this.toggleSingles()}>
            <i className="fa fa-search-plus" aria-hidden="true" />{" "}
            {this.props.posStore.includeSingles
              ? "All Products"
              : "Excluding Singles"}
          </div>
          <div className="link-icon-pos saveCart" onClick={() => this.saveCart()}>
            <i className="fas fa-save" aria-hidden="true" /> Save Cart
          </div>
          <div
            className="link-icon-pos loadCart"
            onClick={() => this.props.posStore.openCartModal()}
          >
            <i className="fas fa-download" aria-hidden="true" /> Load Cart
          </div>
          <div className="link-icon-pos newCart" onClick={() => this.createNewCart()}>
            <i className="fa fa-cart-plus" aria-hidden="true" /> New Cart
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
