import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { POSMenuStructure } from 'menuStructures';
import { Loader, DateTime } from 'components'
import { Link } from "react-router-dom";

@inject('store')
@observer
export default class CartList extends React.Component {

  @observable offset = 0;
  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 50;
  @action setLimit(limit) {
    this.limit = limit;
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = 'Point of Sale Submitted Carts | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.props.store.CartStore.rehydrateAllCarts(this.offset, this.limit);
    this.props.store.MainStore.buildReceiptData();
  }

  getNextCarts() {
    this.setOffset(this.offset + 50);
    this.props.store.CartStore.rehydrateAllCarts(this.offset, this.limit);
  }

  getPrevCarts() {
    var offsetUpdate = this.offset - 50;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.CartStore.rehydrateAllCarts(this.offset, this.limit);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.pos ?
          <React.Fragment>
            {this.props.store.CartStore.carts ?
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon cyan"><i className="fal fa-shopping-cart"></i></span>
                    <span className="header-text">Submitted Carts</span>
                  </h2>
                </div>
                <div>
                  <div>
                    <div className="customer_list">
                      <table>
                        <thead>
                          <tr>
                            <th className="col3">Cart #</th>
                            <th className="col3">Shopify Order #</th>
                            <th className="col2">Date submitted</th>
                            <th className="col2">Till</th>
                            <th className="col2">Customer</th>
                            <th className="col2">Processed by</th>
                            <th className="col3"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.store.CartStore.carts.map((cart, i) => {
                            return (
                              <tr className="list_item" key={i}>
                                <td className="initials">
                                  {cart.id}
                                </td>
                                <td>
                                  {cart.orderNumber}
                                </td>
                                <td><DateTime dateTime={cart.readableDateSubmitted.split("[")[0]} /></td>
                                <td>{cart.till.name}</td>
                                <td>{cart.customer ? (cart.customer.firstName && cart.customer.firstName.length > 0 ? (cart.customer.firstName + " " + (cart.customer.lastName && cart.customer.lastName.length > 0 ? cart.customer.lastName : "")) : (customerToSave.email && cart.customer.email.length > 0 ? cart.customer.email : (cart.customer.phone && cart.customer.phone.length > 0 ? cart.customer.phone : ""))) : "Guest"}</td>
                                <td>{cart.submittedBy}</td>
                                <td>
                                  <Link to={"/pointOfSale/carts/" + cart.id} className="userActions"><i className="fal fa-eye"></i></Link>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="paging-nav-bottom ">
                      <button
                        className="button is-info paging-nav-right-margin"
                        disabled={this.offset == 0 ? true : false}
                        onClick={() => this.getPrevCarts()}
                      >
                        Prev
                    </button>
                      <button
                        className="button is-info"
                        disabled={
                          this.props.store.CartStore.carts &&
                            this.props.store.CartStore.carts.length <
                            this.limit
                            ? true
                            : false
                        }
                        onClick={() => this.getNextCarts()}
                      >
                        Next
                    </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
