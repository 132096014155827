import React from "react";
import { NavLink } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { SideMenu } from "components";
import firebase from "firebase";
import {
  BuylistMenuStructure,
  EventMenuStructure,
  CustomerMenuStructure,
  POSMenuStructure,
  SettingsMenuStructure,
  ProductMenuStructure,
  UserMenuStructure
} from "menuStructures";
import { withRouter } from "react-router";

@inject("store")
@observer
class Header extends React.Component {
  updateMenu = menu => {
    this.props.sideMenuUpdate(menu);
  };
  openFresh = () => {
    window.FreshWidget.show();
  };

  render() {
    return (
      <React.Fragment>
        <div key={this.props.store.screenSettings} className="app-sidebar">
          <div className="brand">
            <img src="/dist/img/logoHover.png" />
          </div>
          <div className="sidebar-inner">
            <NavLink
              className="sidebar-link cyan"
              activeClassName="active"
              to="/dashboard"
              onClick={() => this.updateMenu(null)}
            >
              <div
                className="link-icon"
                data-balloon-length="medium"
                data-balloon="Home"
                data-balloon-pos="right"
              >
                <i className="far fa-home" />
              </div>
            </NavLink>
            {this.props.screenSettings.events ? (
              <NavLink
                className="sidebar-link yellow"
                activeClassName="active"
                to="/events"
                onClick={() => this.updateMenu(EventMenuStructure)}
              >
                <div
                  className="link-icon"
                  data-balloon-length="medium"
                  data-balloon="Events"
                  data-balloon-pos="right"
                >
                  <i className="fal fa-calendar-alt" />
                </div>
              </NavLink>
            ) : null}
            {this.props.screenSettings.customers ? (
              <NavLink
                className="sidebar-link purple"
                activeClassName="active"
                to="/customers"
                onClick={() => this.updateMenu(CustomerMenuStructure)}
              >
                <div
                  className="link-icon"
                  data-balloon-length="medium"
                  data-balloon="Customers"
                  data-balloon-pos="right"
                >
                  <i className="fal fa-users" />
                </div>
              </NavLink>
            ) : null}
            {this.props.screenSettings.inventoryManagement ? (
              <NavLink
                className="sidebar-link"
                activeClassName="active"
                to="/products"
                onClick={() => this.updateMenu(ProductMenuStructure)}
              >
                {" "}
                <div
                  className="link-icon"
                  data-balloon-length="medium"
                  data-balloon="Products"
                  data-balloon-pos="right"
                >
                  <i className="fal fa-inventory" />
                </div>
              </NavLink>
            ) : null}
            {this.props.screenSettings.buylist ? (
              <NavLink
                to="/buylists"
                className="sidebar-link orange"
                activeclassname="active"
                onClick={() => this.updateMenu(BuylistMenuStructure)}
              >
                <div
                  className="link-icon"
                  data-balloon-length="medium"
                  data-balloon="Buylist"
                  data-balloon-pos="right"
                >
                  <i className="fal fa-shopping-cart" />
                </div>
              </NavLink>
            ) : null}
            {this.props.screenSettings.pos ? (
              <NavLink
                className="sidebar-link cyan"
                activeClassName="active"
                to="/pointOfSale"
                onClick={() => this.updateMenu(POSMenuStructure)}
              >
                <div
                  className="link-icon"
                  data-balloon-length="medium"
                  data-balloon="Point of Sale Settings"
                  data-balloon-pos="right"
                >
                  <i className="fal fa-store-alt" />
                </div>
              </NavLink>
            ) : null}
            {this.props.screenSettings.pos ? (
              <NavLink
                className="poslink"
                activeClassName="active"
                to="/pos"
                onClick={() => this.updateMenu(null)}
              >
                {" "}
                <div
                  className="link-icon"
                  data-balloon-length="medium"
                  data-balloon="Point of Sale"
                  data-balloon-pos="right"
                >
                  <i className="fal fa-store-alt" />
                </div>
              </NavLink>
            ) : null}
            <div
              data-balloon-length="medium"
              data-balloon="Customer Support"
              data-balloon-pos="right"
              className="sidebar-link"
            >
              <i className="fal fa-user-headset" onClick={this.openFresh} />
            </div>
            <NavLink
              className="sidebar-link"
              activeClassName="active"
              to="/users"
              onClick={() => this.updateMenu(UserMenuStructure)}
            >
              <div
                className="link-icon"
                data-balloon-length="medium"
                data-balloon="User Management"
                data-balloon-pos="right"
              >
                <i className="fal fa-users-cog" />
              </div>
            </NavLink>
            <NavLink
              className="sidebar-link"
              activeClassName="active"
              to="/settings"
              onClick={() => this.updateMenu(SettingsMenuStructure)}
            >
              <div
                className="link-icon"
                data-balloon-length="medium"
                data-balloon="Settings"
                data-balloon-pos="right"
              >
                <i className="fal fa-cogs" />
              </div>
            </NavLink>
          </div>
        </div>
        <nav
          id="main-nav"
          className="navbar is-light"
          role="navigation"
          aria-label="main navigation"
        >
          <SideMenu sideMenu={this.props.sideMenu} />
          <div className="company">
            {this.props.store.SettingsStore.storeSettings
              ? this.props.store.SettingsStore.storeSettings.name
              : ""}
          </div>
          <div className="pull-right">
            <div className="avatar">
              <img
                src={
                  firebase.auth().currentUser.photoURL
                    ? firebase.auth().currentUser.photoURL
                    : "/dist/img/default.png"
                }
              />
            </div>
            <div className="user">
              <span className="customer">
                {firebase.auth().currentUser.displayName}
              </span>
              <span className="logout">
                <a
                  onClick={() => {
                    firebase.auth().signOut();
                    this.props.store.AuthStore.logUserOut();
                  }}
                >
                  Sign out
                </a>
              </span>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default withRouter(Header);
