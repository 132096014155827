import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ReactToPrint from "react-to-print";
import { fchmod } from "fs";

@inject("posStore")
@observer
class Receipt extends Component {
  convertDateToHumanReadable(theDate) {
    const date = new Date(theDate);
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
    return date.toLocaleDateString();
  }

  convertTime(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }
  render() {
    const fC = this.props.posStore.fCurr;
    const store = this.props.posStore;
    return (
      <div className="receipt">
        <span className="name">{store.storeInfo?.storeName}</span>
        <span className="details">Tel: {store.storeInfo?.phoneNumber}</span>
        <span className="details">{store.storeInfo?.addressLine1}</span>
        <span className="details">{store.storeInfo?.addressLine2}</span>
        <span className="details">
          {store.storeInfo?.city} {store.storeInfo?.state}{" "}
        </span>
        <span className="details">
          {store.storeInfo?.country} {store.storeInfo?.zipCode}{" "}
        </span>
        {store.taxNumber ? (
          <span className="taxNumber">
            {store.taxWording + " " + store.taxNumber}
          </span>
        ) : (
          ""
        )}
        {store.selectedCustomer ? (
          <span className="saleId">
            {store.selectedCustomer.firstName} {store.selectedCustomer.lastName}
            <em>
              Credit:{" "}
              {fC(
                (
                  store.selectedCustomer.storeCredit -
                  parseFloat(
                    store.tenders.find(tend => {
                      return tend.type.toLowerCase() == "store credit";
                    })?.amount
                  )
                ).toFixed(2)
              )}
            </em>
          </span>
        ) : (
          ""
        )}
        <span className="saleId">
          Sale# {store?.cartId}
          <em>Till: {store?.tillId}</em>
        </span>
        <span className="dateTime">
          {this.convertDateToHumanReadable(Date.now())}
          <em>{this.convertTime(new Date(Date.now()).toLocaleTimeString())}</em>
        </span>
        <hr />
        <table>
          <tbody>
            {store.cart.map((item, index) => (
              <tr key={index}>
                <td className="qty">{item.qty}</td>
                <td className="itemTitle">
                  {item.title}
                  <em>
                    {item.variantTitle != "-"
                      ? " - [" + item.variantTitle + "]"
                      : ""}
                  </em>
                </td>
                <td className="price">
                  {item.discountAmount ? (
                    <del>{fC(item.actualPrice)}</del>
                  ) : null}
                  <a>{fC(item.price)}</a>
                  <em className="lineTotal">{fC(item.lineTotal)}</em>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <span className="itemTotalCount">
          ** {store.totalItems} Items purchased on this sale **
        </span>
        {store.globalDiscount.amount ? (
          <span className="subTotal">
            Discount{" "}
            {store.globalDiscount.type == "percentage"
              ? "-" + store.globalDiscount.amount + "%"
              : "-$" + store.globalDiscount.amount}
            <em>{fC(store.discountAmount)}</em>
          </span>
        ) : (
          ""
        )}
        <span className="subTotal">
          SubTotal <em>{fC((store.total - store.taxTotal).toFixed(2))}</em>
        </span>

        <span className="GST">
          {store.taxWording}({store.taxRateDisplay}%){" "}
          <em>{fC(store.taxTotal.toFixed(2))}</em>
        </span>

        <span className="total">
          Total: <em>{fC(store.total.toFixed(2))}</em>
        </span>
        {store.tenders.map((tender, index) => (
          <React.Fragment key={index}>
            {tender.amount != 0 ? (
              <span className="subTotal">
                {tender.type}: <em>{fC(tender.amount)}</em>
              </span>
            ) : (
              ""
            )}
          </React.Fragment>
        ))}
        {store.changeDue ? (
          <span className="subTotal">
            Change: <em>{fC(store.changeDue)}</em>
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }
}

@inject("posStore")
@observer
class ReceiptModal extends Component {
  render() {
    const store = this.props.posStore;

    return (
      <div className={store.receiptModal ? "modal is-active" : "modal"}>
        <div className="modal-background" />
        <div className="modal-card receiptSize">
          <header className="modal-card-head">
            <p className="modal-card-title">Receipt Preview</p>
            <button
              className="delete"
              aria-label="close"
              onClick={store.closeReceipt}
            />
          </header>
          <section className="modal-card-body">
            <Receipt ref={el => (this.componentRef = el)} />
          </section>
          <footer className="modal-card-foot">
            <ReactToPrint
              trigger={() => (
                <button className={"button is-info"}>Print</button>
              )}
              content={() => this.componentRef}
            />
          </footer>
        </div>
      </div>
    );
  }
}

export default ReceiptModal;
