import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser';
import { Provider, inject, observer } from 'mobx-react'
import { HashRouter as Router, Route, Redirect } from 'react-router-dom'
import { Header, SignInForm, ErrorModal, Loader, ConfirmationModal } from 'components'
import { Pos } from 'pos'
import {
  Dashboard, StoreSettings, PricingSettings, TaxSettings, TimezoneSettings, Event, EventFailedToCreate, EventFailedToDelete, EventList, ChildEventList, EventParticipants,
  CustomerList, Customer, ProductList, BuylistRuleList, BuylistRule, PendingBuylist,
  CompletedBuylist, ApprovedBuylist, BuylistDetails, FailedBuylistProducts, TillList, JobQueueList,
  POSFailedBuylistProducts, EODReports, CartList, CartView, Users, User
} from 'views'
import Store from 'store'
import firebase from 'firebase';
import { ItemList } from "./pos/ItemStore";
const posStore = new ItemList();
import StackdriverErrorReporter from "stackdriver-errors-js"

//Sentry.init({ dsn: "https://0dc50f78ecc14110ae60b61be6cf1b60@sentry.io/1481982" });

// window.addEventListener('DOMContentLoaded', function() {
//   var errorHandler = new StackdriverErrorReporter();
//   errorHandler.start({
//     key: 'AIzaSyDC8-Aa2W5SDBzuNc8DjPNYqYlPO__fHw8',
//     projectId: 'hobby-pos',
//     service: 'binder-portal',
//     reportUncaughtExceptions: true,
//     reportUnhandledPromiseRejections: true
//   });
// });

@inject('store')
@observer
class App extends React.Component {

  menuToOpen = (dataFromChild) => {
    this.props.store.MenuStore.setSideMenuToDisplay(dataFromChild);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.MainStore.error ?
          <ErrorModal />
          :
          null
        }
        {this.props.store.MainStore.info ?
          <ConfirmationModal type="confirm" title={this.props.store.MainStore.info.title} text={this.props.store.MainStore.info.message} confirmAction={() => this.props.store.MainStore.setInfo(null)} />
          :
          null
        }
        <Router>
          {this.props.store.AuthStore.hasLoaded ?
            <React.Fragment>
              {this.props.store.AuthStore.user && this.props.store.AuthStore.screenSettings && this.props.store.AuthStore.screenSettings != "No account" ?
                <React.Fragment>
                  <Header sideMenu={this.props.store.MenuStore.sideMenuToDisplay} sideMenuUpdate={this.menuToOpen} screenSettings={this.props.store.AuthStore.screenSettings} />

                  <main id="binderpos-app" className={this.props.store.MenuStore.sideMenuToDisplay ? "hasSubMenu" : ""}>
                    <div className="app-content">
                      <section className="section">
                        <Route exact path="/" render={() => (<Redirect to="/dashboard" />)} />
                        <Route exact path="/dashboard" render={() => <Dashboard />} />

                        <Route exact path="/events" render={() => (<Redirect to="/events/list" />)} />

                        <Route exact path="/events/list" render={() => <EventList />} />
                        <Route exact path="/events/view/:eventId" render={props => (<ChildEventList {...props} />)} />
                        <Route exact path="/events/add" render={() => <Event />} />
                        <Route exact path="/events/update/:eventId" render={props => (<Event {...props} />)} />
                        <Route exact path="/events/participants/:eventId" render={props => <EventParticipants {...props} />} />
                        <Route exact path="/events/failedToCreate" render={props => <EventFailedToCreate {...props} />} />
                        <Route exact path="/events/failedToDelete" render={props => <EventFailedToDelete {...props} />} />

                        <Route exact path="/customers" render={() => (<Redirect to="/customers/list" />)} />
                        <Route exact path="/customers/list" render={() => <CustomerList />} />
                        <Route exact path="/customers/add" render={() => <Customer />} />
                        <Route exact path="/customers/update/:customerId" render={props => <Customer {...props} />} />

                        <Route exact path="/products" render={() => <ProductList />} />
                        <Route exact path="/products/jobQueue" render={() => <JobQueueList />} />

                        <Route exact path="/buylists" render={() => (<Redirect to="/buylists/pending" />)} />
                        <Route exact path="/buylists/pending" render={() => <PendingBuylist />} />
                        <Route exact path="/buylists/pending/moreDetail/:buylistId" render={props => <BuylistDetails {...props} />} />
                        <Route exact path="/buylists/approved" render={() => <ApprovedBuylist />} />
                        <Route exact path="/buylists/approved/moreDetail/:buylistId" render={props => <BuylistDetails {...props} />} />
                        <Route exact path="/buylists/completed" render={() => <CompletedBuylist />} />
                        <Route exact path="/buylists/completed/moreDetail/:buylistId" render={props => <BuylistDetails {...props} />} />
                        <Route exact path="/buylists/rules" render={() => <BuylistRuleList />} />
                        <Route exact path="/buylists/rules/add" render={() => <BuylistRule />} />
                        <Route exact path="/buylists/rules/update/:buylistRuleId" render={props => <BuylistRule {...props} />} />
                        <Route exact path="/buylists/failedToSyncProducts" render={() => <FailedBuylistProducts />} />

                        <Route exact path="/pointOfSale" render={() => (<Redirect to="/pointOfSale/tills" />)} />
                        <Route exact path="/pointOfSale/tills" render={() => <TillList />} />
                        <Route exact path="/pointOfSale/carts" render={() => <CartList />} />
                        <Route exact path="/pointOfSale/carts/:cartId" render={props => <CartView {...props} />} />
                        <Route exact path="/pointOfSale/eodReports" render={() => <EODReports />} />
                        <Route exact path="/pointOfSale/failedToSyncProducts" render={() => <POSFailedBuylistProducts />} />

                        <Route exact path="/pos" render={props => <Pos screenSettings={props} />} />

                        <Route exact path="/settings" render={() => (<Redirect to="/settings/store" />)} />
                        <Route exact path="/settings/store" render={() => <StoreSettings />} />
                        <Route exact path="/settings/pricing" render={() => <PricingSettings />} />
                        <Route exact path="/settings/tax" render={() => <TaxSettings />} />
                        <Route exact path="/settings/timezone" render={() => <TimezoneSettings />} />

                        <Route exact path="/users" render={() => (<Redirect to="/users/list" />)} />
                        <Route exact path="/users/list" render={() => <Users />} />
                        <Route exact path="/users/add" render={() => <User />} />
                        <Route exact path="/users/update/:userId" render={props => <User {...props} />} />
                      </section>
                    </div>
                  </main>
                </React.Fragment>
                :
                <React.Fragment>
                  {!this.props.store.AuthStore.user ?
                    <div id="login-page" className="login-center">
                      <div className="col-md-7 leftBg">
                        <img className="login-logo" src="/dist/img/logo.png" />
                      </div>
                      <div className="col-md-5">
                        <SignInForm />
                      </div>
                    </div>
                    :
                    <React.Fragment>
                      <div id="login-page" className="login-center">
                        <div className="col-md-7 leftBg">
                          <img className="login-logo" src="/dist/img/logo.png" />
                        </div>
                        <div className="col-md-5">
                          <div className="loginWrapper">
                            {this.props.store.AuthStore.screenSettings == "No account" ?
                              <React.Fragment>
                                <p className="title recover">Not a <em>BinderPOS</em> account</p>
                                <p className="wrongAccount">{firebase.auth().currentUser.email} does not have an account with BinderPOS.</p>
                                <button className="signin" onClick={() => { firebase.auth().signOut(); this.props.store.AuthStore.logUserOut(); }}>Go back</button>
                              </React.Fragment>
                              :
                              <React.Fragment>
                                <Loader />
                              </React.Fragment>
                            }
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </React.Fragment>
            :
            <div id="login-page" className="login-center">
              <div className="col-md-7 leftBg">
                <img className="login-logo" src="/dist/img/logo.png" />
              </div>
              <div className="col-md-5">
                <div className="loginWrapper">
                  <Loader />
                </div>
              </div>
            </div>
          }
        </Router>
      </React.Fragment>
    )
  }
}

ReactDOM.render(
  <Provider store={Store} posStore={posStore}>
    <App />
  </Provider>,
  document.getElementById('root')
)

window.posStore = posStore;
