import React, { Component } from "react";
import { action } from "mobx";
import Store from "../../store/index";
import { inject, observer } from "mobx-react";

const WAIT_INTERVAL = 625;

function getUserToken() {
  return Store.AuthStore.user.getIdToken(true);
}
@inject("posStore")
@observer
class SearchBar extends Component {
  handleKeyDown = event => {
    switch (event.keyCode) {
      case 220:
        if (
          document.activeElement.type != "text" &&
          document.activeElement.type != "textarea" &&
          document.activeElement.type != "number"
        ) {
          event.preventDefault();
          this.nameInput.focus();
          this.nameInput.select();
        }
        break;
      case 13:
        break;
      default:
        if (
          document.activeElement.type != "text" &&
          document.activeElement.type != "textarea" &&
          document.activeElement.type != "number"
        ) {
          this.nameInput.focus();
          this.nameInput.select();
        }
        break;
    }
  };

  componentDidMount() {
    this.nameInput.focus();
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  @action
  cashMode(e) {
    this.props.store.cashMode = e.target.checked;
  }

  render() {
    return (
      <form>
        <div className="search-bar">
          <input
            type="text"
            value={this.props.posStore.searchTerm}
            onChange={this.props.posStore.search}
            className="textbox"
            onKeyPress={this.props.posStore.fetchBarcode}
            ref={input => {
              this.nameInput = input;
            }}
          />
        </div>
        <div className="switcher">
          <label className="switch">
            <input
              type="checkbox"
              checked={this.props.posStore.buyMode}
              onChange={this.props.posStore.toggleBuyMode}
            />
            <span className="slider round" />
          </label>
        </div>
      </form>
    );
  }
}

export default SearchBar;
