var POSMenuStructure = {
  title: "Point of Sale",
  activeClass: "cyan",
  links: [
    {
      title: "Point of Sale",
      href: "pos"
    },
    {
      title: "Tills / Registers",
      href: "pointOfSale/tills"
    },
    {
      title: "Submitted carts",
      href: "pointOfSale/carts"
    },
    {
      title: "End of Day Reports",
      href: "pointOfSale/eodReports"
    },
    {
      title: "Failed to Sync from POS",
      href: "pointOfSale/failedToSyncProducts"
    }
  ]
};

export default POSMenuStructure;