var UserMenuStructure = {
  title: "Users",
  activeClass: "orange",
  links: [
    {
      title: "Users",
      href: "users/list"
    },
    {
      title: "Add user",
      href: "users/add"
    }
  ]
};

export default UserMenuStructure;