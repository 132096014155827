import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { BuylistMenuStructure } from 'menuStructures';
import { Loader } from "components";
import { TimeUtils } from 'utils'

const WAIT_INTERVAL = 625;
const ENTER_KEY = 13;

@inject('store')
@observer
export default class CompletedBuylist extends React.Component {

  @observable offset = 0;
  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 10;
  @action setLimit(limit) {
    this.limit = limit;
  }

  @observable searchString = "";
  @action setSearchString(searchString) {
    this.searchString = searchString;
  }

  @observable timer;
  @action setTimer(timer) {
    this.timer = timer;
  }

  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
  }

  componentDidMount() {
    document.title = 'Completed buylists | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);

    this.props.store.BuylistRulesStore.rehydrateCompletedBuylists(this.offset, this.limit, this.searchString);
  }

  searchForCompletedBuylist() {
    clearTimeout(this.timer);

    let localTimer = setTimeout(() => {
      this.setSearchString(this.searchInput.current.value);
      this.setOffset(0);
      this.props.store.BuylistRulesStore.rehydrateCompletedBuylists(this.offset, this.limit, this.searchString);
    }, WAIT_INTERVAL);

    this.setTimer(localTimer);
  }

  getNextCompletedBuylists() {
    this.setOffset(this.offset + 10);
    this.props.store.BuylistRulesStore.rehydrateCompletedBuylists(this.offset, this.limit, this.searchString);
  }

  getPrevCompletedBuylists() {
    var offsetUpdate = this.offset - 10;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.BuylistRulesStore.rehydrateCompletedBuylists(this.offset, this.limit, this.searchString);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.buylist ?
          <React.Fragment>
            {this.props.store.BuylistRulesStore.completedBuylists ?
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon orange"><i className="fal fa-shopping-cart"></i></span>
                    <span className="header-text">Completed buylists</span>
                  </h2>
                </div>
                <div>
                  <div className="search">
                    <div className="control">
                      <i className="far fa-search"></i>
                      <input autoComplete="off" ref={this.searchInput} id="buylistSearch" className="input" type="text" placeholder="Search for a completed buylist by customer email" onChange={this.searchForCompletedBuylist.bind(this)} defaultValue={this.searchString} />
                    </div>
                  </div>
                  <div className="customer_list">
                    <table>
                      <thead>
                        <tr>
                          <th className="col5">#</th>
                          <th colSpan="2" className="col2 completedName">Name</th>
                          <th className="col2 completedEmail">Email</th>
                          <th className="col2">Payment type</th>
                          <th className="col2" className="storeCredit">Date submitted</th>
                          <th className="col2">Status</th>
                          <th className="col4"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.store.BuylistRulesStore.completedBuylists.map((completedBuylist, i) => {
                          return (
                            <tr key={i}>
                              <td>{completedBuylist.id}</td>
                              <td className="initials">
                                <div>{completedBuylist.shopifyCustomerId.firstName && completedBuylist.shopifyCustomerId.firstName.length > 0 ?
                                  <React.Fragment>

                                    {completedBuylist.shopifyCustomerId.firstName.charAt(0)}
                                    {completedBuylist.shopifyCustomerId.lastName && completedBuylist.shopifyCustomerId.lastName.length > 0 ?
                                      <React.Fragment>
                                        {completedBuylist.shopifyCustomerId.lastName.charAt(0)}
                                      </React.Fragment>
                                      :
                                      null
                                    }
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    {completedBuylist.shopifyCustomerId.email && completedBuylist.shopifyCustomerId.email.length > 0 ?
                                      <React.Fragment>
                                        {completedBuylist.shopifyCustomerId.email.charAt(0)}
                                      </React.Fragment>
                                      :
                                      null
                                    }
                                  </React.Fragment>
                                }
                                </div>
                              </td>
                              <td className="name">{completedBuylist.shopifyCustomerId.firstName} {completedBuylist.shopifyCustomerId.lastName}</td>
                              <td>{completedBuylist.shopifyCustomerId.email}</td>
                              <td>{completedBuylist.paymentType}</td>
                              <td>{TimeUtils.convertDateToHumanReadable(completedBuylist.readableSubmittedDate)}</td>
                              <td><span style={completedBuylist.approved ? { color: 'green' } : { color: 'red' }}>{completedBuylist.approved ? "Approved" : "Declined"}</span></td>
                              <td><Link to={`/buylists/completed/moreDetail/${completedBuylist.id}`} className="button is-success">View</Link></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="paging-nav-bottom">
                    <button className="button is-info paging-nav-right-margin"
                      disabled={this.offset == 0 ? true : false}
                      onClick={() => this.getPrevCompletedBuylists()}>Prev</button>
                    <button
                      className="button is-info"
                      disabled={this.props.store.BuylistRulesStore.completedBuylists && this.props.store.BuylistRulesStore.completedBuylists.length < this.limit ? true : false}
                      onClick={() => this.getNextCompletedBuylists()}>Next</button>
                  </div>
                </div>
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
