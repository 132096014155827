import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { POSMenuStructure } from 'menuStructures';
import "react-table/react-table.css";
import ReactTable from "react-table";
import moment from "moment";
import { Loader } from 'components'
import 'moment-timezone';

@inject("store")
@observer
export default class EODReports extends React.Component {

  @observable addNewTillModalVisible;
  @action setAddNewTillModalVisible(bool) {
    this.addNewTillModalVisible = bool;
  }

  @observable addingNewTill;
  @action setAddingNewTill(addingNewTill) {
    this.addingNewTill = addingNewTill;
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }
  @observable reportData = [];

  @action setReportData(data) {
    this.reportData = data;
  }

  componentDidMount() {
    document.title = "End of Day Reports | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.props.store.ReportStore.fetchTestData()
      .then(e => {
        this.setReportData(e)
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      })

    //this.props.store.TillStore.rehydrateTills();
  }

  render() {
    return (
      <React.Fragment>
        {1 ? (
          <React.Fragment>
            {1 ? (
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon red">
                      <i className="fas fa-users" />
                    </span>
                    <span className="header-text">End of Day Reports</span>
                    {/* <a className="button is-primary rightAlign" onClick={() => this.props.store.TillStore.rehydrateTills()}><i className="fas fa-sync-alt"></i>Refresh</a> */}
                  </h2>
                </div>
                <div>
                  <ReactTable
                    data={this.reportData}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name"
                      },
                      {
                        Header: "Opening Time",
                        id: "openedDate",
                        accessor: d => {
                          return moment(d.openedDate)
                            .tz(this.props.store.SettingsStore.customerTimezone)
                            .format("DD MMM YYYY @ hh:mm:ss a");
                        }
                      },
                      {
                        Header: "Closing Time",
                        id: "closedDate",
                        accessor: d => {
                          if (d.closedDate != null) {
                            return moment(d.closedDate)
                              .tz(this.props.store.SettingsStore.customerTimezone)
                              .format("DD MMM YYYY @ hh:mm:ss a");
                          } else {
                            return "-";
                          }
                        }
                      },
                      {
                        Header: "Cash Opening",
                        accessor: "Cash_opening",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "Cash Current",
                        accessor: "Cash_current",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "Cash Diff",
                        accessor: "Cash_disc",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "Cash Closing",
                        accessor: "Cash_closing",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "Credit Opening",
                        accessor: "Credit_opening",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "Credit Current",
                        accessor: "Credit_current",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "Credit Diff",
                        accessor: "Credit_disc",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "Credit Closing",
                        accessor: "Credit_closing",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "EFTPOS Opening",
                        accessor: "EFTPOS_opening",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "EFTPOS Current",
                        accessor: "EFTPOS_current",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "EFTPOS Diff",
                        accessor: "EFTPOS_disc",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "EFTPOS Closing",
                        accessor: "EFTPOS_closing",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      },
                      {
                        Header: "Store Credit",
                        accessor: "StoreCredit_current",
                        Cell: props =>
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(props.value)
                      }
                    ]}
                  />
                </div>
              </React.Fragment>
            ) : (
                <Loader />
              )}
          </React.Fragment>
        ) : (
            <div>
              <p>Please contact BinderPOS to enable this screen.</p>
            </div>
          )}
      </React.Fragment>
    );
  }
}
