import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { SettingsMenuStructure } from 'menuStructures';
import { Loader } from 'components'

@inject('store')
@observer
export default class PricingSettings extends React.Component {


    @observable updatingStoreSettings = false;
    @action setUpdatingStoreSettings(bool) {
        this.updatingStoreSettings = bool;
    }

    @observable storeSettings;
    @action setStoreSettings(storeSettings) {
        this.storeSettings = storeSettings;
    }

    @observable savingSettings;
    @action setSavingSettings(savingSettings) {
        this.savingSettings = savingSettings;
    }

    @observable priceRulesForGames;
    @action setPriceRulesForGames(priceRulesForGames) {
        this.priceRulesForGames = priceRulesForGames;
    }

    @observable defaultPriceRules;
    @action setDefaultPriceRules(defaultPriceRules) {
        this.defaultPriceRules = defaultPriceRules;
    }

    @observable supportedGames;
    @action setSupportedGames(supportedGames) {
        this.supportedGames = supportedGames;
    }

    @observable mergedPriceRulesForGames;
    @action setMergedPriceRulesForGames(mergedPriceRulesForGames) {
        this.mergedPriceRulesForGames = mergedPriceRulesForGames;
    }

    constructor(props) {
        super(props);

        this.form = React.createRef();
    }

    getSettings() {
        return this.props.store.SettingsStore.fetchSettings()
            .then(result => {
                this.props.store.SettingsStore.setSettings(result);
                return Promise.resolve();
            }).catch(error => {
                this.props.store.MainStore.setError(error);
                return Promise.resolve();
            })
    }

    getCardGames() {
        return this.props.store.CardStore.fetchCardGames()
            .then((result) => {
                this.setSupportedGames(result);
                return Promise.resolve();
            }).catch(error => {
                this.props.store.MainStore.setError(error);
                return Promise.resolve();
            })
    }

    getPriceRuleSettings() {
        return this.props.store.MainStore.fetchPriceRuleSettings()
            .then((result) => {
                this.setPriceRulesForGames(result);
                return Promise.resolve();
            }).catch(error => {
                this.props.store.MainStore.setError(error);
                return Promise.resolve();
            });
    }

    getDefaultPriceRuleSettings() {
        return this.props.store.MainStore.fetchDefaultPriceRuleSettings()
            .then((result) => {
                this.setDefaultPriceRules(result);
                return Promise.resolve();
            }).catch(error => {
                this.props.store.MainStore.setError(error);
                return Promise.resolve();
            });
    }

    componentDidMount() {
        document.title = 'Pricing Settings | BinderPOS';
        this.props.store.MenuStore.setSideMenuToDisplay(SettingsMenuStructure);
        var promises = [];
        promises.push(this.getSettings());
        promises.push(this.getCardGames());
        promises.push(this.getPriceRuleSettings());
        promises.push(this.getDefaultPriceRuleSettings());
        Promise.all(promises)
            .then(() => {
                this.mergePriceRules();
            })
        this.refreshStoreSettings();
    }

    mergePriceRules() {
        var priceRulesPromise = [];
        this.supportedGames.forEach(game => {
            priceRulesPromise.push(this.organisePriceRules(game));
        })
        return Promise.all(priceRulesPromise)
            .then(results => {
                console.log(results);
                this.setMergedPriceRulesForGames(results);
            })
    }

    organisePriceRules(game) {
        return this.props.store.CardStore.fetchCardRarities(game.gameId)
            .then(gameRarities => {
                var gameFound = false;
                var priceRule = {};
                this.priceRulesForGames.forEach(priceRulesForGame => {
                    if (priceRulesForGame.game == game.gameId) {
                        gameFound = true;
                        priceRule.game = game.gameId;
                        priceRule.gameName = game.gameName;
                        priceRule.setName = priceRulesForGame.setName;
                        priceRule.priceMarkup = priceRulesForGame.priceMarkup;
                        priceRule.priceMultiplier = priceRulesForGame.priceMultiplier;
                        priceRule.updatePricing = priceRulesForGame.updatePricing;
                        priceRule.noRounding = priceRulesForGame.noRounding;
                        priceRule.roundToNearest = priceRulesForGame.roundToNearest;
                        priceRule.tcgPlayerPriceType = priceRulesForGame.tcgPlayerPriceType;
                        priceRule.useTCGPlayerPricing = priceRulesForGame.useTCGPlayerPricing;
                        var rarityFloors = [];
                        gameRarities.forEach(rarity => {
                            var rarityFound = false;
                            var rarityFloorItem = {};
                            priceRulesForGame.rarityFloors.forEach(rarityFloor => {
                                if (rarity == rarityFloor.rarity) {
                                    rarityFound = true;
                                    rarityFloorItem.rarity = rarity;
                                    rarityFloorItem.price = rarityFloor.price;
                                }
                            })
                            if (!rarityFound) {
                                rarityFloorItem.rarity = rarity;
                                var defaultRarityFound = false;
                                this.defaultPriceRules.rarityFloors.forEach(defaultRarityFloor => {
                                    if (defaultRarityFloor.rarity == rarity) {
                                        defaultRarityFound = true;
                                        rarityFloorItem.price = defaultRarityFloor.price;
                                    }
                                })
                                if (!defaultRarityFound) {
                                    rarityFloorItem.price = null;
                                }
                            }
                            rarityFloors.push(rarityFloorItem);
                        })
                        priceRule.rarityFloors = rarityFloors;
                    }
                })
                if (!gameFound) {
                    priceRule.game = game.gameId;
                    priceRule.gameName = game.gameName;
                    priceRule.setName = null;
                    priceRule.priceMarkup = this.defaultPriceRules.priceMarkup;
                    priceRule.priceMultiplier = this.defaultPriceRules.priceMultiplier;
                    priceRule.updatePricing = this.defaultPriceRules.updatePricing;
                    priceRule.noRounding = this.defaultPriceRules.noRounding;
                    priceRule.roundToNearest = this.defaultPriceRules.roundToNearest;
                    priceRule.tcgPlayerPriceType = this.defaultPriceRules.tcgPlayerPriceType;
                    priceRule.useTCGPlayerPricing = this.defaultPriceRules.useTCGPlayerPricing;
                    var rarityFloors = [];
                    gameRarities.forEach(rarity => {
                        var rarityFloorItem = {
                            rarity: rarity
                        };
                        var defaultRarityFound = false;
                        this.defaultPriceRules.rarityFloors.forEach(defaultRarityFloor => {
                            if (defaultRarityFloor.rarity == rarity) {
                                defaultRarityFound = true;
                                rarityFloorItem.price = defaultRarityFloor.price;
                            }
                        })
                        if (!defaultRarityFound) {
                            rarityFloorItem.price = null;
                        }
                        rarityFloors.push(rarityFloorItem);
                    })
                    priceRule.rarityFloors = rarityFloors;
                }
                console.log(priceRule);
                return Promise.resolve(priceRule);
            }).catch(err => {
                this.props.store.MainStore.setError(err);
                return Promise.resolve();
            })
    }

    refreshStoreSettings() {
        this.setStoreSettings(null);
        this.props.store.SettingsStore.fetchStoreSettings()
            .then((result) => {
                this.setStoreSettings(result);
            })
            .catch(error => {
                this.props.store.MainStore.setError(error);
            })
    }

    render() {
        return (
            <React.Fragment>
                {this.props.store.SettingsStore.settings && this.storeSettings ?
                    <React.Fragment>
                        <div className="app-header">
                            <h2 className="title is-2">
                                <span className="app-icon purple"><i className="fas fa-users"></i></span>
                                <span className="header-text">Pricing Settings</span>
                            </h2>
                        </div>
                        <div>
                            <p>If you want to update any of these settings please contact us at <a href="mailto:support@binderpos.com">support@binderpos.com</a></p>
                            <br />
                            <div className="EventListing">
                                <div className="col-md-12 content">
                                    <div className="notEditable">
                                        <div className="row">
                                            <div className="col-md-6 field">
                                                <label className="label">Shopify URL</label>
                                                <div className="control">
                                                    <input disabled id="shopifyUrl" className="input" type="text" required placeholder="BinderPOS" defaultValue={this.props.store.SettingsStore.settings.siteUrl} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 field">
                                                <label className="label">Main Email</label>
                                                <div className="control">
                                                    <input disabled id="mainEmail" className="input" type="text" defaultValue={this.props.store.SettingsStore.settings.email} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 field">
                                                <label className="label">Store Currency</label>
                                                <div className="control">
                                                    <input disabled id="storeCurrency" className="input" type="text" defaultValue={this.props.store.SettingsStore.settings.currency} />
                                                </div>
                                            </div>
                                            {this.props.store.SettingsStore.settings.priceMultiplier ?
                                                <div className="col-md-6 field">
                                                    <label className="label">Price markup</label>
                                                    <div className="control">
                                                        <input disabled id="priceMarkup" className="input" type="text" defaultValue={this.props.store.SettingsStore.settings.priceMultiplier} />
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 field">
                                                <label className="label">Allow non english</label>
                                                <div className="control">
                                                    <input disabled id="shopifyUrl" className="input" type="text" required placeholder="BinderPOS" defaultValue={this.props.store.SettingsStore.settings.allowNonEnglish ? "Yes" : "No"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 field">
                                                <label className="label">Non english wording</label>
                                                <div className="control">
                                                    <input disabled id="mainEmail" className="input" type="text" defaultValue={this.props.store.SettingsStore.settings.nonEnglishWording || "Non English"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h4 className="title is-4">My Variants</h4>
                            <div className="content">
                                <div className="EventListing">
                                    <div className="col-md-12 content">
                                        <div className="row">
                                            {this.props.store.SettingsStore.settings.variantTypes && this.props.store.SettingsStore.settings.variantTypes.length > 0 ?
                                                this.props.store.SettingsStore.settings.variantTypes.map((variant, i) => {
                                                    return (
                                                        <div className="col-md-4 field" key={i}>
                                                            <label className="label">{variant.name}</label>
                                                            <div className="control">
                                                                <input disabled id="variantValue" className="input" type="text" defaultValue={Number(variant.multiplier).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })} />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="col-md-4 field">
                                                    <label className="label">{variant.name}</label>
                                                    <div className="control">
                                                        <input disabled id="variantValue" className="input" type="text" defaultValue="Non foil / Foil - 100% of card NM value" />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h3 className="title is-3">My price rules</h3>
                            <div className="content">
                                <div className="EventListing">
                                    <div className="col-md-12 content">
                                        {this.mergedPriceRulesForGames && this.mergedPriceRulesForGames.length > 0 ?
                                            <React.Fragment>
                                                {this.mergedPriceRulesForGames.map((priceRule, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <h4 className="title is-4">Price rules for {priceRule.gameName}</h4>
                                                            <div className="row">
                                                                <div className="col-md-6 field">
                                                                    <label className="label">Price markup</label>
                                                                    <div className="control">
                                                                        <input disabled id="priceMarkup" className="input" type="text" defaultValue={Number(priceRule.priceMarkup).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 field">
                                                                    <label className="label">Price multiplier (overrides currency conversion)</label>
                                                                    <div className="control">
                                                                        <input disabled id="priceMultiplier" className="input" type="text" defaultValue={priceRule.priceMultiplier && priceRule.priceMultiplier != 0 ? Number(priceRule.priceMultiplier).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }) : "No multiplier"} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 field">
                                                                    <label className="label">Price updates enabled</label>
                                                                    <div className="control">
                                                                        <input disabled id="updatePricing" className="input" type="text" defaultValue={priceRule.updatePricing ? "Yes" : "No"} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 field">
                                                                    <label className="label">Price rounding</label>
                                                                    <div className="control">
                                                                        <input disabled id="roundToNearest" className="input" type="text" defaultValue={priceRule.roundToNearest && priceRule.roundToNearest > 0 ? Number(priceRule.roundToNearest).toLocaleString(undefined, { minimumFractionDigits: 2 }) : "No price rounding"} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 field">
                                                                    <label className="label">Pricing database</label>
                                                                    <div className="control">
                                                                        <input disabled id="useTCGPlayerPricing" className="input" type="text" defaultValue={priceRule.useTCGPlayerPricing ? "TCGPlayer Pricing" : "Retail Pricing"} />
                                                                    </div>
                                                                </div>
                                                                {priceRule.useTCGPlayerPricing ?
                                                                    <div className="col-md-6 field" >
                                                                        <label className="label">TCGPlayer pricing type</label>
                                                                        <div className="control">
                                                                            <input disabled id="tcgPlayerPriceType" className="input" type="text" defaultValue={priceRule.tcgPlayerPriceType} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            <h5>Price floors for {priceRule.gameName}</h5>
                                                            <div class="row">
                                                                {priceRule.rarityFloors.map((rarityFloor, i) => {
                                                                    return (
                                                                        <div className="col-md-3 field">
                                                                            <label className="label">{rarityFloor.rarity} price floor</label>
                                                                            <div className="control">
                                                                                <input disabled id={rarityFloor.rarity} className="input" type="text" defaultValue={rarityFloor.price && rarityFloor.price > 0 ? Number(rarityFloor.price).toLocaleString(undefined, { minimumFractionDigits: 2 }) : "No price floor set"} />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </React.Fragment>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <Loader />
                }
            </React.Fragment>
        );
    }
}
