import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ReactToPrint from "react-to-print";

@inject("store")
@observer
class Receipt extends Component {

  convertDateToHumanReadable(theDate) {
    const date = new Date(theDate);
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
    return date.toLocaleDateString();
  }

  convertTime(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }

  calculateTotalItems() {
    let items = 0;
    this.props.store.CartStore.selectedCart.cartItems.forEach(cartItem => {
      if (cartItem.quantity > 0) {
        items += cartItem.quantity;
      }
    })
    return items;
  }

  render() {
    const fC = this.props.store.MainStore.currencyBuilder;
    const store = this.props.store.MainStore;
    const cart = this.props.store.CartStore.selectedCart;
    const totalItems = this.calculateTotalItems();
    return (
      <div className="receipt">
        <span className="name">{store.storeInfo ?.storeName}</span>
        <span className="details">Tel: {store.storeInfo ?.phoneNumber}</span>
        <span className="details">{store.storeInfo ?.addressLine1}</span>
        <span className="details">{store.storeInfo ?.addressLine2}</span>
        <span className="details">
          {store.storeInfo ?.city} {store.storeInfo ?.state}{" "}
        </span>
        <span className="details">
          {store.storeInfo ?.country} {store.storeInfo ?.zipCode}{" "}
        </span>
        {store.taxNumber ?
          <span className="taxNumber">
            {store.taxWording + " " + store.taxNumber}
          </span>
          :
          ""
        }
        {cart.customer ?
          <span className="saleId">
            {cart.customer.firstName} {cart.customer.lastName}
          </span>
          :
          ""
        }
        <span className="saleId">
          Sale# {cart.id}
          <em>Till: {cart.till.name}</em>
        </span>
        <span className="dateTime">
          {this.convertDateToHumanReadable(cart.dateSubmitted)}
          <em>{this.convertTime(new Date(cart.dateSubmitted).toLocaleTimeString())}</em>
        </span>
        <hr />
        <table>
          <tbody>
            {cart.cartItems.map((item, index) => (
              <tr key={index}>
                <td className="qty">{item.quantity}</td>
                <td className="itemTitle">
                  {item.productTitle}
                  <em>
                    {item.variantTitle != "-"
                      ? " - [" + item.variantTitle + "]"
                      : ""}
                  </em>
                </td>
                <td className="price">
                  {fC((item.price / item.quantity))}
                  <em className="lineTotal">{fC(item.price)}</em>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <span className="itemTotalCount">
          ** {totalItems} Items purchased on this sale **
        </span>
        <span className="subTotal">
          SubTotal <em>{fC((cart.subTotal))}</em>
        </span>
        <span className="GST">
          {store.taxWording}({cart.taxRate}%){" "}
          <em>{fC(cart.totalTax)}</em>
        </span>
        <span className="total">
          Total: <em>{fC(cart.totalPrice)}</em>
        </span>
        {cart.tenders ?
          <React.Fragment>
            {cart.tenders.map((tender, index) =>
              <React.Fragment key={index}>
                {tender.amount != 0 ?
                  <span className="subTotal">
                    {tender.type}: <em>{fC(tender.amount)}</em>
                  </span>
                  :
                  ""
                }
              </React.Fragment>
            )}
          </React.Fragment>
          :
          null
        }
      </div>
    );
  }
}

@observer
class ReceiptReprint extends Component {

  closeReceipt = () => {
    this.props.closeReceipt()
  }

  render() {
    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card receiptSize">
          <header className="modal-card-head">
            <p className="modal-card-title">Receipt Reprint Preview</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => this.closeReceipt()}
            />
          </header>
          <section className="modal-card-body">
            <Receipt ref={el => (this.componentRef = el)} />
          </section>
          <footer className="modal-card-foot">
            <ReactToPrint
              trigger={() => (
                <button className={"button is-info"}>Print</button>
              )}
              content={() => this.componentRef}
            />
          </footer>
        </div>
      </div>
    );
  }
}

export default ReceiptReprint;
