import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';

@inject("store")
@observer
class DateTime extends Component {

  render() {
    console.log(this.props.dateTime)
    console.log(this.props.store.SettingsStore.customerTimezone)
    return (
      <Moment format="DD MMM YYYY @ hh:mm a" tz={this.props.store.SettingsStore.customerTimezone}>
        {this.props.dateTime}
      </Moment>
    );
  }
}

export default DateTime;
