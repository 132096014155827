import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Loader, SideLabelNumberInput } from 'components'
import { BuylistMenuStructure } from 'menuStructures';

@inject('store')
@observer
export default class BuylistRule extends React.Component {

  @observable selectedCondition;
  @action setSelectedCondition(selectedCondition) {
    this.selectedCondition = selectedCondition;
  }

  @observable modalVisible = false;
  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable modalTitle;
  @action setModalTitle(title) {
    this.modalTitle = title;
  }

  @observable modalText;
  @action setModalText(text) {
    this.modalText = text;
  }

  @observable hasValidationErrors = false;
  @action setHasValidationErrors(bool) {
    this.hasValidationErrors = bool;
  }

  @observable updating;
  @action setUpdating(bool) {
    this.updating = bool
  }

  @observable buylistRuleToApply;
  @action setBuylistRuleToApply(buylistRule) {
    this.buylistRuleToApply = buylistRule;
  }

  @observable buylistIdPresent;
  @action setBuylistIdPresent(isPresent) {
    this.buylistIdPresent = isPresent;
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  fetchBuylistRule(id) {
    this.props.store.BuylistRulesStore.fetchBuylistRule(id)
      .then((result) => {
        this.props.store.BuylistRulesStore.setBuylistRule(result)
        let buylistRule = this.props.store.BuylistRulesStore.buylistRule;
        if (buylistRule.game && buylistRule.game != "all" && buylistRule.game != "") {
          this.fetchSetInfo(buylistRule.game);
          this.fetchCardRarities(buylistRule.game);
        }
        this.props.store.BuylistRulesStore.buylistRule.buylistVariants.forEach(buylistVariant => {
          buylistRule[`id-${buylistVariant.customerVariantId}`] = buylistVariant.id;
          buylistRule[`allowPurchase-${buylistVariant.customerVariantId}`] = (buylistVariant.allowPurchase == null || buylistVariant.allowPurchase == undefined ? false : buylistVariant.allowPurchase);
          buylistRule[`cashPercent-${buylistVariant.customerVariantId}`] = buylistVariant.cashPercent;
          buylistRule[`storeCreditPercent-${buylistVariant.customerVariantId}`] = buylistVariant.storeCreditPercent;
          buylistRule[`quantity-${buylistVariant.customerVariantId}`] = buylistVariant.quantity;
          buylistRule[`overQuantityCashPercent-${buylistVariant.customerVariantId}`] = buylistVariant.overQuantityCashPercent;
          buylistRule[`overQuantityAllowPurchase-${buylistVariant.customerVariantId}`] = (buylistVariant.overQuantityAllowPurchase == null || buylistVariant.overQuantityAllowPurchase == undefined ? false : buylistVariant.overQuantityAllowPurchase);
          buylistRule[`overQuantityStoreCreditPercent-${buylistVariant.customerVariantId}`] = buylistVariant.overQuantityStoreCreditPercent;
          buylistVariant.buylistVariantRarityRules.forEach(buylistVariantRarity => {
            let rarityNoSpace = buylistVariantRarity.rarity.replace(/\s/g, '').replace(/[^a-zA-Z ]/g, '');
            buylistRule[`id-${buylistVariant.customerVariantId}-${rarityNoSpace}`] = buylistVariantRarity.id;
            buylistRule[`allowPurchase-${buylistVariant.customerVariantId}-${rarityNoSpace}`] = (buylistVariantRarity.allowPurchase == null || buylistVariantRarity.allowPurchase == undefined ? false : buylistVariantRarity.allowPurchase);
            buylistRule[`cashPercent-${buylistVariant.customerVariantId}-${rarityNoSpace}`] = buylistVariantRarity.cashPercent;
            buylistRule[`storeCreditPercent-${buylistVariant.customerVariantId}-${rarityNoSpace}`] = buylistVariantRarity.storeCreditPercent;
            buylistRule[`quantity-${buylistVariant.customerVariantId}-${rarityNoSpace}`] = buylistVariantRarity.quantity;
            buylistRule[`overQuantityCashPercent-${buylistVariant.customerVariantId}-${rarityNoSpace}`] = buylistVariantRarity.overQuantityCashPercent;
            buylistRule[`overQuantityAllowPurchase-${buylistVariant.customerVariantId}-${rarityNoSpace}`] = (buylistVariantRarity.overQuantityAllowPurchase == null || buylistVariantRarity.overQuantityAllowPurchase == undefined ? false : buylistVariantRarity.overQuantityAllowPurchase);
            buylistRule[`overQuantityStoreCreditPercent-${buylistVariant.customerVariantId}-${rarityNoSpace}`] = buylistVariantRarity.overQuantityStoreCreditPercent;
          })
        })
        console.log(buylistRule);
        this.setBuylistRuleToApply(buylistRule);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  fetchSetInfo(type) {
    if (type) {
      this.props.store.CardStore.setSearchResults(null);
      this.props.store.CardStore.fetchCardSetNames(type)
        .then((result) => {
          this.props.store.CardStore.setSearchResults(result);
        })
        .catch(error => {
          this.props.store.MainStore.setError(error);
        });
    }
  }

  fetchCardRarities(type) {
    if (type) {
      this.props.store.CardStore.setRarities(null);
      this.props.store.CardStore.fetchCardRarities(type)
        .then((result) => {
          this.props.store.CardStore.setRarities(result);
        })
        .catch(error => {
          this.props.store.MainStore.setError(error);
        });
    }
  }

  fetchCustomerVariants() {
    this.props.store.CustomersStore.getCustomerVariants()
      .then((result) => {
        this.props.store.CustomersStore.setCustomerVariants(result);
        if (result.length > 0) {
          this.setSelectedCondition(result[0].id);
        }
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  fetchCardGames() {
    this.props.store.CardStore.fetchCardGames()
      .then((result) => {
        this.props.store.CardStore.setGames(result);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  showErrorModal(modalText, modalHeader) {
    this.setUpdating(false);
    this.setModalVisible(true);
    this.setModalTitle(modalHeader);
    this.setModalText(modalText);
    this.setHasValidationErrors(true);
  }

  componentWillMount() {
    console.log("HERE");
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);
    this.props.store.CardStore.setSearchResults(null);
    this.setBuylistIdPresent(null);
    const id = (this.props.match ?.params ?.buylistRuleId || null);
    this.setBuylistRuleToApply(null);
    this.fetchCardGames();
    this.fetchCustomerVariants();
    if (id) {
      document.title = 'Update Buylist Rule | BinderPOS';
      this.fetchBuylistRule(id);
      this.setBuylistIdPresent(true);
    } else {
      this.setBuylistIdPresent(false);
      document.title = 'Add Buylist Rule | BinderPOS';
    }
  }

  fetchSetsForSelectedItem() {
    var gameSelect = document.querySelector('#game');
    var selectedGame = gameSelect.options[gameSelect.selectedIndex].value;
    if (selectedGame == "all") {
      this.props.store.CardStore.setSearchResults(null);
      this.props.store.CardStore.setRarities(null);
    } else {
      this.fetchSetInfo(selectedGame);
      this.fetchCardRarities(selectedGame);
    }
  }

  fetchCardsForSelectedSet() {
    var gameSelect = document.querySelector('#game');
    var selectedGame = gameSelect.options[gameSelect.selectedIndex].value;
    var setSelect = document.querySelector('#set');
    var selectedSet = setSelect.options[setSelect.selectedIndex].value;
    if (selectedGame != "all") {
      this.props.store.CardStore.fetchCardsWithinSet(selectedGame, selectedSet)
        .then(result => {
          this.props.store.CardStore.setCards(result);
        })
        .catch(error => {
          this.props.store.MainStore.setError(error);
        })
    }
  }

  showOrHideField(key, className, checkbox, childCheckboxLabel) {
    var checkboxToFind = `#${checkbox}-${key}`;
    var divToFind = `#${className}-${key}`;
    var checkboxInput = document.querySelector(checkboxToFind);
    var divToShowOrHide = document.querySelector(divToFind);
    if (childCheckboxLabel && childCheckboxLabel.length > 0) {
      var childCheckboxLabelElement = document.querySelector("#" + childCheckboxLabel);
    }
    if (!checkboxInput.checked) {
      divToShowOrHide.classList.add("hideItem");
      if (childCheckboxLabelElement) {
        childCheckboxLabelElement.classList.add("hideItem");
      }
    } else {
      divToShowOrHide.classList.remove("hideItem");
      if (childCheckboxLabelElement) {
        childCheckboxLabelElement.classList.remove("hideItem");
      }
    }
  }

  validateBuylistRule(elm) {
    let modalText = ``;
    let canSubmit = true;
    const customerVariants = this.props.store.CustomersStore.customerVariants;
    if (!elm.buylistRuleName.value || elm.buylistRuleName.value == "") {
      modalText += `<p>Please enter a name for your buylist rule</p><br/>`;
      canSubmit = false;
    }
    for (let i = 0; i < customerVariants.length; i++) {
      if (elm[`allowPurchaseCheckbox-${customerVariants[i].id}`].checked == true) {
        if (!elm[`cashPercent-${customerVariants[i].id}`].value || elm[`cashPercent-${customerVariants[i].id}`].value == "") {
          modalText += `<p>Value is required for <strong>${customerVariants[i].name} Cash buy percentage</strong> when you allow the purchase of this variant</p><br/>`;
          canSubmit = false;
        }
        if (!elm[`storeCreditPercent-${customerVariants[i].id}`].value || elm[`storeCreditPercent-${customerVariants[i].id}`].value == "") {
          modalText += `<p>Value is required for <strong>${customerVariants[i].name} Store credit buy percentage</strong> when you allow the purchase of this variant</p><br/>`;
          canSubmit = false;
        }
        if (!elm[`quantity-${customerVariants[i].id}`].value || elm[`quantity-${customerVariants[i].id}`].value == "") {
          modalText += `<p>Value is required for <strong>${customerVariants[i].name} Stock level</strong> when you allow the purchase of this variant</p><br/>`;
          canSubmit = false;
        }
        if (elm[`allowOverStockPurchaseCheckbox-${customerVariants[i].id}`].checked == true) {
          if (!elm[`overQuantityCashPercent-${customerVariants[i].id}`].value || elm[`overQuantityCashPercent-${customerVariants[i].id}`].value == "") {
            modalText += `<p>Value is required for <strong>${customerVariants[i].name} Cash buy percentage over stock level</strong> when you allow the purchase of this variant when over stocked</p><br/>`;
            canSubmit = false;
          }
          if (!elm[`overQuantityStoreCreditPercent-${customerVariants[i].id}`].value || elm[`overQuantityStoreCreditPercent-${customerVariants[i].id}`].value == "") {
            modalText += `<p>Value is required for <strong>${customerVariants[i].name} Store credit buy percentage over stock level</strong> when you allow the purchase of this variant when over stocked</p><br/>`;
            canSubmit = false;
          }
        }
      }
    }
    if (!canSubmit) {
      this.setUpdating(false);
      this.setModalVisible(true);
      this.setModalTitle('Fix the following issues with your buylist rule');
      this.setModalText(modalText);
      this.setHasValidationErrors(true);
    }
    return canSubmit;
  }

  save(e) {
    e.preventDefault()
    this.setHasValidationErrors(false);
    this.setUpdating(true)

    const elm = this.form.current.elements;

    let canSubmit = this.validateBuylistRule(elm);

    if (canSubmit == true) {
      let listOfVariantRules = [];
      const customerVariants = this.props.store.CustomersStore.customerVariants;
      for (let i = 0; i < customerVariants.length; i++) {
        let listOfRarityRules = [];
        let rarities = this.props.store.CardStore.rarities;
        if (rarities && rarities.length > 0) {
          for (let j = 0; j < rarities.length; j++) {
            let rarityNoSpace = rarities[j].replace(/\s/g, '').replace(/[^a-zA-Z ]/g, '');
            let buylistVarirantRarityRule = {
              rarity: rarities[j],
              cashPercent: elm[`cashPercent-${customerVariants[i].id}-${rarityNoSpace}`].value,
              storeCreditPercent: elm[`storeCreditPercent-${customerVariants[i].id}-${rarityNoSpace}`].value,
              quantity: elm[`quantity-${customerVariants[i].id}-${rarityNoSpace}`].value,
              allowPurchase: (elm[`allowPurchaseCheckbox-${customerVariants[i].id}-${rarityNoSpace}`].checked ? true : false),
              overQuantityCashPercent: elm[`overQuantityCashPercent-${customerVariants[i].id}-${rarityNoSpace}`].value,
              overQuantityStoreCreditPercent: elm[`overQuantityStoreCreditPercent-${customerVariants[i].id}-${rarityNoSpace}`].value,
              overQuantityAllowPurchase: (elm[`allowOverStockPurchaseCheckbox-${customerVariants[i].id}-${rarityNoSpace}`].checked ? true : false),
            }
            if (this.props.match ?.params ?.buylistRuleId) {
              if (elm[`id-${customerVariants[i].id}-${rarityNoSpace}`].value && elm[`id-${customerVariants[i].id}-${rarityNoSpace}`].value != "") {
                buylistVarirantRarityRule.id = elm[`id-${customerVariants[i].id}-${rarityNoSpace}`].value;
              } else {
                buylistVarirantRarityRule.id = null;
              }
            }
            listOfRarityRules.push(buylistVarirantRarityRule);
          }
        }
        let buylistVarirantRule = {
          customerVariantId: customerVariants[i].id,
          cashPercent: elm[`cashPercent-${customerVariants[i].id}`].value,
          storeCreditPercent: elm[`storeCreditPercent-${customerVariants[i].id}`].value,
          quantity: elm[`quantity-${customerVariants[i].id}`].value,
          allowPurchase: (elm[`allowPurchaseCheckbox-${customerVariants[i].id}`].checked ? true : false),
          overQuantityCashPercent: elm[`overQuantityCashPercent-${customerVariants[i].id}`].value,
          overQuantityStoreCreditPercent: elm[`overQuantityStoreCreditPercent-${customerVariants[i].id}`].value,
          overQuantityAllowPurchase: (elm[`allowOverStockPurchaseCheckbox-${customerVariants[i].id}`].checked ? true : false),
          buylistVariantRarityRules: listOfRarityRules,
        }
        if (this.props.match ?.params ?.buylistRuleId) {
          buylistVarirantRule.id = elm[`id-${customerVariants[i].id}`].value;
        }
        listOfVariantRules.push(buylistVarirantRule);
      }

      if (this.props.match ?.params ?.buylistRuleId) {
        let buylistRule = {
          id: this.props.match.params.buylistRuleId,
          name: elm.buylistRuleName.value,
          price: elm.buylistRulePrice.value,
          priceRule: elm.buylistRulePriceRuleSelect[elm.buylistRulePriceRuleSelect.selectedIndex].value,
          buylistVariants: listOfVariantRules,
        };
        this.props.store.BuylistRulesStore.updateBuylistRule(buylistRule)
          .then(result => {
            this.setUpdating(false);
            this.setModalVisible(true);
            this.setModalTitle('Buylist rule updated');
            this.setModalText(result.message);
            this.props.store.BuylistRulesStore.rehydrate();
          }).catch(error => {
            this.setUpdating(false);
            this.props.store.MainStore.setError(error);
          })
      } else {
        let buylistRule = {
          name: elm.buylistRuleName.value,
          price: elm.buylistRulePrice.value,
          priceRule: elm.buylistRulePriceRuleSelect[elm.buylistRulePriceRuleSelect.selectedIndex].value,
          game: elm.game[elm.game.selectedIndex].value,
          buylistVariants: listOfVariantRules,
        };
        if (elm.set && elm.set.length > 0) {
          buylistRule.setName = elm.set[elm.set.selectedIndex].value;
        }
        if (elm.card && elm.card.length > 0) {
          buylistRule.cardId = elm.card[elm.card.selectedIndex].value;
          buylistRule.cardName = elm.card[elm.card.selectedIndex].text;
        }
        this.props.store.BuylistRulesStore.addBuylistRule(buylistRule)
          .then(result => {
            this.setUpdating(false);
            this.setModalVisible(true);
            this.setModalTitle('Buylist rule added');
            this.setModalText(result.message);
            this.props.store.BuylistRulesStore.rehydrate();
          }).catch(error => {
            this.setUpdating(false);
            this.props.store.MainStore.setError(error);
          })
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.buylist ?
          <React.Fragment>
            {this.props.store.CustomersStore.customerVariants && this.props.store.CardStore.games && (this.buylistIdPresent === true && this.buylistRuleToApply || this.buylistIdPresent === false) ?
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="header-text">{this.props.store.BuylistRulesStore.buylistRule ? `Update ${this.props.store.BuylistRulesStore.buylistRule.name}` : `Create new rule`}</span>
                  </h2>
                </div>
                <form ref={this.form} onSubmit={e => this.save(e)} noValidate>
                  <div className="rulePanel">
                    <div className="row">
                      <div className="col-md-12 field">
                        <label className="label">Buylist Rule Name</label>
                        <div className="control">
                          <input id="buylistRuleName" className="input" type="text" placeholder="E.g. Price greater than $100 rule" required defaultValue={this.buylistRuleToApply ?.name || ""} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rulePanel">
                    <p className="subHeading">Basic Rules</p>
                    <div className="row">
                      <div className="col-md-5th field">
                        <label className="label">Price to apply</label>
                        <div className="control">
                          <input id="buylistRulePrice" className="input" type="number" digits="2" min="0" placeholder="E.g. $100" required defaultValue={this.buylistRuleToApply && (this.buylistRuleToApply.price || this.buylistRuleToApply.price == 0) ? this.buylistRuleToApply.price : ""} />
                        </div>
                      </div>
                      <div className="col-md-5th field">
                        <label className="label">Price rule to apply</label>
                        <div className="control">
                          <div className="select">
                            <select id="buylistRulePriceRuleSelect" defaultValue={this.buylistRuleToApply ?.priceRule || ""}>
                              <option value="">Don't apply</option>
                              <option value="greaterThan">Greater than</option>
                              <option value="greaterThanOrEqualTo">Greater than or Equal to</option>
                              <option value="lessThan">Less than</option>
                              <option value="lessThanOrEqualTo">Less than or Equal to</option>
                              <option value="EqualTo">Equal to</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {!this.props.store.BuylistRulesStore.buylistRule ?
                        <div className="col-md-5th field">
                          <label className="label">Rule to apply to Game</label>
                          <div className="control has-icons-left">
                            <div className="select">
                              <select id="game" onChange={this.fetchSetsForSelectedItem.bind(this)}>
                                <option value="all">All games</option>
                                {this.props.store.CardStore.games.map((game, i) => {
                                  return (
                                    <option key={i} value={game.gameId}>{game.gameName}</option>
                                  )
                                })}
                              </select>
                            </div>
                            <span className="icon is-small is-left">
                              <i className="fas fa-gamepad"></i>
                            </span>
                          </div>
                        </div>
                        :
                        <div className="col-md-5th field">
                          <label className="label">Rule to apply to Game</label>
                          <p>{this.props.store.BuylistRulesStore.buylistRule.game && this.props.store.BuylistRulesStore.buylistRule.game != "all" ? this.props.store.BuylistRulesStore.buylistRule.gameName : "All games"}</p>
                        </div>
                      }
                      {this.props.store.CardStore.searchResults ?
                        <React.Fragment>
                          {!this.props.store.BuylistRulesStore.buylistRule ?
                            <div className="col-md-5th field">
                              <label className="label">Rule to apply to Set</label>
                              <div className="control has-icons-left">
                                <div className="select">
                                  <select id="set" onChange={this.fetchCardsForSelectedSet.bind(this)}>
                                    <option value=""></option>
                                    {this.props.store.CardStore.searchResults.map((set, i) => {
                                      return (
                                        <option key={i} value={set}>{set}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            :
                            <React.Fragment>
                              {this.props.store.BuylistRulesStore.buylistRule.setName ?
                                <div className="col-md-5th field">
                                  <label className="label">Rule to apply to Set</label>
                                  <p>{this.props.store.BuylistRulesStore.buylistRule.setName}</p>
                                </div>
                                :
                                null
                              }
                            </React.Fragment>
                          }
                        </React.Fragment>
                        :
                        null
                      }
                      {this.props.store.CardStore.cards ?
                        <React.Fragment>
                          {!this.props.store.BuylistRulesStore.buylistRule ?
                            <div className="col-md-5th field">
                              <label className="label">Rule to apply to Card</label>
                              <div className="control has-icons-left">
                                <div className="select">
                                  <select id="card">
                                    <option value=""></option>
                                    {this.props.store.CardStore.cards.map((card, i) => {
                                      return (
                                        <option key={i} value={card.id}>{card.cardName}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            :
                            <React.Fragment>
                              {this.props.store.BuylistRulesStore.buylistRule.cardName ?
                                <div className="col-md-5th field">
                                  <label className="label">Rule to apply to Card</label>
                                  <p>{this.props.store.BuylistRulesStore.buylistRule.cardName}</p>
                                </div>
                                :
                                null
                              }
                            </React.Fragment>
                          }
                        </React.Fragment>
                        :
                        null
                      }
                    </div>
                  </div>
                  <div className="conditionSelector">
                    {this.props.store.CustomersStore.customerVariants.map((customerVariant, i) => {
                      return (
                        <React.Fragment key={i}>
                          <span className={`tab tabid-${customerVariant.id}` + (this.selectedCondition == customerVariant.id ? " active" : "")} onClick={() => this.setSelectedCondition(customerVariant.id)}>{customerVariant.name}</span>
                        </React.Fragment>
                      )
                    }
                    )}
                  </div>
                  <div className="rulesWrapper">
                    {this.props.store.CustomersStore.customerVariants.map((customerVariant, i) => {
                      return (

                        <React.Fragment key={i}>
                          <div className={`rulesBlock variants variant-${customerVariant.id}` + (this.selectedCondition == customerVariant.id ? " active" : "")}>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <label className="checkbox">
                                    <input id={`allowPurchaseCheckbox-${customerVariant.id}`} type="checkbox" onChange={this.showOrHideField.bind(this, customerVariant.id, 'allowPurchase', 'allowPurchaseCheckbox')} defaultChecked={this.buylistRuleToApply ? this.buylistRuleToApply[`allowPurchase-${customerVariant.id}`] : false} />
                                    <span className="checkmark"></span>
                                    Allow the purchase of these cards from customers
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <label className="checkbox">
                                    <input id={`allowOverStockPurchaseCheckbox-${customerVariant.id}`} type="checkbox" onChange={this.showOrHideField.bind(this, customerVariant.id, 'allowOverStockPurchase', 'allowOverStockPurchaseCheckbox')} defaultChecked={this.buylistRuleToApply ? this.buylistRuleToApply[`overQuantityAllowPurchase-${customerVariant.id}`] : false} />
                                    <span className="checkmark"></span>
                                    Allow the purchase of these cards when stock level is too high
                                      </label>
                                </div>
                              </div>
                            </div>
                            <div className="card-content content">

                              {this.props.store.BuylistRulesStore.buylistRule ?
                                <div className="hideItem">
                                  <input id={`id-${customerVariant.id}`} type="hidden" defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`id-${customerVariant.id}`] : ""} />
                                </div>
                                :
                                null
                              }
                              <div id={`allowPurchase-${customerVariant.id}`} className={this.buylistRuleToApply ? (this.buylistRuleToApply[`allowPurchase-${customerVariant.id}`] ? "" : "hideItem") : "hideItem"}>
                                <div className="row">
                                  <div className="col-md-4">
                                    <SideLabelNumberInput label="Cash buy percentage" inputId={`cashPercent-${customerVariant.id}`} digits="2" min="0" placeholder="E.g. 60%" required={false}
                                      helpText="For example the card is worth $1.00, if you put 60% below this indicates to your customers that you will buy it off them for $0.60 cash" defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`cashPercent-${customerVariant.id}`] : ""} />
                                  </div>
                                  <div className="col-md-4">
                                    <SideLabelNumberInput label="Store credit buy percentage" inputId={`storeCreditPercent-${customerVariant.id}`} digits="2" min="0" placeholder="E.g. 70%" required={false}
                                      helpText="For example the card is worth $1.00, if you put 70% below this indicates to your customers that you will buy it off them for $0.70 store credit" defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`storeCreditPercent-${customerVariant.id}`] : ""} />
                                  </div>
                                  <div className="col-md-4">
                                    <SideLabelNumberInput label="Stock level" inputId={`quantity-${customerVariant.id}`} digits="0" min="0" placeholder="E.g. 10" required={false}
                                      helpText="Cards that have the stock equal to or greater than the number entered below can have additional rules." defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`quantity-${customerVariant.id}`] : ""} />
                                  </div>
                                </div>
                                <div id={`allowOverStockPurchase-${customerVariant.id}`} className={"revised " + (this.buylistRuleToApply ? (this.buylistRuleToApply[`overQuantityAllowPurchase-${customerVariant.id}`] ? "" : "hideItem") : "hideItem")}>
                                  <p className="subHeading">Overstock percentage overide</p>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <SideLabelNumberInput label="Cash buy percentage" inputId={`overQuantityCashPercent-${customerVariant.id}`} digits="2" min="0" placeholder="E.g. 40%" required={false}
                                        helpText="For example the card is worth $1.00, if you put 40% below this indicates to your customers that you will buy it off them for $0.40 cash" defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`overQuantityCashPercent-${customerVariant.id}`] : ""} />
                                    </div>
                                    <div className="col-md-4">
                                      <SideLabelNumberInput label="Store credit buy percentage" inputId={`overQuantityStoreCreditPercent-${customerVariant.id}`} digits="2" min="0" placeholder="E.g. 45%" required={false}
                                        helpText="For example the card is worth $1.00, if you put 45% below this indicates to your customers that you will buy it off them for $0.45 store credit" defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`overQuantityStoreCreditPercent-${customerVariant.id}`] : ""} />
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  {this.props.store.CardStore.rarities ?
                                    <div className={`rarityWrapper rarityContainer-${customerVariant.id}`}>
                                      <p className="subHeading centered">Rarities</p>
                                      {this.props.store.CardStore.rarities.map((rarity, j) => {
                                        let rarityNoSpace = rarity.replace(/\s/g, '').replace(/[^a-zA-Z ]/g, '');
                                        return (
                                          <div className={`rarities variants rarity-${rarityNoSpace}`} key={`${customerVariant.id}-${rarityNoSpace}`}>
                                            <div className="card-content content">
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <div className="field">
                                                    <label className="checkbox">
                                                      <input id={`allowPurchaseCheckbox-${customerVariant.id}-${rarityNoSpace}`} type="checkbox" onChange={this.showOrHideField.bind(this, rarityNoSpace, `allowPurchase-${customerVariant.id}`, `allowPurchaseCheckbox-${customerVariant.id}`, `allowOverStockPurchaseCheckboxLabel-${customerVariant.id}-${rarityNoSpace}`)} defaultChecked={this.buylistRuleToApply ? this.buylistRuleToApply[`allowPurchase-${customerVariant.id}-${rarityNoSpace}`] : false} />
                                                      <span className="checkmark"></span>
                                                      Purchase {rarity}
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="field">
                                                    <label className={"checkbox " + (this.buylistRuleToApply && this.buylistRuleToApply[`allowPurchase-${customerVariant.id}-${rarityNoSpace}`] ? "" : "hideItem")} id={`allowOverStockPurchaseCheckboxLabel-${customerVariant.id}-${rarityNoSpace}`}>
                                                      <input id={`allowOverStockPurchaseCheckbox-${customerVariant.id}-${rarityNoSpace}`} type="checkbox" onChange={this.showOrHideField.bind(this, rarityNoSpace, `allowOverStockPurchase-${customerVariant.id}`, `allowOverStockPurchaseCheckbox-${customerVariant.id}`)} defaultChecked={this.buylistRuleToApply ? this.buylistRuleToApply[`overQuantityAllowPurchase-${customerVariant.id}-${rarityNoSpace}`] : false} />
                                                      <span className="checkmark"></span> Allow overstocked purchases
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                              {this.props.store.BuylistRulesStore.buylistRule ?
                                                <div className="hideItem">
                                                  <input id={`id-${customerVariant.id}-${rarityNoSpace}`} type="hidden" defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`id-${customerVariant.id}-${rarityNoSpace}`] : ""} />
                                                </div>
                                                :
                                                null
                                              }
                                              <div id={`allowPurchase-${customerVariant.id}-${rarityNoSpace}`} className={this.buylistRuleToApply ? (this.buylistRuleToApply[`allowPurchase-${customerVariant.id}-${rarityNoSpace}`] ? "" : "hideItem") : "hideItem"}>
                                                <div className="row">
                                                  <div className="col-md-4 rarityHelp">
                                                    <SideLabelNumberInput helpText="Only input a % if overiding default rule settings" label="Cash buy percentage" inputId={`cashPercent-${customerVariant.id}-${rarityNoSpace}`} digits="2" min="0" placeholder="E.g. 60%" required={false} defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`cashPercent-${customerVariant.id}-${rarityNoSpace}`] : ""} />
                                                  </div>
                                                  <div className="col-md-4 rarityHelp">
                                                    <SideLabelNumberInput helpText="Only input a % if overiding default rule settings" label="Store credit buy percentage" inputId={`storeCreditPercent-${customerVariant.id}-${rarityNoSpace}`} digits="2" min="0" placeholder="E.g. 70%" required={false} defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`storeCreditPercent-${customerVariant.id}-${rarityNoSpace}`] : ""} />
                                                  </div>
                                                  <div className="col-md-4 rarityHelp">
                                                    <SideLabelNumberInput helpText="Only input a qty if overiding default rule settings" label="Stock level" inputId={`quantity-${customerVariant.id}-${rarityNoSpace}`} digits="0" min="0" placeholder="E.g. 10" required={false} defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`quantity-${customerVariant.id}-${rarityNoSpace}`] : ""} />
                                                  </div>
                                                </div>
                                                <div id={`allowOverStockPurchase-${customerVariant.id}-${rarityNoSpace}`} className={"revised " + (this.buylistRuleToApply ? (this.buylistRuleToApply[`overQuantityAllowPurchase-${customerVariant.id}-${rarityNoSpace}`] ? "" : "hideItem") : "hideItem")}>
                                                  <p className="subHeading">Revised {rarity} percentages if overstocked</p>
                                                  <div className="row">
                                                    <div className="col-md-4 rarityHelp">
                                                      <SideLabelNumberInput helpText="Only input a % if overiding default rule settings" label="Cash buy percentage" inputId={`overQuantityCashPercent-${customerVariant.id}-${rarityNoSpace}`} digits="2" min="0" placeholder="E.g. 40%" required={false} defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`overQuantityCashPercent-${customerVariant.id}-${rarityNoSpace}`] : ""} />
                                                    </div>
                                                    <div className="col-md-4 rarityHelp">
                                                      <SideLabelNumberInput helpText="Only input a % if overiding default rule settings" label="Store credit buy percentage" inputId={`overQuantityStoreCreditPercent-${customerVariant.id}-${rarityNoSpace}`} digits="2" min="0" placeholder="E.g. 45%" required={false} defaultValue={this.buylistRuleToApply ? this.buylistRuleToApply[`overQuantityStoreCreditPercent-${customerVariant.id}-${rarityNoSpace}`] : ""} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                    :
                                    null
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                  <button type="submit" className="button is-success buylistRuleBtn">{this.props.match ?.params ?.buylistRuleId ? "Update rule" : "Save rule"}</button>
                </form>
                {this.modalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.modalTitle}</p>
                      </header>
                      <section className="modal-card-body" dangerouslySetInnerHTML={{ __html: this.modalText }}>
                      </section>
                      <footer className="modal-card-foot">
                        {this.hasValidationErrors ?
                          <button className="button is-link" onClick={() => this.setModalVisible(false)}>Ok</button>
                          :
                          <Link to="/buylists/rules" className="button is-success">Ok</Link>
                        }
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
                {this.updating ?
                  <Loader text={this.props.store.BuylistRulesStore.buylistRule ? "Updating your buylist rule..." : "Adding your new buylist rule..."} />
                  :
                  null
                }
              </React.Fragment>
              :
              <Loader text={this.props.store.BuylistRulesStore.buylistRule ? "Loading buylist rule information... Please wait a moment." : "Loading information for buylist rules... Please wait a moment"} />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
