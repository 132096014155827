import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Loader, ProductLineItem, BulkUpdateModal, ConfirmationModal, LargeList } from 'components'
import { Link } from 'react-router-dom'
import Select from 'react-select'

const priceOverrideTypes = [
  {
    id: "",
    value: "Include both"
  },
  {
    id: "manual",
    value: "Manual override"
  },
  {
    id: "automatic",
    value: "Automatic price update"
  }
];

@inject('store')
@observer
export default class ProductList extends React.Component {

  @observable stockAddType = "set";
  @action setStockAddType(type) {
    this.stockAddType = type;
  }

  @observable currentInput = 1;
  @action setCurrentInput(currentInput) {
    this.currentInput = currentInput;
  }

  @observable deleteModalVisible;
  @action setDeleteModalVisible(deleteModalVisible) {
    this.deleteModalVisible = deleteModalVisible;
  }

  @observable savedSearchFilterToDelete;
  @action setSavedSearchFilterToDelete(filter) {
    this.savedSearchFilterToDelete = filter;
  }

  @observable deleteModalLoading;
  @action setDeleteModalLoading(bool) {
    this.deleteModalLoading = bool;
  }

  @observable offset = 0;
  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 25;
  @action setLimit(limit) {
    this.limit = limit;
  }

  @observable queuedJobsOffset = 0;
  @action setQueuedJobsOffset(queuedJobsOffset) {
    this.queuedJobsOffset = queuedJobsOffset;
  }

  @observable queuedJobsLimit = 20;
  @action setQueuedJobsLimit(queuedJobsLimit) {
    this.queuedJobsLimit = queuedJobsLimit;
  }

  @observable queuedJobsType;
  @action setQueuedJobsType(queuedJobsType) {
    this.queuedJobsType = queuedJobsType;
  }

  @observable filtersSetup = false;
  @action setFiltersSetup(bool) {
    this.filtersSetup = bool;
  }

  @observable selectedTags = [];
  @action setSelectedTags(tags) {
    this.selectedTags = tags;
  }

  @action pushTag(tag) {
    this.selectedTags.push(tag);
  }

  @observable productInfoSaving = false;
  @action setProductInfoSaving(saving) {
    this.productInfoSaving = saving;
  }

  @observable productInfo;
  @action setProductInfo(product) {
    this.productInfo = product;
  }

  @observable productInfoModalVisible;
  @action setProductInfoModalVisible(visible) {
    this.productInfoModalVisible = visible;
  }

  @observable selectedGame;
  @action setSelectedGame(selectedGame) {
    this.selectedGame = selectedGame;
  }

  @observable selectedTags;
  @action setSelectedTags(selectedTags) {
    this.selectedTags = selectedTags;
  }

  @observable selectedProductTypes;
  @action setSelectedProductTypes(selectedProductTypes) {
    this.selectedProductTypes = selectedProductTypes;
  }

  @observable selectedVendors;
  @action setSelectedVendors(selectedVendors) {
    this.selectedVendors = selectedVendors;
  }

  @observable selectedVariants;
  @action setSelectedVariants(selectedVariants) {
    this.selectedVariants = selectedVariants;
  }

  @observable selectedRarities;
  @action setSelectedRarities(selectedRarities) {
    this.selectedRarities = selectedRarities;
  }

  @observable selectedCardTypes;
  @action setSelectedCardTypes(selectedCardTypes) {
    this.selectedCardTypes = selectedCardTypes;
  }

  @observable selectedSets;
  @action setSelectedSets(selectedSets) {
    this.selectedSets = selectedSets;
  }

  @observable selectedMonsterTypes;
  @action setSelectedMonsterTypes(selectedMonsterTypes) {
    this.selectedMonsterTypes = selectedMonsterTypes;
  }

  @observable selectedColors;
  @action setSelectedColors(selectedColors) {
    this.selectedColors = selectedColors;
  }

  @observable showBulkUpdate;
  @action setShowBulkUpdate(bool) {
    this.showBulkUpdate = bool;
  }

  @observable bulkUpdateType;
  @action setBulkUpdateType(type) {
    this.bulkUpdateType = type;
  }

  @observable variantsToUpdate;
  @action setVariantsToUpdate(variantsToUpdate) {
    this.variantsToUpdate = variantsToUpdate;
  }

  @observable currentSearch;
  @action setCurrentSearch(currentSearch) {
    this.currentSearch = currentSearch;
  }

  @observable productCount;
  @action setProductCount(productCount) {
    this.productCount = productCount;
  }

  @observable selectedTab;
  @action setSelectedTab(selectedTab) {
    this.selectedTab = selectedTab;
  }

  @observable selectedPriceOverrideType;
  @action setSelectedPriceOverrideType(selectedPriceOverrideType) {
    this.selectedPriceOverrideType = selectedPriceOverrideType;
  }

  @observable minimizeFilters = false;
  @action setMinimizeFitlers(bool) {
    this.minimizeFilters = bool;
  }

  @observable columnsToShow = ["stock", "sellPrice", "sellPriceOverride", "cashBuyPrice", "cashBuyPercent", "creditBuyPrice", "creditBuyPercent", "buyLimit", "overstockCashBuyPrice", "overstockCashBuyPercent", "overstockCreditBuyPrice", "overstockCreditBuyPercent", "updateButton"];
  @action setColumnsToShow(columnsToShow) {
    this.columnsToShow = columnsToShow;
  }

  @observable columnsToUpdate = ["stock", "sellPrice", "sellPriceOverride", "cashBuyPrice", "cashBuyPercent", "creditBuyPrice", "creditBuyPercent", "buyLimit", "overstockCashBuyPrice", "overstockCashBuyPercent", "overstockCreditBuyPrice", "overstockCreditBuyPercent", "updateButton"];
  @action setColumnsToUpdate(columnsToUpdate) {
    this.columnsToUpdate = columnsToUpdate;
  }

  @observable sortTypes;
  @action setSortTypes(sortTypes) {
    this.sortTypes = sortTypes;
  }

  @observable stockUpdateTypes = [
    {
      label: "Set inventory level",
      value: "set"
    }, {
      label: "Add inventory level",
      value: "add"
    }, {
      label: "Remove inventory level",
      value: "remove"
    }
  ];

  @observable selectedSortType = {
    label: "Title A-Z",
    value: {
      type: "title",
      asc: true
    }
  };
  @action setSelectedSortType(selectedSortType) {
    this.selectedSortType = selectedSortType;
  }

  @observable reloadProductFilters = false;
  @action setReloadProductFilters(bool) {
    this.reloadProductFilters = bool;
  }

  @observable showProcessUpdateVariants = false;
  @action setShowProcessUpdateVariants(bool) {
    this.showProcessUpdateVariants = bool;
  }

  cancelVariantUpdate = () => {
    this.setShowProcessUpdateVariants(false);
  }

  updateCurrentInput = (value) => {
    this.setCurrentInput(value);
  }

  getCurrentInput = () => {
    return this.currentInput;
  }

  getCurrentStockUpdateType = () => {
    return this.stockAddType;
  }

  processLineByLineUpdateList = [];

  constructor(props) {
    super(props);
    this.advancedSearchForm = React.createRef();
    this.columnToggle = React.createRef();
    this.scrollTo = React.createRef();
  }

  handleSelectedTagsChange = (selectedOptions) => {
    this.setSelectedTags(selectedOptions);
  }

  handleSelectedProductTypesChange = (selectedOptions) => {
    this.setSelectedProductTypes(selectedOptions);
  }

  handleSelectedVendorsChange = (selectedOptions) => {
    this.setSelectedVendors(selectedOptions);
  }

  handleSelectedVariantsChange = (selectedOptions) => {
    this.setSelectedVariants(selectedOptions);
  }

  handleSelectedRaritiesChange = (selectedOptions) => {
    this.setSelectedRarities(selectedOptions);
  }

  handleSelectedSetsChange = (selectedOptions) => {
    this.setSelectedSets(selectedOptions);
  }

  handleSelectedCardTypesChange = (selectedOptions) => {
    this.setSelectedCardTypes(selectedOptions);
  }

  handleSelectedMonsterTypesChange = (selectedOptions) => {
    this.setSelectedMonsterTypes(selectedOptions);
  }

  handleSelectedColorsChange = (selectedOptions) => {
    this.setSelectedColors(selectedOptions);
  }

  handleStockUpdateType = (selectedOption) => {
    this.setStockAddType(selectedOption.value);
  }

  handleSelectedPriceOverrideType = (selectedOption) => {
    console.log(selectedOption.value);
    this.setSelectedPriceOverrideType(selectedOption.value);
  }

  buildSearchString(searchFilters) {
    var searchUpdated = "";
    if (searchFilters.game && searchFilters.game.length > 0) {
      searchUpdated += "Game: " + searchFilters.game + ", ";
    }
    if (searchFilters.title && searchFilters.title.length > 0) {
      searchUpdated += "Title: " + searchFilters.title + ", ";
    }
    if (searchFilters.productTypes && searchFilters.productTypes.length > 0) {
      searchUpdated += "Product Types: " + this.buildListForSearchFilters(searchFilters.productTypes) + ", ";
    }
    if (searchFilters.vendors && searchFilters.vendors.length > 0) {
      searchUpdated += "Vendors: " + this.buildListForSearchFilters(searchFilters.vendors) + ", ";
    }
    if (searchFilters.tags && searchFilters.tags.length > 0) {
      searchUpdated += "Tags: " + this.buildListForSearchFilters(searchFilters.tags) + ", ";
    }
    if (searchFilters.setNames && searchFilters.setNames.length > 0) {
      searchUpdated += "Set Names: " + this.buildListForSearchFilters(searchFilters.setNames) + ", ";
    }
    if (searchFilters.variants && searchFilters.variants.length > 0) {
      searchUpdated += "Variants: " + this.buildListForSearchFilters(searchFilters.variants) + ", ";
    }
    if (searchFilters.colors && searchFilters.colors.length > 0) {
      searchUpdated += "Colors: " + this.buildListForSearchFilters(searchFilters.colors) + ", ";
    }
    if (searchFilters.types && searchFilters.types.length > 0) {
      searchUpdated += "Types: " + this.buildListForSearchFilters(searchFilters.types) + ", ";
    }
    if (searchFilters.rarities && searchFilters.rarities.length > 0) {
      searchUpdated += "Rarities: " + this.buildListForSearchFilters(searchFilters.rarities) + ", ";
    }
    if (searchFilters.monsterTypes && searchFilters.rarities.monsterTypes > 0) {
      searchUpdated += "Monster types: " + this.buildListForSearchFilters(searchFilters.monsterTypes) + ", ";
    }
    if (searchFilters.priceGreaterThan) {
      searchUpdated += "Price > " + searchFilters.priceGreaterThan + ", ";
    }
    if (searchFilters.priceLessThan) {
      searchUpdated += "Price < " + searchFilters.priceLessThan + ", ";
    }
    if (searchFilters.priceOverrideType) {
      searchUpdated += "Price override type: " + searchFilters.priceOverrideType + ", ";
    }
    if (searchFilters.overallQuantityGreaterThan) {
      searchUpdated += "Overall Quantity > " + searchFilters.overallQuantityGreaterThan + ", ";
    }
    if (searchFilters.overallQuantityLessThan) {
      searchUpdated += "Overall Quantity < " + searchFilters.overallQuantityLessThan + ", ";
    }
    if (searchFilters.quantityGreaterThan) {
      searchUpdated += "Quantity > " + searchFilters.quantityGreaterThan + ", ";
    }
    if (searchFilters.quantityLessThan) {
      searchUpdated += "Quantity < " + searchFilters.quantityLessThan + ", ";
    }
    if (searchFilters.barcode && searchFilters.barcode.length > 0) {
      searchUpdated += "Barcode: " + searchFilters.barcode + ", ";
    }
    if (searchFilters.sku && searchFilters.sku.length > 0) {
      searchUpdated += "Sku: " + searchFilters.sku + ", ";
    }
    return searchUpdated.substring(0, searchUpdated.length - 2);
  }

  buildListForSearchFilters(list) {
    var listOfItemsToReturn = "[";
    list.forEach(item => {
      listOfItemsToReturn += item + ", ";
    })
    listOfItemsToReturn = listOfItemsToReturn.substring(0, listOfItemsToReturn.length - 2);
    listOfItemsToReturn += "]";
    return listOfItemsToReturn;
  }

  componentDidMount() {
    document.title = 'Products | BinderPOS';
    this.props.store.MainStore.getCurrency();
    this.setupGenericFilter();
    this.currentSearch = {
      sortTypes: [{
        type: "title",
        asc: true
      }]
    };
    this.setProductCount(0);
    this.props.store.ProductsStore.setProducts([]);
    this.props.store.ProductsStore.rehydrateSavedSearches();
    let sortTypes = [{
      label: "Title A-Z",
      value: {
        type: "title",
        asc: true
      }
    }, {
      label: "Title Z-A",
      value: {
        type: "title",
        asc: false
      }
    }, {
      label: "Price high to low",
      value: {
        type: "price",
        asc: false
      }
    }, {
      label: "Price low to high",
      value: {
        type: "price",
        asc: true
      }
    }, {
      label: "Stock high to low",
      value: {
        type: "inventoryQuantity",
        asc: false
      }
    }, {
      label: "Stock low to high",
      value: {
        type: "inventoryQuantity",
        asc: true
      }
    }];
    this.setSortTypes(sortTypes);
  }

  buildOptionsList(listToConvert) {
    let options = [];
    listToConvert.forEach(item => {
      if (typeof item === "string") {
        if (item && item.length > 0) {
          options.push({ value: item, label: item });
        }
      } else {
        if (item.value && item.value.length > 0) {
          options.push({ value: item.id, label: item.value });
        }
      }
    })
    return options;
  }

  setupGenericFilter() {
    this.fetchCardGames();
    this.props.store.ProductsStore.buildGenericFilters()
      .then(() => {
        this.setFiltersSetup(true);
      })
  }

  changeGameType() {
    this.props.store.CardStore.buildGameTypeData
  }

  fetchInformationForGameType = (selectedOptions) => {
    this.setSelectedGame(selectedOptions.value);
    if (!selectedOptions.value || selectedOptions.value.length == 0) {
      this.props.store.CardStore.clearGameTypeData()
    } else {
      this.props.store.CardStore.buildGameTypeData(selectedOptions.value);
    }
    this.setSelectedVariants(null);
    this.setSelectedRarities(null);
    this.setSelectedSets(null);
    this.setSelectedCardTypes(null);
    this.setSelectedMonsterTypes(null);
    this.setSelectedColors(null);
  }

  fetchInformationForFilterForGameType(game) {
    this.setSelectedGame(game);
    if (!game || game.length == 0) {
      this.props.store.CardStore.clearGameTypeData()
    } else {
      this.props.store.CardStore.buildGameTypeData(game);
    }
  }

  fetchCardGames() {
    this.props.store.CardStore.fetchCardGames()
      .then((results) => {
        let options = [{ value: "", label: "All games" }];
        results.forEach(item => {
          options.push({ value: item.gameId, label: item.gameName });
        })
        this.props.store.CardStore.setGames(options);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  cancelBulkUpdate = () => {
    this.setShowBulkUpdate(false);
    this.setVariantsToUpdate(null);
    this.setBulkUpdateType(null);
  }

  @action
  getNextProducts(top) {
    this.processLineByLineUpdateList = [];
    this.props.store.ProductsStore.setProducts(null);
    if (this.currentSearch) {
      this.currentSearch.offset = (this.offset + 25);
      this.currentSearch.limit = this.limit;
    } else {
      this.currentSearch = {
        offset: this.offset + 25,
        limit: 25
      }
    }
    this.props.store.ProductsStore.advancedSearch(this.currentSearch)
      .then((result) => {
        this.setOffset(result.offset);
        this.setLimit(result.limit);
        this.setProductCount(result.count);
        this.props.store.ProductsStore.setProducts(result.products);
        this.handleScroll();
        if (!top) {
          this.handleScroll();
        }
      })
      .catch(err => {
        this.props.store.MainStore.setError(err);
      })
  }

  handleScroll = () => {
    const { index, selected } = this.props
    if (index === selected) {
      setTimeout(() => {
        var element = this.scrollTo.current;
        var headerOffset = 100;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "auto"
        });
      }, 250)
    }
  }

  @action
  getPrevProducts(top) {
    this.processLineByLineUpdateList = [];
    this.props.store.ProductsStore.setProducts(null);

    var offsetUpdate = this.offset - 25;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.currentSearch.offset = offsetUpdate;
    this.currentSearch.limit = this.limit;
    this.props.store.ProductsStore.advancedSearch(this.currentSearch)
      .then((result) => {
        this.setOffset(result.offset);
        this.setLimit(result.limit);
        this.setProductCount(result.count);
        this.props.store.ProductsStore.setProducts(result.products);
        this.handleScroll();
        if (!top) {
          this.handleScroll();
        }
      })
      .catch(err => {
        this.props.store.MainStore.setError(err);
      })
  }

  setProductToUpdateStock(product) {
    this.setProductInfoSaving(false);
    this.setProductInfo(product);
    this.setProductInfoModalVisible(true);
  }

  getValues(list) {
    return (list && list.length > 0) ? list.map(s => s.value) : [];
  }

  processBatchUpdate() {
    let variantsToUpdate = [];
    this.props.store.ProductsStore.products.forEach(product => {
      product.variants.forEach(variant => {
        variantsToUpdate.push(variant.shopifyId);
      })
    });
    this.setVariantsToUpdate(variantsToUpdate);
    this.setBulkUpdateType("batch");
    this.setShowBulkUpdate(true);
  }

  processBulkUpdate() {
    this.processLineByLineUpdateList = [];
    this.props.store.ProductsStore.setProducts(null);
    const elm = this.advancedSearchForm.current.elements;

    var selectedTags = this.getValues(this.selectedTags);
    var selectedProductTypes = this.getValues(this.selectedProductTypes);
    var selectedVendors = this.getValues(this.selectedVendors);
    var selectedVariants = this.getValues(this.selectedVariants);
    var selectedRarities = this.getValues(this.selectedRarities);
    var selectedCardTypes = this.getValues(this.selectedCardTypes);
    var selectedSets = this.getValues(this.selectedSets);
    var selectedMonsterTypes = this.getValues(this.selectedMonsterTypes);
    var selectedColors = this.getValues(this.selectedColors);

    let searchObj = {
      game: this.selectedGame,
      tags: selectedTags,
      productTypes: selectedProductTypes,
      vendors: selectedVendors,
      variants: selectedVariants,
      rarities: selectedRarities,
      types: selectedCardTypes,
      setNames: selectedSets,
      monsterTypes: selectedMonsterTypes,
      colors: selectedColors,
      title: (elm.title.value && elm.title.value.length > 0 ? elm.title.value : null),
      barcode: (elm.barcode.value && elm.barcode.value.length > 0 ? elm.barcode.value : null),
      sku: (elm.sku.value && elm.sku.value.length > 0 ? elm.sku.value : null),
      priceOverrideType: (this.selectedPriceOverrideType && this.selectedPriceOverrideType.length > 0 ? this.selectedPriceOverrideType : null),
      priceGreaterThan: (elm.priceGreaterThan.value && elm.priceGreaterThan.value.length > 0 ? parseFloat(elm.priceGreaterThan.value) : null),
      priceLessThan: (elm.priceLessThan.value && elm.priceLessThan.value.length > 0 ? parseFloat(elm.priceLessThan.value) : null),
      overallQuantityGreaterThan: (elm.overallQuantityGreaterThan.value && elm.overallQuantityGreaterThan.value.length > 0 ? parseInt(elm.overallQuantityGreaterThan.value) : null),
      overallQuantityLessThan: (elm.overallQuantityLessThan.value && elm.overallQuantityLessThan.value.length > 0 ? parseInt(elm.overallQuantityLessThan.value) : null),
      quantityGreaterThan: (elm.quantityGreaterThan.value && elm.quantityGreaterThan.value.length > 0 ? parseInt(elm.quantityGreaterThan.value) : null),
      quantityLessThan: (elm.quantityLessThan.value && elm.quantityLessThan.value.length > 0 ? parseInt(elm.quantityLessThan.value) : null),
    }
    this.setCurrentSearch(searchObj);

    this.props.store.ProductsStore.advancedSearch(searchObj)
      .then((result) => {
        this.setOffset(result.offset);
        this.setLimit(result.limit);
        this.setProductCount(result.count);
        this.props.store.ProductsStore.setProducts(result.products);
        this.setBulkUpdateType("bulk");
        this.setShowBulkUpdate(true);
      })
      .catch(err => {
        this.props.store.ProductsStore.setProducts([]);
        this.props.store.MainStore.setError(err);
      })
  }

  applyFilters(searchObj) {
    const self = this;
    this.setReloadProductFilters(true);
    this.props.store.ProductsStore.setProducts(null);
    this.setCurrentSearch(searchObj);
    this.fetchInformationForFilterForGameType(searchObj.game);
    setTimeout(function () {
      self.setReloadProductFilters(false);
    }, 250)

    this.props.store.ProductsStore.advancedSearch(searchObj)
      .then((result) => {
        this.setOffset(result.offset);
        this.setLimit(result.limit);
        this.setProductCount(result.count);
        this.props.store.ProductsStore.setProducts(result.products);
      })
      .catch(err => {
        this.props.store.ProductsStore.setProducts([]);
        this.props.store.MainStore.setError(err);
      })
  }

  processAdvancedSearch(e) {
    if (e) {
      e.preventDefault()
    }
    this.processLineByLineUpdateList = [];
    this.props.store.ProductsStore.setProducts(null);

    const elm = this.advancedSearchForm.current.elements;

    var selectedTags = this.getValues(this.selectedTags);
    var selectedProductTypes = this.getValues(this.selectedProductTypes);
    var selectedVendors = this.getValues(this.selectedVendors);
    var selectedVariants = this.getValues(this.selectedVariants);
    var selectedRarities = this.getValues(this.selectedRarities);
    var selectedCardTypes = this.getValues(this.selectedCardTypes);
    var selectedSets = this.getValues(this.selectedSets);
    var selectedMonsterTypes = this.getValues(this.selectedMonsterTypes);
    var selectedColors = this.getValues(this.selectedColors);

    let searchObj = {
      game: this.selectedGame,
      tags: selectedTags,
      productTypes: selectedProductTypes,
      vendors: selectedVendors,
      variants: selectedVariants,
      rarities: selectedRarities,
      types: selectedCardTypes,
      setNames: selectedSets,
      monsterTypes: selectedMonsterTypes,
      colors: selectedColors,
      title: (elm.title.value && elm.title.value.length > 0 ? elm.title.value : null),
      barcode: (elm.barcode.value && elm.barcode.value.length > 0 ? elm.barcode.value : null),
      sku: (elm.sku.value && elm.sku.value.length > 0 ? elm.sku.value : null),
      priceOverrideType: (this.selectedPriceOverrideType && this.selectedPriceOverrideType.length > 0 ? this.selectedPriceOverrideType : null),
      priceGreaterThan: (elm.priceGreaterThan.value && elm.priceGreaterThan.value.length > 0 ? parseFloat(elm.priceGreaterThan.value) : null),
      priceLessThan: (elm.priceLessThan.value && elm.priceLessThan.value.length > 0 ? parseFloat(elm.priceLessThan.value) : null),
      overallQuantityGreaterThan: (elm.overallQuantityGreaterThan.value && elm.overallQuantityGreaterThan.value.length > 0 ? parseInt(elm.overallQuantityGreaterThan.value) : null),
      overallQuantityLessThan: (elm.overallQuantityLessThan.value && elm.overallQuantityLessThan.value.length > 0 ? parseInt(elm.overallQuantityLessThan.value) : null),
      quantityGreaterThan: (elm.quantityGreaterThan.value && elm.quantityGreaterThan.value.length > 0 ? parseInt(elm.quantityGreaterThan.value) : null),
      quantityLessThan: (elm.quantityLessThan.value && elm.quantityLessThan.value.length > 0 ? parseInt(elm.quantityLessThan.value) : null),
      sortTypes: this.selectedSortType ? [this.selectedSortType.value] : [{
        type: "title",
        asc: true
      }],
    }

    this.setCurrentSearch(searchObj);

    this.props.store.ProductsStore.advancedSearch(searchObj)
      .then((result) => {
        this.setOffset(result.offset);
        this.setLimit(result.limit);
        this.setProductCount(result.count);
        this.props.store.ProductsStore.setProducts(result.products);
      })
      .catch(err => {
        this.props.store.ProductsStore.setProducts([]);
        this.props.store.MainStore.setError(err);
      })
  }

  saveFilter() {
    const elm = this.advancedSearchForm.current.elements;

    var selectedTags = this.getValues(this.selectedTags);
    var selectedProductTypes = this.getValues(this.selectedProductTypes);
    var selectedVendors = this.getValues(this.selectedVendors);
    var selectedVariants = this.getValues(this.selectedVariants);
    var selectedRarities = this.getValues(this.selectedRarities);
    var selectedCardTypes = this.getValues(this.selectedCardTypes);
    var selectedSets = this.getValues(this.selectedSets);
    var selectedMonsterTypes = this.getValues(this.selectedMonsterTypes);
    var selectedColors = this.getValues(this.selectedColors);
    var searchName = elm.saveFilter.value;

    if (searchName && searchName.length > 0) {
      let searchObj = {
        name: searchName,
        game: this.selectedGame,
        tags: selectedTags,
        productTypes: selectedProductTypes,
        vendors: selectedVendors,
        variants: selectedVariants,
        rarities: selectedRarities,
        types: selectedCardTypes,
        setNames: selectedSets,
        monsterTypes: selectedMonsterTypes,
        colors: selectedColors,
        title: (elm.title.value && elm.title.value.length > 0 ? elm.title.value : null),
        barcode: (elm.barcode.value && elm.barcode.value.length > 0 ? elm.barcode.value : null),
        sku: (elm.sku.value && elm.sku.value.length > 0 ? elm.sku.value : null),
        priceOverrideType: (this.selectedPriceOverrideType && this.selectedPriceOverrideType.length > 0 ? this.selectedPriceOverrideType : null),
        priceGreaterThan: (elm.priceGreaterThan.value && elm.priceGreaterThan.value.length > 0 ? parseFloat(elm.priceGreaterThan.value) : null),
        priceLessThan: (elm.priceLessThan.value && elm.priceLessThan.value.length > 0 ? parseFloat(elm.priceLessThan.value) : null),
        overallQuantityGreaterThan: (elm.overallQuantityGreaterThan.value && elm.overallQuantityGreaterThan.value.length > 0 ? parseInt(elm.overallQuantityGreaterThan.value) : null),
        overallQuantityLessThan: (elm.overallQuantityLessThan.value && elm.overallQuantityLessThan.value.length > 0 ? parseInt(elm.overallQuantityLessThan.value) : null),
        quantityGreaterThan: (elm.quantityGreaterThan.value && elm.quantityGreaterThan.value.length > 0 ? parseInt(elm.quantityGreaterThan.value) : null),
        quantityLessThan: (elm.quantityLessThan.value && elm.quantityLessThan.value.length > 0 ? parseInt(elm.quantityLessThan.value) : null),
      }

      this.props.store.ProductsStore.saveSearch(searchObj)
        .then(() => {
          this.props.store.ProductsStore.rehydrateSavedSearches();
          this.props.store.MainStore.setInfo({
            title: "Search filter saved!",
            message: "Your search filter '" + searchName + "' has been saved!"
          });

        }).catch(err => {
          this.props.store.MainStore.setError(err);
        })

    } else {
      this.props.store.MainStore.setError({
        error: "Search filter name cannot be empty",
        detailedError: "Please enter a name for you saved search"
      });
    }

  }

  changeSortType = (selectedOption) => {
    this.setSelectedSortType(selectedOption);
    this.processAdvancedSearch();
  }

  @action
  updateColumnShown(columnName) {
    var checked = this.columnToggle.current.elements[columnName + "ColumnCheckbox"].checked;
    if (checked) {
      if (!this.columnsToShow.includes(columnName)) {
        this.columnsToShow.push(columnName);
      }
    } else {
      if (this.columnsToShow.includes(columnName)) {
        var index = this.columnsToShow.indexOf(columnName);
        if (index > -1) {
          this.columnsToShow.splice(index, 1);
        }
      }
    }
  }

  processColumnUpdate() {
    var toSet = JSON.parse(JSON.stringify(this.columnsToShow));
    this.setColumnsToUpdate(toSet);
  }

  processLineUpdates = () => {
    this.processLineByLineUpdateList.forEach(functionToRun => {
      functionToRun();
    });
    this.setShowProcessUpdateVariants(false);
  }

  showDeleteModal(searchFilter) {
    this.setSavedSearchFilterToDelete(searchFilter);
    this.setDeleteModalVisible(true);
  }

  deleteFilter(filterId) {
    this.setDeleteModalLoading(true);
    this.props.store.ProductsStore.deleteSavedSearchFilter(filterId)
      .then(() => {
        this.props.store.ProductsStore.rehydrateSavedSearches()
        this.setDeleteModalLoading(false);
        this.setDeleteModalVisible(false);
        this.setSavedSearchFilterToDelete(null);
      })
      .catch(err => {
        this.setDeleteModalLoading(false);
        this.setDeleteModalVisible(false);
        this.setSavedSearchFilterToDelete(null);
        this.props.store.MainStore.setError(err);
      })
  }

  getDefaultValuesForFields(type) {
    if (type == "game") {
      var selectedGame = this.props.store.CardStore.games;
      if (this.currentSearch && this.currentSearch.game) {
        var found = selectedGame.filter(game => game.value == this.currentSearch.game);
        if (found && found.length > 0) {
          return found;
        }
      }
      return selectedGame[0];
    }
    if (type == "productType") {
      var selected = this.buildOptionsList(this.props.store.ProductsStore.productTypes);
      if (this.currentSearch && this.currentSearch.productTypes) {
        var found = selected.filter(s => this.currentSearch.productTypes.includes(s.value));
        this.setSelectedProductTypes(found);
        return found;
      }
    }
    if (type == "vendor") {
      var selected = this.buildOptionsList(this.props.store.ProductsStore.vendors);
      if (this.currentSearch && this.currentSearch.vendors) {
        var found = selected.filter(s => this.currentSearch.vendors.includes(s.value));
        this.setSelectedVendors(found);
        return found;
      }
    }
    if (type == "variants") {
      var selected = this.buildOptionsList(this.props.store.CardStore.cardVariants);
      if (this.currentSearch && this.currentSearch.variants) {
        var found = selected.filter(s => this.currentSearch.variants.includes(s.value));
        this.setSelectedVariants(found);
        return found;
      }
    }
    if (type == "types") {
      var selected = this.buildOptionsList(this.props.store.CardStore.cardTypes);
      if (this.currentSearch && this.currentSearch.types) {
        var found = selected.filter(s => this.currentSearch.types.includes(s.value));
        this.setSelectedCardTypes(found);
        return found;
      }
    }
    if (type == "tags") {
      var selected = this.buildOptionsList(this.props.store.ProductsStore.tags);
      if (this.currentSearch && this.currentSearch.tags) {
        var found = selected.filter(s => this.currentSearch.tags.includes(s.value));
        this.setSelectedTags(found);
        return found;
      }
    }
    if (type == "setNames") {
      var selected = this.buildOptionsList(this.props.store.CardStore.sets);
      if (this.currentSearch && this.currentSearch.setNames) {
        var found = selected.filter(s => this.currentSearch.setNames.includes(s.value));
        this.setSelectedSets(found);
        return found;
      }
    }
    if (type == "rarities") {
      var selected = this.buildOptionsList(this.props.store.CardStore.rarities);
      if (this.currentSearch && this.currentSearch.rarities) {
        var found = selected.filter(s => this.currentSearch.rarities.includes(s.value));
        this.setSelectedRarities(found);
        return found;
      }
    }
    if (type == "monsterTypes") {
      var selected = this.buildOptionsList(this.props.store.CardStore.monsterTypes);
      if (this.currentSearch && this.currentSearch.monsterTypes) {
        var found = selected.filter(s => this.currentSearch.monsterTypes.includes(s.value));
        this.setSelectedMonsterTypes(found);
        return found;
      }
    }
    if (type == "colors") {
      var selected = this.buildOptionsList(this.props.store.CardStore.colors);
      if (this.currentSearch && this.currentSearch.colors) {
        var found = selected.filter(s => this.currentSearch.colors.includes(s.value));
        this.setSelectedColors(found);
        return found;
      }
    }
    if (type == "priceOverrideType") {
      var selected = this.buildOptionsList(priceOverrideTypes);
      if (this.currentSearch && this.currentSearch.selectedPriceOverrideTypes) {
        var found = selected.filter(s => selectedPriceOverrideTypes == s.id);
        this.setSelectedPriceOverrideType(found);
        return found;
      }
    }
  }

  render() {
    const fC = this.props.store.MainStore.currencyBuilder;
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.inventoryManagement ?
          <React.Fragment>
            {this.props.store.CardStore.games &&
              this.props.store.ProductsStore.productTypes &&
              this.props.store.ProductsStore.vendors &&
              this.filtersSetup ?
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon purple"><i className="fal fa-inventory"></i></span>
                    <span className="header-text">My Products</span>
                    <button className={`button rightAlign is-link green`} onClick={() => this.processBulkUpdate()}>Bulk update search</button>
                    {/* <button className={`button rightAlign is-link`} onClick={() => this.processBatchUpdate()}>Update {this.props.store.ProductsStore.products ? this.props.store.ProductsStore.products.length + " records" : 'visible records'}</button> */}
                  </h2>
                </div>
                <div className="filterTabs">
                  <span className={(!this.selectedTab || this.selectedTab == "filters") && !this.minimizeFilters ? "active" : ""} onClick={() => this.setSelectedTab("filters")}>{this.minimizeFilters ? "" : "Product filters"}</span>
                  <span className={this.selectedTab == "fields" && !this.minimizeFilters ? "active" : ""} onClick={() => this.setSelectedTab("fields")}>{this.minimizeFilters ? "" : "Modify fields"}</span>
                  <span className={this.selectedTab == "savedFilters" && !this.minimizeFilters ? "active" : ""} onClick={() => this.setSelectedTab("savedFilters")}>{this.minimizeFilters ? "" : "Saved filters"}</span>
                  <Link to="/products/jobQueue"><span className={this.selectedTab == "queuedJobs" && !this.minimizeFilters ? "active" : ""}>Queued Jobs</span></Link>

                  <div className={this.minimizeFilters ? "minimizeFilters" : "minimizeFilters expanded"} onClick={() => this.setMinimizeFitlers(this.minimizeFilters ? false : true)}>{this.minimizeFilters ? "Show filters" : "Hide filters"}</div>
                </div>
                <div id="filters" className={(this.selectedTab && this.selectedTab != "filters") || this.minimizeFilters ? "hidden" : ""}>
                  {!this.reloadProductFilters ?
                    <form className="filters" ref={this.advancedSearchForm} onSubmit={e => this.processAdvancedSearch(e)}>
                      <div className="row">
                        <div className="col-md-4 field">
                          <label className="label">Select game</label>
                          <Select
                            placeholder="Select game"
                            onChange={this.fetchInformationForGameType}
                            options={this.props.store.CardStore.games}
                            defaultValue={this.getDefaultValuesForFields("game")}
                          />
                        </div>
                        <div className="col-md-4 field">
                          <label className="label">Product type</label>
                          <Select
                            placeholder="Select product types"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedProductTypesChange}
                            isMulti
                            options={this.buildOptionsList(this.props.store.ProductsStore.productTypes)}
                            defaultValue={this.getDefaultValuesForFields("productType")}
                          />
                        </div>
                        <div className="col-md-4 field">
                          <label className="label">Vendor</label>
                          <Select
                            placeholder="Select vendors"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedVendorsChange}
                            isMulti
                            options={this.buildOptionsList(this.props.store.ProductsStore.vendors)}
                            defaultValue={this.getDefaultValuesForFields("vendor")}
                          />
                        </div>
                        <div className="col-md-3 field">
                          <label className="label">Product title</label>
                          <input id="title" className="input" type="text" placeholder="E.g. Tezzeret" defaultValue={this.currentSearch ?.title} />
                        </div>
                        {this.props.store.CardStore.cardVariants && this.props.store.CardStore.cardVariants.length > 0 ?
                          <div className="col-md-3 field" >
                            <label className="label">Variants</label>
                            <Select
                              placeholder="Select variants"
                              closeMenuOnSelect={false}
                              onChange={this.handleSelectedVariantsChange}
                              isMulti
                              options={this.buildOptionsList(this.props.store.CardStore.cardVariants)}
                              defaultValue={this.getDefaultValuesForFields("variants")}
                            />
                          </div>
                          :
                          null
                        }
                        {this.props.store.ProductsStore.tags && this.props.store.ProductsStore.tags.length > 0 ?
                          <div className="col-md-2 field">
                            <label className="label">Tagged with</label>
                            <Select
                              components={{ LargeList }}
                              placeholder="Select product tags"
                              closeMenuOnSelect={false}
                              onChange={this.handleSelectedTagsChange}
                              isMulti
                              options={this.buildOptionsList(this.props.store.ProductsStore.tags)}
                              defaultValue={this.getDefaultValuesForFields("tags")}
                            />
                          </div>
                          :
                          null
                        }
                        <div className="col-md-2 field">
                          <label className="label">Product barcode</label>
                          <input id="barcode" className="input" type="text" placeholder="Product barcode" defaultValue={this.currentSearch ?.barcode} />
                        </div>
                        <div className="col-md-2 field">
                          <label className="label">Product sku</label>
                          <input id="sku" className="input" type="text" placeholder="Product sku" defaultValue={this.currentSearch ?.sku} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-2 field">
                          <label className="label">Price greater than</label>
                          <input id="priceGreaterThan" className="input" type="number" step="0.01" min="0" placeholder="E.g. 100.05" defaultValue={this.currentSearch ?.priceGreaterThan} />
                        </div>
                        <div className="col-md-2 field">
                          <label className="label">Price less than</label>
                          <input id="priceLessThan" className="input" type="number" step="0.01" min="0" placeholder="E.g. 140.99" defaultValue={this.currentSearch ?.priceLessThan} />
                        </div>
                        <div className="col-md-2 field">
                          <label className="label">Overall qty greater than</label>
                          <input id="overallQuantityGreaterThan" className="input" type="number" placeholder="E.g. 4" defaultValue={this.currentSearch ?.overallQuantityGreaterThan} />
                        </div>
                        <div className="col-md-2 field">
                          <label className="label">Overall qty less than</label>
                          <input id="overallQuantityLessThan" className="input" type="number" placeholder="E.g. 8" defaultValue={this.currentSearch ?.overallQuantityLessThan} />
                        </div>
                        <div className="col-md-2 field">
                          <label className="label">Variant qty greater than</label>
                          <input id="quantityGreaterThan" className="input" type="number" placeholder="E.g. 1" defaultValue={this.currentSearch ?.quantityGreaterThan} />
                        </div>
                        <div className="col-md-2 field">
                          <label className="label">Variant qty less than</label>
                          <input id="quantityLessThan" className="input" type="number" placeholder="E.g. 4" defaultValue={this.currentSearch ?.quantityLessThan} />
                        </div>
                        {this.props.store.CardStore.rarities && this.props.store.CardStore.rarities.length > 0 ?
                          <div className="col-md-2 field" >
                            <label className="label">Rarities</label>
                            <Select
                              placeholder="Select rarities"
                              closeMenuOnSelect={false}
                              onChange={this.handleSelectedRaritiesChange}
                              isMulti
                              options={this.buildOptionsList(this.props.store.CardStore.rarities)}
                              defaultValue={this.getDefaultValuesForFields("rarities")}
                            />
                          </div>
                          :
                          null
                        }
                        {this.props.store.CardStore.sets && this.props.store.CardStore.sets.length > 0 ?
                          <div className="col-md-2 field" >
                            <label className="label">Sets</label>
                            <Select
                              closeMenuOnSelect={false}
                              onChange={this.handleSelectedSetsChange}
                              isMulti
                              options={this.buildOptionsList(this.props.store.CardStore.sets)}
                              defaultValue={this.getDefaultValuesForFields("setNames")}
                            />
                          </div>
                          :
                          null
                        }
                        {this.props.store.CardStore.cardTypes && this.props.store.CardStore.cardTypes.length > 0 ?
                          <div className="col-md-2 field" >
                            <label className="label">Card types</label>
                            <Select
                              closeMenuOnSelect={false}
                              onChange={this.handleSelectedCardTypesChange}
                              isMulti
                              options={this.buildOptionsList(this.props.store.CardStore.cardTypes)}
                              defaultValue={this.getDefaultValuesForFields("types")}
                            />
                          </div>
                          :
                          null
                        }
                        {this.props.store.CardStore.colors && this.props.store.CardStore.colors.length > 0 ?
                          <div className="col-md-2 field" >
                            <label className="label">Colors</label>
                            <Select
                              closeMenuOnSelect={false}
                              onChange={this.handleSelectedColorsChange}
                              isMulti
                              options={this.buildOptionsList(this.props.store.CardStore.colors)}
                              defaultValue={this.getDefaultValuesForFields("colors")}
                            />
                          </div>
                          :
                          null
                        }
                        {this.props.store.CardStore.monsterTypes && this.props.store.CardStore.monsterTypes.length > 0 ?
                          <div className="col-md-2 field" >
                            <label className="label">Monster types</label>
                            <Select
                              closeMenuOnSelect={false}
                              onChange={this.handleSelectedMonsterTypesChange}
                              isMulti
                              options={this.buildOptionsList(this.props.store.CardStore.monsterTypes)}
                              defaultValue={this.getDefaultValuesForFields("monsterTypes")}
                            />
                          </div>
                          :
                          null
                        }
                        <div className="col-md-2 field" >
                          <label className="label">Price override type</label>
                          <Select
                            onChange={this.handleSelectedPriceOverrideType}
                            options={this.buildOptionsList(priceOverrideTypes)}
                            defaultValue={this.getDefaultValuesForFields("priceOverrideType")}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-6">
                          <input id="saveFilter" className="input" type="text" placeholder="My custom filter" />
                          <button type="button" className="button saveFilter" onClick={() => this.saveFilter()}>Save filter</button>
                        </div>
                        <div className="col-md-6">
                          <button className="button applyFilters" onClick={this.advancedSearch}>Apply filters</button>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-4 field">
                          <label className="label">Action when updating inventory level</label>
                          <Select
                            closeMenuOnSelect={true}
                            onChange={this.handleStockUpdateType}
                            options={this.stockUpdateTypes}
                            defaultValue={this.stockUpdateTypes[0]}
                          />
                        </div>
                      </div>
                    </form>
                    :
                    null
                  }
                </div>
                <div id="modifyFilters" className={this.selectedTab != "fields" || this.minimizeFilters ? "hidden" : ""}>
                  <div className="modifyFilters">
                    <form ref={this.columnToggle} onSubmit={() => { return false; }} >
                      <div className="row">
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="stockColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("stock")} defaultChecked={this.columnsToShow.includes("stock")} />
                            <span className="checkmark"></span>
                            &nbsp; Show stock column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="sellPriceColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("sellPrice")} defaultChecked={this.columnsToShow.includes("sellPrice")} />
                            <span className="checkmark"></span>
                            &nbsp; Show sell price column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="sellPriceOverrideColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("sellPriceOverride")} defaultChecked={this.columnsToShow.includes("sellPriceOverride")} />
                            <span className="checkmark"></span>
                            &nbsp; Show sell override column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="cashBuyPriceColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("cashBuyPrice")} defaultChecked={this.columnsToShow.includes("cashBuyPrice")} />
                            <span className="checkmark"></span>
                            &nbsp; Show cash buy column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="cashBuyPercentColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("cashBuyPercent")} defaultChecked={this.columnsToShow.includes("cashBuyPercent")} />
                            <span className="checkmark"></span>
                            &nbsp; Show cash percent column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="creditBuyPriceColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("creditBuyPrice")} defaultChecked={this.columnsToShow.includes("creditBuyPrice")} />
                            <span className="checkmark"></span>
                            &nbsp; Show credit buy column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="creditBuyPercentColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("creditBuyPercent")} defaultChecked={this.columnsToShow.includes("creditBuyPercent")} />
                            <span className="checkmark"></span>
                            &nbsp; Show credit buy column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="buyLimitColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("buyLimit")} defaultChecked={this.columnsToShow.includes("buyLimit")} />
                            <span className="checkmark"></span>
                            &nbsp; Show buy limit column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="overstockCashBuyPriceColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("overstockCashBuyPrice")} defaultChecked={this.columnsToShow.includes("overstockCashBuyPrice")} />
                            <span className="checkmark"></span>
                            &nbsp; Show overstock cash buy column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="overstockCashBuyPercentColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("overstockCashBuyPercent")} defaultChecked={this.columnsToShow.includes("overstockCashBuyPercent")} />
                            <span className="checkmark"></span>
                            &nbsp; Show overstock cash percent column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="overstockCreditBuyPriceColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("overstockCreditBuyPrice")} defaultChecked={this.columnsToShow.includes("overstockCreditBuyPrice")} />
                            <span className="checkmark"></span>
                            &nbsp; Show overstock credit buy column
                        </label>
                        </div>
                        <div className="col-md-3">
                          <label className="checkbox">
                            <input id="overstockCreditBuyPercentColumnCheckbox" type="checkbox" onChange={() => this.updateColumnShown("overstockCreditBuyPercent")} defaultChecked={this.columnsToShow.includes("overstockCreditBuyPercent")} />
                            <span className="checkmark"></span>
                            &nbsp; Show overstock credit buy column
                        </label>
                        </div>
                        <div className="col-md-12">
                          <button type="button" className="button updateColumns" onClick={() => this.processColumnUpdate()}>Update columns</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div id="savedFilters" className={this.selectedTab != "savedFilters" || this.minimizeFilters ? "hidden" : ""}>
                  <div className="savedFilters">

                    {this.props.store.ProductsStore.savedSearches ?
                      <React.Fragment>
                        {this.props.store.ProductsStore.savedSearches.map((savedSearch, i) => {
                          return (
                            <React.Fragment key={i}>
                              <div className="row">
                                <div className="col-md-2 savedName">
                                  {savedSearch.name}
                                </div>
                                <div className="col-md-8 savedQueries">
                                  {this.buildSearchString(savedSearch)}
                                </div>
                                <div className="col-md-2 savedActions">
                                  <i className="fal fa-trash" onClick={() => this.showDeleteModal(savedSearch)}></i>
                                  <button className="button" onClick={() => this.applyFilters(savedSearch)}>Apply filter</button>
                                </div>

                              </div>
                              <hr />
                            </React.Fragment>
                          )
                        })}
                      </React.Fragment>
                      :
                      null
                    }
                  </div>
                </div>
                <br />
                {this.props.store.ProductsStore.products ?
                  <React.Fragment>
                    <div ref={this.scrollTo} className="row">
                      <div className="col-md-12">
                        <div className="paging-nav margin-bottom">
                          <button className="button is-info paging-nav-right-margin"
                            disabled={this.offset == 0 ? true : false}
                            onClick={() => this.getPrevProducts(true)}>Prev</button>
                          <button
                            className="button is-info"
                            disabled={this.props.store.ProductsStore.products && this.props.store.ProductsStore.products.length < this.limit ? true : false}
                            onClick={() => this.getNextProducts(true)}>Next</button>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-12">
                        <div className="searchCount">{this.productCount} products found</div>
                        <div className="sort">
                          {this.sortTypes && this.sortTypes.length > 0 ?
                            <div className="field">
                              <label className="label"><i className="far fa-filter"></i>Sort by</label>
                              <Select
                                placeholder="Sort by"
                                defaultValue={this.selectedSortType ? [this.selectedSortType] : this.sortTypes[0]}
                                onChange={this.changeSortType}
                                options={this.sortTypes}
                              />
                            </div>
                            :
                            null
                          }
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid resultsPanel">

                      {this.props.store.ProductsStore.products.map((product, i) => {
                        return (
                          <ProductLineItem setUpdateProduct={theFunction => this.processLineByLineUpdateList.push(theFunction)} key={i} product={product} listIndex={i} columnsToShow={this.columnsToUpdate} currentInput={this.getCurrentInput} updateCurrentInput={this.updateCurrentInput} getStockUpdateType={this.getCurrentStockUpdateType} />
                        )
                      })}
                    </div>
                    <button className={`button updateAll is-link`} onClick={() => this.setShowProcessUpdateVariants(true)}>Update</button>
                    {this.showProcessUpdateVariants ?
                      <ConfirmationModal type="confirm" title="Update variants?" text={"Do you want to update the above variants? This cannot be undone."} cancelAction={this.cancelVariantUpdate} confirmAction={this.processLineUpdates} />
                      :
                      null
                    }
                    <div className="paging-nav">
                      <button className="button is-info paging-nav-right-margin"
                        disabled={this.offset == 0 ? true : false}
                        onClick={() => this.getPrevProducts()}>Prev</button>
                      <button
                        className="button is-info"
                        disabled={this.props.store.ProductsStore.products && this.props.store.ProductsStore.products.length < this.limit ? true : false}
                        onClick={() => this.getNextProducts()}>Next</button>
                    </div>
                  </React.Fragment>
                  :
                  <Loader text="Loading products..." />
                }
                {this.showBulkUpdate ?
                  <BulkUpdateModal search={this.currentSearch} submitType={this.bulkUpdateType} variants={this.variantsToUpdate} productCount={this.productCount} cancelAction={this.cancelBulkUpdate} getStockUpdateType={this.getCurrentStockUpdateType} />
                  :
                  null
                }
                {this.deleteModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Are you sure?</p>
                        <button className="delete" aria-label="close" onClick={() => this.setDeleteModalVisible(false)}></button>
                      </header>
                      <section className="modal-card-body">
                        <p>If you proceed with deleting your saved filter: <strong>{this.savedSearchFilterToDelete.name}</strong>, you will not be able to restore it.</p>
                        <br />
                        <p>Are you sure you want to delete your saved filter?</p>
                      </section>
                      <footer className="modal-card-foot">
                        <button className={`button half is-danger ${this.deleteModalLoading ? 'is-loading disabled' : ''}`} onClick={() => this.deleteFilter(this.savedSearchFilterToDelete.id)}>Delete</button>
                        <button className="button  half is-text" onClick={() => this.setDeleteModalVisible(false)}>Cancel</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
              </React.Fragment>
              :
              <Loader text="Loading products..." />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
