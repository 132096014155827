import React from 'react';
import { inject, observer } from 'mobx-react'
import { ProductLineVariantItem } from 'components';

@inject('store')
@observer
export default class ProductLineItem extends React.Component {

  variantLineItemsToProcess = [];

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.setUpdateProduct(() => this.process(this.variantLineItemsToProcess));
  }

  updateCurrentInput = (value) => {
    this.props.updateCurrentInput(value);
  }

  getCurrentInput = () => {
    return this.props.currentInput();
  }

  getStockUpdateType = () => {
    return this.props.getStockUpdateType();
  }

  process(variantLineItemsToProcess) {
    variantLineItemsToProcess.forEach(functionToProcess => {
      functionToProcess();
    })
  }

  getProductTitle(value) {
    return value.split('[')[0];
  }

  getProductSet(value) {
    var split = value.split('[');
    if (split && split.length > 1) {
      return split[1].replace("]", '');
    }
    return "";
  }

  render() {
    return (
      <div key={this.props.listIndex} className="row lineItem">
        <div className="col-md-6">
          <img src={this.props.product.img} style={{ width: '40px' }} />
          <div className="productTitle">{this.getProductTitle(this.props.product.title)}
            <span>{this.getProductSet(this.props.product.title)}</span>
          </div>
        </div>
        <div className="col-md-3"></div>
        <div className="col-md-3 productStats">Total Stock: {this.props.product.overallQuantity}</div>
        <div className="col-md-12 variantsInfo">
          <div className="row variantSelectTitles">
            <div className="col-md-1 left-align">Variant</div>
            {this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("stock") ?
              <div className="col-md-1">Stock Qty</div>
              :
              null
            }
            {this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("sellPrice") ?
              <div className="col-md-1">Sell</div>
              :
              null
            }
            {this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("sellPriceOverride") ?
              <div className="col-md-1">Sell Override</div>
              :
              null
            }
            {this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("cashBuyPrice") || this.props.columnsToShow.includes("cashBuyPercent") ?
              <div className="col-md-1-5 noHeight">Cash Buy
                {this.props.columnsToShow.includes("cashBuyPrice") ?
                  <React.Fragment>
                    <span> {this.props.store.MainStore.currency} </span>
                  </React.Fragment>
                  :
                  null
                }
                {this.props.columnsToShow.includes("cashBuyPrice") && this.props.columnsToShow.includes("cashBuyPercent") ?
                  <React.Fragment>
                    <span> or </span>
                  </React.Fragment>
                  :
                  null
                }
                {this.props.columnsToShow.includes("cashBuyPercent") ?
                  <React.Fragment>
                    <span> % </span>
                  </React.Fragment>
                  :
                  null
                }
              </div>
              :
              null
            }
            {this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("creditBuyPrice") || this.props.columnsToShow.includes("creditBuyPercent") ?
              <div className="col-md-1-5 noHeight">Credit Buy
                {this.props.columnsToShow.includes("creditBuyPrice") ?
                  <React.Fragment>
                    <span> {this.props.store.MainStore.currency} </span>
                  </React.Fragment>
                  :
                  null
                }
                {this.props.columnsToShow.includes("creditBuyPrice") && this.props.columnsToShow.includes("creditBuyPercent") ?
                  <React.Fragment>
                    <span> or </span>
                  </React.Fragment>
                  :
                  null
                }
                {this.props.columnsToShow.includes("creditBuyPercent") ?
                  <React.Fragment>
                    <span> % </span>
                  </React.Fragment>
                  :
                  null
                }
              </div>
              :
              null
            }
            {this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("buyLimit") ?
              <div className="col-md-1">Buy Limit</div>
              :
              null
            }
            {this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("overstockCashBuyPrice") || this.props.columnsToShow.includes("overstockCashBuyPercent") ?
              <div className="col-md-1-5 noHeight">Over-limit Cash Buy
                {this.props.columnsToShow.includes("overstockCashBuyPrice") ?
                  <React.Fragment>
                    <span> {this.props.store.MainStore.currency} </span>
                  </React.Fragment>
                  :
                  null
                }
                {this.props.columnsToShow.includes("overstockCashBuyPrice") && this.props.columnsToShow.includes("overstockCashBuyPercent") ?
                  <React.Fragment>
                    <span> or </span>
                  </React.Fragment>
                  :
                  null
                }
                {this.props.columnsToShow.includes("overstockCashBuyPercent") ?
                  <React.Fragment>
                    <span> % </span>
                  </React.Fragment>
                  :
                  null
                }
              </div>
              :
              null
            }
            {this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("overstockCreditBuyPrice") || this.props.columnsToShow.includes("overstockCreditBuyPercent") ?
              <div className="col-md-1-5 noHeight">Over-limit Credit Buy
                {this.props.columnsToShow.includes("overstockCreditBuyPrice") ?
                  <React.Fragment>
                    <span> {this.props.store.MainStore.currency} </span>
                  </React.Fragment>
                  :
                  null
                }
                {this.props.columnsToShow.includes("overstockCreditBuyPrice") && this.props.columnsToShow.includes("overstockCreditBuyPercent") ?
                  <React.Fragment>
                    <span> or </span>
                  </React.Fragment>
                  :
                  null
                }
                {this.props.columnsToShow.includes("overstockCreditBuyPercent") ?
                  <React.Fragment>
                    <span> % </span>
                  </React.Fragment>
                  :
                  null
                }
              </div>
              :
              null
            }
          </div>
          {this.props.product.variants.map((variant, i) => {
            return (
              <ProductLineVariantItem setUpdateVariant={theFunction => this.variantLineItemsToProcess.push(theFunction)} key={i} variant={variant} parentListIndex={this.props.listIndex} listIndex={i} columnsToShow={this.props.columnsToShow} currentInput={this.getCurrentInput} updateCurrentInput={this.updateCurrentInput} getStockUpdateType={this.getStockUpdateType} />
            )
          })}
        </div>
      </div>
    );
  }
}
