import React, { Component } from "react";
import GridItem from "./GridItem";
import { observer } from "mobx-react";
import { Loader } from "components";

@observer
class ResultsGrid extends Component {
  state = {};
  render() {
    const store = this.props.store;
    return (
      <div className="results-grid">
        {store.items.map(item => (
          <GridItem store={store} item={item} key={item.id} />
        ))}
        {store.items.length == 0 &&
          !store.fetchingSearch &&
          store.searchTerm != "" &&
          !store.waitingToSearch ? (
            <div className="emptySearch">No products found...</div>
          ) : (
            ""
          )}
        {store.fetchingSearch ? <Loader text={"Searching products"} /> : ""}
      </div>
    );
  }
}

export default ResultsGrid;
