import React, { Component } from "react";
import { action } from "mobx";
import { observer, inject } from "mobx-react";
import { Loader } from "components";

@inject("posStore")
@observer
class Checkout extends Component {
  @action tenderUpdate = (e, index) => {
    if (isNaN(e.target.value) | (e.target.value == "")) {
      this.props.store.tenders[index].amount = e.target.value.replace(
        /[^0-9.-]/g,
        ""
      );
      if ((e.target.value == "") | (e.target.value == ".")) {
        this.props.store.tenders[index].amount = "0.00";
      }
    } else {
      this.props.store.tenders[index].amount = e.target.value;
    }
    this.verifyAmounts();
  };

  @action verifyAmounts = () => {
    this.props.store.tenders.forEach((tender, index) => {
      if (isNaN(tender.amount) | (tender.amount == "")) {
        if (tender.amount) {
          this.props.store.tenders[index].amount = tender.amount.replace(
            /[^0-9.-]/g,
            ""
          );
        }
        if ((tender.amount == "") | (tender.amount == ".")) {
          this.props.store.tenders[index].amount = "0.00";
        }
      } else {
        this.props.store.tenders[index].amount = tender.amount;
      }
    });
  };
  @action selectAll = (e, name) => {
    this.props.store.tenders[name].amount = (
      parseFloat(this.props.store.tenders[name].amount) +
      parseFloat(this.props.store.balance)
    ).toFixed(2);

    if (
      this.props.posStore.tenders[name].type == "Store Credit" &&
        this.props.posStore.tenders[name].amount >
        this.props.posStore ?.selectedCustomer ?.storeCredit
    ) {
      this.props.posStore.tenders[
        name
      ].amount = this.props.posStore.selectedCustomer.storeCredit;
    }
    e.target.select();
    this.verifyAmounts();
  };
  @action setTender = (e, index) => {
    this.props.posStore.activeTender = index;
    if (this.props.store.tenders[index].amount == "") {
      this.props.store.tenders[index].amount = "0.00";
    }
    this.myInp.focus();
    this.myInp.select();
  };
  @action quickCash = (e, amount) => {
    this.props.store.tenders[0].amount = (
      parseFloat(this.props.store.tenders[0].amount) + amount
    ).toFixed(2);
  };
  render() {
    const store = this.props.posStore;
    const fC = this.props.posStore.fCurr;
    const showHideClassName = store.checkoutModal
      ? "modal display-block"
      : "modal display-none";
    return (
      <React.Fragment>
        {store.submittingCart ?
          <React.Fragment>
            <div className="submitCartLoader">
              <Loader text={"Submitting cart..."} />
            </div>
          </React.Fragment>
          :
          ""
        }
        <div className={showHideClassName + "" + (store.submittingCart ? "display-none" : "")}>
          <section className="modal-main">
            <div className="checkoutWrapper">
              <div className="tenderWrapper">
                <button className="close-button" onClick={this.props.handleClose}>
                  <i className="fal fa-times" />
                </button>
                <span className="balance">
                  Balance to Pay:<em>{store.balance}</em>
                </span>
                <span className="tenderTitle">
                  Amount tendered{" "}
                  <i className="fas fa-redo-alt" onClick={store.zeroTenders} />
                </span>
                <span className="tender">
                  <p className="symbol">{this.props.posStore.currency}</p>
                  <input
                    value={
                      store.tenders.length != 0
                        ? store.tenders[store.activeTender].amount
                        : 0
                    }
                    onChange={e => this.tenderUpdate(e, store.activeTender)}
                    //onBlur={e => this.verifyAmounts()}
                    onFocus={e => this.selectAll(e, store.activeTender)}
                    ref={ip => (this.myInp = ip)}
                    type="textbox"
                  />
                </span>
                <div className="tenderAmounts">
                  {store.tenders.map((tender, index) => (
                    <div key={index} className="tenderType">
                      <span
                        onClick={e => {
                          if (store.selectedCustomer && tender.type == "Store Credit") {
                            this.setTender(e, index);
                          }
                        }}
                      >
                        {fC(store.tenders[index].amount)}
                      </span>
                      <button
                        className="payment-button"
                        onClick={e => {
                          this.setTender(e, index);
                        }}
                        key={index}
                        disabled={
                          !store.selectedCustomer && tender.type == "Store Credit"
                        }
                      >
                        {tender.type}
                      </button>
                    </div>
                  ))}
                </div>
                <span className="tenderTitle quickPay">Quick cash payment</span>
                <div className="quickBtns">
                  {store.cashDenoms.map((denom, index) => (
                    <button
                      key={index}
                      onClick={e => this.quickCash(e, denom)}
                      className="payment-button-cash"
                    >
                      {fC(denom)}
                    </button>
                  ))}
                  {store.changeDue ? (
                    <span className="changeDue">
                      Change Due: <em>{fC(store.changeDue)}</em>
                    </span>
                  ) : null}
                </div>
              </div>
              <button
                key={!(store.balance == 0)}
                className="payment-final"
                onClick={store.submitCart}
                disabled={
                  !(
                    parseFloat(store.balance) +
                    (store.changeDue == "" ? 0 : parseFloat(store.changeDue)) ==
                    0
                  )
                }
              >
                Finalize Sale
            </button>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default Checkout;
