import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { action } from "mobx";

@inject("posStore")
@observer
class POSErrorModal extends Component {
  @action clearError = () => {
    this.props.posStore.errorMessage = "";
  };

  render() {
    const store = this.props.posStore;
    return (
      <div className={store.errorMessage ? "modal is-active" : "modal"}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{store.errorHeader}</p>
            <button
              className="delete"
              aria-label="close"
              onClick={this.clearError}
            />
          </header>
          <section className="modal-card-body">
            <div className="field">{store.errorMessage}</div>
            <div className="field">
              {store.externalUrl ? (<a href={store.externalUrl} target="_blank">{store.externalUrl}</a>) : ""}
            </div>
            <div className="field">
              {store.errorTraceId ? "Trace ID: " + store.errorTraceId : ""}
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className={"button is-info"} onClick={this.clearError}>
              OK
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default POSErrorModal;
