import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Loader } from "components";
import { BuylistMenuStructure } from 'menuStructures';
import { TimeUtils } from 'utils'

@inject('store')
@observer
export default class ApprovedBuylist extends React.Component {

  constructor(props) {
    super(props);
  }

  @observable offset = 0;
  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 20;
  @action setLimit(limit) {
    this.limit = limit;
  }

  @observable searchString = "";
  @action setSearchString(searchString) {
    this.searchString = searchString;
  }

  componentDidMount() {
    document.title = 'Approved buylists | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);

    this.props.store.BuylistRulesStore.rehydrateApprovedBuylists(this.offset, this.limit, this.searchString);
  }

  getNextBuylists() {
    this.setOffset(this.offset + 10);
    this.props.store.BuylistRulesStore.rehydrateApprovedBuylists(this.offset, this.limit, this.searchString);
  }

  getPrevBuylists() {
    var offsetUpdate = this.offset - 10;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.BuylistRulesStore.rehydrateApprovedBuylists(this.offset, this.limit, this.searchString);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.buylist ?
          <React.Fragment>
            <div className="app-header">
              <h2 className="title is-2">
                <span className="app-icon orange"><i className="fal fa-shopping-cart"></i></span>
                <span className="header-text">Approved buylists</span>
              </h2>
            </div>
            <br />
            <div className="customer_list">
              {this.props.store.BuylistRulesStore.approvedBuylists ?
                <table>
                  <thead>
                    <tr>
                      <th className="col5">#</th>
                      <th colSpan="2" className="col1 buylistName">Name</th>
                      <th className="col1 buylistEmail">Email</th>
                      <th className="col2" className="storeCredit">Date submitted</th>
                      <th className="col2">Payment type</th>
                      <th className="col4"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.store.BuylistRulesStore.approvedBuylists.map((approvedBuylist, i) => {
                      return (
                        <tr className="list_item" key={i}>
                          <td>{approvedBuylist.id}</td>
                          <td className="initials">
                            <div>{approvedBuylist.shopifyCustomerId.firstName && approvedBuylist.shopifyCustomerId.firstName.length > 0 ?
                              <React.Fragment>

                                {approvedBuylist.shopifyCustomerId.firstName.charAt(0)}
                                {approvedBuylist.shopifyCustomerId.lastName && approvedBuylist.shopifyCustomerId.lastName.length > 0 ?
                                  <React.Fragment>
                                    {approvedBuylist.shopifyCustomerId.lastName.charAt(0)}
                                  </React.Fragment>
                                  :
                                  null
                                }
                              </React.Fragment>
                              :
                              <React.Fragment>
                                {approvedBuylist.shopifyCustomerId.email && approvedBuylist.shopifyCustomerId.email.length > 0 ?
                                  <React.Fragment>
                                    {approvedBuylist.shopifyCustomerId.email.charAt(0)}
                                  </React.Fragment>
                                  :
                                  null
                                }
                              </React.Fragment>
                            }
                            </div>
                          </td>
                          <td className="name">{approvedBuylist.shopifyCustomerId.firstName} {approvedBuylist.shopifyCustomerId.lastName}</td>
                          <td>{approvedBuylist.shopifyCustomerId.email}</td>
                          <td>{TimeUtils.convertDateToHumanReadable(approvedBuylist.readableSubmittedDate)}</td>
                          <td>{approvedBuylist.paymentType}</td>
                          <td><Link to={`/buylists/approved/moreDetail/${approvedBuylist.id}`} className="button is-success">Review</Link></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                :
                <Loader />
              }
            </div>
            <div className="paging-nav-bottom">
              <button className="button is-info paging-nav-right-margin"
                disabled={this.offset == 0 ? true : false}
                onClick={() => this.getPrevBuylists()}>Prev</button>
              <button
                className="button is-info"
                disabled={this.props.store.BuylistRulesStore.approvedBuylists && this.props.store.BuylistRulesStore.approvedBuylists.length < this.limit ? true : false}
                onClick={() => this.getNextBuylists()}>Next</button>
            </div>
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
