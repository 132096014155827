import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import Checkout from "./Checkout";
import { action, observable } from "mobx";
import CartLine from "./CartLine";
import Store from "../../store/index";
import { CustomLineItem } from "../../pos/ItemStore";
import CustomerLine from "./CustomerLine";

import { Customer } from "views";

async function _fetch({
  method,
  endpoint,
  payload,
  requestSchema,
  responseSchema
}) {
  let authToken = await getUserToken();
  console.log(endpoint);
  try {
    let response = await fetch(endpoint, {
      credentials: "same-origin",
      method: method,
      mode: "no-cors",
      body: payload ? JSON.stringify(payload) : null,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken
      })
    });

    if (!response.ok && response.status != 201) {
      let error = await response.json();
      throw error;
    }

    let data = await response.json();
    console.log("Data: ", data);
    return data;
  } catch (error) {
    throw error;
  }
}

function getUserToken() {
  return Store.AuthStore.user.getIdToken(true);
}
@inject("posStore")
@observer
class Cart extends Component {
  async componentDidMount() {
    console.log("Getting Tax");
    await this.props.posStore.getTax();
    console.log("Getting Cart");
    await this.props.posStore.getLatestCart();
  }

  state = { show: false, results: [], itemShow: false, discountModal: false };
  @action runCart(response) {
    this.hideModal();
    this.props.store.cart.clear();
    console.log("Success:", JSON.stringify(response));
  }

  @observable showAddCustomer = false;
  @action setShowAddCustomer(showAddCustomer) {
    this.showAddCustomer = showAddCustomer;
  }

  closeCustomerModal = value => {
    this.setShowAddCustomer(value);
  };

  @action setSelectedCustomer(customer) {
    this.props.store.selectedCustomer = customer;
    this.props.store.validateCart();
  }

  setSelectedCustomerForPOS = customer => {
    this.setSelectedCustomer(customer);
  };

  @action
  discountChange = e => {
    console.log(e.target.id);
    const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]{0,2})?|[.][0-9]+)$");
    if (e.target.id == "discountAmount") {
      console.log(e.target.value);
      if (e.target.value === "" || floatRegExp.test(e.target.value)) {
        this.props.store.globalDiscount.amount = e.target.value || "0";
      }
    }
    if (e.target.id == "percent") {
      this.props.store.globalDiscount.type = "percentage";
    }
    if (e.target.id == "amount") {
      this.props.store.globalDiscount.type = "amount";
    }
  };

  @action
  customerSearch = () => {
    if (!!this.props.posStore.customerInput) {
      getUserToken().then(authToken =>
        fetch(
          "https://devportal.binderpos.comhttps://devportal.binderpos.com/api/customers?keyword=" +
          this.props.store.customerInput,
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken
            })
          }
        )
          .then(res => res.json())
          .then(data => {
            if (!!this.props.posStore.customerInput) {
              console.log(data);
              this.props.store.addCustomerResults(data);
            }
          })
      );
    } else {
      this.props.store.customerResults = [];
    }
  };
  @action
  showModal = () => {
    this.props.posStore.checkoutModal = true;
    this.props.posStore.setDisableLineItems(true);
  };
  @action
  hideModal = () => {
    this.props.posStore.checkoutModal = false;
    this.props.posStore.setDisableLineItems(false);
  };

  toggleItem = () => {
    this.setState({ itemShow: !this.state.itemShow });
  };

  toggleDiscount = () => {
    this.setState({ discountModal: !this.state.discountModal });
  };

  @action
  handleInputChange = e => {
    this.props.store.customerInput = e.target.value;
    e.target.className = "customerChoosen";
    this.customerSearch();
  };

  _handleKeyPress = e => {
    if (e.key === "Enter") {
      this.addCustomItem();
    }
  };
  @action
  updatePrice = e => {
    this.props.store.customItem.actualPrice = parseFloat(e.target.value);
  };
  @action
  updateName = e => {
    this.props.store.customItem.name = e.target.value;
  };

  @action
  addCustomItem = () => {
    this.props.store.addToCart(
      new CustomLineItem(
        this.props.store.customItem,
        this.props.store.buyMode,
        this.props.store.allTax
      )
    );
    this.setState({ itemShow: false });
  };

  @action
  clearCustomer = e => {
    this.props.posStore.selectedCustomer = null;
    this.props.posStore.customerInput = "";
    //zero the tenders out so store credit doesn't get stuck with non zero value
    this.props.posStore.zeroTenders();
    this.props.posStore.validateCartNoRefresh();
  };

  render() {
    const store = this.props.store;
    const fC = this.props.posStore.fCurr;
    const roundCents = this.props.posStore.roundCents;
    return (
      <React.Fragment>
        {this.showAddCustomer ? (
          <div className="modal is-active">
            <div
              className="modal-background"
              onClick={() => this.setShowAddCustomer(false)}
            />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Add new customer</p>
                <i
                  onClick={() => this.setShowAddCustomer(false)}
                  className="fal fa-times"
                />
              </header>
              <section className="modal-card-body">
                <Customer
                  isPos={true}
                  setShowAddCustomer={this.closeCustomerModal}
                  setSelectedCustomerForPOS={this.setSelectedCustomerForPOS}
                />
              </section>
            </div>
          </div>
        ) : null}

        <div
          className={
            this.props.posStore.disableLineItems ? " cart disable" : "cart"
          }
        >
          <div
            className={
              !!store.selectedCustomer
                ? "customer-select selected"
                : "customer-select"
            }
          >
            <input
              className={!!store.selectedCustomer ? "customerChosen" : ""}
              onClick={this.clearCustomer}
              type="textbox"
              placeholder="Select a Customer"
              value={
                store.selectedCustomer
                  ? (store.selectedCustomer.firstName &&
                    store.selectedCustomer.firstName.length > 0
                    ? store.selectedCustomer.firstName
                    : "") +
                  " " +
                  (store.selectedCustomer.lastName &&
                    store.selectedCustomer.lastName.length > 0
                    ? store.selectedCustomer.lastName
                    : "")
                  : store.customerInput
              }
              ref={input => (this.search = input)}
              onChange={this.handleInputChange}
            />
            <span
              data-balloon-length="large"
              data-balloon="Add a new customer"
              data-balloon-pos="left"
              className="userActions"
              className={
                store.selectedCustomer ? "addCustomer hidden" : "addCustomer"
              }
              onClick={() => this.setShowAddCustomer(true)}
            >
              <i className="far fa-user-plus" />
            </span>
            <span className="email" onClick={this.clearCustomer}>
              {store.selectedCustomer ? store.selectedCustomer.email : " "}
            </span>
            <span className="credit" onClick={this.clearCustomer}>
              {store.selectedCustomer
                ? fC(store.selectedCustomer.storeCredit.toFixed(2))
                : ""}
            </span>

            <div
              className={
                !store.customerResults[0]
                  ? "autocomplete-items hidden"
                  : "autocomplete-items"
              }
            >
              {!store.customerResults[0]
                ? null
                : store.customerResults.map(customer => (
                  <CustomerLine
                    store={store}
                    key={customer.id}
                    customer={customer}
                  />
                ))}
            </div>
          </div>
          <div
            className={
              (!store.buyMode ? "cartItem buy" : "cartItem sell") +
              (this.state.itemShow ? null : " hidden")
            }
            key={Math.random()}
          >
            <p className="createTitle">Create a custom item</p>
            <span className="qty hidden">
              <input type="number" step="1" defaultValue={1} />
            </span>
            <form>
              <input
                className="customTitle"
                type="textbox"
                placeholder="Miscellaneous item"
                onKeyPress={this._handleKeyPress}
                onChange={this.updateName}
              />
              <input
                className="customPrice"
                type="number"
                placeholder="$19.95"
                onKeyPress={this._handleKeyPress}
                onChange={this.updatePrice}
              />
              <button
                className="customAdd"
                type="button"
                onClick={this.addCustomItem}
              >
                <i className="fal fa-plus" /> Create item
              </button>
            </form>
          </div>

          {store.cart.map((item, index) =>
            !item.title.match(/POS Discount /) ? (
              <CartLine store={store} item={item} key={index} />
            ) : (
                ""
              )
          )}
          <div className="cartTotals">
            <div className="cartButtons">
              <div className="addItem" onClick={this.toggleItem}>
                <i className="fal fa-plus" /> Add a custom item{" "}
              </div>
            </div>
            <span className="discounts">
              Discount:{" "}
              <div className="discountWrapper">
                <button
                  id="amount"
                  className={
                    this.props.posStore.globalDiscount.type == "amount"
                      ? "amount"
                      : "amount notActive"
                  }
                  onClick={this.discountChange}
                >
                  $
                </button>
                <input
                  type="text"
                  name="discountAmount"
                  id="discountAmount"
                  value={store.globalDiscount.amount}
                  onChange={this.discountChange}
                />
                <button
                  id="percent"
                  className={
                    this.props.posStore.globalDiscount.type == "percentage"
                      ? "percentage"
                      : "percentage notActive"
                  }
                  onClick={this.discountChange}
                >
                  %
                </button>
              </div>
              <em>{fC(roundCents(store.discountAmount))}</em>
            </span>
            <span className="subTotal">
              Subtotal ({store.totalItems} Item)
              <em>{fC(roundCents(store.subTotal))}</em>
            </span>
            {/* <span className="discount">Add discount</span> */}
            <span className="tax">
              Tax ({store.taxRateDisplay}%)
              <em>{fC(roundCents(store.taxTotal))}</em>
            </span>
            {store.negatedTaxTotal ? (
              <span className="taxNegated">
                Tax Negated
                <em>{fC(roundCents(store.negatedTaxTotal))}</em>
              </span>
            ) : (
                ""
              )}
            <span className="total">
              Total<em>{fC(roundCents(store.total))}</em>
            </span>
          </div>
          <button className="buy-button" type="button" onClick={this.showModal}>
            Pay
          </button>
          <Checkout
            store={this.props.store}
            show={this.state.show}
            handleClose={this.hideModal}
            checkout={this.checkout}
          >
            <p>Modal</p>
            <p>Data</p>
          </Checkout>
        </div>
      </React.Fragment>
    );
  }
}

export default Cart;
