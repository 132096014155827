import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { EventMenuStructure } from 'menuStructures'
import { Loader } from 'components'

@inject('store')
@observer
export default class EventList extends React.Component {

  @observable modalVisible = false;
  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable modalEvent;
  @action setModalEvent(event) {
    this.modalEvent = event;
  }

  @observable modalRecurringText;
  @action setModalRecurringText(modalRecurringText) {
    this.modalRecurringText = modalRecurringText;
  }

  @observable deleteModalVisible = false;
  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteModalEvent;
  @action setDeleteModalEvent(event) {
    this.deleteModalEvent = event;
  }

  @observable deleteModalLoading;
  @action setDeleteModalLoading(loading) {
    this.deleteModalLoading = loading;
  }

  constructor(props) {
    super(props);
  }

  showModalFor(eventId) {
    var event = this.props.store.EventsStore.events.filter(event => { return event.id == eventId });
    this.setModalVisible(true);
    this.setModalEvent(event[0]);
    this.setModalRecurringText(this.generateTextForRecurring(event[0]));
  }

  showDeleteModalFor(eventId) {
    var event = this.props.store.EventsStore.events.filter(event => { return event.id == eventId });
    this.setDeleteModalVisible(true);
    this.setDeleteModalEvent(event[0]);
  }

  getOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  convertDateToHumanReadable(stringDate) {
    var dateToConvert = stringDate.substr(0, stringDate.indexOf('T'));
    const date = new Date(dateToConvert);
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
    return date.toLocaleDateString();
  }

  convertTime(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  commaSplitWithAnd(theItems) {
    var list = theItems.split(',');
    return [list.slice(0, -1).join(', '), list.slice(-1)[0]].join(list.length < 2 ? '' : ' and ')
  }

  generateTextForRecurring(event) {
    if (event.recurring) {
      let typeName = typeName = event.recurringType + "s"
      if (event.recurringFrequency == 1) {
        if (event.recurringType.toLowerCase() == "day") {
          typeName = "daily";
        } else {
          typeName = event.recurringType + "ly";
        }
      }
      let value = "Event scheduled " + (event.recurringFrequency == 1 ? "" : `for every ${event.recurringFrequency} `) + typeName;
      if (event.recurringType == "week") {
        value += ` on ${this.commaSplitWithAnd(event.recurringDays)}`;
      }
      if (event.recurringType == "month") {
        value += ` on ${this.getOrdinal(event.recurringDate)} of every month`;
      }
      if (event.recurringEndDate) {
        value += ` and ends on ${this.convertDateToHumanReadable(event.recurringEndDate)}`;
      }
      return value;
    }
  }

  deleteEvent(eventId) {
    this.setDeleteModalLoading(true);
    this.props.store.EventsStore.deleteEvent(eventId).then(result => {
      this.setDeleteModalVisible(false);
      this.setDeleteModalEvent(null);
      this.setDeleteModalLoading(false);
      this.props.store.EventsStore.rehydrate();
    }).catch(error => {
      this.props.store.MainStore.setError(error);
    })
  }

  setEventInStore(event) {
    this.props.store.EventsStore.setEvent(event);
  }

  componentDidMount() {
    document.title = 'Events | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(EventMenuStructure);

    this.props.store.EventsStore.fetchEvents()
      .then(result => {
        this.props.store.EventsStore.setEvents(result)
      }).catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.events ?
          <React.Fragment>
            {this.props.store.EventsStore.events ?
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon yellow"><i className="far fa-calendar-alt"></i></span>
                    <span className="header-text">My Events</span>
                    <Link to="/events/add" className="button is-primary rightAlign" onClick={this.setEventInStore.bind(this, null)}><i className="fal fa-plus"></i>Add Event</Link>
                  </h2>
                </div>

                <div>
                  <div className="search">
                    <div className="control">
                      <i className="far fa-search"></i>
                      <input autoComplete="off" id="EventSearch" className="input" type="text" placeholder="Search events" />
                    </div>
                  </div>
                  <div className="advanced_list tiled events">
                    <div className="row">
                      {this.props.store.EventsStore.events.map((event, i) => {
                        return (
                          <div className="col-lg-4 col-md-6 col-sm-12 has_avatar" key={i}>
                            <div className={"panel panel-default" + (event.isDeleting == true || event.deleting == true || event.isDeleting || event.deleting ? " disabled" : "")}>
                              <div className="topBlock">
                                <div className="view">
                                  {event.recurring ?
                                    <Link to={`/events/view/${event.id}`} data-balloon-length="large" data-balloon="View all event and recurring details." data-balloon-pos="up"><i className="far fa-eye" aria-hidden="true"></i></Link>
                                    :
                                    <React.Fragment>
                                      <a data-balloon-length="medium" data-balloon="View event details." data-balloon-pos="up" className="userActions" onClick={this.showModalFor.bind(this, event.id)}><i className="far fa-eye" aria-hidden="true"></i></a>
                                    </React.Fragment>
                                  }
                                </div>
                                <div className="eventImage"><img src={event.calendarIcon} /></div>
                                <div className="titleDiv">
                                  <span className="eventTitle">{event.title}</span>
                                  <span className="eventDate"><i className="far fa-calendar-alt"></i>{event.recurring ? `Recurring` : `${this.convertDateToHumanReadable(event.date)}`}</span>
                                  <span className="eventTime"><i className="far fa-clock"></i>{event.recurring ? `${this.convertTime(event.time)}` : `${this.convertTime(event.time)}`}</span>

                                </div>
                              </div>
                              <hr />
                              <div className="panel-body">
                                <div className="eventInfo">
                                  <div className="types">
                                    <em>Game Type:</em>{event.game}
                                  </div>
                                  <div className="types">
                                    <em>Event Type:</em>{event.type}
                                  </div>
                                  {event.recurring ?
                                    null
                                    :
                                    <React.Fragment>
                                      <div className="types">
                                        <em>Participants:</em><Link to={`/events/participants/${event.id}`}>View the entrants</Link>
                                      </div>
                                    </React.Fragment>
                                  }
                                </div>
                                <div className="editInfo">
                                  <span className="edit">
                                    <React.Fragment>
                                      <Link to={`/events/update/${event.id}`} onClick={this.setEventInStore.bind(this, event)}><i className="fas fa-pencil-alt"></i> Edit</Link>
                                    </React.Fragment>
                                  </span>
                                  <span className="deleteEvent">
                                    <React.Fragment>
                                      <a onClick={this.showDeleteModalFor.bind(this, event.id)}>Remove <i className="fas fa-times-circle"></i></a>
                                    </React.Fragment>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <Link to="/events/add" className="button is-primary bottomAdd" onClick={this.setEventInStore.bind(this, null)}><i className="fal fa-plus"></i>Add Event</Link>
                </div>
                {this.modalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.modalEvent.title}</p>
                        <i onClick={() => this.setModalVisible(false)} className="fal fa-times"></i>
                      </header>
                      <section className="modal-card-body">
                        {this.modalEvent ?
                          <div className="content">
                            {this.modalEvent.banner ?
                              <React.Fragment>
                                <h5>Background image</h5>
                                <img className="eventBanner" src={this.modalEvent.banner} />
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.calendarIcon ?
                              <React.Fragment>
                                <h5>Icon shown on calendar</h5>
                                <img className="eventImage" src={this.modalEvent.calendarIcon}></img>
                              </React.Fragment>
                              :
                              null
                            }
                            <h5>Game</h5>
                            <p>{this.modalEvent.game}</p>
                            <h5>Type</h5>
                            <p>{this.modalEvent.type}</p>
                            <h5>Date</h5>
                            <p>{this.convertDateToHumanReadable(this.modalEvent.date)}</p>
                            <h5>Time</h5>
                            <p>{this.convertTime(this.modalEvent.time)}</p>
                            {this.modalEvent.description ?
                              <React.Fragment>
                                <h5>Description</h5>
                                <div dangerouslySetInnerHTML={{ __html: this.modalEvent.description }}></div>
                                <br />
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.maxParticipants ?
                              <React.Fragment>
                                <h5>Max participants</h5>
                                <p>{this.modalEvent.maxParticipants}</p>
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.prizeStructure ?
                              <React.Fragment>
                                <h5>Prize structure</h5>
                                <div dangerouslySetInnerHTML={{ __html: this.modalEvent.prizeStructure }}></div>
                                <br />
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.participants && this.modalEvent.participants.length > 0 ?
                              <React.Fragment>
                                <h5>Current participants</h5>
                                <p>{this.commaSplitWithAnd(this.modalEvent.participants)}</p>
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.recurring ?
                              <React.Fragment>
                                <h5>Recurring details</h5>
                                <p>{this.modalRecurringText}</p>
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.isTicketed ?
                              <React.Fragment>
                                <h5>Ticket Price</h5>
                                <p>${this.modalEvent.ticketPrice}</p>
                                {this.modalEvent.banner ?
                                  <React.Fragment>
                                    <h5>Ticket image</h5>
                                    <img className="eventImage" src={this.modalEvent.ticketImage || "https://storage.googleapis.com/binderpos-event-images/binderpos-ticket.png"} />
                                  </React.Fragment>
                                  :
                                  null
                                }
                              </React.Fragment>
                              :
                              null
                            }
                          </div>
                          :
                          null
                        }
                      </section>
                      <footer className="modal-card-foot">
                        <button className="button is-success" onClick={() => this.setModalVisible(false)}>Close</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
                {this.deleteModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Are you sure?</p>
                        <button className="delete" aria-label="close" onClick={() => this.setDeleteModalVisible(false)}></button>
                      </header>
                      <section className="modal-card-body">
                        <p>If you proceed with deleting your event: <strong>{this.deleteModalEvent.title}</strong>, you will not be able to restore it.</p>
                        <p>Are you sure you want to delete the event?</p>
                      </section>
                      <footer className="modal-card-foot">
                        <button className={`button half is-danger ${this.deleteModalLoading ? 'is-loading disabled' : ''}`} onClick={() => this.deleteEvent(this.deleteModalEvent.id)}>Delete</button>
                        <button className="button half is-text" onClick={() => this.setDeleteModalVisible(false)}>Cancel</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
