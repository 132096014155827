var SettingsMenuStructure = {
    title: "Settings",
    activeClass: "orange",
    links: [
        {
            title: "Store settings",
            href: "settings/store"
        },
        {
            title: "Pricing settings",
            href: "settings/pricing"
        },
        {
            title: "Tax settings",
            href: "settings/tax"
        },
        {
            title: "Timezone settings",
            href: "settings/timezone"
        }
    ]
};

export default SettingsMenuStructure;