import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { CustomerMenuStructure } from 'menuStructures';
import { Loader } from 'components';
import { TimeUtils } from 'utils';

const WAIT_INTERVAL = 625;
const ENTER_KEY = 13;

@inject("posStore")
@inject("store")
@observer
export default class CustomerList extends React.Component {

  @observable offset = 0;
  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 100;
  @action setLimit(limit) {
    this.limit = limit;
  }

  @observable modalVisible = false;
  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable history;
  @action setModalHistory(history) {
    this.history = history;
  }

  @observable historyModalTitle;
  @action setHistoryModalTitle(title) {
    this.historyModalTitle = title;
  }

  @observable adjustText;
  @action setAdjustText(text) {
    this.adjustText = text;
  }

  @observable adjustModalTitle;
  @action setAdjustModalTitle(title) {
    this.adjustModalTitle = title;
  }

  @observable adjustCreditModalVisible;
  @action setAdjustCreditModalVisible(bool) {
    this.adjustCreditModalVisible = bool;
  }

  @observable customerToAdjust;
  @action setCustomerToAdjust(customer) {
    this.customerToAdjust = customer;
  }

  @observable adjustCreditLoading;
  @action setAdjustCreditLoading(bool) {
    this.adjustCreditLoading = bool;
  }

  @observable searchString = "";
  @action setSearchString(searchString) {
    this.searchString = searchString;
  }

  @observable uploadFileModalVisible;
  @action setUploadFileModalVisible(bool) {
    this.uploadFileModalVisible = bool;
    if (!bool) {
      this.setCsvMessage(null);
    }
  }

  @observable csvMessage;
  @action setCsvMessage(message) {
    this.csvMessage = message;
  }

  @observable processing;
  @action setProcessing(bool) {
    this.processing = bool;
  }

  @observable timer;
  @action setTimer(timer) {
    this.timer = timer;
  }

  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
    this.form = React.createRef();
    this.csvFile = React.createRef();
  }

  componentDidMount() {
    document.title = "Customers | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(CustomerMenuStructure);
    this.props.posStore.getTax();

    this.props.store.CustomersStore.fetchCustomers(this.offset, this.limit)
      .then(result => {
        this.props.store.CustomersStore.setCustomers(result);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  updateCustomerList() {
    this.props.store.CustomersStore.setCustomers(null);
    this.props.store.CustomersStore.refreshCustomers(this.offset, this.limit)
      .then(result => {
        this.props.store.CustomersStore.setCustomers(result);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  uploadCSVFile() {
    var file = this.csvFile.current.files[0];
    if (file) {
      this.setProcessing(true);
      let data = new FormData();
      data.append("file", file);
      data.append("name", file.name);
      this.props.store.CSVStore.postCustomerCSV(data)
        .then(result => {
          this.setCsvMessage(result.message);
          this.setProcessing(false);
        })
        .catch(error => {
          this.setCsvMessage(
            "There was an issue uploading your CSV, please try again."
          );
          this.setProcessing(false);
        });
    }
  }

  viewHistory(customer) {
    this.setHistoryModalTitle(
      "Store credit history for " + customer.firstName + " " + customer.lastName
    );
    this.props.store.CustomersStore.getCustomerCreditHistory(customer.id)
      .then(results => {
        console.log(results);
        this.setModalHistory(results);
        this.setModalVisible(true);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      })
  }

  showAdjustModal(customer) {
    this.setAdjustText("Enter how much you want to add to their store credit.");
    this.setCustomerToAdjust(null);
    this.setCustomerToAdjust(customer);
    this.setAdjustCreditModalVisible(true);
    this.setAdjustModalTitle(
      "Adjust store credit for " + customer.firstName + " " + customer.lastName
    );
  }

  changeAdjustWorking() {
    let value = this.form.current.elements.adjustType.options[
      this.form.current.elements.adjustType.selectedIndex
    ].value;
    if (value == "overwrite") {
      this.setAdjustText(
        "Enter the amount of store credit they have. This will overwrite the current value."
      );
    } else {
      this.setAdjustText(
        "Enter how much you want to " +
        (value == "increase" ? "add to" : "remove from") +
        " their store credit."
      );
    }
  }

  searchForCustomer() {
    clearTimeout(this.timer);

    let localTimer = setTimeout(() => {
      this.setSearchString(this.searchInput.current.value);
      this.setOffset(0);
      this.props.store.CustomersStore.fetchCustomers(
        this.offset,
        this.limit,
        this.searchString
      )
        .then(result => {
          this.props.store.CustomersStore.setCustomers(result);
        })
        .catch(error => {
          this.props.store.MainStore.setError(error);
        });
    }, WAIT_INTERVAL);

    this.setTimer(localTimer);
  }

  @observable
  submitButtonVisible = true;

  @action
  adjustStoreCredit(e) {
    this.submitButtonVisible = false;
    e.preventDefault();

    const elm = this.form.current.elements;
    let toUpdate = {
      customerId: this.customerToAdjust.id,
      amountToAdjust: elm.adjustPrice.value,
      typeOfAdjustment:
        elm.adjustType.options[elm.adjustType.selectedIndex].value,
      publicNotes: elm.publicNotes.value,
      privateNotes: elm.privateNotes.value
    };

    this.props.store.CustomersStore.adjustCustomerCredit(toUpdate)
      .then(() => {
        this.props.store.CustomersStore.rehydrate(this.offset, this.limit);
        this.setAdjustCreditModalVisible(false);
        this.setCustomerToAdjust(null);
        this.submitButtonVisible = true;
      })
      .catch(error => {
        this.submitButtonVisible = true;
        this.props.store.MainStore.setError(error);
      });
  }

  getNextCustomers() {
    this.setOffset(this.offset + 100);
    this.props.store.CustomersStore.fetchCustomers(
      this.offset,
      this.limit,
      this.searchString
    )
      .then(result => {
        this.props.store.CustomersStore.setCustomers(result);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  getPrevCustomers() {
    var offsetUpdate = this.offset - 100;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.CustomersStore.fetchCustomers(
      this.offset,
      this.limit,
      this.searchString
    )
      .then(result => {
        this.props.store.CustomersStore.setCustomers(result);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  render() {
    const fC = this.props.posStore.fCurr;
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.customers ? (
          <React.Fragment>
            {this.props.store.CustomersStore.customers ? (
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon purple"><i className="fal fa-users"></i></span>
                    <span className="header-text">My Customers</span>
                    <a className="button is-primary rightAlign" onClick={() => this.setUploadFileModalVisible(true)}><i className="fal fa-upload"></i>Upload Store Credit</a>
                    <a className="button is-primary rightAlign" onClick={this.updateCustomerList.bind(this)}><i className="fas fa-sync-alt"></i>Refresh</a>
                    <Link to="/customers/add" className="button is-primary rightAlign"><i className="fal fa-plus"></i>Add Customer</Link>
                  </h2>
                </div>
                <div>
                  <div className="search">
                    <div className="control">
                      <i className="far fa-search"></i>
                      <input autoComplete="off" ref={this.searchInput} id="customerSearch" className="input" type="text" placeholder="Search for a customer by email or name" onChange={this.searchForCustomer.bind(this)} />
                    </div>
                  </div>
                  <br />
                  <div className="customer_list">
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="2" className="col1 customerName">Name</th>
                          <th className="col1 customerEmail">Email</th>
                          <th className="col2">Phone</th>
                          <th className="col2" className="storeCredit">Store Credit</th>
                          <th className="col3"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.store.CustomersStore.customers.map((customer, i) => {
                          return (
                            <tr className="list_item" key={i}>
                              <td className="initials"><div>{customer.firstName && customer.firstName.length > 0 ?
                                <React.Fragment>

                                  {customer.firstName.charAt(0)}
                                  {customer.lastName && customer.lastName.length > 0 ?
                                    <React.Fragment>
                                      {customer.lastName.charAt(0)}
                                    </React.Fragment>
                                    :
                                    null
                                  }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  {customer.email && customer.email.length > 0 ?
                                    <React.Fragment>
                                      {customer.email.charAt(0)}
                                    </React.Fragment>
                                    :
                                    null
                                  }
                                </React.Fragment>
                              }
                              </div>
                              </td>
                              <td className="name">{customer.firstName} {customer.lastName}</td>
                              <td>{customer.email ?
                                <a href={`mailto:${customer.email}`}>{customer.email}</a>
                                :
                                <React.Fragment>
                                  <i className="fa fa-exclamation-triangle" aria-hidden="true"></i><span className="noEmail">No Email Provided</span>
                                </React.Fragment>
                              }
                              </td>
                              <td>{customer.phone ?
                                <a href={`tel:${customer.phone}`}>{customer.phone}</a>
                                :
                                <React.Fragment>
                                  <span className="noPhone">N/A</span>
                                </React.Fragment>
                              }</td>
                              <td><a data-balloon-length="medium" data-balloon="Adjust store credit." data-balloon-pos="up" className="userActions" onClick={() => this.showAdjustModal(customer)}><i className="fal fa-badge-dollar"></i> ${customer.storeCredit.toFixed(2)}</a></td>
                              <td><Link data-balloon-length="medium" data-balloon="Edit customer details." data-balloon-pos="up" to={"/customers/update/" + customer.id} className="userActions"><i className="far fa-edit edit"></i></Link>
                                <a className="userActions" data-balloon-length="medium" data-balloon="Store credit history." data-balloon-pos="up" onClick={() => this.viewHistory(customer)}><i className="far fa-history history"></i></a>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="advanced_list tiled mobile hidden">
                    {this.props.store.CustomersStore.customers.map((customer, i) => {
                      return (
                        <div className="list_item has_avatar" key={i}>
                          <div className="panel panel-default">
                            <div className="panel-body">
                              <div className="clearfix item_info user_info">
                                <div className="item_info_img user_avatar pull-left">
                                  <div className="avatar color5 no_image circle">
                                    <div className="initials">
                                      {customer.firstName && customer.firstName.length > 0 ?
                                        <React.Fragment>
                                          {customer.firstName.charAt(0)}
                                          {customer.lastName && customer.lastName.length > 0 ?
                                            <React.Fragment>
                                              {customer.lastName.charAt(0)}
                                            </React.Fragment>
                                            :
                                            null
                                          }
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                          <i
                                            className="fa fa-exclamation-triangle"
                                            aria-hidden="true"
                                          />
                                          <span className="noEmail">
                                            No Email Provided
                                          </span>
                                        </React.Fragment>
                                      }
                                    </div>
                                  </div>
                                  <div className="actions item_actions">
                                    <a
                                      className="userActions"
                                      onClick={() =>
                                        this.showAdjustModal(customer)
                                      }
                                    >
                                      <i className="far fa-edit edit" />
                                    </a>
                                    <a
                                      className="userActions"
                                      onClick={() => this.viewHistory(customer)}
                                    >
                                      <i className="fas fa-history history" />
                                    </a>
                                  </div>
                                </div>
                                <div className="item_additional_info">
                                  <div className="additional_info user_credit truncate">
                                    <em>Credit Balance:</em>
                                    {fC(customer.storeCredit)}
                                  </div>
                                  <div className="additional_info user_phone truncate">
                                    <em>Contact Number:</em> {customer.phone}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    )}
                  </div>

                  <div className="paging-nav-bottom ">
                    <button
                      className="button is-info paging-nav-right-margin"
                      disabled={this.offset == 0 ? true : false}
                      onClick={() => this.getPrevCustomers()}
                    >
                      Prev
                    </button>
                    <button
                      className="button is-info"
                      disabled={
                        this.props.store.CustomersStore.customers &&
                          this.props.store.CustomersStore.customers.length <
                          this.limit
                          ? true
                          : false
                      }
                      onClick={() => this.getNextCustomers()}
                    >
                      Next
                    </button>
                  </div>
                </div>
                {this.modalVisible ? (
                  <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.historyModalTitle}</p>
                        <i onClick={() => this.setModalVisible(false)} className="fal fa-times"></i>
                      </header>
                      <section className="modal-card-body">
                        {this.history && this.history.length > 0 ? (
                          <div className="content">
                            {this.history.map((storeCreditHistory, i) => {
                              return (
                                <div key={i}>
                                  <table className="table is-fullwidth storeCredit">
                                    <colgroup>
                                      <col width="30%" />
                                      <col width="70%" />
                                    </colgroup>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Actioned by</strong>
                                        </td>
                                        <td className="info">{storeCreditHistory.actionedBy}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Date actioned</strong>
                                        </td>
                                        <td className="info">{`${TimeUtils.convertDateToHumanReadable(storeCreditHistory.readableUpdatedDate)}`}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Amount before</strong>
                                        </td>
                                        <td className="info">

                                          {
                                            fC(storeCreditHistory.amountBeforeChange)
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Amount after</strong>
                                        </td>
                                        <td className="info">

                                          {fC(storeCreditHistory.amountAfterChange)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Amount changed</strong>
                                        </td>
                                        <td className="info">
                                          {fC(storeCreditHistory.amountChanged)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Notes</strong>
                                        </td>
                                        <td className="info">
                                          <div>
                                            {storeCreditHistory.actionNotes
                                              ? storeCreditHistory.actionNotes
                                              : storeCreditHistory.publicNotes
                                                ? ""
                                                : "There are no notes attached"}
                                          </div>
                                          <br />
                                          <div>
                                            {storeCreditHistory.publicNotes
                                              ? storeCreditHistory.publicNotes
                                              : ""}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Internal Notes</strong>
                                        </td>
                                        <td className="info">
                                          {storeCreditHistory.privateNotes
                                            ? storeCreditHistory.privateNotes
                                            : "There are no internal notes attached"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                            <div className="content">
                              <p>There is no store credit history</p>
                            </div>
                          )}
                      </section>
                      <footer className="modal-card-foot">
                        <button
                          className="button is-success"
                          onClick={() => this.setModalVisible(false)}
                        >
                          Close
                        </button>
                      </footer>
                    </div>
                  </div>
                ) : null}
                {this.adjustCreditModalVisible ? (
                  <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card storecredit">
                      <form
                        ref={this.form}
                        onSubmit={e => this.adjustStoreCredit(e)}
                        noValidate
                      >
                        <header className="modal-card-head">
                          <p className="modal-card-title">{this.adjustModalTitle}</p>
                          <i onClick={() => this.setAdjustCreditModalVisible(false)} className="fal fa-times"></i>
                        </header>
                        <section className="modal-card-body">
                          <div className="field">
                            <span className="select">
                              <select
                                id="adjustType"
                                onChange={this.changeAdjustWorking.bind(this)}
                              >
                                <option value="increase">Increase</option>
                                <option value="decrease">Decrease</option>
                                <option value="overwrite">Overwrite</option>
                              </select>
                            </span>
                          </div>
                          <div className="field">
                            <label className="label">{this.adjustText}</label>
                            <div className="control has-icons-left">
                              <input
                                id="adjustPrice"
                                className="input"
                                type="number"
                                min="0"
                                required
                                placeholder="E.g. 29.95"
                                step=".01"
                              />
                              <span className="icon is-small is-left">
                                <i className="fas fa-dollar-sign" />
                              </span>
                            </div>
                          </div>
                          <div className="field">
                            <label className="label">Public Notes</label>
                            <div className="control">
                              <textarea
                                id="publicNotes"
                                className="input"
                                rows="5"
                                placeholder="E.g. Won tournament and $50.00 store credit was applied"
                              />
                            </div>
                          </div>
                          <div className="field">
                            <label className="label">Internal Notes</label>
                            <div className="control">
                              <textarea
                                id="privateNotes"
                                className="input"
                                rows="5"
                                placeholder="E.g. Customer used $20.00 store credit while the internet was not working, correcting their store credit."
                              />
                            </div>
                          </div>
                        </section>
                        <footer className="modal-card-foot">
                          <button
                            disabled={!this.submitButtonVisible}
                            className={`button half is-info ${
                              this.adjustCreditLoading
                                ? "is-loading disabled"
                                : ""
                              }`}
                          >
                            Adjust
                          </button>
                          <button
                            type="button"
                            className="button half is-text"
                            onClick={() =>
                              this.setAdjustCreditModalVisible(false)
                            }
                          >
                            Cancel
                          </button>
                        </footer>
                      </form>
                    </div>
                  </div>
                ) : null}
                {this.uploadFileModalVisible ? (
                  <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Upload CSV of Customers</p>
                        <i onClick={() => this.setUploadFileModalVisible(false)} className="fal fa-times"></i>
                      </header>
                      <section className="modal-card-body">
                        {this.csvMessage ? (
                          <React.Fragment>
                            <pre>{this.csvMessage}</pre>
                          </React.Fragment>
                        ) : (
                            <React.Fragment>
                              Upload a CSV of customers with the following headers
                              to process:
                            <ul>
                                <li>First Name</li>
                                <li>Last Name</li>
                                <li>Email</li>
                                <li>Phone</li>
                                <li>Credit</li>
                              </ul>
                              <input
                                ref={this.csvFile}
                                id="csvFileUpload"
                                type="file"
                              />

                            </React.Fragment>
                          )}
                      </section>
                      <footer className="modal-card-foot">
                        {this.csvMessage ?
                          <button
                            type="button"
                            className="button is-primary"
                            onClick={() => this.setUploadFileModalVisible(false)}
                          >Ok</button>
                          :
                          <button
                            type="button"
                            className="button is-primary"
                            onClick={() => this.uploadCSVFile()}
                          >Upload!</button>
                        }
                      </footer>
                    </div>
                  </div>
                ) : null}
                {this.processing ? (
                  <Loader text="Processing CSV... Please wait a moment." />
                ) : null}
              </React.Fragment>
            ) : (
                <Loader />
              )}
          </React.Fragment>
        ) : (
            <div>
              <p>Please contact BinderPOS to enable this screen.</p>
            </div>
          )}
      </React.Fragment>
    );
  }
}
