import {
  observable,
  computed,
  action,
  extendObservable,
  autorun,
  toJS,
  onBecomeObserved
} from "mobx";
import Cookie from "mobx-cookie";
import firebase from "firebase/app";

import Store from "../store/index";
import { toast } from "react-toastify";

class Item {
  @observable id;
  @observable name;
  constructor(name, image) {
    this.name = name;
    this.id = Date.now();
    this.image = image;
  }
}

class Register {}

class LineItem {
  @observable title;
  @observable buyItem;
  @observable qty;
  @observable actualPrice;
  @observable shopifyPrice;
  @observable variantTitle;
  @observable productId;
  @observable variantId;
  @observable lineId;
  @observable imageUrl;
  @observable discountAmount = 0;
  @observable discountType = "percentage";
  @observable taxSetting;
  @observable discountModal = false;
  tags;
  taxDisabledShopify;
  taxDisabledUI;
  itemTaxIncluded;

  @computed get tax() {
    //If taxes are included in BinderPOS UI
    if (this.taxSetting.taxIncluded) {
      console.log("globalTaxIncluded");
      if (this.taxDisabledShopify) {
        var actualPrice = this.actualPrice;
        var itemPrice = this.actualPrice + this.discountValue / this.qty;
        var displayPrice = this.actualPrice + this.discountValue / this.qty;
        var itemTax = 0;
        console.log("shopifyTaxDisabled");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax
        };
      }
      if (this.taxDisabledUI) {
        var actualPrice = this.actualPrice;
        var itemPrice =
          (this.actualPrice + this.discountValue / this.qty) /
          (1 + this.itemTaxRate);
        if (this.buyItem) {
          itemPrice = this.actualPrice + this.discountValue / this.qty;
        }
        var displayPrice = this.actualPrice + this.discountValue / this.qty;
        var itemTax = 0;
        console.log("UITaxDisabled");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax
        };
      }
      //if no settings

      var actualPrice = this.actualPrice;
      var itemPrice =
        (this.actualPrice + this.discountValue / this.qty) /
        (1 + this.itemTaxRate);
      if (this.buyItem) {
        itemPrice = this.actualPrice + this.discountValue / this.qty;
      }
      var displayPrice = this.actualPrice + this.discountValue / this.qty;
      var itemTax = itemPrice * this.itemTaxRate;
      console.log("itemTaxedNormal");
      return {
        actualPrice,
        itemPrice,
        displayPrice,
        itemTax
      };
    }
    //If taxes are NOT included in BinderPOS UI
    else {
      //if tax has been disabled in shopify or POS toggle
      if (this.taxDisabledShopify | this.taxDisabledUI) {
        var actualPrice = this.actualPrice;
        var itemPrice = this.actualPrice + this.discountValue / this.qty;
        var displayPrice = this.actualPrice + this.discountValue / this.qty;
        var itemTax = 0;
        console.log("itemTaxDisabled");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax
        };
      }
      //if tax is included via tag or later db column
      if (this.itemTaxIncluded) {
        var actualPrice = this.actualPrice;
        var itemPrice =
          (this.actualPrice + this.discountValue / this.qty) /
          (1 + this.itemTaxRate);
        var displayPrice = this.actualPrice + this.discountValue / this.qty;
        var itemTax = itemPrice * this.itemTaxRate;
        console.log("itemTaxIncluded");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax
        };
      }
      //if no settings or tags have been set
      var actualPrice = this.actualPrice;
      var itemPrice = this.actualPrice + this.discountValue / this.qty;
      var displayPrice = this.actualPrice + this.discountValue / this.qty;
      var itemTax =
        (this.actualPrice + this.discountValue / this.qty) * this.itemTaxRate;
      console.log("itemTaxedNormal");
      return {
        actualPrice,
        itemPrice,
        displayPrice,
        itemTax
      };
    }
  }

  @computed get lineTotalFormatted() {
    return this.round(this.currencySymbol + this.qty * this.tax.itemPrice);
  }
  @computed get lineTotal() {
    return this.qty * this.tax.itemPrice;
  }
  @computed get lineTotalActual() {
    return this.qty * this.actualPrice;
  }
  @computed get displayTotal() {
    return this.qty * this.tax.displayPrice;
  }
  @computed get lineTaxTotal() {
    return this.qty * this.tax.itemTax;
  }
  @computed get itemTaxRate() {
    if (this.tags && this.tags.match(/(AT)\{(.+)\}([0-9\.]+)/)) {
      return parseFloat(this.tags.match(/(AT)\{(.+)\}([0-9\.]+)/)[3])
        ? parseFloat(this.tags.match(/(AT)\{(.+)\}([0-9\.]+)/)[3]) / 100
        : this.taxSetting.taxRate;
    } else {
      return this.taxSetting.taxRate;
    }
  }

  @computed get specialTax() {
    if (this.tags && this.tags.match(/(AT)\{(.+)\}([0-9\.]+)/)) {
      return this.tags.match(/(AT)\{(.+)\}([0-9\.]+)/)[2];
    } else {
      return false;
    }
  }

  @computed get discountValue() {
    if (!this.discountType || !this.discountAmount) {
      return 0;
    }
    if (this.discountType == "percentage") {
      return -this.lineTotalActual * (this.discountAmount / 100);
    }
    return -this.discountAmount;
  }

  @computed get price() {
    return this.actualPrice + this.discountValue;
  }

  constructor(product, buyMode, cashPrice, taxSetting, eventAdditionalInfo) {
    this.tags = product.tags;
    this.taxSetting = taxSetting;

    //if we are creating the line item by adding within POS
    if (!product.hasOwnProperty("buying")) {
      var index = buyMode
        ? product.selectedBuyVariant
        : product.selectedVariant;
      this.buyItem = false;
      this.title = product.title;
      this.qty = 1;
      this.actualPrice = product.variants[index].price;
      this.shopifyPrice = product.variants[index].price;
      this.cashBuyPrice = product.variants[index].cashBuyPrice;
      this.creditBuyPrice = product.variants[index].storeCreditBuyPrice;
      this.variantTitle = product.variants[index].title;
      this.productId = product.id;
      this.variantId = product.variants[index].id;
      this.imageUrl = product.img;
      this.eventAdditionalInfo = eventAdditionalInfo;
      this.taxDisabledShopify =
        product.variants[index].taxable == null
          ? false
          : !product.variants[index].taxable;
      this.taxDisabledUI = false;
      this.itemTaxIncluded = false;
      this.discountAmount = product.discountAmount;
      this.discountType = product.discountType;
      if (buyMode) {
        this.buyItem = true;
        this.actualPrice = cashPrice
          ? product.variants[index].cashBuyPrice * -1
          : product.variants[index].storeCreditBuyPrice * -1;
        this.variantId = product.variants[index].id;
      }
      //if we are creating the line item from server response ie cart validation
    } else {
      this.buyItem = product.buying;
      this.title = product.productTitle;
      this.lineId = product.id;
      this.qty = product.quantity;
      this.productId = product.id;
      this.variantId = product.variantId;
      this.variantTitle = product.variantTitle;
      this.imageUrl = product.imageSrc;
      this.discount = 0;
      this.taxable = product.taxable;
      this.taxDisabledShopify =
        product.shopifyTaxable == null ? false : !product.shopifyTaxable;
      this.taxDisabledUI = product.taxable == null ? false : !product.taxable;
      this.itemTaxIncluded = false;
      this.eventAdditionalInfo = eventAdditionalInfo;
      this.shopifyPrice = product.shopifyPrice;
      this.actualPrice = product.actualPrice;
      this.discountAmount = product.discountAmount;
      this.discountType = product.discountType;
    }
  }
}

class CustomLineItem {
  @observable title;
  @observable buyItem;
  @observable qty;
  @observable actualPrice;
  @observable cashBuyPrice;
  @observable creditBuyPrice;
  @observable variantTitle;
  @observable productId;
  @observable variantId;
  @observable lineId;
  @observable imageUrl;
  @observable taxSetting;
  @observable discountAmount = 0;
  @observable discountType = "percentage";

  @computed get tax() {
    //If taxes are included in BinderPOS UI
    if (this.taxSetting.taxIncluded) {
      console.log("globalTaxIncluded");
      if (this.taxDisabledShopify) {
        var actualPrice = this.actualPrice;
        var itemPrice = this.actualPrice + this.discountValue / this.qty;
        var displayPrice = this.actualPrice + this.discountValue / this.qty;
        var itemTax = 0;
        console.log("shopifyTaxDisabled");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax
        };
      }
      if (this.taxDisabledUI) {
        var actualPrice = this.actualPrice;
        var itemPrice =
          (this.actualPrice + this.discountValue / this.qty) /
          (1 + this.itemTaxRate);
        if (this.buyItem) {
          itemPrice = this.actualPrice + this.discountValue / this.qty;
        }
        var displayPrice = this.actualPrice + this.discountValue / this.qty;
        var itemTax = 0;
        console.log("UITaxDisabled");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax
        };
      }
      //if no settings

      var actualPrice = this.actualPrice;
      var itemPrice =
        (this.actualPrice + this.discountValue / this.qty) /
        (1 + this.itemTaxRate);
      if (this.buyItem) {
        itemPrice = this.actualPrice + this.discountValue / this.qty;
      }
      var displayPrice = this.actualPrice + this.discountValue / this.qty;
      var itemTax = itemPrice * this.itemTaxRate;
      console.log("itemTaxedNormal");
      return {
        actualPrice,
        itemPrice,
        displayPrice,
        itemTax
      };
    }
    //If taxes are NOT included in BinderPOS UI
    else {
      //if tax has been disabled in shopify or POS toggle
      if (this.taxDisabledShopify | this.taxDisabledUI) {
        var actualPrice = this.actualPrice;
        var itemPrice = this.actualPrice + this.discountValue / this.qty;
        var displayPrice = this.actualPrice + this.discountValue / this.qty;
        var itemTax = 0;
        console.log("itemTaxDisabled");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax
        };
      }
      //if tax is included via tag or later db column
      if (this.itemTaxIncluded) {
        var actualPrice = this.actualPrice;
        var itemPrice =
          (this.actualPrice + this.discountValue / this.qty) /
          (1 + this.itemTaxRate);
        var displayPrice = this.actualPrice + this.discountValue / this.qty;
        var itemTax = itemPrice * this.itemTaxRate;
        console.log("itemTaxIncluded");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax
        };
      }
      //if no settings or tags have been set
      var actualPrice = this.actualPrice;
      var itemPrice = this.actualPrice + this.discountValue / this.qty;
      var displayPrice = this.actualPrice + this.discountValue / this.qty;
      var itemTax =
        (this.actualPrice + this.discountValue / this.qty) * this.itemTaxRate;
      console.log("itemTaxedNormal");
      return {
        actualPrice,
        itemPrice,
        displayPrice,
        itemTax
      };
    }
  }

  @computed get lineTotalFormatted() {
    return this.roundCents(this.currencySymbol + this.qty * this.tax.itemPrice);
  }
  @computed get lineTotal() {
    return this.qty * this.tax.itemPrice;
  }

  @computed get displayTotal() {
    return this.qty * this.tax.displayPrice;
  }
  @computed get lineTaxTotal() {
    return this.qty * this.tax.itemTax;
  }

  @computed get lineTotalActual() {
    return this.qty * this.actualPrice;
  }

  @computed get itemTaxRate() {
    return this.taxSetting.taxRate;
  }

  @computed get specialTax() {
    return false;
  }

  @computed get discountValue() {
    if (!this.discountType || !this.discountAmount) {
      return 0;
    }
    if (this.discountType == "percentage") {
      return -this.lineTotalActual * (this.discountAmount / 100);
    }
    return -this.discountAmount;
  }

  @computed get price() {
    return this.actualPrice + this.discountValue;
  }

  constructor(custom, buyMode, taxSetting) {
    this.taxSetting = taxSetting;

    this.buyItem = false;
    this.title = custom.name;
    this.qty = 1;
    this.actualPrice = custom.actualPrice;
    this.cashBuyPrice = custom.actualPrice;
    this.creditBuyPrice = custom.actualPrice;
    this.variantTitle = "-";
    this.productId = null;
    this.variantId = null;
    this.imageUrl = "";
    this.currencySymbol = "$";
    this.taxable = true;
    if (buyMode) {
      this.buyItem = true;
      this.actualPrice = custom.actualPrice * -1;
      this.variantId = null;
    }
  }
}
/**
 * ItemList contains two arrays one for items in the
 * results grid and another for items in the cart. This
 * is just for messing around and will be restructured.
 */
class ItemList {
  @observable currentToken = "";
  @action getToken() {
    return Store.AuthStore.user.getIdToken(true).then(token => {
      this.currentToken = token;
      return token;
    });
  }

  @observable items = [];
  @action clearSearchItems = () => {
    this.items = [];
  };
  @observable cart = [];
  @action setCart = input => {
    this.cart = input;
    console.log(input);
  };

  @observable customItem = { name: "", actualPrice: 0, qty: 1 };
  @observable fetchingSearch = false;
  @observable searchNumber = 0;
  @observable searchTerm = "";
  @action setSearchTerm(term) {
    this.searchTerm = term;
  }

  @observable timer;

  @action setTimer(timer) {
    this.timer = timer;
  }

  @action
  refreshSearch = () => {
    if (this.searchTerm) {
      this.loaderOn();
      this.getCall(
        encodeURI(
          "https://devportal.binderpos.com/api/products?searchLimit=50&keyword=" +
            this.searchTerm +
            "&includeSingles=" +
            this.includeSingles.toString() +
            "&includeBuyprice=" +
            this.buyMode.toString()
        ),
        this.currentToken
      )
        .then(data => {
          this.loaderOff();
          this.emptyList();
          if (data[0]) {
            data.map(data => this.addItem(data));
          }
        })
        .catch(err => {
          console.error(err);
          this.loaderOff();
        });
    }
  };
  @observable waitingToSearch = false;
  @action setWaitingToSearch(value) {
    this.waitingToSearch = !!value;
  }
  @observable
  searchSuggestions = [];

  @action
  setSearchSuggestions = value => {
    this.searchSuggestions = value;
  };
  @action
  search = e => {
    this.setWaitingToSearch(true);
    this.searchNumber++;
    var currentSearch = this.searchNumber;
    this.loaderOff();
    clearTimeout(this.timer);
    var query = e.target.value;
    this.setSearchTerm(query);
    // this.db.products
    //   .where("title")
    //   .startsWith(query)
    //   .limit(10)
    //   .toArray()
    //   .then(e => e.map(t => t.title))
    //   .then(l => this.setSearchSuggestions(l));
    let localTimer = setTimeout(() => {
      if (query) {
        this.setWaitingToSearch(false);
        this.loaderOn();
        this.getCall(
          encodeURI(
            "https://devportal.binderpos.com/api/products?searchLimit=50&keyword=" +
              query +
              "&includeSingles=" +
              this.includeSingles.toString() +
              "&includeBuyprice=" +
              this.buyMode.toString()
          ),
          this.currentToken
        )
          .then(data => {
            this.loaderOff();
            this.emptyList();
            if (data[0] && currentSearch == this.searchNumber) {
              data.map(data => this.addItem(data));
            }
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        this.emptyList();
      }
    }, 650);

    this.setTimer(localTimer);
  };

  @action fetchBarcode = async e => {
    var barcode = e.target.value;
    if (e.key === "Enter" && !this.cartLoading) {
      clearTimeout(this.timer);
      e.preventDefault();
      this.loaderOn();
      const result = await this.getCall(
        "https://devportal.binderpos.com/api/products/byBarcode/" + barcode,
        this.currentToken
      );
      if (!result.error && result.length == 1) {
        result[0].variants.map((variant, index) => {
          if (variant.barcode == barcode || variant.sku == barcode) {
            result[0].selectedVariant = index;
            result[0].selectedBuyVariant = index;
          }
          this.loaderOff();
        });
        this.addToCart(
          new LineItem(result[0], this.buyMode, null, this.allTax)
        );
      } else {
        this.loaderOff();
        this.emptyList();
        result?.map(data => this.addItem(data));
      }

      this.setSearchTerm("");
    }
  };

  @action loaderOn() {
    this.fetchingSearch = true;
  }
  @action loaderOff() {
    this.fetchingSearch = false;
  }
  @observable cartLoading = false;
  @action cartLoadingOn() {
    this.cartLoading = true;
  }
  @action cartLoadingOff() {
    this.cartLoading = false;
  }

  bob = "hello3";

  @observable errorMessage = "";
  @action setErrorMessage(value) {
    this.errorMessage = value;
    this.logError();
    this.errorTraceId = "";
  }

  @observable errorHeader = "";
  @action setHeaderMessage(value) {
    this.headerMessage = value;
    this.logError();
    this.errorTraceId = "";
  }

  @observable errorTraceId = "";
  @observable lastTraceId = "";
  @observable externalUrl = "";

  @action setAPIError(json) {
    this.errorHeader = json.error;
    this.errorMessage = json.detailedMessage;
    this.errorTraceId = json.traceId;
    this.lastTraceId = json.traceId;
    this.externalUrl = json.externalUrl;
    this.logError();
  }

  @observable floatOpenAmount = "0.00";

  toast(msg) {
    toast.info(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }

  burntToast(msg) {
    toast.warn(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }

  /**
   * This boolean tracks wether the POS is in buy or sell mode
   */
  @observable buyMode = false;
  @action toggleBuyMode = () => {
    this.buyMode = !this.buyMode;
    this.buyMode
      ? this.toast("Switched to Buy-Mode")
      : this.toast("Switched to Sell-Mode");
    this.refreshSearch();
  };
  @observable cashPrice = false;
  @action toggleCashPrice = () => {
    this.cashPrice = !this.cashPrice;
    this.cashPrice
      ? this.toast("Switched to cash pricing")
      : this.toast("Switched to credit pricing");
  };
  @observable floatModal = false;
  @action closeFloatModal() {
    this.floatModal = false;
  }

  @observable selectedCustomer = null;
  @action setSelectedCustomer(customer) {
    this.selectedCustomer = customer;
  }
  @observable customerResults = [];
  @observable activeTender = 0;
  @action emptyList() {
    this.items = [];
  }
  @action addItem(item) {
    item.selectedVariant = -1;
    item.selectedBuyVariant = -1;
    item.variants.some((variant, index) => {
      if (variant.quantity > 0) {
        item.selectedVariant = index;
        return true;
      }
    });
    item.variants.some((variant, index) => {
      if (variant.quantity > 0) {
        item.selectedBuyVariant = index;
        return true;
      }
    });

    this.items.push(item);
  }
  @action
  addCustomerResults(data) {
    this.customerResults = data;
  }

  @observable customerInput = "";
  @observable cartId = "";
  @action setCartId = value => {
    this.cartId = value;
  };
  @observable includeSingles = true;

  @action toggleSingle = () => {
    this.includeSingles = !this.includeSingles;
  };

  @computed get total() {
    return parseFloat(parseFloat(this.subTotal) + parseFloat(this.taxTotal));
    //return Number(Math.round(this.subTotal + this.taxTotal + "e2") + "e-2");
  }

  @computed get taxRates() {
    var taxes = [];
    var standardTax = { title: this.taxWording, price: 0, rate: this.taxRate };
    for (var line of this.cart) {
      if (!line.buyItem) {
        if (line.specialTax) {
          var exists = false;
          const taxLine = {
            title: line.specialTax,
            price: line.lineTaxTotal,
            rate: line.itemTaxRate
          };
          if (taxes.length) {
            for (var tax of taxes) {
              if (taxLine.title == tax.title && taxLine.rate == tax.rate) {
                tax.price += taxLine.price;
                exists = true;
                break;
              }
            }
          }
          if (!exists) {
            taxes.push(taxLine);
          }
        } else {
          standardTax.price += line.lineTaxTotal;
        }
      }
    }
    taxes.push(standardTax);
    for (const tax of taxes) {
      if (tax.price < 0) {
        tax.price = 0;
      }
      if (this.discountPercentage) {
        tax.price = tax.price - tax.price * this.discountPercentage;
      }
      if (this.negatedTaxTotal) {
        tax.price =
          tax.price + this.negatedTaxTotal * (tax.price / this.saleTaxTotal);
      }
    }
    return taxes;
  }

  @computed get subTotal() {
    return this.saleTotal + this.buyTotal;
  }

  @observable
  globalDiscount = { type: "percentage", amount: "0.00" };

  @computed get globalDiscountBAD() {
    var globalDiscount = null;
    this.cart.forEach(item => {
      var discount = item.title.match(/POS Discount ([0-9]+\.[0-9]{2})(\%)/);
      if (discount) {
        if (discount[2]) {
          globalDiscount = { type: "percentage", amount: discount[1] };
        } else {
          globalDiscount = { type: "amount", amount: discount[1] };
        }
      }
    });
    return globalDiscount;
  }

  @computed get discountAmount() {
    var tempTotal;
    if (this.cart.length) {
      var tots = this.cart.reduce(
        (reducer, line) =>
          parseFloat(reducer) + parseFloat(line.buyItem ? 0 : line.lineTotal),
        0
      );
      tempTotal = tots;
    } else {
      tempTotal = 0;
    }
    if (this.globalDiscount?.type == "percentage") {
      return parseFloat(
        this.roundCents(
          -tempTotal * (parseFloat(this.globalDiscount?.amount) / 100)
        )
      );
    } else if (this.globalDiscount?.type == "amount") {
      return -this.globalDiscount?.amount;
    }
    return 0;
  }

  @computed get discountPercentage() {
    return -this.discountAmount / (this.saleTotal + -this.discountAmount);
  }

  @computed get discountTaxAmount() {
    var tempTaxTotal;
    var discountTaxAmount = 0;
    if (this.cart.length) {
      var tots = 0;

      this.cart.forEach(line => {
        console.log(line.tax.itemTax);
        if (!line.buyItem) {
          tots += parseFloat(line.lineTaxTotal);
        }
      });
      tempTaxTotal = tots;
    } else {
      tempTaxTotal = 0;
    }

    var tempTotal;
    if (this.cart.length) {
      var tots = this.cart.reduce(
        (reducer, line) =>
          parseFloat(reducer) + parseFloat(line.buyItem ? 0 : line.lineTotal),
        0
      );

      tempTotal = tots;
    } else {
      tempTotal = 0;
    }

    if (this.globalDiscount?.type == "percentage") {
      discountTaxAmount =
        -tempTaxTotal * (parseFloat(this.globalDiscount?.amount) / 100);
    } else if (this.globalDiscount?.type == "amount" && tempTaxTotal) {
      discountTaxAmount =
        -this.globalDiscount?.amount * (tempTaxTotal / tempTotal);
    }
    if (discountTaxAmount + tempTaxTotal < 0) {
      discountTaxAmount = -tempTaxTotal;
    }
    return discountTaxAmount;
  }

  @computed get saleTotal() {
    if (this.cart.length) {
      var tots = this.cart.reduce(
        (reducer, line) =>
          parseFloat(reducer) + parseFloat(line.buyItem ? 0 : line.lineTotal),
        0
      );

      return tots + this.discountAmount;
    }
    return 0 + this.discountAmount;
  }

  @computed get buyTotal() {
    if (this.cart.length) {
      var tots = this.cart.reduce(
        (reducer, line) =>
          parseFloat(reducer) + parseFloat(!line.buyItem ? 0 : line.lineTotal),
        0
      );
      return tots;
    }
    return 0;
  }

  /* @computed get taxTotal() {
    var tax = 0;
    if (!this.taxTrades) {
      tax = this.subTotal * this.taxRate;
    } else {
      if (this.taxIncluded) {
        tax = this.saleTotal - this.saleTotal / (1 + this.taxRate);
      } else {
        tax = this.saleTotal * this.taxRate;
      }
    }
    return tax < 0 ? 0 : tax;
  }*/
  @computed get negatedTaxTotal() {
    if (this.cart.length && !this.taxTrades) {
      var tots = 0;
      this.cart.forEach(line => {
        if (line.buyItem) {
          tots += parseFloat(line.tax.itemTax * line.qty);
        }
      });
      return this.saleTaxTotal + tots < 0 ? -this.saleTaxTotal : tots;
    }
    return 0;
  }

  @computed get saleTaxTotal() {
    if (this.cart.length) {
      var tots = 0;

      this.cart.forEach(line => {
        console.log(line.tax.itemTax);
        if (!line.buyItem) {
          tots += parseFloat(line.lineTaxTotal);
        }
      });
      return tots + this.discountTaxAmount;
    }
    return 0 + this.discountTaxAmount;
  }

  @computed get taxTotal() {
    return this.saleTaxTotal + this.negatedTaxTotal < 0
      ? 0
      : this.saleTaxTotal + this.negatedTaxTotal;
  }

  @computed get totalItems() {
    if (this.cart.length) {
      var tots = this.cart.reduce(
        (reducer, line) => parseInt(reducer) + parseInt(line.qty),
        0
      );

      return tots;
    }
    return 0;
  }

  @action addToCart(item) {
    var newLine = true;
    var oldCart = JSON.parse(JSON.stringify(this.cart));
    this.cart.map((lines, index) => {
      if (
        lines.variantId == item.variantId &&
        item.variantId != null &&
        lines.buyItem != item.buyItem
      ) {
        newLine = false;
        lines.buyItem
          ? this.burntToast("You are already buying that exact item!")
          : this.burntToast("You are already selling that exact item!");
      } else if (lines.variantId == item.variantId && item.variantId != null) {
        this.cart[index].qty++;
        newLine = false;
      } else if (lines.lineId == item.lineId && item.lineId != null) {
        this.cart[index].qty++;
        newLine = false;
      }
    });
    if (newLine) {
      this.cart.push(item);
    }
    this.cartLoadingOn();
    this.putCall("https://devportal.binderpos.com/api/pos/carts", this.currentToken, this.cartObject)
      .then(response => {
        if (response.error) {
          // this.setCart(oldCart);
          this.cartLoadingOff();
          this.setAPIError(response);
          this.getCartById(null, this.cartId);
        } else {
          this.cartLoadingOff();
          this.checkCart(response);
        }
      })
      .catch(err => {
        //this.setCart(oldCart);
        console.log(err);
        this.setErrorMessage("Unknown Error");
        this.cartLoadingOff();
      });
  }

  @observable isDeletingCartItem;
  @action setIsDeletingCartItem(isDeletingCartItem) {
    this.isDeletingCartItem = isDeletingCartItem;
  }

  /**
   * matches an item by variantId and removes it if there is a match
   * @param {object} item
   */
  @action async removeFromCart(item) {
    this.cartLoadingOn();
    this.cart.map((lines, index) => {
      if (lines.lineId == item.lineId) {
        this.cart.splice(index, 1);
      }
    });
    await this.validateCart();
    this.cartLoadingOff();
  }

  @observable tenders = [{ type: "cash", amount: "0.00" }];

  @computed get changeDue() {
    if (
      this.tenders.find(tend => {
        return tend.type.toLowerCase() == "cash";
      })?.amount > 0 &&
      this.balance < 0 &&
      this.balance * -1 <
        this.tenders.find(tend => {
          return tend.type.toLowerCase() == "cash";
        })?.amount
    ) {
      return (this.balance * -1).toFixed(2);
    } else {
      return "";
    }
  }

  @computed get balance() {
    return this.roundCents(
      this.total -
        Object.values(this.tenders).reduce(
          (reducer, line) =>
            parseFloat(reducer) +
            parseFloat(line.amount == "" ? 0 : line.amount),
          0
        )
    );
  }

  @action zeroTenders = () => {
    this.tenders.forEach(tend => {
      tend.amount = "0.00";
    });
  };

  @observable tillList = [];
  @observable forceTill = false;
  @observable cartList = [];
  @action setCartList = list => {
    this.cartList = list;
  };

  @action setTillList(json) {
    this.tillList = json;
  }

  @action fetchTills() {
    this.getCall("https://devportal.binderpos.com/api/pos/tills", this.currentToken).then(json =>
      this.setTillList(json)
    );
  }

  @action fetchCarts() {
    this.getCall(
      "https://devportal.binderpos.com/api/pos/carts/all?limit=1000&offset=0",
      this.currentToken
    ).then(json => this.setCartList(json));
  }

  @action fetchFloat() {
    this.getCall(
      "https://devportal.binderpos.com/api/pos/float/byTill/" + this.tillId,
      this.currentToken
    ).then(json => this.setFloat(json));
  }

  @action
  adjustFloat = () => {
    this.postCall("https://devportal.binderpos.com/api/pos/float/entry", this.currentToken, this.adjustObject);
    this.toast(
      this.adjustObject.float[0].name +
        " has been adjusted by " +
        this.fCurr(this.adjustAmount)
    );
    this.adjustAmount = "0.00";
    this.adjustNote = "";
    this.floatModal = false;
  };
  @observable adjustAmount = "";
  @observable adjustNote = "";
  @observable adjustTender = "";

  @action updateNote(input) {
    this.adjustNote = input;
  }

  @computed get adjustObject() {
    var floatA = {
      name: this.adjustTender ? this.adjustTender : this.tenders[0].type,
      notes: this.adjustNote
    };
    this.adjustAmount > 0
      ? (floatA.increment = this.adjustAmount)
      : (floatA.decrement = this.adjustAmount * -1);
    return {
      till: this.tillId,
      float: [floatA]
    };
  }

  @observable cookie = new Cookie("register");
  @observable float = {
    status:
      this.cookie.value != undefined &&
      this.cookie.value != -1 &&
      this.cookie.value != "No Till Selected"
        ? "open"
        : "closed"
  };
  @action setFloat = float => {
    this.float = float;
    this.tenders = [];
    float?.float?.map(tender =>
      this.tenders.push({ type: tender.name, amount: 0.0 })
    );
  };
  @computed get tillId() {
    //this.fetchTills();
    return this.cookie.value;
  }

  @computed get floatStatus() {
    return this.float.status == "open" ? true : false;
  }

  @action setTill = value => {
    if (!value) {
      value = -1;
    }
    this.cookie.set(value, { expires: 365 }); // 2 day expiry
  };

  @action submitFloat = () => {
    if (this.tillId != -1) {
      this.postCall("https://devportal.binderpos.com/api/pos/float/open", this.currentToken, this.floatObject)
        .then(json => this.setFloat(json))
        .then(() => {
          this.getLatestCart(0);
          this.closeFloatModal();
          if (this.forceTill) {
            this.fetchFloat();
          }
        });
    } else {
      this.toast("Please select a valid till");
    }
  };

  @action closeFloat = () => {
    this.postCall(
      "https://devportal.binderpos.com/api/pos/float/byTill/" + this.tillId + "/close",
      this.currentToken,
      this.floatObject
    )
      .then(json => this.setFloat(json))
      .then(this.fetchTills());
    this.closeTillWarning();
  };

  @observable tillWarning = false;

  @action openTillWarning = () => {
    this.tillWarning = true;
  };
  @action closeTillWarning = () => {
    this.tillWarning = false;
  };
  @computed get floatObject() {
    return {
      till: this.tillId,
      float: [
        {
          name: "Cash",
          openingAmount: this.floatOpenAmount,
          closingAmount: this.tenderClose ? this.tenderClose[0] : null
        },
        {
          name: "Credit",
          openingAmount: 0.0,
          closingAmount: this.tenderClose ? this.tenderClose[1] : null
        },
        {
          name: "EFTPOS",
          openingAmount: 0.0,
          closingAmount: this.tenderClose ? this.tenderClose[2] : null
        },
        {
          name: "Store Credit",
          openingAmount: 0.0,
          closingAmount: this.tenderClose ? this.tenderClose[3] : null
        }
      ]
    };
  }

  @computed get cartObject() {
    var cart = {};
    if (this.cartId) {
      cart.id = this.cartId;
    }
    cart.tillId = this.tillId;
    cart.customer = this.selectedCustomer
      ? { id: this.selectedCustomer.id }
      : null;

    cart.cartItems = [];
    this.cart.map(lineItem => {
      var line = {};
      line.variantTitle = lineItem.variantTitle;
      line.productTitle = lineItem.title;
      line.id = lineItem.lineId;
      line.variantId = lineItem.variantId;
      line.price = lineItem.displayTotal;
      line.actualPrice = lineItem.actualPrice;
      line.quantity = lineItem.qty;
      line.imageSrc = lineItem.imageUrl;
      line.buying = lineItem.buyItem;
      line.taxable = !lineItem.taxDisabledUI;
      line.shopifyTaxable = !lineItem.taxDisabledShopify;
      line.eventAdditionalInfo = lineItem.eventAdditionalInfo;
      line.shopifyPrice = lineItem.shopifyPrice;
      line.tags = lineItem.tags;
      line.discountAmount = lineItem.discountAmount;
      line.discountType = lineItem.discountType;
      line.discountValue = lineItem.discountValue;
      cart.cartItems.push(line);
    });
    cart.tenders = [];
    cart.totalTax = this.roundCents(this.taxTotal);
    this.tenders.map((tender, index) => {
      if (tender.amount != 0) {
        if (tender.type.toLowerCase() == "cash") {
          var temp = tender;
          // temp.amount = 5;
          cart.tenders.push({
            type: temp.type,
            amount: this.roundCents(temp.amount - this.changeDue)
          });
        } else {
          cart.tenders.push(tender);
        }
      }
    });

    cart.customer = this.selectedCustomer
      ? { id: this.selectedCustomer.id }
      : null;
    cart.taxLines = this.taxRates;

    cart.discountValue = this.globalDiscount.amount;
    cart.discountType = this.globalDiscount.type;
    cart.discountAmount = this.discountAmount;

    return cart;
  }

  @observable submittingCart = false;
  @action setSubmittingCart(value) {
    this.submittingCart = value;
  }
  @action submitCart = () => {
    this.setSubmittingCart(true);
    if (this.cartObject.cartItems.length == 0) {
      this.setSubmittingCart(false);
      this.setErrorMessage(
        "You need and item in the cart to checkout! \n (Try adding a custom item for 0.00 if you are trying to exchange tenders.)"
      );
    } else {
      this.postCall(
        "https://devportal.binderpos.com/api/pos/carts?submit=true",
        this.currentToken,
        this.cartObject
      ).then(body => {
        this.setSubmittingCart(false);
        this.setSearchTerm("");
        this.clearSearchItems();
        if (body.dateSubmitted) {
          this.openReceipt();

          // this.setErrorMessage("Cart successfully submitted!");
          //this.setReceiptData(body);
        } else if (body.error) {
          this.setAPIError(body);
        } else {
          this.setErrorMessage("Unknown error, cart submission failed");
        }
      });
    }
  };

  @observable tenderClose = [];
  @computed get tenderDiff() {
    var diff = [];
    this.tenderClose.map((close, index) => {
      diff[index] = (
        this.tenderClose[index] - this.float.float[index].currentAmount
      ).toFixed(2);
    });
    return diff;
  }

  @action clearCustomer() {
    this.selectedCustomer = null;
    this.customerInput = "";
  }

  @observable checkoutModal = false;
  @observable disableLineItems = false;
  @action setDisableLineItems(bool) {
    this.disableLineItems = bool;
  }

  @observable cartModal = false;
  @action closeCartModal = () => {
    this.cartModal = false;
  };
  @action openCartModal = () => {
    this.fetchCarts();
    this.cartModal = true;
  };
  @observable receiptModal = false;
  @action closeReceipt = () => {
    this.receiptModal = false;
    this.newCart();
    this.checkoutModalFalse();
    this.clearCustomer();
    this.getTax();
    this.zeroTenders();
    this.setDisableLineItems(false);
  };
  @action openReceipt = () => {
    this.receiptModal = true;
  };

  @observable receiptData = {};

  @action setReceiptData = data => {
    this.receiptData = data;
  };
  @action checkoutModalFalse = () => {
    this.checkoutModal = false;
    this.setDisableLineItems(false);
  };

  @observable cashDenoms = [5, 10, 20, 50, 100];

  @action newCart = () => {
    var blankBody = {
      tillId: this.tillId,
      cartItems: []
    };
    this.clearCustomer();
    this.clearDiscount();
    this.buyMode = false;
    blankBody.customer = this.selectedCustomer
      ? { id: this.selectedCustomer.id }
      : null;
    this.postCall("https://devportal.binderpos.com/api/pos/carts", this.currentToken, blankBody).then(
      response => {
        this.setCartId(response.id);
        this.getLatestCart();
      }
    );
  };

  @action clearDiscount = () => {
    this.globalDiscount = { amount: 0, type: "percentage" };
  };

  @action saveCart = () => {
    var blankBody = {
      savedName: "",
      savedCart: "true",
      id: this.cartId
    };
    this.postCall("https://devportal.binderpos.com/api/pos/carts/save", this.currentToken, blankBody).then(
      response => {
        this.setCartId(response.id);
        this.getLatestCart();
      }
    );
  };

  @action checkCart = response => {
    this.cart = [];
    response.cartItems?.map(line => {
      this.cart.push(
        new LineItem(line, null, null, this.allTax, line.eventAdditionalInfo)
      );
    });
    if (response.customer) {
      //this.selectedCustomer = response.customer;
    }
    if (response.discountAmount && response.discountType) {
      this.globalDiscount.amount = response.discountValue;
      this.globalDiscount.type = response.discountType;
    }
  };

  @action validateCart = () => {
    this.cartLoadingOn();
    return this.putCall("https://devportal.binderpos.com/api/pos/carts", this.currentToken, this.cartObject)
      .then(response => {
        if (response.error) {
          this.setAPIError(response);
          this.cartLoadingOff();
          //this.newCart();
        } else {
          this.checkCart(response);
          this.cartLoadingOff();
        }
        this.setIsDeletingCartItem(false);
      })
      .then(this.refreshSearch);
  };

  @action validateCartNoRefresh = () => {
    this.cartLoadingOn();
    this.putCall("https://devportal.binderpos.com/api/pos/carts", this.currentToken, this.cartObject).then(
      response => {
        if (response.error) {
          this.setAPIError(response);
          this.cartLoadingOff();
          //this.newCart();
        } else {
          this.checkCart(response);
          this.cartLoadingOff();
        }
      }
    );
  };

  @action getLatestCart = () => {
    this.getCall(
      "https://devportal.binderpos.com/api/pos/carts/latest/forTill/" + this.tillId,
      this.currentToken
    ).then(result => {
      if (result.dateSubmitted | (result.id == null)) {
        this.newCart();
      } else {
        this.setCartId(result.id);
        this.setSelectedCustomer(result.customer);
        this.checkCart(result);
      }
    });
  };

  @action getCartById = (e, id) => {
    this.getCall("https://devportal.binderpos.com/api/pos/carts/byId/" + id, this.currentToken).then(
      result => {
        if (result.dateSubmitted | (result.id == null)) {
          this.toast("This cart has already been submitted");
        } else {
          this.setCartId(result.id);
          this.setSelectedCustomer(result.customer);
          this.checkCart(result);
        }
        this.closeCartModal();
      }
    );
  };

  @action unsetTill = () => {
    this.cookie.remove();
  };

  @observable taxRate = 0;
  @observable taxRateDisplay = 0;
  @observable taxNumber = "";
  @observable taxIncluded = true;
  @observable taxTrades = false;
  @observable taxWording = "";
  @observable currency = "$";

  @observable storeInfo = {};
  @action setTaxTrades = value => {
    this.taxTrades = value;
  };
  @action taxIncludedSet = value => {
    this.taxIncluded = value;
  };
  @action taxRateSet = value => {
    this.taxRate = value;
  };
  @action taxRateDisplaySet = value => {
    this.taxRateDisplay = value;
  };
  @action taxNumberSet = value => {
    this.taxNumber = value;
  };
  @action setStoreInfo = value => {
    this.storeInfo = value;
  };
  @action taxWordingSet = value => {
    this.taxWording = value;
  };
  @action currencySet = value => {
    this.currency = value;
  };

  @action getTax = async () => {
    const taxRate = await this.getCall(
      "https://devportal.binderpos.com/api/settings/taxRate/forMe",
      this.currentToken
    );
    this.taxRateDisplaySet(taxRate.settingValue);
    this.taxRateSet(taxRate.settingValue / 100);

    const taxIncluded = await this.getCall(
      "https://devportal.binderpos.com/api/settings/taxIncluded/forMe",
      this.currentToken
    );

    this.taxIncludedSet(taxIncluded.settingValue == "true");

    const taxTrades = await this.getCall(
      "https://devportal.binderpos.com/api/settings/tradeInTax/forMe",
      this.currentToken
    );
    this.setTaxTrades(taxTrades.settingValue == "true");

    const storeInfo = await this.getCall(
      "https://devportal.binderpos.com/api/settings/store",
      this.currentToken
    );
    this.setStoreInfo(storeInfo);

    const taxNumber = await this.getCall(
      "https://devportal.binderpos.com/api/settings/taxNumber/forMe",
      this.currentToken
    );
    this.taxNumberSet(taxNumber?.settingValue);

    const taxWording = await this.getCall(
      "https://devportal.binderpos.com/api/settings/taxWording/forMe",
      this.currentToken
    );
    this.taxWordingSet(taxWording?.settingValue);

    const currencySymbol = await this.getCall(
      "https://devportal.binderpos.com/api/settings/currencySymbol",
      this.currentToken
    );
    this.currencySet(currencySymbol?.settingValue);

    //this.validateCartNoRefresh();
  };

  @action fetchCurrency() {
    this.getCall("https://devportal.binderpos.com/api/settings/currencySymbol", this.currentToken).then(
      result => {
        this.currencySymbol(result?.settingValue);
      }
    );
  }

  @computed get allTax() {
    return {
      taxRate: this.taxRate,
      taxTrades: this.taxTrades,
      taxIncluded: this.taxIncluded
    };
  }
  @observable stockLimit = false;

  getCall = async (url, token) => {
    if (this.currentToken == "") {
      token = await this.getToken();
    }
    const response = await fetch(url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // prettier-ignore
        "Authorization": "Bearer " + token
      })
    }).catch(err => {
      console.error(err);
      this.logError();
    });
    if (response.status === 401) {
      token = await this.getToken();
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": "Bearer " + token
        })
      }).catch(err => {
        console.error(err);
        this.logError();
      });
    }
    if (response.status === 200) {
      return response.json();
    } else {
      var payload = response.json();
      return payload;
    }
  };

  putCall = async (url, token, body) => {
    if (this.currentToken == "") {
      token = await this.getToken();
    }
    const response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(toJS(body)),
      headers: new Headers({
        "Content-Type": "application/json",
        // prettier-ignore
        "Authorization": "Bearer " + token
      })
    }).catch(err => {
      console.error(err);
      this.logError();
    });
    if (response.status === 401) {
      token = await this.getToken();
      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(toJS(body)),
        headers: new Headers({
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": "Bearer " + token
        })
      }).catch(err => {
        console.error(err);
        this.logError();
      });
    }
    if (response.status === 200) {
      return response.json();
    } else {
      var payload = response.json();
      return payload;
    }
  };

  postCall = async (url, token, body) => {
    if (this.currentToken == "") {
      token = await this.getToken();
    }
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(toJS(body)),
      headers: new Headers({
        "Content-Type": "application/json",
        // prettier-ignore
        "Authorization": "Bearer " + token
      })
    }).catch(err => {
      console.error(err);
      this.logError();
    });
    if (response.status === 401) {
      token = await this.getToken();
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(toJS(body)),
        headers: new Headers({
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": "Bearer " + token
        })
      }).catch(err => {
        console.error(err);
        this.logError();
      });
    }
    if (response.status === 200) {
      return response.json();
    } else {
      var payload = response.json();
      return payload;
    }
  };

  logError = () => {
    // let time = new Date(Date.now()).toLocaleString();
    // console.log(toJS(this));
    // fetch("https://pos.alvarandhurriks.com:1880/binderLog", {
    //   method: "POST",
    //   body: JSON.stringify(
    //     Object.assign(
    //       {
    //         time: time,
    //         user: firebase.auth().currentUser.displayName
    //       },
    //       toJS(this)
    //     )
    //   )
    // });
  };

  roundCents = amount => {
    amount = parseFloat(amount);
    return (Math.round(Math.round(amount * 10000000) / 100000) / 100).toFixed(
      2
    );
  };

  fCurr = amount => {
    if (amount) {
      var theAmount = parseFloat(amount);
      if (theAmount >= 0) {
        return this.currency + this.roundCents(theAmount);
      } else {
        return "-" + this.currency + this.roundCents(Math.abs(theAmount));
      }
    }
    return amount;
  };
}

export { Item, ItemList, LineItem, CustomLineItem, Register };
