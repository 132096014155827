import React from 'react';
import { inject, observer } from 'mobx-react'
import firebase from 'firebase/app';
import { Link } from 'react-router-dom'
import 'firebase/auth';
import { LineChart } from 'react-chartkick'
import 'chart.js'
import { observable, action } from 'mobx';

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const emailProvider = new firebase.auth.GoogleAuthProvider();

// Configure Firebase.

@inject('store')
@observer
export default class Dashboard extends React.Component {

  @observable graphData;
  @action setGraphData(graphData) {
    this.graphData = graphData;
  }

  uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      googleProvider.PROVIDER_ID,
      emailProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  };

  constructor(props) {
    super(props);
  }

  getDaysArray() {
    return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  }

  componentDidMount() {
    document.title = 'Dashboard | BinderPOS';
    this.props.store.MainStore.getAppUpdate();
    this.props.store.MainStore.fetchPOSSaleData()
      .then((data) => {
        this.buildDataForGraph(data);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      })
  }

  convertDate(theDate) {
    const date = new Date(theDate);
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
    return date;
  }

  buildDataForGraph(posSalesData) {
    var obj = {};
    posSalesData.forEach(data => {
      if (data) {
        obj[this.getDaysArray()[this.convertDate(data.dataDate).getDay()]] = parseFloat(data.total.toFixed(2));
      }
    })
    this.setGraphData(obj);
  }

  render() {
    return (
      <section id="dashboard">
        <div className="row">
          <div className="col-md-8">
            <div className="mains">
              <h1>Welcome back <em>{firebase.auth().currentUser.displayName}</em>!</h1>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12"><Link to="/pos"><div className="tile cyan"><span><i className="fal fa-store-alt"></i></span><p>Point of Sale</p><em><i className="fal fa-long-arrow-right"></i></em></div></Link></div>
                <div className="col-lg-4 col-md-6 col-sm-12"><Link to="/buylists"><div className="tile orange"><span><i className="fal fa-shopping-cart"></i></span><p>Buylist</p><em><i className="fal fa-long-arrow-right"></i></em></div></Link></div>
                <div className="col-lg-4 col-md-6 col-sm-12"><Link to="/customers"><div className="tile purple"><span><i className="fal fa-users"></i></span><p>Customers</p><em><i className="fal fa-long-arrow-right"></i></em></div></Link></div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="block">
              <h2>Weekly POS Sales</h2>
              {this.graphData ?
                <LineChart data={this.graphData} />
                :
                null
              }
            </div>
            <div className="block mobileFull">
              {this.props.store.MainStore.appUpdate ?
                <React.Fragment>
                  <h2>{this.props.store.MainStore.appUpdate.title}</h2>
                  <hr></hr>
                  <p>{this.props.store.MainStore.appUpdate.message}</p>
                  <p>- {this.props.store.MainStore.appUpdate.postedBy}</p>
                </React.Fragment>
                :
                <React.Fragment>
                  <h2>BinderPOS App Updates</h2>
                  <hr></hr>
                  <p>There are no new updates at the moment.</p>
                </React.Fragment>
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}
