import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, action } from "mobx";
import { Link } from "react-router-dom";

@inject("store")
@observer
class ConfirmationModal extends Component {
  @observable isLoading;
  @action setIsLoading(loading) {
    this.isLoading = loading;
  }

  cancel = () => {
    this.props.cancelAction();
  };

  confirm = () => {
    this.setIsLoading(true);
    this.props.confirmAction();
  };

  render() {
    return (
      <div className={"modal is-active"}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {this.props.title && this.props.title.length > 0
                ? this.props.title
                : "Are you sure?"}
            </p>
            {!this.isLoading ? (
              <React.Fragment>
                {this.props.cancelAction ? (
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => this.cancel()}
                  />
                ) : null}
              </React.Fragment>
            ) : null}
          </header>
          <section className="modal-card-body">
            <div className="field">
              {this.props.isHtml ? (
                <React.Fragment>
                  <div dangerouslySetInnerHTML={this.props.text}></div>
                </React.Fragment>
              ) : (
                <React.Fragment>{this.props.text}</React.Fragment>
              )}
            </div>
          </section>
          <footer className="modal-card-foot">
            {this.props.confirmAction ? (
              <button
                className={
                  "button is-info " +
                  (this.props.cancelAction ? " half " : "") +
                  (this.props.type && this.props.type == "confirm"
                    ? ""
                    : " is-danger") +
                  (this.isLoading ? " is-loading disabled" : "")
                }
                onClick={() => this.confirm()}
              >
                {this.props.confirmWord && this.props.confirmWord.length > 0
                  ? this.props.confirmWord
                  : "Confirm"}
              </button>
            ) : (
              <React.Fragment>
                {this.props.link ? (
                  <Link
                    to={this.props.link}
                    className={
                      "button is-info " +
                      (this.props.cancelAction ? " half " : "") +
                      (this.props.type && this.props.type == "confirm"
                        ? ""
                        : " is-danger") +
                      (this.isLoading ? " is-loading disabled" : "")
                    }
                  >
                    {this.props.confirmWord && this.props.confirmWord.length > 0
                      ? this.props.confirmWord
                      : "Confirm"}
                  </Link>
                ) : null}
              </React.Fragment>
            )}
            {this.props.cancelAction ? (
              <button
                className={
                  "button is-text" +
                  (this.props.confirmAction ? " half " : "") +
                  (this.isLoading ? " disabled" : "")
                }
                onClick={() => this.cancel()}
              >
                {this.props.cancelWord && this.props.cancelWord.length > 0
                  ? this.props.cancelWord
                  : "Cancel"}
              </button>
            ) : null}
          </footer>
        </div>
      </div>
    );
  }
}

export default ConfirmationModal;
