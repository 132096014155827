import React from 'react';
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import firebase from 'firebase/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import 'firebase/auth';
import { Loader } from 'components';

@inject('store')
@observer
export default class SignInForm extends React.Component {

  @observable signInFailedMessage;
  @action setSignInFailedMessage(signInFailedMessage) {
    this.signInFailedMessage = signInFailedMessage;
  }

  @observable showLoader = false;
  @action setShowLoader(showLoader) {
    this.showLoader = showLoader;
  }

  @observable showResetPassword = false;
  @action setShowResetPassword(showResetPassword) {
    this.showResetPassword = showResetPassword;
  }

  @observable resetPasswordMessage;
  @action setResetPasswordMessage(resetPasswordMessage) {
    this.resetPasswordMessage = resetPasswordMessage;
  }

  uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  };

  constructor(props) {
    super(props);
    this.userEmail = React.createRef();
    this.userPassword = React.createRef();
  }

  removeMessageAndShowResetPassword(value) {
    this.setResetPasswordMessage(null);
    this.setSignInFailedMessage(null);
    this.setShowResetPassword(value);
  }

  signUserIn() {
    const self = this;
    this.setShowLoader(true);
    firebase.auth().signInWithEmailAndPassword(this.userEmail.current.value, this.userPassword.current.value)
      .catch(function (error) {
        self.userPassword.current.value = "";
        if (error.code == "auth/user-not-found") {
          self.setSignInFailedMessage("You do not have an account. Contact BinderPOS for more information");
        } else if (error.code == "auth/invalid-email") {
          self.setSignInFailedMessage("Please enter a valid email address");
        } else if (error.code == "auth/wrong-password") {
          self.setSignInFailedMessage("Username or password is incorrect, is it a Google account? Sign in using \"Sign in with Google\"");
        } else {
          self.setSignInFailedMessage(error.message);
        }
        self.setShowLoader(false);
      });
  }

  resetPassword() {
    const self = this;
    this.setShowLoader(true);
    firebase.auth().sendPasswordResetEmail(this.userEmail.current.value)
      .then(() => {
        this.setResetPasswordMessage("An email has been sent to " + this.userEmail.current.value + " which contains instructions to reset your password")
        self.userEmail.current.value = "";
        this.setShowLoader(false);
      })
      .catch(function (error) {
        self.userEmail.current.value = "";
        if (error.code == "auth/user-not-found") {
          self.setSignInFailedMessage("You do not have an account. Contact BinderPOS for more information");
        } else if (error.code == "auth/invalid-email") {
          self.setSignInFailedMessage("Please enter a valid email address");
        } else if (error.code == "auth/wrong-password") {
          self.setSignInFailedMessage("Username or password is incorrect, is it a Google account? Sign in using \"Sign in with Google\"");
        } else {
          self.setSignInFailedMessage(error.message);
        }
        self.setShowLoader(false);
      })
  }

  render() {
    return (
      <div className="loginWrapper">
        {this.showLoader ?
          <Loader />
          :
          null
        }
        {this.signInFailedMessage ?
          <p className="failed"><i className="fal fa-exclamation-triangle"></i>{this.signInFailedMessage}</p>
          :
          null
        }
        {this.resetPasswordMessage ?
          <p className="reset"><i className="fal fa-check-circle"></i>{this.resetPasswordMessage}</p>
          :
          null
        }
        {!this.showResetPassword ?
          <React.Fragment>
            <p className="title">Sign into <em>BinderPOS</em></p>
            <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
            <p className="subTitle">or</p>
            <label>Email
            <input ref={this.userEmail} id="useEmail" type="email" placeholder="Your email" />
            </label>
            <label>Password
            <input placeholder="*********************" ref={this.userPassword} id="userPassword" type="password" onKeyDown={e => {
                if (e.key == "Enter") {
                  this.signUserIn();
                }
              }} />
            </label>
            <button className="signin" type="button" onClick={() => this.signUserIn()}>Sign in</button>
            <p className="link"><a onClick={() => this.removeMessageAndShowResetPassword(true)}>Reset password</a></p>
          </React.Fragment>
          :
          <React.Fragment>
            <p className="title recover">Recover your password</p>
            <label>Email
            <input ref={this.userEmail} id="useEmail" type="email" placeholder="Your email" />
            </label>
            <button className="signin" type="button" onClick={() => this.resetPassword()}>Reset my password</button>
            <p className="link"><a onClick={() => this.removeMessageAndShowResetPassword(false)}>Back to login</a></p>
          </React.Fragment>
        }
      </div>
    );
  }
}
