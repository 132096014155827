import React, { Component } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";

@inject("posStore")
@observer
class FloatModal extends Component {

  @observable floatAdjustmentOpen = false;
  @action updateFloatAdjustment(value) {
    this.floatAdjustmentOpen = value;
  }

  componentDidMount() {
    this.props.posStore.fetchTills();
    this.props.posStore.fetchFloat();
  }
  @action floatUpdate = e => {
    if (isNaN(e.target.value) && e.target.value != "-") {
      this.props.posStore.floatOpenAmount = e.target.value.replace(
        /[^0-9.-]/g,
        ""
      );
    } else {
      this.props.posStore.floatOpenAmount = e.target.value;
    }
  };
  @action validateFormat = e => {
    if (isNaN(e.target.value)) {
      this.props.posStore.floatOpenAmount = "0.00";
    } else {
      this.props.posStore.floatOpenAmount = parseFloat(e.target.value).toFixed(
        2
      );
    }
  };
  selectAll = e => {
    e.target.select();
  };

  @action changeTill = e => {
    if (e.target.value != this.props.posStore.tillId) {
      this.props.posStore.setTill(e.target.value);
    }
  };

  @action updateTenderClose(e, index) {
    if (isNaN(e.target.value) && e.target.value != "-") {
      this.props.posStore.tenderClose[index] = e.target.value.replace(
        /[^0-9.-]/g,
        ""
      );
      if (isNaN(this.props.posStore.tenderClose[index])) {
        this.props.posStore.tenderClose[index] = "";
      }
    } else {
      this.props.posStore.tenderClose[index] = e.target.value;
    }
  }

  @action updateAdjustment = e => {
    if (isNaN(e.target.value) && e.target.value != "-") {
      this.props.posStore.adjustAmount = e.target.value.replace(
        /[^0-9.-]/g,
        ""
      );
      if (isNaN(this.props.posStore.adjustAmount)) {
        this.props.posStore.adjustAmount = "";
      }
    } else {
      this.props.posStore.adjustAmount = e.target.value;
    }
  };

  @action changeAdjustTender = e => {
    this.props.posStore.adjustTender = this.props.posStore.tenders[
      e.target.value
    ].type;
  };
  @action
  closeModal = () => {
    this.props.posStore.floatModal = false;
  };

  @action setForceTill = e => {
    this.props.posStore.forceTill = e.target.checked;
    console.log(e);
  };

  convertDateToHumanReadable(theDate) {
    const date = new Date(theDate);
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
    return date.toLocaleDateString();
  }

  convertTime(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }

  render() {
    const store = this.props.posStore;
    const showHideClassName =
      store.floatModal | !store.floatStatus
        ? "modal display-block"
        : "modal display-none";
    return (
      <div className={showHideClassName}>
        <section className="modal-main dark">
          <div className={store.floatStatus ? "openTill hidden" : "openTill"}>
            <span className="welcome">
              BinderPOS's POS Solution.
            </span>
            <div className="tillSelector">
              <div className="tillDropdownDiv">
              <label>
              {store.tillId == null
                ? "No till selected, please select the till you'd like to use"
                : ""}
                </label>
              <select
                className="tillDropdown"
                onChange={this.changeTill}
                disabled={store.floatStatus}
                value={store.tillId ? store.tillId : -1}
              >
                <option value={-1} key="-1">
                  No Till Selected
                </option>
                {store.tillList.map((till, index) => (
                  <option
                    value={till.id}
                    key={till.id}
                    disabled={till.open && !store.forceTill}
                  >
                    {till.name + "\t-\t" + till.description}
                  </option>
                ))}
              </select>
              </div><label>
              {"Force login "}
              <input
                name="forceTill"
                type="checkbox"
                checked={store.forceTill}
                onChange={this.setForceTill}
              /></label>
            </div>
            <hr />
            <span className="floatTitle">Opening Float</span>
            <input
              className="OpeningAmount"
              value={store.floatOpenAmount}
              onChange={this.floatUpdate}
              onFocus={this.selectAll}
              disabled={store.floatStatus}
              onBlur={this.validateFormat}
              type="textbox"
            />

            <button
              className="payment-button-open-float"
              onClick={store.submitFloat}
              disabled={store.floatStatus}
            ><i className="fal fa-lock-open"></i>
              {store.floatStatus ? "Till Open" : "Set Opening Float"}
            </button>
          </div>
          <div
            className={!store.floatStatus ? "closeTill hidden" : "closeTill"}
          >
            {store.floatStatus ? (
              <button
                className="close-button"
                onClick={this.closeModal}
                disabled={!store.floatStatus}
              >
                <i className="fal fa-times"></i>
              </button>
            ) : null}
            <div className="toggle"><span className={!this.floatAdjustmentOpen ? "active" : ""} onClick={() => this.updateFloatAdjustment(false)}>Close Till</span><span className={this.floatAdjustmentOpen ? "active" : ""} onClick={() => this.updateFloatAdjustment(true)}>Float Adjustment</span></div>
            <div className={"closeToggle " + (this.floatAdjustmentOpen ? " hidden" : "")}>
              <div className="closeTitle">Close till</div>
              <span className="openDate">
                ** Till opened on the{" "}
                {this.convertDateToHumanReadable(store.float.dateOpened) +
                  " at " +
                  this.convertTime(
                    new Date(store.float.dateOpened).toLocaleTimeString()
                  )}{" "}
                **
              </span>
              <table className="closeTillTable">
                <tbody>
                  <tr className="header">
                    <th>Payment Type</th>
                    <th>Expected</th>
                    <th>Difference</th>
                    <th>End of Day Count</th>
                  </tr>

                  {store.tenders
                    .filter(ten => ten.type != "Store Credit")
                    .map((tender, index) => (
                      <tr key={index}>
                        <td className="type" dangerouslySetInnerHTML={{
                          __html: (tender.type == "Cash" ? `<i className='fas fa-money-bill-wave'></i> ${tender.type}` :
                            (tender.type == "Credit" ? `<i className='fas fa-credit-card-front'></i> ${tender.type}` :
                              (tender.type == "EFTPOS" ? `<i className='fas fa-credit-card'></i> ${tender.type}` :
                                `<i className='fas fa-money-bill-wave'></i> ${tender.type}`)))
                        }}>
                        </td>
                        <td className="expected">
                          {store.float.float &&
                            store.float.float[index].currentAmount.toFixed(2)}
                        </td>
                        <td
                          className={
                            store.tenderDiff[index] == 0
                              ? "balanced difference"
                              : "difference"
                          }
                        >
                          {store.tenderDiff[index] && store.tenderDiff[index]}
                        </td>
                        <td className="counted">
                          <input
                            type="textbox"
                            key={index}
                            placeholder="0.00"
                            value={
                              store.tenderClose[index] && store.tenderClose[index]
                            }
                            onChange={e => this.updateTenderClose(e, index)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <button
                className="closeTillBtn"
                onClick={store.openTillWarning}
                disabled={!store.floatStatus}
              >
                <i className="fas fa-power-off"></i> Close Till
              </button>
            </div>
            <div
              className={
                !store.floatStatus ? "changeToggle hidden" : "changeToggle " + (!this.floatAdjustmentOpen ? " hidden" : "")
              }
            >
              <div className="adjTitle">Float Adjustment</div>
              <div className="row">
                <div className="col-md-4"><span>Type</span>
                  <select
                    className="tillDropdown"
                    onChange={this.changeAdjustTender}
                    disabled={!store.floatStatus}
                  //value={store.tillId ? store.tillId : -1}
                  >
                    {store.tenders
                      .filter(ten => ten.type != "Store Credit")
                      .map((till, index) => (
                        <option value={index} key={index}>
                          {till.type}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4"><span>Adjustment Amount</span>
                  <input
                    value={store.adjustAmount}
                    type="textbox"
                    placeholder="e.g, 50.00 or -50.00"
                    onChange={this.updateAdjustment}
                  />
                </div>
                <div className="col-md-4"><span>Note</span>
                  <input
                    type="textbox"
                    placeholder="Type to add a note"
                    onChange={e => {
                      store.updateNote(e.target.value);
                    }}
                    value={store.adjustNote}
                  />
                </div>
              </div>
              <button
                className="adjust-button"
                disabled={!store.floatStatus}
                onClick={store.adjustFloat}
              >
                <i className="fas fa-paper-plane"></i> Submit Adjustment
                </button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default FloatModal;
