var EventMenuStructure = {
  title: "Events",
  activeClass: "yellow",
  links: [
    {
      title: "My Events",
      href: "events/list"
    },
    {
      title: "Add an Event",
      href: "events/add"
    },
    {
      title: "Failed to create",
      href: "events/failedToCreate"
    },
    {
      title: "Failed to delete",
      href: "events/failedToDelete"
    }
  ]
};

export default EventMenuStructure;