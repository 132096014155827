import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import Quill from 'quill'
import showdown from 'showdown'
import xssFilter from 'showdown-xss-filter'
import "quill/dist/quill.snow.css"
import { EventMenuStructure } from 'menuStructures';
import { Loader } from "components";
import { TimeUtils } from "utils";
import { ConfirmationModal } from "components";

@inject('store')
@observer
export default class Event extends React.Component {
  @observable isRecurring;
  @observable recurringType;

  @action setRecurring(isRecurring) {
    this.isRecurring = isRecurring;
  }

  @action setRecurringType(recurringType) {
    this.recurringType = recurringType;
  }

  @observable ticketed;
  @action setTicketed(ticketed) {
    this.ticketed = ticketed;
  }

  @observable additionalInfo;
  @action setAdditionalInfo(additionalInfo) {
    this.additionalInfo = additionalInfo;
  }

  @observable canShowAdditionalInfo;
  @action setCanShowAdditionalInfo(canShow) {
    this.canShowAdditionalInfo = canShow;
  }

  @observable updating;
  @action setUpdating(bool) {
    this.updating = bool
  }

  @observable modalVisible = false;
  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable modalTitle;
  @action setModalTitle(title) {
    this.modalTitle = title;
  }

  @observable modalText;
  @action setModalText(text) {
    this.modalText = text;
  }

  @observable hasValidationErrors = false;
  @action setHasValidationErrors(bool) {
    this.hasValidationErrors = bool;
  }

  @observable descriptionHTML;
  @action setDescriptionHTML(html) {
    this.descriptionHTML = html
  }

  @observable prizeStructureHTML;
  @action setPrizeStructureHTML(html) {
    this.prizeStructureHTML = html
  }

  @observable calendarIconImage;
  @action setCalendarIconImage(image) {
    this.calendarIconImage = image
  }

  @observable calendarBannerImage;
  @action setCalendarBannerImage(image) {
    this.calendarBannerImage = image
  }

  @observable calendarFeatureImage;
  @action setCalendarFeatureImage(image) {
    this.calendarFeatureImage = image
  }

  @observable calendarTicketImage;
  @action setCalendarTicketImage(image) {
    this.calendarTicketImage = image
  }

  @observable additionalInfoList = [{ header: "", headerDescription: "", timestamp: new Date().getTime() }];
  @action addAdditionalInfo() {
    var additionalInfo = { header: "", headerDescription: "", timestamp: new Date().getTime() };
    this.additionalInfoList.push(additionalInfo);
  }

  @action setAdditionalInfoList(additionalInfo) {
    this.additionalInfoList = additionalInfo;
  }

  @action removeAdditionalInfoFromList(index) {
    var tmpArray = this.additionalInfoList.slice();
    if (index > -1) {
      tmpArray.splice(index, 1);
    }
    this.additionalInfoList = tmpArray;
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.descriptionHTMLEditor = React.createRef();
    this.prizeStructureHTMLEditor = React.createRef();
    this.quillEditorDesc;
    this.quillEditorPrize;
  }

  fetchEventInfo(id) {
    return this.props.store.EventsStore.fetchEventInfo(id)
      .then((result) => {
        this.props.store.EventsStore.setEvent(result)
        if (this.props.store.EventsStore.event.calendarIcon && this.props.store.EventsStore.event.calendarIcon.length > 0) {
          this.setCalendarIconImage({ src: this.props.store.EventsStore.event ?.calendarIcon, name: "Calendar icon" });
        }
        if (this.props.store.EventsStore.event.banner && this.props.store.EventsStore.event.banner.length > 0) {
          this.setCalendarBannerImage({ src: this.props.store.EventsStore.event ?.banner, name: "Background image" });
        }
        if (this.props.store.EventsStore.event.featureImage && this.props.store.EventsStore.event.featureImage.length > 0) {
          this.setCalendarFeatureImage({ src: this.props.store.EventsStore.event ?.featureImage, name: "Feature image" });
        }
        if (this.props.store.EventsStore.event.ticketImage && this.props.store.EventsStore.event.ticketImage.length > 0) {
          this.setCalendarTicketImage({ src: this.props.store.EventsStore.event ?.ticketImage, name: "Ticket image" });
        }
        if (this.props.store.EventsStore.event ?.additionalInfoRequired) {
          this.setAdditionalInfo(this.props.store.EventsStore.event ?.additionalInfoRequired);
        }
        if (this.props.store.EventsStore.event ?.additionalInfo) {
          var additionalInfo = [];
          this.props.store.EventsStore.event.additionalInfo.forEach(additionalInfoItem => {
            let addInfo = {
              id: additionalInfoItem.id,
              header: additionalInfoItem.header,
              headerDescription: additionalInfoItem.headerDescription,
              timestamp: additionalInfoItem.id,
            }
            additionalInfo.push(addInfo);
          })
        }
        this.setAdditionalInfoList(additionalInfo && additionalInfo.length > 0 ? additionalInfo : [])
        return Promise.resolve();
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  fetchCategoryInfo() {
    return this.props.store.EventsStore.fetchEventCategories()
      .then((result) => {
        this.props.store.EventsStore.setEventCategories(result)
        return Promise.resolve();
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  showCalendarIconImage() {
    const self = this;
    let input = this.form.current.elements.eventCalendarIcon;
    if (input.files && input.files[0]) {
      var fileSize = input.files[0].size / 1024 / 1024;
      var fileName = input.files[0].name;
      var ticketObj = {
        name: fileName,
      };
      if (fileSize <= 2) {
        var reader = new FileReader();
        reader.onload = function (e) {
          ticketObj.src = e.target.result;
          self.setCalendarIconImage(ticketObj);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        var modalText = `<p>Your calendar icon cannot be more than <strong>2mb</strong> in size. Please reduce the size of the image and upload again</p>`;
        var modalHeader = 'Calendar icon uploaded is too large';
        this.showErrorModal(modalText, modalHeader);
        this.form.current.elements.eventCalendarIcon.value = null;
        self.setCalendarIconImage(null);
      }
    }
  }

  showCalendarBannerImage() {
    const self = this;
    let input = this.form.current.elements.eventBanner;
    if (input.files && input.files[0]) {
      var fileSize = input.files[0].size / 1024 / 1024;
      var fileName = input.files[0].name;
      var ticketObj = {
        name: fileName,
      };
      if (fileSize <= 2) {
        var reader = new FileReader();
        reader.onload = function (e) {
          ticketObj.src = e.target.result;
          self.setCalendarBannerImage(ticketObj);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        var modalText = `<p>Your background cannot be more than <strong>2mb</strong> in size. Please reduce the size of the image and upload it again</p>`;
        var modalHeader = 'background uploaded is too large';
        this.showErrorModal(modalText, modalHeader);
        this.form.current.elements.eventBanner.value = null;
        self.setCalendarBannerImage(null);
      }
    }
  }

  showCalendarFeatureImage() {
    const self = this;
    let input = this.form.current.elements.eventFeatureImage;
    if (input.files && input.files[0]) {
      var fileSize = input.files[0].size / 1024 / 1024;
      var fileName = input.files[0].name;
      var ticketObj = {
        name: fileName,
      };
      if (fileSize <= 2) {
        var reader = new FileReader();
        reader.onload = function (e) {
          ticketObj.src = e.target.result;
          self.setCalendarFeatureImage(ticketObj);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        var modalText = `<p>Your feature image cannot be more than <strong>2mb</strong> in size. Please reduce the size of the image and upload it again</p>`;
        var modalHeader = 'Feature image uploaded is too large';
        this.showErrorModal(modalText, modalHeader);
        this.form.current.elements.eventFeatureImage.value = null;
        self.setCalendarFeatureImage(null);
      }
    }
  }

  showCalendarTicketImage() {
    const self = this;
    let input = this.form.current.elements.eventTicketImage;
    if (input.files && input.files[0]) {
      var fileSize = input.files[0].size / 1024 / 1024;
      var fileName = input.files[0].name;
      var ticketObj = {
        name: fileName,
      };
      if (fileSize <= 2) {
        var reader = new FileReader();
        reader.onload = function (e) {
          ticketObj.src = e.target.result;
          self.setCalendarTicketImage(ticketObj);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        var modalText = `<p>Your event ticket image cannot be more than <strong>2mb</strong> in size. Please reduce the size of the image and upload it again</p>`;
        var modalHeader = 'Event ticket image uploaded is too large';
        this.showErrorModal(modalText, modalHeader);
        this.form.current.elements.eventTicketImage.value = null;
        self.setCalendarTicketImage(null);
      }
    }
  }

  showErrorModal(modalText, modalHeader) {
    this.setUpdating(false);
    this.setModalVisible(true);
    this.setModalTitle(modalHeader);
    this.setModalText(modalText);
    this.setHasValidationErrors(true);
  }

  componentWillMount() {
    this.props.store.MenuStore.setSideMenuToDisplay(EventMenuStructure);
    this.props.store.EventsStore.setEvent(null);
    const id = (this.props.match ?.params ?.eventId || null);
    if (id) {
      document.title = 'Update Event | BinderPOS';
      this.fetchEventInfo(id);
    } else {
      document.title = 'Add Event | BinderPOS';
    }
    if (!this.props.store.EventsStore.eventCategories) {
      this.fetchCategoryInfo();
    }
  }

  componentDidMount() {
    if (this.descriptionHTMLEditor.current && !this.quillEditorDesc) {
      this.initQuillEditor("description");
    }
    if (this.prizeStructureHTMLEditor.current && !this.quillEditorPrize) {
      this.initQuillEditor("prizeStructure");
    }
  }

  componentDidUpdate() {
    if (this.descriptionHTMLEditor.current && !this.quillEditorDesc) {
      this.initQuillEditor("description");
    }
    if (this.prizeStructureHTMLEditor.current && !this.quillEditorPrize) {
      this.initQuillEditor("prizeStructure");
    }
  }

  initQuillEditor(type) {
    var toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],

      ['clean']                                         // remove formatting button
    ];
    if (type == "description") {
      let markdownConverter = new showdown.Converter({ extensions: [xssFilter] });
      let descriptionHTML = markdownConverter.makeHtml(this.props.store.EventsStore.event ?.description || "");

      this.setDescriptionHTML(descriptionHTML);
      this.descriptionHTMLEditor.current.innerHTML = descriptionHTML;

      this.quillEditorDesc = new Quill(this.descriptionHTMLEditor.current, {
        modules: { 'toolbar': toolbarOptions }, theme: 'snow'
      });
      this.quillEditorDesc.on('text-change', (delta, oldDelta, source) => {
        this.setDescriptionHTML(this.quillEditorDesc.root.innerHTML);
      });
    } else {
      let markdownConverter = new showdown.Converter({ extensions: [xssFilter] });
      let prizeStructureHTML = markdownConverter.makeHtml(this.props.store.EventsStore.event ?.prizeStructure || "");

      this.setPrizeStructureHTML(prizeStructureHTML);
      this.prizeStructureHTMLEditor.current.innerHTML = prizeStructureHTML;

      this.quillEditorPrize = new Quill(this.prizeStructureHTMLEditor.current, {
        modules: { 'toolbar': toolbarOptions }, theme: 'snow'
      });
      this.quillEditorPrize.on('text-change', (delta, oldDelta, source) => {
        this.setPrizeStructureHTML(this.quillEditorPrize.root.innerHTML);
      });
    }
  }

  getDateToday() {
    return new Date().getDate();
  }

  getDaysArray() {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  }

  getDayToday() {
    return getDaysArray[new Date().getDay()];
  }

  changeRecurringText() {
    const recurringFrequency = this.form.current.elements.recurringFrequency.value;
    if (!recurringFrequency) {
      return;
    }
    if (recurringFrequency > 1) {
      this.form.current.elements.recurringSelect.options[0].text = "Days";
      this.form.current.elements.recurringSelect.options[1].text = "Weeks";
      this.form.current.elements.recurringSelect.options[2].text = "Months";
      this.form.current.elements.recurringSelect.options[3].text = "Years";
    } else {
      this.form.current.elements.recurringSelect.options[0].text = "Day";
      this.form.current.elements.recurringSelect.options[1].text = "Week";
      this.form.current.elements.recurringSelect.options[2].text = "Month";
      this.form.current.elements.recurringSelect.options[3].text = "Year";
    }
  }

  todaysDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    return yyyy + '-' + mm + '-' + dd;
  }

  showOrHideRecurringInfo() {
    this.setRecurring(this.form.current.elements.eventRecurring.options[this.form.current.elements.eventRecurring.selectedIndex].value == 0 ? false : true);
  }

  showOrHideEventPrice() {
    this.setTicketed(this.form.current.elements.eventIsTicketed.checked);
  }

  showOrHideAdditionalInfo() {
    this.setAdditionalInfo(this.form.current.elements.additionalInfoRequired.checked);
  }

  showRepeatOptions() {
    this.setRecurringType(this.form.current.elements.recurringSelect.options[this.form.current.elements.recurringSelect.selectedIndex].value);
  }

  getMonthWeek() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return Math.ceil((date.getDate() + firstDay) / 7);
  }

  getOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  generateTextForRecurring(event) {
    if (event.recurring) {
      let typeName = typeName = event.recurringType + "s"
      if (event.recurringFrequency == 1) {
        if (event.recurringType.toLowerCase() == "day") {
          typeName = "daily";
        } else {
          typeName = event.recurringType + "ly";
        }
      }
      let value = "Event scheduled " + (event.recurringFrequency == 1 ? "" : `for every ${event.recurringFrequency} `) + typeName;
      if (event.recurringType == "week") {
        value += ` on ${this.commaSplitWithAnd(event.recurringDays)}`;
      }
      if (event.recurringType == "month") {
        value += ` on ${this.getOrdinal(event.recurringDate)} of every month`;
      }
      if (event.recurringEndDate) {
        value += ` and ends on ${TimeUtils.convertDateToHumanReadableNoTime(event.recurringEndDate)}`;
      }
      return value;
    }
    return "This is a single event";
  }

  commaSplitWithAnd(theItems) {
    var list = theItems.split(',');
    return [list.slice(0, -1).join(', '), list.slice(-1)[0]].join(list.length < 2 ? '' : ' and ')
  }

  buildDateString(dateString) {
    var date;
    if (typeof dateString.getMonth !== 'function') {
      var splitDate = dateString.split('T');
      return splitDate[0];
    } else {
      date = new Date(dateString);
    }
    const year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return year + "-" + month + "-" + day;
  }

  makeEndsOnChecked() {
    this.form.current.elements.endsOn.checked = true;
  }

  checkForFormErrorsAdding(elm) {
    let modalText = ``;
    let canSubmit = true;
    if (!elm.eventTitle.value || elm.eventTitle.value == "") {
      modalText += `<p>Please make sure a <strong>title</strong> has been entered for this event</p>`;
      canSubmit = false;
    }
    if (!elm.eventGameCategory.value || elm.eventGameCategory.value == "") {
      modalText += `<p>Please make sure a <strong>game</strong> has been entered for this event</p>`;
      canSubmit = false;
    }
    if (!elm.eventTypeCategory.value || elm.eventTypeCategory.value == "") {
      modalText += `<p>Please make sure a <strong>type</strong> has been entered for this event</p>`;
      canSubmit = false;
    }
    if (!elm.eventDate.value || elm.eventDate.value == "") {
      modalText += `<p>Please make sure a <strong>date</strong> has been entered for this event</p>`;
      canSubmit = false;
    } else if (elm.eventDate.value && elm.eventDate.value != "") {
      let todayDate = new Date(this.buildDateString(new Date()));
      let myDate = new Date(elm.eventDate.value);
      if (myDate < todayDate) {
        //modalText += `<p>Please make sure the <strong>date</strong> is for today or in the future</p>`;
        canSubmit = true;
      }
    }
    if (!elm.eventTime.value || elm.eventTime.value == "") {
      modalText += `<p>Please make sure a <strong>time</strong> has been entered for this event</p>`;
      canSubmit = false;
    }
    if (!canSubmit) {
      this.setUpdating(false);
      this.setModalVisible(true);
      this.setModalTitle('Fix the following issues with your event');
      this.setModalText(modalText);
      this.setHasValidationErrors(true);
    }
    return canSubmit;
  }

  checkForFormErrorsUpdating(elm, isRecurring) {
    let modalText = ``;
    let canSubmit = true;
    if (!elm.eventTitle.value || elm.eventTitle.value == "") {
      modalText += `<p>Please make sure a <strong>title</strong> has been entered for this event</p>`;
      canSubmit = false;
    }
    if (!elm.eventGameCategory.value || elm.eventGameCategory.value == "") {
      modalText += `<p>Please make sure a <strong>game</strong> has been entered for this event</p>`;
      canSubmit = false;
    }
    if (!elm.eventTypeCategory.value || elm.eventTypeCategory.value == "") {
      modalText += `<p>Please make sure a <strong>type</strong> has been entered for this event</p>`;
      canSubmit = false;
    }
    if (!isRecurring) {
      if (!elm.eventDate.value || elm.eventDate.value == "") {
        modalText += `<p>Please make sure a <strong>date</strong> has been entered for this event</p>`;
        canSubmit = false;
      } else if (elm.eventDate.value && elm.eventDate.value != "") {
        let todayDate = new Date(this.buildDateString(new Date()));
        let myDate = new Date(elm.eventDate.value);
        if (myDate < todayDate) {
          //modalText += `<p>Please make sure the <strong>date</strong> is for today or in the future</p>`;
          canSubmit = true;
        }
      }
    }
    if (!elm.eventTime.value || elm.eventTime.value == "") {
      modalText += `<p>Please make sure a <strong>time</strong> has been entered for this event</p>`;
      canSubmit = false;
    }
    if (!canSubmit) {
      this.setUpdating(false);
      this.setModalVisible(true);
      this.setModalTitle('Fix the following issues with your event');
      this.setModalText(modalText);
      this.setHasValidationErrors(true);
    }
    return canSubmit;
  }

  save(e) {
    e.preventDefault()
    this.setHasValidationErrors(false);
    this.setUpdating(true)

    const elm = this.form.current.elements;


    var formData = new FormData();
    formData.append('calendarIcon', elm.eventCalendarIcon.files[0]);
    formData.append('banner', elm.eventBanner.files[0]);
    formData.append('featureImage', elm.eventFeatureImage.files[0]);

    if (this.props.match ?.params ?.eventId) {
      let eventToUpdate = JSON.parse(JSON.stringify(this.props.store.EventsStore.event));
      if (this.checkForFormErrorsUpdating(elm, eventToUpdate.recurring)) {
        delete eventToUpdate['date'];
        eventToUpdate.title = elm.eventTitle.value;
        eventToUpdate.game = elm.eventGameCategory.value;
        eventToUpdate.type = elm.eventTypeCategory.value;
        if (!eventToUpdate.recurring) {
          eventToUpdate.date = elm.eventDate.value;
        }
        eventToUpdate.time = elm.eventTime.value;
        eventToUpdate.description = this.descriptionHTML;
        eventToUpdate.prizeStructure = this.prizeStructureHTML;
        if (eventToUpdate.isTicketed) {
          formData.append('ticketImage', elm.eventTicketImage.files[0]);
          eventToUpdate.maxParticipants = elm.maxParticipants.value;
        }

        if (elm.additionalInfoRequired.checked) {
          eventToUpdate.additionalInfoRequired = elm.additionalInfoRequired.checked;
          eventToUpdate.additionalInfo = [];
          for (var i = 0; i < this.additionalInfoList.length; i++) {
            if (elm['additionalInfoHeader-' + i].value && elm['additionalInfoHeader-' + i].value != "") {
              let additionalInfoObj = {
                header: elm['additionalInfoHeader-' + i].value,
                headerDescription: elm['additionalInfoDescription-' + i].value,
              }
              if (elm['additionalInfoId-' + i] && elm['additionalInfoId-' + i].value) {
                additionalInfoObj.id = elm['additionalInfoId-' + i].value;
              }
              eventToUpdate.additionalInfo.push(additionalInfoObj);
            }
          }
        }

        formData.append("event", JSON.stringify(eventToUpdate));

        var eventId = this.props.match.params.eventId;
        this.props.store.EventsStore.updateEvent(eventId, formData)
          .then(result => {
            if (result && result.length > 0) {
              this.props.store.EventsStore.setFailedEvents(result);
            }
            this.setUpdating(false);
            this.setModalVisible(true);
            this.setModalTitle('Event updated');
            this.setModalText('Your event has successfully been updated!');
            this.props.store.EventsStore.rehydrate();
          }).catch(error => {
            this.setUpdating(false);
            this.props.store.MainStore.setError(error);
          })
      }
    } else {
      if (this.checkForFormErrorsAdding(elm)) {
        let event = {
          "game": elm.eventGameCategory.value,
          "type": elm.eventTypeCategory.value,
          "title": elm.eventTitle.value,
          "date": elm.eventDate.value,
          "time": elm.eventTime.value,
          "description": this.descriptionHTML,
          "prizeStructure": this.prizeStructureHTML,
          "recurring": elm.eventRecurring.checked,
          "isTicketed": elm.eventIsTicketed.checked,
          "additionalInfoRequired": elm.additionalInfoRequired.checked
        }

        if (elm.additionalInfoRequired.checked) {
          event.additionalInfo = [];
          for (var i = 0; i < this.additionalInfoList.length; i++) {
            if (elm['additionalInfoHeader-' + i].value && elm['additionalInfoHeader-' + i].value != "") {
              let additionalInfoObj = {
                header: elm['additionalInfoHeader-' + i].value,
                headerDescription: elm['additionalInfoDescription-' + i].value,
              }
              event.additionalInfo.push(additionalInfoObj);
            }
          }
        }

        if (elm.eventIsTicketed.checked) {
          event.ticketPrice = (elm.eventPrice.value && elm.eventPrice.value != "" ? elm.eventPrice.value : 0);
          formData.append('ticketImage', elm.eventTicketImage.files[0]);
          event.maxParticipants = elm.maxParticipants.value;
          event.taxable = elm.eventIsTaxed.checked;
        }

        if (elm.eventRecurring[elm.eventRecurring.selectedIndex].value == "1") {
          event.recurring = true;
          event.recurringFrequency = elm.recurringFrequency.value;
          event.recurringType = elm.recurringSelect[elm.recurringSelect.selectedIndex].value;
          if (event.recurringType == "week") {
            let days = [];
            this.getDaysArray().forEach(day => {
              if (elm[`${day}Checkbox`].checked) {
                days.push(day);
              }
            });
            event.recurringDays = days.join(',');
          }
          if (event.recurringType == "month") {
            event.recurringDate = elm.monthlySelect[elm.monthlySelect.selectedIndex].value;
          }
          if (elm.endsOn.checked && (elm.eventEndDate.value && elm.eventEndDate.value != "")) {
            event.recurringEndDate = elm.eventEndDate.value;
          }
        }

        formData.append("event", JSON.stringify(event));
        this.props.store.EventsStore.addEvent(formData)
          .then(result => {
            if (result && result.length > 0) {
              this.props.store.EventsStore.setFailedEvents(result);
            }
            this.setUpdating(false);
            this.setModalVisible(true);
            this.setModalTitle('Event created');
            this.setModalText('Your event has successfully been added!');
            this.props.store.EventsStore.rehydrate();
          }).catch(error => {
            this.setUpdating(false);
            this.props.store.MainStore.setError(error);
          })
      }
    }
  }

  buildEventsFailedString() {
    var myString = "";
    this.props.store.EventsStore.failedEvents.forEach(failed => {
      myString += failed.title + " on " + TimeUtils.convertDateToHumanReadableNoTime(failed.readableDate) + "\n";
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.events ?
          <React.Fragment>
            {this.props.store.EventsStore.failedEvents ?
              <ConfirmationModal type="confirm" title={this.props.store.EventsStore.event ? "The following events failed to update" : "The following events failed to be created"} text={this.buildEventsFailedString()} confirmAction={() => this.props.store.EventsStore.setFailedEvents(null)} />
              :
              null
            }
            {this.props.store.EventsStore.eventCategories && ((this.props.match ?.params ?.eventId && this.props.store.EventsStore.event ?.title) || !this.props.match ?.params ?.eventId) ?
              <React.Fragment>
                <div className="app-header noSearch">
                  <h2 className="title is-2">
                    <span className="header-text">{this.props.store.EventsStore.event ? `Update ${this.props.store.EventsStore.event.title}` : `Create new event`}</span>
                    <button className={`button rightAlign is-link ${this.updating ? ' is-loading' : ''}`} type="submit">{this.props.store.EventsStore.event ? "Update" : "Save"}</button>
                  </h2>
                </div>
                <div className="EventListing">
                  <div className="col-md-12 content">
                    <form ref={this.form} onSubmit={e => this.save(e)} noValidate>
                      <div className="eventPanel">
                        <div className="row">
                          <div className="col-md-12 field">
                            <label className="label">Event title</label>
                            <div className="control">
                              <input id="eventTitle" className="input" type="text" placeholder="E.g. Friday Commander Night" required defaultValue={this.props.store.EventsStore.event ?.title || ""} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 field">
                            <label className="label">Event game</label>
                            <div className="control has-icons-left">
                              <input list="eventGameCategoryDatalist" id="eventGameCategory" className="input" type="text" required defaultValue={this.props.store.EventsStore.event ?.game || ""} />
                              <datalist id="eventGameCategoryDatalist">
                                {this.props.store.EventsStore.eventGames.map((category, i) => {
                                  return (
                                    <option key={i} value={category.category} />
                                  )
                                })}
                              </datalist>
                              <span className="icon is-small is-left">
                                <i className="fas fa-gamepad"></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 field">
                            <label className="label">Event type</label>
                            <div className="control has-icons-left">
                              <input list="eventTypeCategoryDatalist" id="eventTypeCategory" className="input" type="text" required defaultValue={this.props.store.EventsStore.event ?.type || ""} />
                              <datalist id="eventTypeCategoryDatalist">
                                {this.props.store.EventsStore.eventTypes.map((category, i) => {
                                  return (
                                    <option key={i} value={category.category} />
                                  )
                                })}
                              </datalist>
                              <span className="icon is-small is-left">
                                <i className="fas fa-trophy"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="eventPanel">
                        <p className="subHeading">Event information</p>
                        <div className="row">
                          <div className="col-md-4 field">
                            <label className="label">Event date</label>
                            {this.props.store.EventsStore.event ?.recurring ?
                              <p>{TimeUtils.convertDateToHumanReadableNoTime(this.props.store.EventsStore.event.date)}</p>
                              :
                              <div className="control">
                                <input id="eventDate" className="input" type="date" placeholder="E.g. 18/07/2018" required defaultValue={this.props.store.EventsStore.event ?.date ? this.buildDateString(this.props.store.EventsStore.event.date, this.props.store.EventsStore.event.time) : ""} />
                              </div>
                            }
                          </div>
                          <div className="col-md-4 field">
                            <label className="label">Event time</label>
                            <div className="control">
                              <input id="eventTime" className="input" type="time" placeholder="E.g. 10:00" required defaultValue={this.props.store.EventsStore.event ?.time || ""} />
                            </div>
                          </div>
                          {!this.props.store.EventsStore.event ?
                            <React.Fragment>
                              <div className="col-md-4 field">
                                <label className="label">Event Frequency</label>
                                <div className="control">
                                  <select id="eventRecurring" onChange={this.showOrHideRecurringInfo.bind(this)}>
                                    <option value="0">One off event</option>
                                    <option value="1">Recurring</option>
                                  </select>
                                </div>
                              </div>
                              <div id="recurringSection" className={"col-md-12 recurring-section" + (!this.isRecurring ? " hideItem" : "")}>
                                <div className="card-content">
                                  <div className="field is-horizontal">
                                    <div className="field-label is-normal">
                                      <label className="label">Repeat every</label>
                                    </div>
                                    <div className="field-body">
                                      <div className="field">
                                        <p className="control">
                                          <input id="recurringFrequency" className="input" type="number" max="52" min="1" onChange={this.changeRecurringText.bind(this)} defaultValue="1" />
                                        </p>
                                      </div>
                                      <div className="field">
                                        <span className="select">
                                          <select id="recurringSelect" onChange={this.showRepeatOptions.bind(this)}>
                                            <option value="day">Day</option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                            <option value="year">Year</option>
                                          </select>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="weeklySelection" className={"field is-horizontal" + (this.recurringType === "week" ? "" : " hideItem")}>
                                    <div className="repeatOn">
                                      <label className="label">Repeat on</label>
                                    </div>
                                    <div className="row">
                                      {this.getDaysArray().map((day, i) => {
                                        return (
                                          <div className="field" key={i}>
                                            <label className="checkbox">
                                              <input id={`${day}Checkbox`} type="checkbox" />
                                              {` ${day}`}
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                  <div id="monthlySelection" className={"field is-horizontal" + (this.recurringType === "month" ? "" : " hideItem")}>
                                    <div className="field-label is-normal">
                                      <label className="label">Repeat</label>
                                    </div>
                                    <div className="field-body">
                                      <div className="field">
                                        <span className="select">
                                          <select id="monthlySelect">
                                            <option value={this.getDateToday()}>{`Monthly on day ${this.getDateToday()}`}</option>
                                          </select>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <p>Ends</p>
                                  <div className="row">
                                    <div className="col-md-1 field">
                                      <div className="field-label is-normal">
                                        <label className="radio">
                                          <input id="neverEnds" type="radio" name="ends" defaultChecked />
                                          {` Never`}
                                        </label>
                                      </div>
                                      <div className="field-body">
                                      </div>
                                    </div>
                                    <div className="col-md-3 field">
                                      <div className="field-label is-normal">
                                        <label className="radio">
                                          <input id="endsOn" type="radio" name="ends" />
                                          {` On`}
                                        </label>
                                      </div>
                                      <div className="field-body">
                                        <div className="control">
                                          <input id="eventEndDate" className="input" type="date" placeholder="E.g. 18/07/2018" onChange={this.makeEndsOnChecked.bind(this)} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                            :
                            <div className="col-md-4 field">
                              <label className="label">Event frequency</label>
                              <p>{this.generateTextForRecurring(this.props.store.EventsStore.event)}</p>
                            </div>
                          }
                        </div>
                        <hr />

                        <div className="field">
                          <label className="label">Description</label>
                          <div className="control editor">
                            <div className="content" ref={this.descriptionHTMLEditor}></div>
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Prize structure</label>
                          <div className="control editor">
                            <div className="content" ref={this.prizeStructureHTMLEditor}></div>
                          </div>
                        </div>
                      </div>
                      <div className="eventPanel">
                        <p className="subHeading">Images</p>
                        <div className="row">
                          <div className="col-md-6 field mobile">
                            <label className="label">Event calendar icon</label>
                            <div className="uploadSection">
                              <div className="imageWrapper"><img id="calendarIconImage" className="eventImage" src={(this.calendarIconImage ? this.calendarIconImage.src : "https://storage.googleapis.com/binderpos-event-images/binder-event-icon.png")} /></div>
                              <div className="control">
                                <label className="custom-file-upload">
                                  <input id="eventCalendarIcon" className="input" type="file" onChange={this.showCalendarIconImage.bind(this)} />
                                  {this.props.store.EventsStore.event && this.props.store.EventsStore.event.calendarIcon ? "Change" : "Upload"}
                                </label>
                                <span className="fileName">{this.calendarIconImage ? this.calendarIconImage.name : "Default event icon"}</span>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 field mobile">
                            <label className="label">Event background</label>
                            <div className="uploadSection">
                              <div className={this.calendarBannerImage ? "imageWrapper" : "imageWrapper background"}><img id="calendarBannerImage" className="eventBanner" src={(this.calendarBannerImage ? this.calendarBannerImage.src : "")} /></div>
                              <div className="control">
                                <label className="custom-file-upload">
                                  <input id="eventBanner" className="input" type="file" onChange={this.showCalendarBannerImage.bind(this)} />
                                  {this.props.store.EventsStore.event && this.props.store.EventsStore.event.banner ? "Change" : "Upload"}
                                </label>
                                <span className="fileName">{this.calendarBannerImage ? this.calendarBannerImage.name : "Example Image.png"}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 field mobile">
                            <label className="label">Event feature image</label>
                            <div className="uploadSection">
                              <div className={this.calendarFeatureImage ? "imageWrapper" : "imageWrapper background"}><img id="calendarFeatureImage" className="eventBanner" src={(this.calendarFeatureImage ? this.calendarFeatureImage.src : "")} /></div>
                              <div className="control">
                                <label className="custom-file-upload">
                                  <input id="eventFeatureImage" className="input" type="file" onChange={this.showCalendarFeatureImage.bind(this)} />
                                  {this.props.store.EventsStore.event && this.props.store.EventsStore.event.featureImage ? "Change" : "Upload"}
                                </label>
                                <span className="fileName">{this.calendarFeatureImage ? this.calendarFeatureImage.name : "Example Image.png"}</span>
                              </div>
                            </div>
                          </div>
                          <div className={"col-md-6 field mobile" + (this.ticketed || this.props.store.EventsStore.event ?.isTicketed ? "" : " hideItem")}>
                            <label className="label">Event ticket image</label>
                            <div className="uploadSection">
                              <div className="imageWrapper"><img id="calendarTicketImage" className="eventImageTickets" src={(this.calendarTicketImage ? this.calendarTicketImage.src : "https://storage.googleapis.com/binderpos-event-images/binderpos-ticket.png")} /></div>
                              <div className="control">
                                <label className="custom-file-upload">
                                  <input id="eventTicketImage" className="input" type="file" onChange={this.showCalendarTicketImage.bind(this)} />
                                  {this.props.store.EventsStore.event && this.props.store.EventsStore.event.ticketImage ? "Change" : "Upload"}
                                </label>
                                <span className="fileName">{this.calendarTicketImage ? this.calendarTicketImage.name : "Default ticket Image"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="eventPanel">
                        <div className="row">
                          {!this.props.store.EventsStore.event ?
                            <React.Fragment>
                              <div className="col-md-12 field">
                                <label className="checkbox">
                                  <input id="eventIsTicketed" type="checkbox" onChange={this.showOrHideEventPrice.bind(this)} />
                                  <span className="checkmark"></span>
                                  &nbsp; Does this event require a ticket?
                                </label>
                              </div>
                              <div className={"col-md-6 field" + (!this.ticketed ? " hideItem" : "")} id="eventPriceDiv">
                                <label className="label">Price of ticket</label>
                                <p className="textNorm">Entering 0 will mean the ticket is free</p>
                                <div className="control has-icons-left">
                                  <input id="eventPrice" className="input" type="number" min="1" placeholder="E.g. 29.95" step=".01" />
                                  <span className="icon is-small is-left">
                                    <i className="fas fa-dollar-sign"></i>
                                  </span>
                                </div>
                              </div>
                              <div className={"col-md-6 field" + (this.ticketed || (this.props.store.EventsStore.event ?.isTicketed) ? "" : " hideItem")}>
                                <label className="label">Maximum participants (Max tickets)</label>
                                <p className="textNorm">Entering 0 indicates that there are unlimited tickets available</p>
                                <div className="control">
                                  <input id="maxParticipants" className="input" type="number" min="0" placeholder="E.g. 50" defaultValue={this.props.store.EventsStore.event ?.maxParticipants || 0} />
                                </div>
                              </div>
                              <div className={"col-md-12 field" + (this.ticketed || (this.props.store.EventsStore.event ?.isTicketed) ? "" : " hideItem")}>
                                <label className="checkbox">
                                  <input id="eventIsTaxed" type="checkbox" />
                                  <span className="checkmark"></span>
                                  &nbsp; Charge tax on event ticket?
                                </label>
                              </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              {this.props.store.EventsStore.event ?.isTicketed ?
                                <React.Fragment>
                                  <div className="col-md-6 field">
                                    <label className="label">Ticket price</label>
                                    <p>The ticket price for this event is {this.props.store.EventsStore.event.ticketPrice}</p>
                                  </div>
                                  <div className={"col-md-6 field" + (this.ticketed || (this.props.store.EventsStore.event ?.isTicketed) ? "" : " hideItem")}>
                                    <label className="label">Maximum participants (Max tickets)</label>
                                    <p className="textNorm">Entering 0 indicates that there are unlimited tickets available</p>
                                    <div className="control">
                                      <input id="maxParticipants" className="input" type="number" min="0" placeholder="E.g. 50" defaultValue={this.props.store.EventsStore.event ?.maxParticipants || 0} />
                                    </div>
                                  </div>
                                  <div className="col-md-12 field">
                                    <label className="label">Tax</label>
                                    <p>{this.props.store.EventsStore.event ?.taxable ? "Tax is charged on this ticket" : "This ticket is not taxed"}</p>
                                  </div>
                                </React.Fragment>
                                :
                                null
                              }
                            </React.Fragment>
                          }
                        </div>
                      </div>
                      <div className={"eventPanel" + (this.ticketed || (this.props.store.EventsStore.event ?.isTicketed) ? "" : " hideItem")}>
                        <div className="field">
                          <label className="checkbox">
                            <input id="additionalInfoRequired" type="checkbox" onChange={this.showOrHideAdditionalInfo.bind(this)} defaultChecked={this.additionalInfo} disabled={this.props.store.EventsStore.event ?.additionalInfoRequired} />
                            <span className="checkmark"></span>
                            &nbsp; Does this event require a additional information? <em>For example DCI number</em>
                          </label>
                          <div id="additionalInfoSection" className={"additional-info-section" + (!this.additionalInfo ? " hideItem" : "")}>
                            <div className="row">
                              {this.additionalInfoList.map((additionalInfo, i) => {
                                return (
                                  <div className="col-md-6" key={additionalInfo.timestamp}>
                                    <div className="card-content">
                                      {!additionalInfo.id ?
                                        <button className={`deleteInfo`} type="button" onClick={this.removeAdditionalInfoFromList.bind(this, i)}><i className="far fa-trash"></i></button>
                                        :
                                        <input id={`additionalInfoId-${i}`} type="hidden" value={additionalInfo.id} />
                                      }
                                      <div className="field">
                                        <label className="label">Additional Info header</label>
                                        <p>This is the text that will be displayed for the customer. E.g 'Please enter your DCI number'</p>
                                        <div className="control">
                                          <input id={`additionalInfoHeader-${i}`} className="input" type="text" placeholder="E.g Please enter your DCI number" defaultValue={additionalInfo.header} />
                                        </div>
                                      </div>
                                      <div className="field">
                                        <label className="label">Additional Info description</label>
                                        <p>This is the helper text if needed that will be displayed for the customer. E.g 'You can find this number by contacting the store owner or looking on your register card'</p>
                                        <div className="control">
                                          <textarea id={`additionalInfoDescription-${i}`} className="textarea" placeholder="E.g You can find this number by contacting the store owner or looking on your register card" defaultValue={additionalInfo.headerDescription}></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                              <div className="col-md-6">
                                <div className="card-content addNew" onClick={this.addAdditionalInfo.bind(this)}>
                                  <span><i className="fal fa-plus"></i>Add Info</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.updating ?
                        <Loader text={this.props.store.EventsStore.event ? "Updating your event..." : "Adding your new event..."} />
                        :
                        null
                      }
                      <button className={`button is-link ${this.updating ? ' is-loading' : ''}`} type="submit">{this.props.store.EventsStore.event ? "Save and update event" : "Save and publish event"}</button>

                    </form>
                    {this.modalVisible ?
                      <div className="modal is-active eventModal">
                        <div className="modal-background"></div>
                        <div className="modal-card">
                          <header className="modal-card-head">
                            <p className="modal-card-title">{this.modalTitle}</p>
                          </header>
                          <section className="modal-card-body" dangerouslySetInnerHTML={{ __html: this.modalText }}>
                          </section>
                          <footer className="modal-card-foot">
                            {this.hasValidationErrors ?
                              <button className="button is-link" onClick={() => this.setModalVisible(false)}>Ok</button>
                              :
                              <React.Fragment>
                                {this.props.store.EventsStore.event ?.parentEventId ?
                                  <Link to={`/events/view/${this.props.store.EventsStore.event.parentEventId}`} className="button is-success">Ok</Link>
                                  :
                                  <Link to="/events" className="button is-success">Ok</Link>
                                }
                              </React.Fragment>
                            }
                          </footer>
                        </div>
                      </div>
                      :
                      null
                    }
                  </div>
                </div>
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
