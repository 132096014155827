import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { POSMenuStructure } from 'menuStructures';
import { Loader, ReceiptReprint } from 'components'
import { TimeUtils } from 'utils'

@inject('store')
@inject('posStore')
@observer
export default class CartView extends React.Component {

  @observable showReceiptModal = false;
  @action setShowReceiptModal(showReceiptModal) {
    this.showReceiptModal = showReceiptModal;
  }

  closeReceiptModal = () => {
    this.setShowReceiptModal(false);
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = 'Point of Sale | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.props.store.MainStore.buildReceiptData();
  }

  componentWillMount() {
    this.props.store.CartStore.setSelectedCart(null);
    const id = (this.props.match ?.params ?.cartId || null);
    if (id) {
      document.title = 'Cart #' + id + ' | BinderPOS';
      this.props.store.CartStore.fetchCartById(id)
        .then((cart) => {
          this.props.store.CartStore.setSelectedCart(cart);
        })
        .catch(error => {
          this.props.store.MainStore.setError(error);
        })
    }

  }

  render() {
    const fC = this.props.store.MainStore.currencyBuilder;
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.pos ?
          <React.Fragment>
            {this.props.store.CartStore.selectedCart ?
              <React.Fragment>
                {this.showReceiptModal ?
                  <ReceiptReprint closeReceipt={this.closeReceiptModal} />
                  :
                  null
                }
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon cyan"><i className="fal fa-shopping-cart"></i></span>
                    <span className="header-text">{"Cart #" + this.props.store.CartStore.selectedCart.id}</span>
                    <a className="button is-primary rightAlign" onClick={() => this.setShowReceiptModal(true)}>Reprint Receipt</a>
                  </h2>
                </div>
                <br />
                <div className="customer_list reviewBg cartviewTop">
                  <table>
                    <thead>
                      <tr>
                        <React.Fragment>
                          <th colSpan="2" className="col2">Customer</th>
                          {this.props.store.CartStore.selectedCart.customer ?
                            <th className="col1">Email</th>
                            :
                            null
                          }
                          <th className="storeCredit col2">Date submitted</th>
                          <th className="col2">Order #</th>
                        </React.Fragment>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {this.props.store.CartStore.selectedCart.customer ?
                          <React.Fragment>
                            <td className="initials">
                              <div>{this.props.store.CartStore.selectedCart.customer.firstName && this.props.store.CartStore.selectedCart.customer.firstName.length > 0 ?
                                <React.Fragment>
                                  {this.props.store.CartStore.selectedCart.customer.firstName.charAt(0)}
                                  {this.props.store.CartStore.selectedCart.customer.lastName && this.props.store.CartStore.selectedCart.customer.lastName.length > 0 ?
                                    <React.Fragment>
                                      {this.props.store.CartStore.selectedCart.customer.lastName.charAt(0)}
                                    </React.Fragment>
                                    :
                                    null
                                  }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  {this.props.store.CartStore.selectedCart.customer.email && this.props.store.CartStore.selectedCart.customer.email.length > 0 ?
                                    <React.Fragment>
                                      {this.props.store.CartStore.selectedCart.customer.email.charAt(0)}
                                    </React.Fragment>
                                    :
                                    null
                                  }
                                </React.Fragment>
                              }
                              </div>
                            </td>
                            <td className="name">
                              {this.props.store.CartStore.selectedCart.customer.firstName} {this.props.store.CartStore.selectedCart.customer.lastName}
                            </td>
                            <td>
                              {this.props.store.CartStore.selectedCart.customer.email}
                            </td>
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <td className="initials">
                              <div>Guest</div>
                            </td>
                            <td className="name">
                              Guest cart
                            </td>
                          </React.Fragment>
                        }
                        <td>
                          {TimeUtils.convertDateToHumanReadable(this.props.store.CartStore.selectedCart.readableDateSubmitted)}
                        </td>
                        <td>
                          {this.props.store.CartStore.selectedCart.orderNumber}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {this.props.store.CartStore.selectedCart.tenders && this.props.store.CartStore.selectedCart.tenders.length > 0 ?
                    <React.Fragment>
                      <table className="cartViewTenders">
                        <p className="subHeading">Cart tenders below</p>
                        <tbody>

                          {this.props.store.CartStore.selectedCart.tenders.map((tender, i) => {
                            return (
                              <tr key={i}>
                                <td ><em>{tender.type} :</em> {fC(parseFloat(tender.amount))}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </React.Fragment>
                    :
                    <p>No tenders used</p>
                  }
                </div>
                <div className="customer_list reviewBg">
                  <table className="cartView">
                    <thead>
                      <tr>
                        <td></td>
                        <td>Product</td>
                        <td>Bought / Sold</td>
                        <td>Quantity</td>
                        <td>Price</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.store.CartStore.selectedCart.cartItems.map((cartItem, i) => {
                        return (
                          <React.Fragment key={i}>
                            <tr>
                              <td><img className="cart-view-image" src={cartItem.imageSrc} /></td>
                              <td>
                                <div className="cartViewTitle">{cartItem.productTitle}</div>
                                <div className="cartViewTitle">{cartItem.variantTitle}</div>
                              </td>
                              <td>{cartItem.buying ? "Bought" : "Sold"}</td>
                              <td>{cartItem.quantity}</td>
                              <td>{fC(parseFloat(parseFloat(cartItem.price) / parseFloat(cartItem.quantity)))}</td>
                              <td>{fC(parseFloat(cartItem.price))}</td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                      <tr className="cartViewTotals">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="3">
                          <div className="cartViewTitle">Sub Total: <em>{fC(this.props.store.CartStore.selectedCart.subTotal)}</em></div>
                          <div className="cartViewTitle">Tax included: <em>{fC(this.props.store.CartStore.selectedCart.totalTax)}</em></div>
                          <div className="cartViewTitle">Total: <em>{fC(this.props.store.CartStore.selectedCart.totalPrice)}</em></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
