import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { SettingsMenuStructure } from 'menuStructures';
import { ToastContainer } from "react-toastify";
import Select from 'react-select'
import { Loader } from 'components'

@inject('store')
@observer
export default class TimezoneSettings extends React.Component {

  @observable updating;
  @action setUpdating(updating) {
    this.updating = updating;
  }

  @observable selectedTimezone;
  @action setSelectedTimezone(selectedTimezone) {
    this.selectedTimezone = selectedTimezone;
  }

  handleSelectedTimezoneChange = (selectedOptions) => {
    this.setSelectedTimezone(selectedOptions.value);
  }

  constructor(props) {
    super(props);
    this.taxRateRef = React.createRef();
    this.taxIncludedRef = React.createRef();
    this.taxNumberRef = React.createRef();
    this.tradeInTaxRef = React.createRef();
    this.taxWordingRef = React.createRef();
  }

  componentDidMount() {
    document.title = 'Timezone Settings | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(SettingsMenuStructure);
    this.props.store.SettingsStore.getCustomerTimezone();
    this.props.store.SettingsStore.getTimezones();
  }

  buildOptionsList(listToConvert) {
    let options = [];
    listToConvert.forEach(item => {
      if (item.zoneName && item.zoneName.length > 0) {
        options.push({ value: item.zoneName, label: item.zoneName });
      }
    })
    return options;
  }

  getValues(list) {
    return (list && list.length > 0) ? list.map(s => s.value) : [];
  }

  updateTimezone() {
    this.props.store.SettingsStore.setCustomerTimezone(null);
    this.setUpdating(true)
    var timezoneUpdate = {
      timezone: this.selectedTimezone
    }
    this.props.store.SettingsStore.updateCustomerTimezone(timezoneUpdate)
      .then(() => {
        this.props.store.MainStore.setInfo({ title: "Timezone updated!", message: "You have updated your timezone to " + this.selectedTimezone });
        this.props.store.SettingsStore.getCustomerTimezone();
        this.setUpdating(false)
      }).catch(err => {
        this.props.store.MainStore.setError(err);
      })
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="app-header">
          <h2 className="title is-2">
            <span className="app-icon purple"><i className="fas fa-users"></i></span>
            <span className="header-text">Timezone Settings</span>
          </h2>
        </div>
        {this.props.store.SettingsStore.timezones && this.props.store.SettingsStore.timezones.length > 0 && this.props.store.SettingsStore.customerTimezone && this.props.store.SettingsStore.customerTimezone.length > 0 && !this.updating ?
          <div className="row">
            <div className="col-md-12 field">
              <label className="label">Your timezone</label>
              <Select
                onChange={this.handleSelectedTimezoneChange}
                options={this.buildOptionsList(this.props.store.SettingsStore.timezones)}
                defaultValue={{ label: this.props.store.SettingsStore.customerTimezone, value: this.props.store.SettingsStore.customerTimezone }}
              />
            </div>
            <button className="button" onClick={() => this.updateTimezone()}>Update timezone</button>
          </div>
          :
          <Loader />
        }
      </React.Fragment >
    );
  }
}
