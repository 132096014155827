import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { POSMenuStructure } from 'menuStructures';
import { Loader } from 'components'
import { TimeUtils } from 'utils'

@inject("store")
@observer
export default class POSFailedBuylistProducts extends React.Component {

  @observable offset = 0;
  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 100;
  @action setLimit(limit) {
    this.limit = limit;
  }

  @observable messageModalText;
  @action setMessageModalText(text) {
    this.messageModalText = text;
  }

  @observable messageModalTitle;
  @action setMessageModalTitle(title) {
    this.messageModalTitle = title;
  }

  @observable messageModalVisible;
  @action setMessageModalVisible(bool) {
    this.messageModalVisible = bool;
  }

  @observable processing;
  @action setProcessing(processing) {
    this.processing = processing;
  }

  @observable deleteModalVisible = false;
  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteModalProduct;
  @action setDeleteModalProduct(product) {
    this.deleteModalProduct = product;
  }

  constructor(props) {
    super(props);
  }

  convertDateToHumanReadable(theDate) {
    const date = new Date(theDate);
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
    return date.toLocaleDateString() + " @ " + new Date(theDate).toLocaleTimeString();
  }

  componentDidMount() {
    document.title = "POS Failed to Sync to Stock | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.props.store.POSBuylistRulesStore.rehydratePOSFailedToSync(
      this.offset,
      this.limit
    );
  }

  getNextFailedToSync() {
    this.setOffset(this.offset + 100);
    this.props.store.POSBuylistRulesStore.rehydratePOSFailedToSync(
      this.offset,
      this.limit
    );
  }

  getPrevFailedToSync() {
    var offsetUpdate = this.offset - 100;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.POSBuylistRulesStore.rehydratePOSFailedToSync(
      this.offset,
      this.limit
    );
  }

  pushStockThrough(failedToSync) {
    this.setProcessing(true);
    this.props.store.POSBuylistRulesStore.processPOSFailedProduct(failedToSync)
      .then(result => {
        if (!result.actionPass) {
          this.setMessageModalText(result.message);
          this.setMessageModalTitle("Issue pushing through to stock");
          this.setMessageModalVisible(true);
          this.setProcessing(false);
        } else {
          this.setProcessing(false);
          this.props.store.POSBuylistRulesStore.rehydratePOSFailedToSync(
            this.offset,
            this.limit
          );
        }
      })
      .catch(error => {
        this.setProcessing(false);
        this.props.store.MainStore.setError(error);
      });
  }

  showRemoveProductModal(failedToSync) {
    this.setDeleteModalProduct(failedToSync);
    this.setDeleteModalVisible(true);
  }

  removeFromList(failedToSync) {
    this.setProcessing(true);
    this.setDeleteModalVisible(false);
    this.props.store.POSBuylistRulesStore.removePOSFailedProduct(failedToSync)
      .then(result => {
        this.setMessageModalText(
          result.actionPass ? "Product removed successfully!" : result.message
        );
        this.setMessageModalTitle(
          result.actionPass
            ? "Product removed"
            : "There was an issue removing this product"
        );
        this.setMessageModalVisible(true);
        this.setProcessing(false);
        this.props.store.POSBuylistRulesStore.rehydratePOSFailedToSync(
          this.offset,
          this.limit
        );
      })
      .catch(error => {
        this.setProcessing(false);
        this.props.store.MainStore.setError(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.pos ? (
          <React.Fragment>
            {this.props.store.POSBuylistRulesStore.posFailedToSyncList ? (
              <React.Fragment>
                {this.processing ?
                  <Loader />
                  :
                  null
                }
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon purple">
                      <i className="fas fa-users" />
                    </span>
                    <span className="header-text">
                      Failed to Sync to Stock from POS Buylist
                    </span>
                    <div className="paging-nav">
                      <button
                        className="button is-info paging-nav-right-margin"
                        disabled={this.offset == 0 ? true : false}
                        onClick={() => this.getPrevFailedToSync()}
                      >
                        Prev
                      </button>
                      <button
                        className="button is-info"
                        disabled={
                          this.props.store.POSBuylistRulesStore
                            .posFailedToSyncList &&
                            this.props.store.POSBuylistRulesStore
                              .posFailedToSyncList.length < this.limit
                            ? true
                            : false
                        }
                        onClick={() => this.getNextFailedToSync()}
                      >
                        Next
                      </button>
                    </div>
                  </h2>
                </div>
                <br />
                <div className="buylistItem FailedtoSync">
                  <div className="row">
                    {this.props.store.POSBuylistRulesStore.posFailedToSyncList.map(
                      (failedToSync, i) => {
                        return (
                          <div className="col-md-4" key={i}>
                            <div className="tileCard">
                              <div className="topBlock">
                                <div className="cardImg">
                                  <img src={failedToSync.img} />
                                </div>
                                <div className="titleInfo">
                                  <span className="cardName">{`${failedToSync.cardName} ${failedToSync.foil == true ? 'Foil' : ''}`}</span>
                                  <span className="cartSet">{failedToSync.setName}</span>
                                </div>
                              </div>
                              <div className="cardInfo">
                                <span>Condition <em>{failedToSync.variantName}</em></span>
                                <span>Quantity <em>{failedToSync.quantity}</em></span>
                                <span>Processed on <em>{TimeUtils.convertDateToHumanReadable(failedToSync.readableSubmittedDate)}</em></span>
                                <span className="edit" onClick={() => this.pushStockThrough(failedToSync)}><i className="fal fa-paper-plane"></i> Add to Stock</span>
                                <span className="remove" onClick={() => this.showRemoveProductModal(failedToSync)}>Remove <i className="fas fa-times-circle"></i></span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                {this.messageModalVisible ? (
                  <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">
                          {this.messageModalTitle}
                        </p>
                        <button
                          className="delete"
                          aria-label="close"
                          onClick={() => this.setMessageModalVisible(false)}
                        />
                      </header>
                      <section className="modal-card-body">
                        <p>{this.messageModalText}</p>
                      </section>
                      <footer className="modal-card-foot">
                        <button
                          type="button"
                          className="button is-link"
                          onClick={() => this.setMessageModalVisible(false)}
                        >
                          Ok
                        </button>
                      </footer>
                    </div>
                  </div>
                ) : null}
                {this.deleteModalVisible ? (
                  <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Are you sure?</p>
                        <button
                          className="delete"
                          aria-label="close"
                          onClick={() => this.setDeleteModalVisible(false)}
                        />
                      </header>
                      <section className="modal-card-body">
                        <p>
                          Are you sure you want to remove{" "}
                          {this.deleteModalProduct.cardName} [
                          {this.deleteModalProduct.setName}] -{" "}
                          {this.deleteModalProduct.variantName}?
                        </p>
                      </section>
                      <footer className="modal-card-foot">
                        <button
                          className={`button is-danger half`}
                          onClick={() =>
                            this.removeFromList(this.deleteModalProduct)
                          }
                        >
                          Remove
                        </button>
                        <button
                          className="button is-text half"
                          onClick={() => this.setDeleteModalVisible(false)}
                        >
                          Cancel
                        </button>
                      </footer>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ) : (
                <Loader />
              )}
          </React.Fragment>
        ) : (
            <div>
              <p>Please contact BinderPOS to enable this screen.</p>
            </div>
          )}
      </React.Fragment>
    );
  }
}
