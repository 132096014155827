import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { EventMenuStructure } from 'menuStructures';
import { Loader } from "components";
import { TimeUtils } from "utils";
import { ConfirmationModal } from 'components';

@inject('store')
@observer
export default class ChildEventList extends React.Component {

  @observable modalVisible = false;
  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable confirmationModal = false;
  @action setShowConfirmationModal(value) {
    this.confirmationModal = value;
  }

  @observable modalEvent;
  @action setModalEvent(event) {
    this.modalEvent = event;
  }

  @observable modalRecurringText;
  @action setModalRecurringText(modalRecurringText) {
    this.modalRecurringText = modalRecurringText;
  }

  @observable deleteModalVisible = false;
  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteModalEvent;
  @action setDeleteModalEvent(event) {
    this.deleteModalEvent = event;
  }

  @observable deleteModalLoading;
  @action setDeleteModalLoading(loading) {
    this.deleteModalLoading = loading;
  }

  @observable enableModalVisible = false;
  @action setEnableModalVisible(bool) {
    this.enableModalVisible = bool;
  }

  @observable enableModalEvent;
  @action setEnableModalEvent(event) {
    this.enableModalEvent = event;
  }

  @observable enableModalLoading;
  @action setEnableModalLoading(loading) {
    this.enableModalLoading = loading;
  }

  constructor(props) {
    super(props);
  }

  setEventInStore(event) {
    this.props.store.EventsStore.setEvent(event);
  }

  showModalFor(eventId) {
    var event = [];
    if (this.props.store.EventsStore.eventWithChildern.id == eventId) {
      event.push(this.props.store.EventsStore.eventWithChildern);
    } else {
      event = this.props.store.EventsStore.eventWithChildern.childEvents.filter(event => { return event.id == eventId });
    }
    this.setModalVisible(true);
    this.setModalEvent(event[0]);
    this.setModalRecurringText(this.generateTextForRecurring(event[0]));
  }

  showDeleteModalFor(eventId) {
    var event = [];
    if (this.props.store.EventsStore.eventWithChildern.id == eventId) {
      event.push(this.props.store.EventsStore.eventWithChildern);
    } else {
      event = this.props.store.EventsStore.eventWithChildern.childEvents.filter(event => { return event.id == eventId });
    }
    this.setDeleteModalVisible(true);
    this.setDeleteModalEvent(event[0]);
  }

  showEnableModalFor(eventId) {
    var event = this.props.store.EventsStore.eventWithChildern.childEvents.filter(event => { return event.id == eventId });
    this.setEnableModalVisible(true);
    this.setEnableModalEvent(event[0]);
  }

  getOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  commaSplitWithAnd(theItems) {
    var list = theItems.split(',');
    return [list.slice(0, -1).join(', '), list.slice(-1)[0]].join(list.length < 2 ? '' : ' and ')
  }

  generateTextForRecurring(event) {
    if (event.recurring) {
      let typeName = typeName = event.recurringType + "s"
      if (event.recurringFrequency == 1) {
        if (event.recurringType.toLowerCase() == "day") {
          typeName = "daily";
        } else {
          typeName = event.recurringType + "ly";
        }
      }
      let value = "Event scheduled " + (event.recurringFrequency == 1 ? "" : `for every ${event.recurringFrequency} `) + typeName;
      if (event.recurringType == "week") {
        value += ` on ${this.commaSplitWithAnd(event.recurringDays)}`;
      }
      if (event.recurringType == "month") {
        value += ` on ${this.getOrdinal(event.recurringDate)} of every month`;
      }
      if (event.recurringEndDate) {
        value += ` and ends on ${TimeUtils.convertDateToHumanReadableNoTime(event.recurringEndDate)}`;
      }
      return value;
    }
  }

  enableEvent(eventId) {
    this.setEnableModalLoading(true);
    this.props.store.EventsStore.enableEvent(eventId)
      .then(result => {
        this.setEnableModalVisible(false);
        this.setEnableModalEvent(null);
        this.setEnableModalLoading(false);
        this.props.store.EventsStore.rehydrateChildEvents(this.props.store.EventsStore.eventWithChildern.id);
      }).catch(error => {
        console.log(error);
        this.setEnableModalVisible(false);
        this.setEnableModalEvent(null);
        this.setEnableModalLoading(false);
        this.props.store.MainStore.setError(error);
      })
  }

  deleteEvent(eventId) {
    this.setDeleteModalLoading(true);
    this.props.store.EventsStore.deleteEvent(eventId)
      .then(result => {
        this.setDeleteModalVisible(false);
        this.setDeleteModalEvent(null);
        this.setDeleteModalLoading(false);
        if (this.props.store.EventsStore.eventWithChildern.id == eventId) {
          this.setShowConfirmationModal(true);
        } else {
          this.props.store.EventsStore.rehydrateChildEvents(this.props.store.EventsStore.eventWithChildern.id);
        }
      }).catch(error => {
        this.setEnableModalVisible(false);
        this.setEnableModalEvent(null);
        this.setEnableModalLoading(false);
        this.props.store.MainStore.setError(error);
      })
  }

  componentDidMount() {
    document.title = 'Events | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(EventMenuStructure);

    this.props.store.EventsStore.setEventWithChildern(null);
    this.props.store.EventsStore.fetchEventInfo(this.props.match.params.eventId)
      .then(result => {
        this.props.store.EventsStore.setEventWithChildern(result)
      }).catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.events ?
          <React.Fragment>
            {this.confirmationModal ?
              <ConfirmationModal title="Event is preparing to delete" type="confirm" text="Your event has been queued for deletion. This will be processed shortly." link={"/events/list"} />
              :
              null
            }
            {this.props.store.EventsStore.eventWithChildern ?
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon yellow"><i className="far fa-calendar-alt"></i></span>
                    <span className="header-text">Viewing {this.props.store.EventsStore.eventWithChildern.title}</span>
                  </h2>
                </div>
                <p className="subHeading">Parent Event</p>
                <div className="customer_list reviewBg">
                  <table>
                    <thead>
                      <tr>
                        <th className="col1 eventTitle">Title</th>
                        <th className="col2">Game</th>
                        <th className="col3">Type</th>
                        <th className="col3">Start date &amp; time</th>
                        <th className="col3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="eventTitle1">{this.props.store.EventsStore.eventWithChildern.title}</td>
                        <td>{this.props.store.EventsStore.eventWithChildern.game}</td>
                        <td>{this.props.store.EventsStore.eventWithChildern.type}</td>
                        <td>{`${TimeUtils.convertDateToHumanReadableNoTime(this.props.store.EventsStore.eventWithChildern.date)} at ${TimeUtils.convertTime(this.props.store.EventsStore.eventWithChildern.time)}`}</td>
                        <td className="actions"><a data-balloon-length="medium" data-balloon="View event details." data-balloon-pos="up" onClick={this.showModalFor.bind(this, this.props.store.EventsStore.eventWithChildern.id)}><i className="fal fa-eye"></i></a>
                          <Link data-balloon-length="large" data-balloon="Update master event information." data-balloon-pos="up" to={`/events/update/${this.props.store.EventsStore.eventWithChildern.id}`} onClick={this.setEventInStore.bind(this, this.props.store.EventsStore.eventWithChildern)}><i className="fal fa-edit"></i></Link>
                          <a data-balloon-length="large" data-balloon="Delete the event including recurring events." data-balloon-pos="up" onClick={this.showDeleteModalFor.bind(this, this.props.store.EventsStore.eventWithChildern.id)}><i className="fal fa-trash-alt"></i></a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <p className="subHeading">Children Events</p>

                <div className="advanced_list tiled events">
                  <div className="row">
                    {this.props.store.EventsStore.eventWithChildern.childEvents.map((event, i) => {
                      return (
                        <div className="col-lg-4 col-md-6 col-sm-12 has_avatar" key={i}>
                          <div className={"panel panel-default" + (event.isDeleting == true ? " disabled" : "") + (event.isDisabled ? " paused" : "")}>
                            <div className="topBlock">
                              <div className="view">
                                {event.recurring ?
                                  <Link data-balloon-length="large" data-balloon="View and edit event details." data-balloon-pos="up" to={`/events/view/${event.id}`}><i className="far fa-eye" aria-hidden="true"></i></Link>
                                  :
                                  <React.Fragment>
                                    <a data-balloon-length="medium" data-balloon="View event details." data-balloon-pos="up" className="userActions" onClick={this.showModalFor.bind(this, event.id)}><i className="far fa-eye" aria-hidden="true"></i></a>
                                  </React.Fragment>
                                }
                              </div>
                              <div className="eventImage"><img src={event.calendarIcon} /></div>
                              <div className="titleDiv">
                                <span className="eventTitle">{event.title}</span>
                                <span className="eventDate"><i className="far fa-calendar-alt"></i>{event.recurring ? `Recurring` : `${TimeUtils.convertDateToHumanReadableNoTime(event.date)}`}</span>
                                <span className="eventTime"><i className="far fa-clock"></i>{event.recurring ? `${TimeUtils.convertTime(event.time)}` : `${TimeUtils.convertTime(event.time)}`}</span>

                              </div>
                            </div>
                            <hr />
                            <div className="panel-body">
                              <div className="eventInfo">
                                {!event.isDisabled ?
                                  <React.Fragment>
                                    <div className="types">
                                      <em>Game Type:</em>{event.game}
                                    </div>
                                    <div className="types">
                                      <em>Event Type:</em>{event.type}
                                    </div>
                                    {event.recurring ?
                                      null
                                      :
                                      <React.Fragment>
                                        <div className="types">
                                          <em>Participants:</em><Link to={`/events/participants/${event.id}`}>View the entrants</Link>
                                        </div>
                                      </React.Fragment>
                                    }
                                  </React.Fragment>
                                  :
                                  <span className="childPaused">This child event is disabled</span>
                                }
                              </div>
                              <div className="editInfo">
                                <span className="edit">
                                  <React.Fragment>
                                    <Link to={`/events/update/${event.id}`} onClick={this.setEventInStore.bind(this, event)}><i className="fas fa-pencil-alt"></i> Edit</Link>
                                  </React.Fragment>
                                </span>
                                <span className="deleteEvent">
                                  <React.Fragment>
                                    {!event.isDisabled ?
                                      <a className="userActions" onClick={this.showDeleteModalFor.bind(this, event.id)}>Pause <i className="fa fa-pause pause" aria-hidden="true"></i></a>
                                      :
                                      <a className="userActions resume" onClick={this.showEnableModalFor.bind(this, event.id)}>Resume <i className="fa fa-play pause green" aria-hidden="true"></i></a>
                                    }
                                  </React.Fragment>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {this.modalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.modalEvent.title}</p>
                        <button className="delete" aria-label="close" onClick={() => this.setModalVisible(false)}></button>
                      </header>
                      <section className="modal-card-body">
                        {this.modalEvent ?
                          <div className="content">
                            {this.modalEvent.banner ?
                              <React.Fragment>
                                <h5>Background image</h5>
                                <img className="eventBanner" src={this.modalEvent.banner} />
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.calendarIcon ?
                              <React.Fragment>
                                <h5>Icon shown on calendar</h5>
                                <img className="eventImage" src={this.modalEvent.calendarIcon}></img>
                              </React.Fragment>
                              :
                              null
                            }
                            <h5>Game</h5>
                            <p>{this.modalEvent.game}</p>
                            <h5>Type</h5>
                            <p>{this.modalEvent.type}</p>
                            <h5>Date</h5>
                            <p>{TimeUtils.convertDateToHumanReadableNoTime(this.modalEvent.date)}</p>
                            <h5>Time</h5>
                            <p>{TimeUtils.convertTime(this.modalEvent.time)}</p>
                            {this.modalEvent.description ?
                              <React.Fragment>
                                <h5>Description</h5>
                                <div dangerouslySetInnerHTML={{ __html: this.modalEvent.description }}></div>
                                <br />
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.maxParticipants ?
                              <React.Fragment>
                                <h5>Max participants</h5>
                                <p>{this.modalEvent.maxParticipants}</p>
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.prizeStructure ?
                              <React.Fragment>
                                <h5>Prize structure</h5>
                                <div dangerouslySetInnerHTML={{ __html: this.modalEvent.prizeStructure }}></div>
                                <br />
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.participants && this.modalEvent.participants.length > 0 ?
                              <React.Fragment>
                                <h5>Current participants</h5>
                                <p>{this.commaSplitWithAnd(this.modalEvent.participants)}</p>
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.recurring ?
                              <React.Fragment>
                                <h5>Recurring details</h5>
                                <p>{this.modalRecurringText}</p>
                              </React.Fragment>
                              :
                              null
                            }
                            {this.modalEvent.isTicketed ?
                              <React.Fragment>
                                <h5>Ticket Price</h5>
                                <p>${this.modalEvent.ticketPrice}</p>
                                {this.modalEvent.banner ?
                                  <React.Fragment>
                                    <h5>Ticket image</h5>
                                    <img className="eventImage" src={this.modalEvent.ticketImage} />
                                  </React.Fragment>
                                  :
                                  null
                                }
                              </React.Fragment>
                              :
                              null
                            }
                          </div>
                          :
                          null
                        }
                      </section>
                      <footer className="modal-card-foot">
                        <button className="button is-success" onClick={() => this.setModalVisible(false)}>Close</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
                {this.deleteModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Are you sure?</p>
                        <button className="delete" aria-label="close" onClick={() => this.setDeleteModalVisible(false)}></button>
                      </header>
                      <section className="modal-card-body">
                        {this.deleteModalEvent.parentEventId ?
                          <React.Fragment>
                            <p>If you proceed with disabling this event: <strong>{this.deleteModalEvent.title}</strong>, customers will not be able to register for this event if you do so.</p>
                            <p>Are you sure you want to disable the event?</p>
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <p>If you proceed with deleting your event: <strong>{this.deleteModalEvent.title}</strong>, this event and all child events will be deleted and you will not be able to restore them.</p>
                            <p>Are you sure you want to delete the event?</p>
                          </React.Fragment>
                        }
                      </section>
                      <footer className="modal-card-foot">
                        <button className={`button half is-danger ${this.deleteModalLoading ? 'is-loading disabled' : ''}`} onClick={() => this.deleteEvent(this.deleteModalEvent.id)}>{this.deleteModalEvent.parentEventId ? `Disable` : `Delete`}</button>
                        <button className="button half is-text" onClick={() => this.setDeleteModalVisible(false)}>Cancel</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
                {this.enableModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Are you sure?</p>
                        <button className="delete" aria-label="close" onClick={() => this.setEnableModalVisible(false)}></button>
                      </header>
                      <section className="modal-card-body">
                        <p>Are you sure you want to enable the event <strong>{this.enableModalEvent.title}</strong>?</p>
                      </section>
                      <footer className="modal-card-foot">
                        <button className={`button half is-info ${this.enableModalLoading ? 'is-loading disabled' : ''}`} onClick={() => this.enableEvent(this.enableModalEvent.id)}>Enable</button>
                        <button className="button half is-text" onClick={() => this.setEnableModalVisible(false)}>Cancel</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
