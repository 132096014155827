import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { action } from "mobx";

@inject("posStore")
@observer
class CloseTillWarning extends Component {
  @action clearError = () => {
    this.props.posStore.errorMessage = "";
  };

  render() {
    const store = this.props.posStore;
    return (
      <div className={store.tillWarning ? "modal is-active" : "modal"}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Close till</p>
            <i onClick={this.closeModal} className="fal fa-times"></i>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <p> Are you sure you want to close the till?</p>
            </div>
          </section>
          <footer className="modal-card-foot closeTill">
            <button className={"button half is-info"} onClick={store.closeFloat}>
              <i className="fal fa-lock"></i> OK
            </button>
            <button
              className={"button half is-text"}
              onClick={store.closeTillWarning}
            >
              Cancel
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default CloseTillWarning;
