import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { BuylistMenuStructure } from 'menuStructures'
import { Loader } from 'components'
import Cookie from "mobx-cookie";
import { TimeUtils } from 'utils'

@inject('store')
@observer
export default class BuylistDetails extends React.Component {

  @observable cookie = new Cookie("buylistListView");

  @observable listView = this.cookie.value;
  @action setListView(value) {
    this.listView = value;
  }

  @observable noRedirect = false;
  @action setNoRedirect(bool) {
    this.noRedirect = bool;
  }

  @observable modalVisible = false;
  @action setModalVisible(bool) {
    this.modalVisible = bool;
    if (!bool) {
      this.noRedirect = bool;
    }
  }

  @observable modalTitle;
  @action setModalTitle(title) {
    this.modalTitle = title;
  }

  @observable modalText;
  @action setModalText(text) {
    this.modalText = text;
  }

  @observable buylistDetails;
  @action setBuylistDetails(buylistDetails) {
    this.buylistDetails = buylistDetails;
  }

  @observable overridePriceText;
  @action setOverridePriceText(text) {
    this.overridePriceText = text;
  }

  @observable overridePriceTitle;
  @action setOverridePriceTitle(title) {
    this.overridePriceTitle = title;
  }

  @observable overridePriceVisible;
  @action setOverridePriceVisible(bool) {
    this.overridePriceVisible = bool;
  }

  @observable buylistDetailToOverridePrice;
  @action setBuylistDetailToOverridePrice(buylistDetail) {
    this.buylistDetailToOverridePrice = buylistDetail;
  }

  @observable adjustText;
  @action setAdjustText(text) {
    this.adjustText = text;
  }

  @observable adjustModalTitle;
  @action setAdjustModalTitle(title) {
    this.adjustModalTitle = title;
  }

  @observable adjustCreditModalVisible;
  @action setAdjustCreditModalVisible(bool) {
    this.adjustCreditModalVisible = bool;
  }

  @observable buylistDetailToAdjust;
  @action setBuylistDetailToAdjust(buylistDetail) {
    this.buylistDetailToAdjust = buylistDetail;
  }

  @observable conditionModalText;
  @action setConditionModalText(text) {
    this.conditionModalText = text;
  }

  @observable conditionModalTitle;
  @action setConditionModalTitle(title) {
    this.conditionModalTitle = title;
  }

  @observable conditionModalVisible;
  @action setConditionModalVisible(bool) {
    this.conditionModalVisible = bool;
  }

  @observable conditionModalLoading;
  @action setConditionModalLoading(bool) {
    this.conditionModalLoading = bool;
  }

  @observable buylistDetailToChangeCondition;
  @action setBuylistDetailToChangeCondition(buylistDetail) {
    this.buylistDetailToChangeCondition = buylistDetail;
  }

  @observable declineModalTitle;
  @action setDeclineModalTitle(title) {
    this.declineModalTitle = title;
  }

  @observable declineModalVisible;
  @action setDeclineModalVisible(bool) {
    this.declineModalVisible = bool;
  }

  @observable declineModalLoading;
  @action setDeclineModalLoading(bool) {
    this.declineModalLoading = bool;
  }

  @observable buylistDetailToDecline;
  @action setBuylistDetailToDecline(buylistDetail) {
    this.buylistDetailToDecline = buylistDetail;
  }

  @observable approveModalLoading;
  @action setApproveModalLoading(bool) {
    this.approveModalLoading = bool;
  }

  @observable approveModalTitle;
  @action setApproveModalTitle(title) {
    this.approveModalTitle = title;
  }

  @observable approveModalVisible;
  @action setApproveModalVisible(bool) {
    this.approveModalVisible = bool;
  }

  @observable completeModalLoading;
  @action setCompleteModalLoading(bool) {
    this.completeModalLoading = bool;
  }

  @observable completeModalTitle;
  @action setCompleteModalTitle(title) {
    this.completeModalTitle = title;
  }

  @observable completeModalVisible;
  @action setCompleteModalVisible(bool) {
    this.completeModalVisible = bool;
  }

  @observable buylistDetailToApprove;
  @action setBuylistDetailToApprove(buylistDetail) {
    this.buylistDetailToApprove = buylistDetail;
  }

  @observable buylistDetailToComplete;
  @action setBuylistDetailToComplete(buylistDetail) {
    this.buylistDetailToComplete = buylistDetail;
  }

  @observable customerVariants;
  @action setCustomerVariants(customerVariants) {
    this.customerVariants = customerVariants;
  }

  @observable returnedBuylistCards;
  @action setReturnedBuylistCards(returnedBuylistCards) {
    this.returnedBuylistCards = returnedBuylistCards;
  }

  @observable searchModalVisible;
  @action setSearchModalVisible(bool) {
    this.searchModalVisible = bool;
  }

  @observable searchModalTitle;
  @action setSearchModalTitle(searchModalTitle) {
    this.searchModalTitle = searchModalTitle;
  }

  @observable searchModalLoading;
  @action setSearchModalLoading(bool) {
    this.searchModalLoading = bool;
  }

  @observable total;
  @action setTotal(total) {
    this.total = total;
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.quantityInput = React.createRef();
    this.cardSearch = React.createRef();
    this.gameSelect = React.createRef();
    this.overrideInput = React.createRef();
  }

  convertDateToHumanReadable(theDate) {
    console.log(theDate);
    const date = new Date(theDate);
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
    return date.toLocaleDateString() + " @ " + new Date(theDate).toLocaleTimeString();
  }

  componentWillMount() {
    document.title = 'Buylist details | BinderPOS';
    this.props.store.MainStore.getCurrency();
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);
    this.setModalVisible(false);
    this.setModalTitle("");
    this.setModalText("");
    const buylistId = (this.props.match ?.params ?.buylistId || null);
    this.props.store.CustomersStore.getCustomerVariants()
      .then((result) => {
        this.props.store.CustomersStore.setCustomerVariants(result);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });

    this.props.store.CardStore.fetchCardGames()
      .then((result) => {
        this.props.store.CardStore.setGames(result);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      });

    if (buylistId && buylistId > 0) {
      this.props.store.BuylistRulesStore.setBuylistDetails(null);
      this.props.store.BuylistRulesStore.fetchBuylistDetails(buylistId)
        .then((result) => {
          this.props.store.BuylistRulesStore.setBuylistDetails(result);
          this.calculateTotal();
        })
        .catch(error => {
          this.props.store.MainStore.setError(error);
        })
    }

  }

  @action changeQuantity(buylistDetail) {
    var quantity = this.quantityInput.current.value;
    var detail = this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.filter(shopifyCustomerBuylistDetail => {
      return shopifyCustomerBuylistDetail.id == buylistDetail.id;
    });
    if (detail[0] != null) {
      detail[0].quantity = quantity;
      var price = detail[0].storeCreditBuyPrice;
      if (this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash") {
        price = detail[0].cashBuyPrice;
      }
      detail[0].totalPrice = parseFloat(parseFloat(price) * quantity).toFixed(2);
    }
    this.calculateTotal();
    this.setBuylistDetailToAdjust(null);
    this.setAdjustModalTitle("");
    this.setAdjustText("");
    this.setAdjustCreditModalVisible(false);
  }

  @action removeLine(buylistDetail) {
    var details = this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.filter(shopifyCustomerBuylistDetail => {
      return shopifyCustomerBuylistDetail.id != buylistDetail.id;
    });
    this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails = details;
    this.calculateTotal();
  }

  showConditionModal(buylistDetail) {
    this.setBuylistDetailToChangeCondition(buylistDetail);
    this.setConditionModalTitle("Update condition")
    console.log(JSON.stringify(buylistDetail));
    this.setConditionModalText("Update the condition for " + buylistDetail.cardName + " [" + buylistDetail.setName + "] - " + buylistDetail.type + " . Current condition is: " + buylistDetail.variantName);
    this.setConditionModalVisible(true);
  }

  showAdjustModal(buylistDetail) {
    this.setBuylistDetailToAdjust(buylistDetail);
    this.setAdjustModalTitle("Update quantity");
    this.setAdjustText("Update the quantity for " + buylistDetail.cardName + " [" + buylistDetail.setName + "] - " + buylistDetail.type + ". Current quantity is: " + buylistDetail.quantity);
    this.setAdjustCreditModalVisible(true);
  }

  showOverridePriceModal(buylistDetail) {
    this.setOverridePriceText("Override price for " + buylistDetail.cardName + " [" + buylistDetail.setName + "] - " + buylistDetail.type);
    this.setOverridePriceTitle("Override price");
    this.setBuylistDetailToOverridePrice(buylistDetail);
    this.setOverridePriceVisible(true);
  }

  @action overridePriceForBuylistItem() {
    var details = this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.filter(shopifyCustomerBuylistDetail => {
      return shopifyCustomerBuylistDetail.id != this.buylistDetailToOverridePrice.id;
    });
    this.buylistDetailToOverridePrice.cashBuyPrice = this.overrideInput.current.value;
    this.buylistDetailToOverridePrice.storeCreditBuyPrice = this.overrideInput.current.value;
    var price = this.buylistDetailToOverridePrice.storeCreditBuyPrice;
    if (this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash") {
      price = this.buylistDetailToOverridePrice.cashBuyPrice;
    }
    this.buylistDetailToOverridePrice.totalPrice = parseFloat(parseFloat(price) * this.buylistDetailToOverridePrice.quantity).toFixed(2);
    this.calculateTotal();
    this.setOverridePriceVisible(false);
    this.setBuylistDetailToOverridePrice(null);
  }

  showDeclineModal(buylistDetail) {
    this.setBuylistDetailToDecline(buylistDetail);
    this.setDeclineModalTitle("Decline buylist request");
    this.setDeclineModalVisible(true);
  }

  fetchPricingForCard() {
    this.setConditionModalLoading(true);
    console.log("TO CHANGE CONDITION:", this.buylistDetailToChangeCondition);
    var buylistDetail = JSON.parse(JSON.stringify(this.buylistDetailToChangeCondition));
    console.log("Buylist details: ", buylistDetail);
    this.props.store.BuylistRulesStore.fetchCardBuylistDetails(this.buylistDetailToChangeCondition.game, encodeURIComponent(this.buylistDetailToChangeCondition.setName), encodeURIComponent(this.buylistDetailToChangeCondition.cardName))
      .then((result) => {
        var resultToProcess = result.filter(toProcess => {
          return toProcess.id == this.buylistDetailToChangeCondition.cardId;
        })
        if (resultToProcess && resultToProcess.length > 0) {
          var variantInfo = resultToProcess[0].variants.filter(variant => {
            return this.form.current.elements.variantId[this.form.current.elements.variantId.selectedIndex].value == variant.id;
          });
          var cardType = variantInfo[0].cardBuylistTypes.filter(cardTypeToCheck => {
            return this.buylistDetailToChangeCondition.type == cardTypeToCheck.type;
          })
          buylistDetail.type = cardType[0].type;
          buylistDetail.cashBuyPrice = cardType[0].buyPrice;
          buylistDetail.storeCreditBuyPrice = cardType[0].creditBuyPrice;
          buylistDetail.storeSellPrice = cardType[0].storeSellPrice;
          buylistDetail.variantId = variantInfo[0].id;
          buylistDetail.variantName = variantInfo[0].variantName;
          var price = buylistDetail.storeCreditBuyPrice;
          if (this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash") {
            price = buylistDetail.cashBuyPrice;
          }
          if (price && price > 0) {
            buylistDetail.totalPrice = parseFloat(parseFloat(price) * buylistDetail.quantity).toFixed(2);
          } else {
            buylistDetail.totalPrice = parseFloat(0).toFixed(2);
          }
          this.setBuylistDetailToChangeCondition(buylistDetail);
        }
        this.setConditionModalLoading(false);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      })
  }

  fetchSetsForSelectedItem() {

  }

  @action updateCondition(e) {
    e.preventDefault();
    this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.forEach(card => {
      console.log('----------------')
      console.log(this.buylistDetailToChangeCondition.setName);
      console.log(this.buylistDetailToChangeCondition.variantName);
      console.log(this.buylistDetailToChangeCondition.variantId);
      console.log(this.buylistDetailToChangeCondition.cardName);
      console.log(this.buylistDetailToChangeCondition.type);
      console.log('----------------')
      console.log(card.setName);
      console.log(card.variantName);
      console.log(card.variantId);
      console.log(card.cardName);
      console.log(card.type);
      console.log('----------------')
    })
    var matchingCard = this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.filter(shopifyCustomerBuylistDetail => {
      return (shopifyCustomerBuylistDetail.cardName == this.buylistDetailToChangeCondition.cardName && shopifyCustomerBuylistDetail.setName == this.buylistDetailToChangeCondition.setName
        && shopifyCustomerBuylistDetail.variantName == this.buylistDetailToChangeCondition.variantName && shopifyCustomerBuylistDetail.variantId == this.buylistDetailToChangeCondition.variantId
        && shopifyCustomerBuylistDetail.type == this.buylistDetailToChangeCondition.type);
    });
    console.log("Matching Card: ", JSON.stringify(matchingCard));
    if (matchingCard && matchingCard.length > 0) {
      console.log("SOME THING HERE");
      matchingCard[0].quantity = (parseInt(matchingCard[0].quantity) + (parseInt(this.buylistDetailToChangeCondition.quantity)));
      var price = matchingCard[0].storeCreditBuyPrice;
      if (this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash") {
        price = matchingCard[0].cashBuyPrice;
      }
      matchingCard[0].totalPrice = parseFloat(parseFloat(price) * matchingCard[0].quantity).toFixed(2);
      var details = this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.filter(shopifyCustomerBuylistDetail => {
        return shopifyCustomerBuylistDetail.id != this.buylistDetailToChangeCondition.id;
      });
      console.log(details);
      this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails = details;
    } else {
      var details = this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.filter(shopifyCustomerBuylistDetail => {
        return shopifyCustomerBuylistDetail.id == this.buylistDetailToChangeCondition.id;
      });
      var index = this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.findIndex(obj => obj.id == details[0].id);
      this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails[index] = this.buylistDetailToChangeCondition;

      console.log(JSON.stringify(this.buylistDetailToChangeCondition));
      // details.push(this.buylistDetailToChangeCondition);
      // this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails = details;
    }
    this.calculateTotal();
    this.setBuylistDetailToChangeCondition(null);
    this.setConditionModalTitle(null)
    this.setConditionModalText(null);
    this.setConditionModalVisible(false);
  }

  declineBuylist(e) {
    e.preventDefault()
    this.setDeclineModalLoading(true);
    var buylistDetail = JSON.parse(JSON.stringify(this.buylistDetailToDecline));
    buylistDetail.declinedReason = this.form.current.elements.declineReason.value;

    this.props.store.BuylistRulesStore.declineBuylist(buylistDetail)
      .then(() => {
        this.props.store.BuylistRulesStore.rehydratePendingBuylists();
        this.setDeclineModalVisible(false);
        this.setBuylistDetailToAdjust(null);
        this.setDeclineModalLoading(false);
        this.setModalTitle("Buylist declined");
        this.setModalText("You have declined the buylist from " + buylistDetail.shopifyCustomer.firstName + " " + buylistDetail.shopifyCustomer.lastName);
        this.setModalVisible(true);
      }).catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  showApproveModal(buylistDetail) {
    this.setBuylistDetailToApprove(buylistDetail);
    this.setApproveModalTitle("Approve buylist request");
    this.setApproveModalVisible(true);
  }

  showCompleteModal(buylistDetail) {
    this.setBuylistDetailToComplete(buylistDetail);
    this.setCompleteModalTitle("Complete buylist request");
    this.setCompleteModalVisible(true);
  }

  saveChanges(buylistDetail) {
    this.setNoRedirect(true);
    this.props.store.BuylistRulesStore.saveBuylist(buylistDetail)
      .then(() => {
        this.setModalTitle("Buylist changes saved!");
        this.setModalText("The changes you have made to the buylist have been saved!");
        this.setModalVisible(true);
      }).catch(error => {
        this.props.store.MainStore.setError(error);
        this.setNoRedirect(false);
      });
  }

  approveBuylist(e) {
    e.preventDefault();
    this.setApproveModalLoading(true);
    var buylistDetail = JSON.parse(JSON.stringify(this.buylistDetailToApprove));
    buylistDetail.approvedNotes = this.form.current.elements.approveReason.value;
    buylistDetail.pushProducts = this.form.current.elements.pushStock.checked;
    if (this.form.current.elements.applyStoreCredit) {
      buylistDetail.applyStoreCredit = this.form.current.elements.applyStoreCredit.checked;
    }

    if (buylistDetail.pushProducts === true && buylistDetail.applyStoreCredit === true) {
      this.props.store.BuylistRulesStore.completeBuylist(buylistDetail)
        .then(() => {
          this.props.store.BuylistRulesStore.rehydratePendingBuylists();
          this.setApproveModalVisible(false);
          this.setApproveModalLoading(false);
          this.setModalTitle("Buylist Approved and Completed!");
          this.setModalText("You have approved and completed the buylist from " + buylistDetail.shopifyCustomer.firstName + " " + buylistDetail.shopifyCustomer.lastName);
          this.setModalVisible(true);
        }).catch(error => {
          this.props.store.MainStore.setError(error);
        });
    } else {
      this.props.store.BuylistRulesStore.approveBuylist(buylistDetail)
        .then(() => {
          this.props.store.BuylistRulesStore.rehydratePendingBuylists();
          this.setApproveModalVisible(false);
          this.setApproveModalLoading(false);
          this.setModalTitle("Buylist Approved!");
          this.setModalText("You have approved the buylist from " + buylistDetail.shopifyCustomer.firstName + " " + buylistDetail.shopifyCustomer.lastName);
          this.setModalVisible(true);
        }).catch(error => {
          this.props.store.MainStore.setError(error);
        });
    }

  }

  completeBuylist(e) {
    e.preventDefault();
    this.setApproveModalLoading(true);
    var buylistDetail = JSON.parse(JSON.stringify(this.buylistDetailToComplete));
    buylistDetail.approvedNotes = this.form.current.elements.approveReason.value;
    buylistDetail.pushProducts = this.form.current.elements.pushStock.checked;
    if (this.form.current.elements.applyStoreCredit) {
      buylistDetail.applyStoreCredit = this.form.current.elements.applyStoreCredit.checked;
    }

    this.props.store.BuylistRulesStore.completeBuylist(buylistDetail)
      .then(() => {
        this.props.store.BuylistRulesStore.rehydrateApprovedBuylists();
        this.setApproveModalVisible(false);
        this.setApproveModalLoading(false);
        this.setModalTitle("Buylist Completed!");
        this.setModalText("You have completed the buylist from " + buylistDetail.shopifyCustomer.firstName + " " + buylistDetail.shopifyCustomer.lastName);
        this.setModalVisible(true);
      }).catch(error => {
        this.props.store.MainStore.setError(error);
      });
  }

  showSearchModal() {
    this.setSearchModalLoading(false);
    this.setSearchModalTitle("Search for card to add to the list");
    this.setSearchModalVisible(true);
  }

  handleEnterSearch(e) {
    console.log(e);
    if (e.key === "Enter") {
      this.searchForBuylistCards();
    }
  }

  searchForBuylistCards() {
    this.setSearchModalLoading(true);
    this.props.store.BuylistRulesStore.fetchCardBuylistDetails(this.gameSelect.current[this.gameSelect.current.selectedIndex].value, "", this.cardSearch.current.value)
      .then((results) => {
        var resultsWithConditions = [];
        results.forEach(result => {
          result.variants.forEach(variant => {
            console.log("Variants", variant);
            variant.cardBuylistTypes.forEach(buylistType => {
              var obj = {
                id: Math.floor((Math.random() * 10000000000000000) + 1),
                cardName: result.cardName,
                setName: result.setName,
                cardId: result.id,
                game: result.game,
                type: buylistType.type,
                storeSellPrice: buylistType.storeSellPrice,
                imageUrl: result.imageUrl,
                cashBuyPrice: buylistType.buyPrice,
                storeCreditBuyPrice: buylistType.creditBuyPrice,
                quantity: 1,
                variantId: variant.id,
                variantName: variant.variantName,
                shopifyCustomerBuylistId: this.props.store.BuylistRulesStore.buylistDetails.id,
              };
              resultsWithConditions.push(obj);
            });
          })
        })
        this.setReturnedBuylistCards(resultsWithConditions);
        this.setSearchModalLoading(false);
      })
  }

  @action addCardToList(buylistCard) {
    var found = false;
    this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.forEach(existing => {
      if (buylistCard.cardName == existing.cardName && buylistCard.setName == existing.setName
        && buylistCard.variantName == existing.variantName && buylistCard.variantId == existing.variantId
        && buylistCard.type == existing.type) {
        var thePrice = existing.storeCreditBuyPrice;
        if (this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash") {
          thePrice = existing.cashBuyPrice;
        }
        if (thePrice && thePrice > 0) {
          existing.totalPrice = parseFloat(parseFloat(thePrice) * existing.quantity).toFixed(2);
        } else {
          existing.totalPrice = parseFloat(0).toFixed(2);
        }
        existing.quantity = existing.quantity + 1;
        found = true;
      }
    })
    if (!found) {
      var price = buylistCard.storeCreditBuyPrice;
      if (this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash") {
        price = buylistCard.cashBuyPrice;
      }
      if (price && price > 0) {
        buylistCard.totalPrice = parseFloat(parseFloat(price) * buylistCard.quantity).toFixed(2);
      } else {
        buylistCard.totalPrice = parseFloat(0).toFixed(2);
      }
      this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.push(buylistCard);
    }
    this.setSearchModalVisible(false);
    this.calculateTotal();
  }

  calculateTotal() {
    var list = this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails;
    if (this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails && this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails.length > 0) {
      list = this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails;
    }
    console.log(list);
    var localTotal = 0;
    if (this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash") {
      list.forEach(item => {
        if (item.cashBuyPrice && item.cashBuyPrice > 0) {
          localTotal = parseFloat(parseFloat(localTotal) + (parseFloat(item.cashBuyPrice) * parseFloat(item.quantity))).toFixed(2);
        }
      })
    } else {
      list.forEach(item => {
        if (item.storeCreditBuyPrice && item.storeCreditBuyPrice > 0) {
          localTotal = parseFloat(parseFloat(localTotal) + (parseFloat(item.storeCreditBuyPrice) * parseFloat(item.quantity))).toFixed(2);
        }
      })
    }
    this.setTotal(localTotal);
  }

  changeListView() {
    this.cookie.set("enabled", { expires: 365 });
    this.setListView(this.cookie.value);
  }

  changeGridView() {
    this.cookie.set("disabled", { expires: 365 });
    this.setListView(this.cookie.value);
  }


  render() {
    const fC = this.props.store.MainStore.currencyBuilder;
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.buylist ?
          <React.Fragment>
            {this.props.store.BuylistRulesStore.buylistDetails && this.props.store.CardStore.games ?
              <React.Fragment>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon orange"><i className="fal fa-shopping-cart"></i></span>
                    <span className="header-text">{this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails && this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails.length > 0 ? "Viewing Completed buylist" : "Review Submitted Buylist"}</span>
                    {this.props.store.BuylistRulesStore.buylistDetails.completed === true ?
                      <Link to="/buylists/completed" className="button is-link rightAlign">Done</Link>
                      :
                      <React.Fragment>
                        {this.props.store.BuylistRulesStore.buylistDetails.approved === true ?
                          <React.Fragment>
                            <button className="button is-success rightAlign" onClick={this.showCompleteModal.bind(this, this.props.store.BuylistRulesStore.buylistDetails)}>Complete</button>
                            &nbsp;
                            <button className="button is-danger rightAlign" onClick={this.showDeclineModal.bind(this, this.props.store.BuylistRulesStore.buylistDetails)}>Decline</button>
                            &nbsp;
                            <Link to="/buylists/pending" className="button cancel rightAlign">Cancel</Link>
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <button className="button is-success rightAlign" onClick={this.showApproveModal.bind(this, this.props.store.BuylistRulesStore.buylistDetails)}>Approve</button>
                            &nbsp;
                            <button className="button is-danger rightAlign" onClick={this.showDeclineModal.bind(this, this.props.store.BuylistRulesStore.buylistDetails)}>Decline</button>
                            &nbsp;
                            <Link to="/buylists/pending" className="button cancel rightAlign">Cancel</Link>
                          </React.Fragment>
                        }
                      </React.Fragment>

                    }
                  </h2>
                </div>
                <br />
                <div className="customer_list reviewBg">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="2" className={this.props.store.BuylistRulesStore.buylistDetails.completed === true ? "col6 eventTitle" : "col1 eventTitle"}>Submitted by</th>
                        <th className={this.props.store.BuylistRulesStore.buylistDetails.completed === true ? "col6" : "col1"}>Email</th>
                        <th className={this.props.store.BuylistRulesStore.buylistDetails.completed === true ? "storeCredit col3" : "storeCredit col2"}>Date submitted</th>
                        <th className={this.props.store.BuylistRulesStore.buylistDetails.completed === true ? "col4 mobileHide" : "col2 mobileHide"}>Payment type</th>
                        {
                          this.props.store.BuylistRulesStore.buylistDetails.completed === true ?
                            <React.Fragment>
                              <th className="col3">Date completed</th>
                              <th className="col5">Approved</th>
                            </React.Fragment>
                            :
                            null
                        }
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="initials eventTitle1">
                          <div>{this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.firstName && this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.firstName.length > 0 ?
                            <React.Fragment>

                              {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.firstName.charAt(0)}
                              {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.lastName && this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.lastName.length > 0 ?
                                <React.Fragment>
                                  {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.lastName.charAt(0)}
                                </React.Fragment>
                                :
                                null
                              }
                            </React.Fragment>
                            :
                            <React.Fragment>
                              {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.email && this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.email.length > 0 ?
                                <React.Fragment>
                                  {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.email.charAt(0)}
                                </React.Fragment>
                                :
                                null
                              }
                            </React.Fragment>
                          }
                          </div>
                        </td>
                        <td className="name">
                          {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.firstName} {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.lastName}
                        </td>
                        <td>
                          {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomer.email}
                        </td>
                        <td>
                          {TimeUtils.convertDateToHumanReadable(this.props.store.BuylistRulesStore.buylistDetails.readableSubmittedDate)}
                        </td>
                        <td className="mobileHide">
                          {this.props.store.BuylistRulesStore.buylistDetails.paymentType}
                        </td>
                        {
                          this.props.store.BuylistRulesStore.buylistDetails.completed === true ?
                            <React.Fragment>
                              <td>{TimeUtils.convertDateToHumanReadable(this.props.store.BuylistRulesStore.buylistDetails.readableCompletedDate)}</td>
                              <td>{this.props.store.BuylistRulesStore.buylistDetails.approved ? "Yes" : "No"}</td>
                            </React.Fragment>
                            :
                            null
                        }
                      </tr>
                      <tr>
                        <td className="eventTitle1" colSpan="3"><strong className="mobileShow">Payment Type:</strong><p className="reason mobileShow">{this.props.store.BuylistRulesStore.buylistDetails.paymentType}</p></td>
                      </tr>
                      {
                        this.props.store.BuylistRulesStore.buylistDetails.completed === true ?
                          <React.Fragment>
                            {this.props.store.BuylistRulesStore.buylistDetails.approved === false ?
                              <React.Fragment>
                                <tr>
                                  <td className="eventTitle1" colSpan="3"><strong>Reason for declining:</strong><p className="reason">{this.props.store.BuylistRulesStore.buylistDetails.declinedReason && this.props.store.BuylistRulesStore.buylistDetails.declinedReason != "" ? this.props.store.BuylistRulesStore.buylistDetails.declinedReason : "There was no reason provided for declining this buylist"}</p></td>
                                </tr>
                              </React.Fragment>
                              :
                              <React.Fragment>
                                <tr>
                                  <td className="eventTitle1" colSpan="3"><strong>Approval note:</strong> <p className="reason">{this.props.store.BuylistRulesStore.buylistDetails.approvedNotes && this.props.store.BuylistRulesStore.buylistDetails.approvedNotes != "" ? this.props.store.BuylistRulesStore.buylistDetails.approvedNotes : "No approval notes provided"}</p></td>
                                </tr>
                              </React.Fragment>
                            }
                          </React.Fragment>
                          :
                          null
                      }
                      <tr>
                        <td className="eventTitle1" colSpan="3"><strong>Buylist value:</strong><p className="reason">{fC(parseFloat(this.total))}</p></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <div className="viewChange">
                  <p>View</p>
                  <i className={this.cookie.value == "disabled" ? "fas fa-th active" : "fas fa-th"} onClick={() => this.changeGridView()}></i>
                  <i className={this.cookie.value == "enabled" ? "fas fa-th-list active" : "fas fa-th-list"} onClick={() => this.changeListView()}></i>
                </div>
                {this.listView == "enabled" ?
                  <div className="col-md-12 buylistList">
                    <div className="row">
                      {
                        this.props.store.BuylistRulesStore.buylistDetails.completed === true ?
                          <React.Fragment>
                            {
                              this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails && this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails.length > 0 ?
                                <React.Fragment>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>Qty</th>
                                        <th>Card Name</th>
                                        <th>Set</th>
                                        <th>Condition</th>
                                        <th>Buy Price</th>
                                        <th>Sell Price</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails.map((buylistDetail, i) => {
                                        return (
                                          <tr key={i}>
                                            <td><img src={buylistDetail.imageUrl} /></td>
                                            <td>{buylistDetail.quantity}</td>
                                            <td>{`${buylistDetail.cardName} ${buylistDetail.type ? '- ' + buylistDetail.type : ''}`}</td>
                                            <td>{buylistDetail.setName}</td>
                                            <td>{buylistDetail.variantName}</td>
                                            <td>{buylistDetail.totalPrice && buylistDetail.totalPrice > 0 ?
                                              fC(parseFloat(buylistDetail.totalPrice))
                                              :
                                              fC(parseFloat(0.00))
                                            }
                                            </td>
                                            {buylistDetail.storeSellPrice && buylistDetail.storeSellPrice > 0 ?
                                              <td>{
                                                fC(parseFloat(buylistDetail.storeSellPrice))
                                              }
                                              </td> : <td>N/A</td>}
                                          </tr>
                                        )
                                      })
                                      }
                                    </tbody>
                                  </table>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>Qty</th>
                                        <th>Card Name</th>
                                        <th>Set</th>
                                        <th>Condition</th>
                                        <th>Buy Price</th>
                                        <th>Sell Price</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.map((buylistDetail, i) => {
                                        return (
                                          <tr key={i}>
                                            <td><img src={buylistDetail.imageUrl} /></td>
                                            <td>{buylistDetail.quantity}</td>
                                            <td>{`${buylistDetail.cardName} ${buylistDetail.type ? '- ' + buylistDetail.type : ''}`}</td>
                                            <td>{buylistDetail.setName}</td>
                                            <td>{buylistDetail.variantName}</td>
                                            <td>{buylistDetail.totalPrice && buylistDetail.totalPrice > 0 ?
                                              fC(parseFloat(buylistDetail.totalPrice))
                                              :
                                              fC(parseFloat(0.00))
                                            }
                                            </td>
                                            <td>{buylistDetail.totalPrice && buylistDetail.totalPrice > 0 ?
                                              fC(parseFloat(buylistDetail.totalPrice))
                                              :
                                              fC(parseFloat(0.00))
                                            }
                                            </td>
                                          </tr>
                                        )
                                      })
                                      }
                                    </tbody>
                                  </table>
                                </React.Fragment>
                            }
                          </React.Fragment>

                          :
                          <React.Fragment>
                            <table>
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Qty</th>
                                  <th>Card Name</th>
                                  <th>Set</th>
                                  <th>Condition</th>
                                  <th>Buy Price</th>
                                  <th>Sell Price</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.map((buylistDetail, i) => {
                                  return (
                                    <tr key={i}>
                                      <td><img src={buylistDetail.imageUrl} /></td>
                                      <td>{buylistDetail.quantity} <i onClick={this.showAdjustModal.bind(this, buylistDetail)} className="fas fa-pencil-alt"></i></td>
                                      <td>{`${buylistDetail.cardName} ${buylistDetail.type ? '- ' + buylistDetail.type : ''}`}</td>
                                      <td>{buylistDetail.setName}</td>
                                      <td>{buylistDetail.variantName} <i onClick={this.showConditionModal.bind(this, buylistDetail)} className="fas fa-pencil-alt"></i></td>
                                      <td>{buylistDetail.totalPrice && buylistDetail.totalPrice > 0 ?
                                        fC(parseFloat(buylistDetail.totalPrice))
                                        :
                                        fC(parseFloat(0.00))
                                      } <i onClick={this.showOverridePriceModal.bind(this, buylistDetail)} className="fas fa-pencil-alt"></i>
                                      </td>
                                      {buylistDetail.storeSellPrice && buylistDetail.storeSellPrice > 0 ?
                                        <td>{
                                          fC(parseFloat(buylistDetail.storeSellPrice * parseInt(buylistDetail.quantity))) + " ( " + fC(parseFloat(buylistDetail.storeSellPrice)) + " )"
                                        }
                                        </td> : <td>N/A</td>}
                                      <td>
                                        <i className="far fa-trash-alt" onClick={this.removeLine.bind(this, buylistDetail)}></i>
                                      </td>
                                    </tr>
                                  )
                                })
                                }
                              </tbody>
                            </table>
                          </React.Fragment>
                      }
                      {
                        this.props.store.BuylistRulesStore.buylistDetails.completed !== true ?
                          <React.Fragment>
                            <div className="col-md-4">
                              <div className="tileCard addCard" onClick={this.showSearchModal.bind(this)}>
                                <p><i className="fal fa-plus"></i> Add a Card</p>
                              </div>
                            </div>
                          </React.Fragment>
                          :
                          null
                      }
                    </div>
                  </div>
                  :
                  <div className="col-md-12 buylistItem">
                    <div className="row">
                      {
                        this.props.store.BuylistRulesStore.buylistDetails.completed === true ?
                          <React.Fragment>
                            {
                              this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails && this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails.length > 0 ?
                                <React.Fragment>
                                  {this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails.map((buylistDetail, i) => {
                                    return (
                                      <div className="col-md-4" key={i}>
                                        <div className="tileCard">
                                          <div className="topBlock">
                                            <div className="cardImg">
                                              <img src={buylistDetail.imageUrl} />
                                            </div>
                                            <div className="titleInfo">
                                              <span className="cardName">{`${buylistDetail.cardName} ${buylistDetail.type ? '- ' + buylistDetail.type : ''}`}</span>
                                              <span className="cartSet">{buylistDetail.setName}</span>
                                              <span className="cardPrice">{this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash" ? `${buylistDetail.cashBuyPrice && buylistDetail.cashBuyPrice > 0 ? fC(parseFloat(buylistDetail.cashBuyPrice)) : fC(parseFloat(0.00))}` : `${buylistDetail.storeCreditBuyPrice && buylistDetail.storeCreditBuyPrice > 0 ? fC(parseFloat(buylistDetail.storeCreditBuyPrice)) : fC(parseFloat(0.00))}`}</span>
                                            </div>
                                          </div>
                                          <div className="cardInfo">
                                            <span>Condition <em>{buylistDetail.variantName}</em></span>
                                            <span>Quantity <em>{buylistDetail.quantity}</em></span>
                                            <span>Total Price <em>{buylistDetail.totalPrice && buylistDetail.totalPrice > 0 ?
                                              fC(parseFloat(buylistDetail.totalPrice))
                                              :
                                              fC(parseFloat(0.00))
                                            }</em>
                                            </span>
                                            {buylistDetail.storeSellPrice && buylistDetail.storeSellPrice > 0 ? <span>Sell Price (per Item)<em>{
                                              fC(parseFloat(buylistDetail.storeSellPrice))
                                            }</em>
                                            </span> : null}

                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })
                                  }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.map((buylistDetail, i) => {
                                    return (
                                      <div className="col-md-4" key={i}>
                                        <div className="tileCard">
                                          <div className="topBlock">
                                            <div className="cardImg">
                                              <img src={buylistDetail.imageUrl} />
                                            </div>
                                            <div className="titleInfo">
                                              <span className="cardName">{`${buylistDetail.cardName} ${buylistDetail.type ? '- ' + buylistDetail.type : ''}`}</span>
                                              <span className="cartSet">{buylistDetail.setName}</span>
                                              <span className="cardPrice">{this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash" ? `${buylistDetail.cashBuyPrice && buylistDetail.cashBuyPrice > 0 ? fC(parseFloat(buylistDetail.cashBuyPrice)) : fC(parseFloat(0.00))}` : `${buylistDetail.storeCreditBuyPrice && buylistDetail.storeCreditBuyPrice > 0 ? fC(parseFloat(buylistDetail.storeCreditBuyPrice)) : fC(parseFloat(0.00))}`}</span>
                                            </div>
                                          </div>
                                          <div className="cardInfo">
                                            <span>Condition <em>{buylistDetail.variantName}</em></span>
                                            <span>Quantity <em>{buylistDetail.quantity}</em></span>
                                            <span>Total Price <em>{buylistDetail.totalPrice && buylistDetail.totalPrice > 0 ?
                                              fC(parseFloat(buylistDetail.totalPrice))
                                              :
                                              fC(parseFloat(0.00))
                                            }</em>
                                            </span>
                                            {buylistDetail.storeSellPrice && buylistDetail.storeSellPrice > 0 ?
                                              <span>Sell Price (per Item)<em>{
                                                fC(parseFloat(buylistDetail.storeSellPrice * parseInt(buylistDetail.quantity))) + " ( " + fC(parseFloat(buylistDetail.storeSellPrice)) + " )"
                                              }</em>
                                              </span> : null}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })
                                  }
                                </React.Fragment>
                            }
                          </React.Fragment>

                          :
                          <React.Fragment>
                            {this.props.store.BuylistRulesStore.buylistDetails.shopifyCustomerBuylistDetails.map((buylistDetail, i) => {
                              return (
                                <div className="col-md-4" key={i}>
                                  <div className="tileCard">
                                    <div className="extra">
                                      <div className="dropdown is-hoverable">
                                        <div className="dropdown-trigger">
                                          <span className="icon is-small">
                                            <i className="fal fa-ellipsis-v"></i>
                                          </span>
                                        </div>
                                        <div className="dropdown-menu" id="dropdown-menu-actions" role="menu">
                                          <div className="dropdown-content">
                                            <a className="dropdown-item" onClick={this.showOverridePriceModal.bind(this, buylistDetail)}>Override price</a>
                                            <a className="dropdown-item" onClick={this.showConditionModal.bind(this, buylistDetail)}>Change condition</a>
                                            <a className="dropdown-item" onClick={this.showAdjustModal.bind(this, buylistDetail)}>Update quantity</a>
                                            <a className="dropdown-item" onClick={this.removeLine.bind(this, buylistDetail)}>Remove</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="topBlock">
                                      <div className="cardImg">
                                        <img src={buylistDetail.imageUrl} />
                                      </div>
                                      <div className="titleInfo">
                                        <span className="cardName">{`${buylistDetail.cardName} ${buylistDetail.type ? '- ' + buylistDetail.type : ''}`}</span>
                                        <span className="cartSet">{buylistDetail.setName}</span>
                                        <span className="cardPrice">{this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash" ? `${buylistDetail.cashBuyPrice && buylistDetail.cashBuyPrice > 0 ? fC(parseFloat(buylistDetail.cashBuyPrice)) : fC(parseFloat(0.00))}` : `${buylistDetail.storeCreditBuyPrice && buylistDetail.storeCreditBuyPrice > 0 ? fC(parseFloat(buylistDetail.storeCreditBuyPrice)) : fC(parseFloat(0.00))}`}</span>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="cardInfo">
                                      <span>Condition <em>{buylistDetail.variantName}</em></span>
                                      <span>Quantity <em>{buylistDetail.quantity}</em></span>
                                      <span>Total Price <em>{buylistDetail.totalPrice && buylistDetail.totalPrice > 0 ?
                                        fC(parseFloat(buylistDetail.totalPrice))
                                        :
                                        fC(parseFloat(0.00))
                                      }</em>
                                      </span>
                                      {buylistDetail.storeSellPrice && buylistDetail.storeSellPrice > 0 ?
                                        <span>Sell Price (per Item)<em>{
                                          fC(parseFloat(buylistDetail.storeSellPrice * parseInt(buylistDetail.quantity))) + " ( " + fC(parseFloat(buylistDetail.storeSellPrice)) + " )"
                                        }</em>
                                        </span> : null}

                                      <span className="edit" onClick={this.showConditionModal.bind(this, buylistDetail)}><i className="fas fa-pencil-alt"></i> Edit</span>
                                      <span className="remove" onClick={this.removeLine.bind(this, buylistDetail)}>Remove <i className="fas fa-times-circle"></i></span>
                                    </div>

                                  </div>
                                </div>
                              )
                            })
                            }
                          </React.Fragment>
                      }
                      {
                        this.props.store.BuylistRulesStore.buylistDetails.completed !== true ?
                          <React.Fragment>
                            <div className="col-md-4">
                              <div className="tileCard addCard" onClick={this.showSearchModal.bind(this)}>
                                <p><i className="fal fa-plus"></i> Add a Card</p>
                              </div>
                            </div>
                          </React.Fragment>
                          :
                          null
                      }
                    </div>
                  </div>
                }
                <div className="actions">
                  {this.props.store.BuylistRulesStore.buylistDetails.completed === true ?
                    <Link to="/buylists/completed" className="button is-link rightAlign">Done</Link>
                    :
                    <React.Fragment>
                      {this.props.store.BuylistRulesStore.buylistDetails.approved === true ?
                        <React.Fragment>
                          <Link to="/buylists/pending" className="button cancel rightAlign">Cancel</Link>
                          &nbsp;
                          <button className="button is-danger rightAlign" onClick={this.showDeclineModal.bind(this, this.props.store.BuylistRulesStore.buylistDetails)}>Decline</button>
                          &nbsp;
                          <button className="button is-success rightAlign" onClick={this.showCompleteModal.bind(this, this.props.store.BuylistRulesStore.buylistDetails)}>Complete</button>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <Link to="/buylists/pending" className="button cancel rightAlign">Cancel</Link>
                          &nbsp;
                          <button className="button is-danger rightAlign" onClick={this.showDeclineModal.bind(this, this.props.store.BuylistRulesStore.buylistDetails)}>Decline</button>
                          &nbsp;
                          <button className="button is-success rightAlign" onClick={this.saveChanges.bind(this, this.props.store.BuylistRulesStore.buylistDetails)}>Save changes</button>
                          &nbsp;
                          <button className="button is-success rightAlign" onClick={this.showApproveModal.bind(this, this.props.store.BuylistRulesStore.buylistDetails)}>Approve</button>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </div>
                {this.searchModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.searchModalTitle}</p>
                        <i onClick={() => this.setSearchModalVisible(false)} className="fal fa-times"></i>
                      </header>
                      <section className="modal-card-body" style={{ maxHeight: "80%" }}>
                        <div className="field">
                          <label className="label">What game are you searching for?</label>
                          <div className="control has-icons-left">
                            <div className="select">
                              <select ref={this.gameSelect} id="game" onChange={this.fetchSetsForSelectedItem.bind(this)}>
                                {this.props.store.CardStore.games.map((game, i) => {
                                  return (
                                    <option key={i} value={game.gameId}>{game.gameName}</option>
                                  )
                                })}
                              </select>
                            </div>
                            <span className="icon is-small is-left">
                              <i className="fas fa-gamepad"></i>
                            </span>
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Card name</label>
                          <div className="control">
                            <input ref={this.cardSearch} id="cardSearch" className="input" type="text" required placeholder="E.g. Fatal Push" onKeyPress={(e) => this.handleEnterSearch.bind(this, e)} />
                          </div>
                        </div>
                        {this.returnedBuylistCards ?
                          <React.Fragment>
                            <br />
                            <p>Is a card showing a price of {this.props.store.MainStore.currency ? this.props.store.MainStore.currency : '$'}0.00? It is because the card and the condition don't meet the criteria in your buylist rules.</p>
                            <br />
                            <table className="table is-fullwidth">
                              <thead>
                                <tr>
                                  <td className="buylistCardImg"></td>
                                  <td className="buylistCardDetails">Card details</td>
                                  <td className="buylistCardCon">Condition</td>
                                  <td></td>
                                </tr>
                              </thead>
                              <tbody>
                                {this.returnedBuylistCards.map((buylistCard, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        <img src={buylistCard.imageUrl} style={{ width: '65px' }}></img>
                                      </td>
                                      <td>
                                        <span>{`${buylistCard.cardName} - ${buylistCard.type}`}</span>
                                        <br />
                                        <span>{buylistCard.setName}</span>
                                        <br />
                                        <span>{this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash" ? `${buylistCard.cashBuyPrice && buylistCard.cashBuyPrice > 0 ? fC(parseFloat(buylistCard.cashBuyPrice)) : fC(parseFloat(0.00))}` : `${buylistCard.storeCreditBuyPrice && buylistCard.storeCreditBuyPrice > 0 ? fC(parseFloat(buylistCard.storeCreditBuyPrice)) : fC(parseFloat(0.00))}`}</span>
                                      </td>
                                      <td className="conditionListing">
                                        {buylistCard.variantName}
                                      </td>
                                      <td>
                                        <button className="button is-success" type="button" onClick={this.addCardToList.bind(this, buylistCard)}>Add</button>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </React.Fragment>
                          :
                          null
                        }
                      </section>
                      <footer className="modal-card-foot">
                        <button type="button" className={`button half is-info ${this.searchModalLoading ? ' is-loading' : ''}`} onClick={this.searchForBuylistCards.bind(this)}>Search</button>
                        <button type="button" className="button half is-text" onClick={() => this.setSearchModalVisible(false)}>Close</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
                {this.conditionModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <form ref={this.form} onSubmit={e => this.updateCondition(e)} noValidate>
                        <header className="modal-card-head">
                          <p className="modal-card-title">{this.conditionModalTitle}</p>
                          <i onClick={() => this.setConditionModalVisible(false)} className="fal fa-times"></i>
                        </header>
                        <section className="modal-card-body">
                          <div>
                            <p>{this.conditionModalText}</p>
                          </div>
                          <br />
                          <div className="field">
                            <label className="label">Select the condition to update to:</label>
                            <div className="select">
                              <select id="variantId" onChange={this.fetchPricingForCard.bind(this)} defaultValue={this.buylistDetailToChangeCondition.variantId}>
                                {this.props.store.CustomersStore.customerVariants.map((variant, i) => {
                                  return (
                                    <option key={i} value={variant.id}>{variant.name}</option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>
                          <br />
                          {this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash" && this.buylistDetailToChangeCondition.cashBuyPrice > 0 || this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Store Credit" && this.buylistDetailToChangeCondition.storeCreditBuyPrice > 0 ?
                            <p><strong>Price of card: </strong>{this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Cash" ? fC(parseFloat(this.buylistDetailToChangeCondition.cashBuyPrice)) : fC(parseFloat(this.buylistDetailToChangeCondition.storeCreditBuyPrice))}</p>
                            :
                            <p>There is no price set for this condition, you will need to set one manually.</p>
                          }
                        </section>
                        <footer className="modal-card-foot">
                          <button className={`button half is-info ${this.conditionModalLoading ? ' is-loading' : ''}`}>Update condition</button>
                          <button type="button" className="button half is-text" onClick={() => this.setConditionModalVisible(false)}>Cancel</button>
                        </footer>
                      </form>
                    </div>
                  </div>
                  :
                  null
                }
                {this.overridePriceVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.overridePriceTitle}</p>
                        <i onClick={() => this.setOverridePriceVisible(false)} className="fal fa-times"></i>
                      </header>
                      <section className="modal-card-body">
                        <div className="field">
                          <label className="label">{this.overridePriceText}</label>
                          <div className="control">
                            <input ref={this.overrideInput} id="overridePrice" className="input" type="number" min="0" required placeholder={"E.g. " + (this.props.store.MainStore.currency ? this.props.store.MainStore.currency : '$') + "10.95"} step="1" />
                          </div>
                        </div>
                      </section>
                      <footer className="modal-card-foot">
                        <button className="button half is-info" onClick={this.overridePriceForBuylistItem.bind(this)}>Override price</button>
                        <button type="button" className="button half is-text" onClick={() => this.setOverridePriceVisible(false)}>Cancel</button>
                      </footer>
                    </div>

                  </div>
                  :
                  null
                }
                {this.adjustCreditModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.adjustModalTitle}</p>
                        <i onClick={() => this.setAdjustCreditModalVisible(false)} className="fal fa-times"></i>
                      </header>
                      <section className="modal-card-body">
                        <div className="field">
                          <label className="label">{this.adjustText}</label>
                          <div className="control">
                            <input ref={this.quantityInput} id="adjustQuantity" className="input" type="number" min="0" required placeholder="E.g. 10" step="1" />
                          </div>
                        </div>
                      </section>
                      <footer className="modal-card-foot">
                        <button className="button half is-info" onClick={this.changeQuantity.bind(this, this.buylistDetailToAdjust)}>Update</button>
                        <button type="button" className="button half is-text" onClick={() => this.setAdjustCreditModalVisible(false)}>Cancel</button>
                      </footer>
                    </div>

                  </div>
                  :
                  null
                }
                {this.declineModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <form ref={this.form} onSubmit={e => this.declineBuylist(e)} noValidate>
                        <header className="modal-card-head">
                          <p className="modal-card-title">{this.declineModalTitle}</p>
                          <i onClick={() => this.setDeclineModalVisible(false)} className="fal fa-times"></i>
                        </header>
                        <section className="modal-card-body">
                          <div className="field">
                            <label className="label">Enter the reason for declining the buylist. This will be emailed to the customer, leave blank for no email to be sent.</label>
                            <div className="control">
                              <textarea id="declineReason" className="input" placeholder="E.g. Most cards are too damaged to sell" />
                            </div>
                          </div>
                        </section>
                        <footer className="modal-card-foot">
                          <button className={`button half is-danger ${this.declineModalLoading ? ' is-loading' : ''}`}>Decline</button>
                          <button type="button" className="button half is-text" onClick={() => this.setDeclineModalVisible(false)}>Cancel</button>
                        </footer>
                      </form>
                    </div>

                  </div>
                  :
                  null
                }
                {this.approveModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <form ref={this.form} onSubmit={e => this.approveBuylist(e)} noValidate>
                        <header className="modal-card-head">
                          <p className="modal-card-title">{this.approveModalTitle}</p>
                          <i onClick={() => this.setApproveModalVisible(false)} className="fal fa-times"></i>
                        </header>
                        <section className="modal-card-body">
                          <div className="field">
                            <label className="label">Do you want to add any notes? These will be sent to the customer once approved.</label>
                            <div className="control">
                              <textarea id="approveReason" className="input" placeholder="E.g. Most cards are in great condition! Thank you." />
                            </div>
                          </div>
                          <p>Checking both 'Push stock automatically' and 'Apply store credit' will mark the buylist as completed</p>
                          <div className="field">
                            <label className="checkbox">
                              <input id="pushStock" type="checkbox" />
                              <span className="checkmark"></span>
                              &nbsp; Push stock automatically
                            </label>
                          </div>
                          {this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Store Credit" ?
                            <div className="field">
                              <label className="checkbox">
                                <input id="applyStoreCredit" type="checkbox" />
                                <span className="checkmark"></span>
                                &nbsp; Apply store credit
                            </label>
                            </div>
                            :
                            null
                          }
                        </section>
                        <footer className="modal-card-foot">
                          <button className={`button half is-info ${this.approveModalLoading ? ' is-loading' : ''}`}>Approve</button>
                          <button type="button" className="button half is-text" onClick={() => this.setApproveModalVisible(false)}>Cancel</button>
                        </footer>
                      </form>
                    </div>

                  </div>
                  :
                  null
                }
                {this.completeModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <form ref={this.form} onSubmit={e => this.completeBuylist(e)} noValidate>
                        <header className="modal-card-head">
                          <p className="modal-card-title">{this.completeModalTitle}</p>
                          <i onClick={() => this.setCompleteModalVisible(false)} className="fal fa-times"></i>
                        </header>
                        <section className="modal-card-body">
                          <div className="field">
                            <label className="label">Do you want to add any notes? These will be sent to the customer once completed.</label>
                            <div className="control">
                              <textarea id="approveReason" className="input" placeholder="E.g. Most cards are in great condition! Thank you." />
                            </div>
                          </div>
                          <div className="field">
                            <label className="checkbox">
                              <input id="pushStock" type="checkbox" />
                              <span className="checkmark"></span>
                              &nbsp; Push stock automatically
                            </label>
                          </div>
                          {this.props.store.BuylistRulesStore.buylistDetails.paymentType == "Store Credit" ?
                            <div className="field">
                              <label className="checkbox">
                                <input id="applyStoreCredit" type="checkbox" />
                                <span className="checkmark"></span>
                                &nbsp; Apply store credit
                            </label>
                            </div>
                            :
                            null
                          }
                        </section>
                        <footer className="modal-card-foot">
                          <button className={`button half is-info ${this.approveModalLoading ? ' is-loading' : ''}`}>Complete</button>
                          <button type="button" className="button half is-text" onClick={() => this.setApproveModalVisible(false)}>Cancel</button>
                        </footer>
                      </form>
                    </div>

                  </div>
                  :
                  null
                }
                {this.modalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.modalTitle}</p>
                      </header>
                      <section className="modal-card-body" dangerouslySetInnerHTML={{ __html: this.modalText }}>
                      </section>
                      <footer className="modal-card-foot">
                        {this.hasValidationErrors || this.noRedirect ?
                          <button className="button is-link" onClick={() => this.setModalVisible(false)}>Ok</button>
                          :
                          <React.Fragment>
                            {this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails && this.props.store.BuylistRulesStore.buylistDetails.finalBuylistDetails.length > 0 ?
                              <Link to="/buylists/completed" className="button">Ok</Link>
                              :
                              <React.Fragment>
                                {this.props.store.BuylistRulesStore.buylistDetails.approved ?
                                  <Link to="/buylists/approved" className="button">Ok</Link>
                                  :
                                  <Link to="/buylists/pending" className="button">Ok</Link>
                                }
                              </React.Fragment>
                            }
                          </React.Fragment>
                        }
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
