var TimeUtils = (function () {

  return {
    convertToISODateTime: function (stringDate) {
      let split = stringDate.split("[");
      return split[0];
    },

    convertDateToHumanReadable: function (stringDate) {
      let split = stringDate.split("T");
      let secondSplit = split[1].split("+");
      secondSplit = secondSplit[0].split("Z");
      const date = new Date(split[0]);
      date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
      let dateAndTime = date.toLocaleDateString() + " " + this.convertTime(new Date(split[0] + " " + secondSplit[0]).toLocaleTimeString());
      return dateAndTime;
    },

    convertDateToHumanReadableNoTime: function (stringDate) {
      let split = stringDate.split("T");
      let secondSplit = split[1].split("+");
      secondSplit = secondSplit[0].split("Z");
      const date = new Date(split[0]);
      date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
      let dateAndTime = date.toLocaleDateString();
      return dateAndTime;
    },

    convertDateToHumanReadableNoTimeNoFormatting: function (stringDate) {
      let split = stringDate.split("T");
      let secondSplit = split[1].split("+");
      secondSplit = secondSplit[0].split("Z");
      const date = new Date(split[0]);
      date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
      return date;
    },

    convertTime: function (time) {
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? " AM" : " PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    }
  }

}())

export default TimeUtils;