import React from 'react';
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'

@inject('store')
@observer
export default class ProductLineVariantItem extends React.Component {

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.updateVariant();
    }
    if (event.key === "w" || event.key === "W") {
      event.preventDefault();
      if (this.parentLineItemId == 0 && this.currentLineItemId == 0) {
        return;
      }
      if (this.currentLineItemId - 1 == -1) {
        var foundLines = document.querySelectorAll("[id^=variantline-" + (this.parentLineItemId - 1) + "-]");
        document.querySelector("#variantlineitem-" + (this.parentLineItemId - 1) + "-" + (foundLines.length - 1) + "-" + this.props.currentInput()).focus();
      } else {
        document.querySelector("#variantlineitem-" + (this.parentLineItemId) + "-" + (this.currentLineItemId - 1) + "-" + this.props.currentInput()).focus();
      }
    }
    if (event.key === "s" || event.key === "S") {
      event.preventDefault();
      var foundElement = document.querySelector("#variantlineitem-" + (this.parentLineItemId) + "-" + (this.currentLineItemId + 1) + "-" + this.props.currentInput());
      if (foundElement) {
        foundElement.focus();
      } else {
        foundElement = document.querySelector("#variantlineitem-" + (this.parentLineItemId + 1) + "-0-" + this.props.currentInput());
        if (foundElement) {
          foundElement.focus();
        }
      }
    }
    if (event.key === "a" || event.key === "A") {
      event.preventDefault();
      this.determineIfVisibleAndFocusLeft(this.props.currentInput() - 1);
    }
    if (event.key === "d" || event.key === "D") {
      event.preventDefault();
      this.determineIfVisibleAndFocusRight(this.props.currentInput() + 1);
    }
  }

  determineIfVisibleAndFocusRight(inputNumber) {
    if (inputNumber < 12) {
      var foundElement = document.querySelector("#variantlineitem-" + (this.parentLineItemId) + "-" + (this.currentLineItemId) + "-" + inputNumber);
      if (foundElement) {
        if (!!(foundElement.offsetWidth || foundElement.offsetHeight || foundElement.getClientRects().length)) {
          this.props.updateCurrentInput(inputNumber)
          foundElement.focus();
        } else {
          this.determineIfVisibleAndFocusRight(inputNumber + 1);
        }
      }
    }
  }

  determineIfVisibleAndFocusLeft(inputNumber) {
    if (inputNumber > 0) {
      var foundElement = document.querySelector("#variantlineitem-" + (this.parentLineItemId) + "-" + (this.currentLineItemId) + "-" + inputNumber);
      if (foundElement) {
        if (!!(foundElement.offsetWidth || foundElement.offsetHeight || foundElement.getClientRects().length)) {
          this.props.updateCurrentInput(inputNumber);
          foundElement.focus();
        } else {
          this.determineIfVisibleAndFocusLeft(inputNumber - 1);
        }
      }
    }
  }

  parentLineItemId;
  currentLineItemId;

  @observable changeHasApplied = false;
  @action setChangeHasApplied(bool) {
    this.changeHasApplied = bool;
  }

  @observable isUpdating = false;
  @action setIsUpdating(bool) {
    this.isUpdating = bool;
  }

  @observable hasUpdated = false;
  @action setHasUpdated(bool) {
    this.hasUpdated = bool;
  }

  @observable currentStock;
  @action setCurrentStock(currentStock) {
    this.currentStock = currentStock;
  }

  constructor(props) {
    super(props);
    this.priceOverride = React.createRef();
    this.cashBuyPrice = React.createRef();
    this.cashBuyPercent = React.createRef();
    this.creditBuyPrice = React.createRef();
    this.creditBuyPercent = React.createRef();
    this.buyLimit = React.createRef();
    this.maxInstockBuyPrice = React.createRef();
    this.maxInstockBuyPercentage = React.createRef();
    this.maxInstockCreditBuyPrice = React.createRef();
    this.maxInstockCreditBuyPercentage = React.createRef();
    this.stock = React.createRef();
    this.setCurrentStock(this.props.variant.quantity);
  }

  componentDidMount() {
    this.props.setUpdateVariant(this.updateVariant.bind(this));
  }

  returnNullOrValue(value) {
    return (value && value.length > 0 ? parseFloat(value) : null);
  }

  updateVariant() {
    this.setChangeHasApplied(false);
    this.setIsUpdating(true);
    let newStock = 0;
    if (this.stock.current.value) {
      newStock = this.stock.current.value;
    }
    let priceOverride = this.priceOverride.current.value;
    let cashBuyPrice = this.cashBuyPrice.current.value;
    let cashBuyPercentage = this.cashBuyPercent.current.value;
    let creditBuyPrice = this.creditBuyPrice.current.value;
    let creditBuyPercentage = this.creditBuyPercent.current.value;
    let buyLimit = this.buyLimit.current.value;
    let maxInstockBuyPrice = this.maxInstockBuyPrice.current.value;
    let maxInstockBuyPercentage = this.maxInstockBuyPercentage.current.value;
    let maxInstockCreditBuyPrice = this.maxInstockCreditBuyPrice.current.value;
    let maxInstockCreditBuyPercentage = this.maxInstockCreditBuyPercentage.current.value;
    var variantToSend = {
      id: this.props.variant.id,
      shopifyId: this.props.variant.shopifyId,
      priceOverride: this.returnNullOrValue(priceOverride),
      cashBuyPrice: this.returnNullOrValue(cashBuyPrice),
      cashBuyPercentage: this.returnNullOrValue(cashBuyPercentage),
      creditBuyPrice: this.returnNullOrValue(creditBuyPrice),
      creditBuyPercentage: this.returnNullOrValue(creditBuyPercentage),
      maxInstockBuyPrice: this.returnNullOrValue(maxInstockBuyPrice),
      maxInstockBuyPercentage: this.returnNullOrValue(maxInstockBuyPercentage),
      maxInstockCreditBuyPrice: this.returnNullOrValue(maxInstockCreditBuyPrice),
      maxInstockCreditBuyPercentage: this.returnNullOrValue(maxInstockCreditBuyPercentage),
      buyLimit: this.returnNullOrValue(buyLimit),
      stockUpdateType: this.props.getStockUpdateType(),
      stock: (newStock == this.currentStock && this.props.getStockUpdateType == "set" ? null : newStock)
    }
    const self = this;
    this.props.store.ProductsStore.updateVariant(variantToSend)
      .then(() => {
        self.setCurrentStock(newStock);
        self.setIsUpdating(false);
        self.setHasUpdated(true);
        self.setChangeHasApplied(true);
        setTimeout(function () {
          self.setHasUpdated(false);
        }, 2000);
      })
      .catch(err => {
        self.setIsUpdating(false);
        self.setHasUpdated(false);
        self.setChangeHasApplied(false);
        self.props.store.MainStore.setError(err);
      })
  }

  onFocus(event, currentInput) {
    event.target.select()
    this.props.updateCurrentInput(currentInput)
  }

  decreaseStockField() {
    var element = document.querySelector("#variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-1");
    if (element.value) {
      element.value = (parseInt(element.value) - 1);
    } else {
      element.value = -1;
    }
  }

  increaseStockField() {
    var element = document.querySelector("#variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-1");
    if (element.value) {
      element.value = (parseInt(element.value) + 1);
    } else {
      element.value = 1;
    }
  }

  render() {
    const fC = this.props.store.MainStore.currencyBuilder;
    this.parentLineItemId = this.props.parentListIndex;
    this.currentLineItemId = this.props.listIndex;
    return (

      <div key={this.props.listIndex} id={"variantline-" + this.parentLineItemId + "-" + this.currentLineItemId} className="row border-bottom">
        <div className="col-md-1 variantTitle noHeight">{this.props.variant.title}</div>
        <div className={"col-md-1 input-group mb-3 " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("stock") ? "" : " hidden")}>
          <div className="input-group-prepend">
            <span className="input-group-text minus" onClick={() => this.decreaseStockField()}><i className="fal fa-minus"></i></span>
          </div>
          <input ref={this.stock} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-1"} onFocus={(e) => this.onFocus(e, 1)} onKeyPress={this.handleKeyPress} type="number" step="1" className="form-control" defaultValue={this.props.variant.quantity} />
          <div className="input-group-append">
            <span className="input-group-text plus" onClick={() => this.increaseStockField()}><i className="fal fa-plus"></i></span>
          </div>
        </div>
        <div className={"col-md-1 text-center price " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("sellPrice") ? "" : " hidden")}>
          {fC(this.props.variant.price)}
        </div>
        <div className={"col-md-1 " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("sellPriceOverride") ? "" : " hidden")}>
          <input ref={this.priceOverride} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-2"} onFocus={(e) => this.onFocus(e, 2)} onKeyPress={this.handleKeyPress} type="number" step="0.01" className="form-control" defaultValue={this.props.variant.priceOverride} />
        </div>
        <div className={"col-md-1-5 text-small " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("cashBuyPrice") || this.props.columnsToShow.includes("cashBuyPercent") ? "" : " hidden")}>
          <input ref={this.cashBuyPrice} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-3"} onFocus={(e) => this.onFocus(e, 3)} onKeyPress={this.handleKeyPress} type="number" step="0.01" className={"form-control " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("cashBuyPrice") ? (this.props.columnsToShow.includes("cashBuyPercent") ? " halfInput" : "") : " hidden")} defaultValue={this.props.variant.cashBuyPrice} placeholder={this.props.store.MainStore.currency} />
          <span className={this.props.columnsToShow.length == 0 || (this.props.columnsToShow.includes("cashBuyPrice") && this.props.columnsToShow.includes("cashBuyPercent")) ? "" : "hidden"}> or </span>
          <input ref={this.cashBuyPercent} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-4"} onFocus={(e) => this.onFocus(e, 4)} onKeyPress={this.handleKeyPress} type="number" step="0.01" className={"form-control " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("cashBuyPercent") ? (this.props.columnsToShow.includes("cashBuyPrice") ? " halfInput" : "") : " hidden")} defaultValue={this.props.variant.cashBuyPercent} placeholder="%" />
        </div>
        <div className={"col-md-1-5 text-small " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("creditBuyPrice") || this.props.columnsToShow.includes("creditBuyPercent") ? "" : " hidden")}>
          <input ref={this.creditBuyPrice} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-5"} onFocus={(e) => this.onFocus(e, 5)} onKeyPress={this.handleKeyPress} type="number" step="0.01" className={"form-control " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("creditBuyPrice") ? (this.props.columnsToShow.includes("creditBuyPercent") ? " halfInput" : "") : " hidden")} defaultValue={this.props.variant.storeCreditBuyPrice} placeholder={this.props.store.MainStore.currency} />
          <span className={this.props.columnsToShow.length == 0 || (this.props.columnsToShow.includes("creditBuyPrice") && this.props.columnsToShow.includes("creditBuyPercent")) ? "" : "hidden"}> or </span>
          <input ref={this.creditBuyPercent} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-6"} onFocus={(e) => this.onFocus(e, 6)} onKeyPress={this.handleKeyPress} type="number" step="0.01" className={"form-control " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("creditBuyPercent") ? (this.props.columnsToShow.includes("creditBuyPrice") ? " halfInput" : "") : " hidden")} defaultValue={this.props.variant.creditBuyPercent} placeholder="%" />
        </div>
        <div className={"col-md-1 " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("buyLimit") ? "" : " hidden")}>
          <input ref={this.buyLimit} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-7"} onFocus={(e) => this.onFocus(e, 7)} onKeyPress={this.handleKeyPress} type="number" step="1" className="form-control" defaultValue={this.props.variant.maxPurchaseQuantity} />
        </div>
        <div className={"col-md-1-5 text-small " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("overstockCashBuyPrice") || this.props.columnsToShow.includes("overstockCashBuyPercent") ? "" : " hidden")}>
          <input ref={this.maxInstockBuyPrice} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-8"} onFocus={(e) => this.onFocus(e, 8)} onKeyPress={this.handleKeyPress} type="number" step="0.01" className={"form-control " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("overstockCashBuyPrice") ? (this.props.columnsToShow.includes("overstockCashBuyPercent") ? " halfInput" : "") : " hidden")} defaultValue={this.props.variant.maxInstockBuyPrice} placeholder={this.props.store.MainStore.currency} />
          <span className={this.props.columnsToShow.length == 0 || (this.props.columnsToShow.includes("overstockCashBuyPrice") && this.props.columnsToShow.includes("overstockCashBuyPercent")) ? "" : "hidden"}> or </span>
          <input ref={this.maxInstockBuyPercentage} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-9"} onFocus={(e) => this.onFocus(e, 9)} onKeyPress={this.handleKeyPress} type="number" step="0.01" className={"form-control " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("overstockCashBuyPercent") ? (this.props.columnsToShow.includes("overstockCashBuyPrice") ? " halfInput" : "") : " hidden")} defaultValue={this.props.variant.maxInstockBuyPercentage} placeholder="%" />
        </div>
        <div className={"col-md-1-5 text-small " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("overstockCreditBuyPrice") || this.props.columnsToShow.includes("overstockCreditBuyPercent") ? "" : " hidden")}>
          <input ref={this.maxInstockCreditBuyPrice} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-10"} onFocus={(e) => this.onFocus(e, 10)} onKeyPress={this.handleKeyPress} type="number" step="0.01" className={"form-control " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("overstockCreditBuyPrice") ? (this.props.columnsToShow.includes("overstockCreditBuyPercent") ? " halfInput" : "") : " hidden")} defaultValue={this.props.variant.maxInstockCreditBuyPrice} placeholder={this.props.store.MainStore.currency} />
          <span className={this.props.columnsToShow.length == 0 || (this.props.columnsToShow.includes("overstockCreditBuyPrice") && this.props.columnsToShow.includes("overstockCreditBuyPercent")) ? "" : "hidden"}> or </span>
          <input ref={this.maxInstockCreditBuyPercentage} id={"variantlineitem-" + this.parentLineItemId + "-" + this.currentLineItemId + "-11"} onFocus={(e) => this.onFocus(e, 11)} onKeyPress={this.handleKeyPress} type="number" step="0.01" className={"form-control " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("overstockCreditBuyPercent") ? (this.props.columnsToShow.includes("overstockCreditBuyPrice") ? " halfInput" : "") : " hidden")} defaultValue={this.props.variant.maxInstockCreditBuyPercentage} placeholder="%" />
        </div>
        <div className={"col-md-1 " + (this.props.columnsToShow.length == 0 || this.props.columnsToShow.includes("updateButton") ? "" : " hidden")}>
          <button tabIndex={-1} className={`button ${this.hasUpdated || this.changeHasApplied ? 'is-success' : 'is-link'} ${this.isUpdating ? ' is-loading' : ''}`} disabled={this.hasUpdated} onClick={() => this.updateVariant()}>{!this.hasUpdated ? 'Update' : 'Queued!'}</button>
        </div>
      </div>
    );
  }
}
