import React, { Component } from "react";

import { action } from "mobx";

class CustomerLine extends Component {
  @action
  selectCustomer = () => {
    this.props.store.selectedCustomer = this.props.customer;
    this.props.store.validateCart();
    this.props.store.customerResults = [];
  };

  render() {
    console.log(this.props);
    const fC = this.props.store.fCurr;
    return (
      <div className="customer" onClick={this.selectCustomer}>
        <span className="initials">
          <p>
            {this.props.customer.firstName &&
              this.props.customer.firstName.length > 0
              ? this.props.customer.firstName.charAt(0)
              : ""}{" "}
            {this.props.customer.lastName &&
              this.props.customer.lastName.length > 0
              ? this.props.customer.lastName.charAt(0)
              : ""}
          </p>
        </span>
        <div className="customerInfo">
          <span className="name">
            {this.props.customer.firstName &&
              this.props.customer.firstName.length > 0
              ? this.props.customer.firstName
              : ""}{" "}
            {this.props.customer.lastName &&
              this.props.customer.lastName.length > 0
              ? this.props.customer.lastName
              : ""}
          </span>
          <span className="email">{this.props.customer.email}</span>
          <span className="credit">
            {fC(this.props.customer.storeCredit.toFixed(2))}
          </span>
        </div>
      </div>
    );
  }
}

export default CustomerLine;
