var BuylistMenuStructure = {
  title: "Buylist",
  activeClass: "orange",
  links: [
    {
      title: "Pending Buylists",
      href: "buylists/pending"
    },
    {
      title: "Approved Buylists",
      href: "buylists/approved"
    },
    {
      title: "Complete Buylists",
      href: "buylists/completed"
    },
    {
      title: "Buylist Rules",
      href: "buylists/rules"
    },
    {
      title: "Failed to Sync",
      href: "buylists/failedToSyncProducts"
    }
  ]
};

export default BuylistMenuStructure;