const getFirebaseConfig = () => {
    if ((window.location.hostname === "portal.binderpos.com") ||
        (window.location.hostname === "kube.binderpos.com") ||
        (window.location.hostname === "betaportal.binderpos.com") ||
        (window.location.hostname === "devportal.binderpos.com") ||
        (window.location.hostname === "beta.binderpos.com") ||
        (window.location.hostname === "localhost") ||
        (window.location.hostname === "binderpos.au.ngrok.io") ||
        (window.location.hostname === "app.binderpos.com") ||
        (window.location.hostname === "appbeta.binderpos.com") ||
        (window.location.hostname === "legacy.binderpos.com")) {
        return {
            apiKey: 'AIzaSyDYmFPRKRkVFM9SQvbtCAT8oWKh4RhBGXg',
            authDomain: "hobby-pos.firebaseapp.com",
            projectId: "hobby-pos",
        }
    } else {
        // Return dev firebase
        return {
            apiKey: 'AIzaSyDZCalPccAAcsMXbW96BUOhG5b1HS-nG3k',
            authDomain: "binderpos-infrastructure.firebaseapp.com",
            projectId: "binderpos-infrastructure"
        }
    }
}

export { getFirebaseConfig }