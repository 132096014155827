import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { EventMenuStructure } from 'menuStructures';
import { Loader } from "components";

@inject('store')
@observer
export default class EventFailedToCreate extends React.Component {

  @observable offset = 0;
  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 100;
  @action setLimit(limit) {
    this.limit = limit;
  }

  @observable messageModalText;
  @action setMessageModalText(text) {
    this.messageModalText = text;
  }

  @observable messageModalTitle;
  @action setMessageModalTitle(title) {
    this.messageModalTitle = title;
  }

  @observable messageModalVisible;
  @action setMessageModalVisible(bool) {
    this.messageModalVisible = bool;
  }

  @observable processing;
  @action setProcessing(processing) {
    this.processing = processing;
  }

  @observable deleteModalVisible = false;
  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteModalProduct;
  @action setDeleteModalProduct(product) {
    this.deleteModalProduct = product;
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = 'Failed events | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(EventMenuStructure);

    this.props.store.EventsStore.rehydrateFailedToCreate(this.offset, this.limit);
  }


  getNextFailedToCreate() {
    this.setOffset(this.offset + 100);
    this.props.store.EventsStore.rehydrateFailedToCreate(this.offset, this.limit);
  }

  getPrevFailedToCreate() {
    var offsetUpdate = this.offset - 100;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.EventsStore.rehydrateFailedToCreate(this.offset, this.limit);
  }

  pushStockThrough(failedToCreate) {
    this.setProcessing(true);
    this.props.store.EventsStore.repushFailedEvent(failedToCreate.id)
      .then((result) => {
        if (result && result.length > 0) {
          this.props.store.EventsStore.setFailedEvents(result);
        } else {
          this.setMessageModalText("Event pushed through to Shopify successfully!");
          this.setMessageModalTitle("Success!");
          this.setMessageModalVisible(true);
        }
        this.setProcessing(false);
        this.props.store.EventsStore.rehydrateFailedToCreate(this.offset, this.limit);
      })
      .catch(error => {
        this.setProcessing(false);
        this.props.store.MainStore.setError(error);
      })
  }

  showRemoveProductModal(failedToCreate) {
    this.setDeleteModalProduct(failedToCreate);
    this.setDeleteModalVisible(true);
  }

  removeFromList(failedToCreate) {
    this.setProcessing(true);
    this.setDeleteModalVisible(false);
    this.props.store.EventsStore.removeFailedEventFromList(failedToCreate.id)
      .then((result) => {
        this.setMessageModalText(result.actionPass ? "Event removed successfully!" : result.message);
        this.setMessageModalTitle(result.actionPass ? "Event removed" : "There was an issue removing this event");
        this.setMessageModalVisible(true);
        this.setProcessing(false);
        this.props.store.EventsStore.rehydrateFailedToCreate(this.offset, this.limit);
      })
      .catch(error => {
        this.setProcessing(false);
        this.props.store.MainStore.setError(error);
      })
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.events ?
          <React.Fragment>
            {this.props.store.EventsStore.failedToCreateEvents ?
              <React.Fragment>
                {this.props.store.EventsStore.failedEvents ?
                  <ConfirmationModal type="confirm" title={this.props.store.EventsStore.event ? "The following events failed to update" : "The following events failed to be created"} text={this.buildEventsFailedString()} confirmAction={() => this.props.store.EventsStore.setFailedEvents(null)} />
                  :
                  null
                }
                {this.processing ?
                  <Loader />
                  :
                  null
                }
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="app-icon orange"><i className="fas fa-users"></i></span>
                    <span className="header-text">Event tickets that had issues being created</span>
                    <div className="paging-nav">
                      <button className="button is-info paging-nav-right-margin"
                        disabled={this.offset == 0 ? true : false}
                        onClick={() => this.getPrevFailedToCreate()}>Prev</button>
                      <button
                        className="button is-info"
                        disabled={this.props.store.EventsStore.failedToCreateEvents && this.props.store.EventsStore.failedToCreateEvents.length < this.limit ? true : false}
                        onClick={() => this.getNextFailedToCreate()}>Next</button>
                    </div>
                  </h2>
                </div>
                <br />
                <div className="buylistItem FailedtoSync">
                  <div className="row">
                    {this.props.store.EventsStore.failedToCreateEvents.map((failedToCreate, i) => {
                      return (
                        <div className="col-md-4" key={i}>
                          <div className="tileCard">
                            <div className="topBlock">
                              <div className="cardImg">
                                <img src={failedToCreate.calendarIcon} />
                              </div>
                              <div className="titleInfo">
                                <span className="cardName">{`${failedToCreate.title}`}</span>
                                <span className="cartSet">{`${failedToCreate.game} - ${failedToCreate.type}`}</span>
                              </div>
                            </div>
                            <div className="cardInfo">
                              <span className="edit" onClick={() => this.pushStockThrough(failedToCreate)}><i className="fal fa-paper-plane"></i> Retry event creation</span>
                              <span className="remove" onClick={() => this.showRemoveProductModal(failedToCreate)}>Remove <i className="fas fa-times-circle"></i></span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {this.messageModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.messageModalTitle}</p>
                        <button className="delete" aria-label="close" onClick={() => this.setMessageModalVisible(false)}></button>
                      </header>
                      <section className="modal-card-body">
                        <p>{this.messageModalText}</p>
                      </section>
                      <footer className="modal-card-foot">
                        <button type="button" className="button is-link" onClick={() => this.setMessageModalVisible(false)}>Ok</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
                {this.deleteModalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Are you sure?</p>
                        <button className="delete" aria-label="close" onClick={() => this.setDeleteModalVisible(false)}></button>
                      </header>
                      <section className="modal-card-body">
                        <p>Are you sure you want to remove {this.deleteModalProduct.title} {this.deleteModalProduct.game} - {this.deleteModalProduct.type}? this will be removed from this list.</p>
                      </section>
                      <footer className="modal-card-foot">
                        <button className={`button is-danger half`} onClick={() => this.removeFromList(this.deleteModalProduct)}>Remove</button>
                        <button className="button is-text half" onClick={() => this.setDeleteModalVisible(false)}>Cancel</button>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }
              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
