import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { CustomerMenuStructure } from 'menuStructures';
import { Link } from "react-router-dom";
import { Loader } from "components";

@inject('store')
@observer
export default class Customer extends React.Component {

  closeParentModal = (value) => {
    this.props.setShowAddCustomer(!value);
  }

  @observable updating;
  @action setUpdating(bool) {
    this.updating = bool
  }

  @observable modalVisible = false;
  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable modalTitle;
  @action setModalTitle(title) {
    this.modalTitle = title;
  }

  @observable modalText;
  @action setModalText(text) {
    this.modalText = text;
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentWillMount() {
    this.props.store.CustomersStore.setCustomer(null);
    this.props.store.MenuStore.setSideMenuToDisplay(CustomerMenuStructure);
    const id = (this.props.match ?.params ?.customerId || null);
    if (id) {
      document.title = 'Update Customer | BinderPOS';
      this.props.store.CustomersStore.fetchCustomerById(id)
        .then((result) => {
          this.props.store.CustomersStore.setCustomer(result);
        })
    } else {
      document.title = 'Add Customer | BinderPOS';
    }
  }

  save(e) {
    e.preventDefault()
    this.setUpdating(true)

    const elm = this.form.current.elements;

    var customerToSave = {
      email: elm.email.value,
      firstName: elm.firstName.value,
      lastName: elm.lastName.value,
      phone: elm.phone.value,
      notes: elm.notes.value,
    };

    if (this.props.match ?.params ?.customerId) {
      customerToSave.id = this.props.match.params.customerId;
      this.props.store.CustomersStore.updateCustomer(customerToSave)
        .then(() => {
          this.setUpdating(false);
          this.setModalVisible(true);
          this.setModalTitle('Customer has been updated');
          var customerText = (customerToSave.firstName && customerToSave.firstName.length > 0 ? (customerToSave.firstName + " " + (customerToSave.lastName && customerToSave.lastName.length > 0 ? customerToSave.lastName : "")) : (customerToSave.email && customerToSave.email.length > 0 ? customerToSave.email : (customerToSave.phone && customerToSave.phone.length > 0 ? customerToSave.phone : "")));
          this.setModalText(customerText + ' has been updated successfully.');
          this.props.store.CustomersStore.rehydrate();
        }).catch(error => {
          this.setUpdating(false);
          this.setModalVisible(false);
          this.props.store.MainStore.setError(error);
        })
    } else {
      this.props.store.CustomersStore.addCustomer(customerToSave)
        .then((customer) => {
          this.setUpdating(false);
          this.setModalVisible(true);
          this.setModalTitle('Customer has been added');
          var customerText = (customerToSave.firstName && customerToSave.firstName.length > 0 ? (customerToSave.firstName + " " + (customerToSave.lastName && customerToSave.lastName.length > 0 ? customerToSave.lastName : "")) : (customerToSave.email && customerToSave.email.length > 0 ? customerToSave.email : (customerToSave.phone && customerToSave.phone.length > 0 ? customerToSave.phone : "")));
          this.setModalText(customerText + ' has been added.');
          this.props.store.CustomersStore.rehydrate();
          if (this.props.isPos) {
            this.props.setSelectedCustomerForPOS(customer);
          }
        }).catch(error => {
          this.setUpdating(false);
          this.setModalVisible(false);
          this.props.store.MainStore.setError(error);
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.AuthStore.screenSettings.customers ?
          <React.Fragment>
            {(this.props.match ?.params ?.customerId && this.props.store.CustomersStore.customer) || !this.props.match ?.params ?.customerId ?
              <React.Fragment>
                <form ref={this.form} onSubmit={e => this.save(e)} noValidate>
                  <div className="app-header">
                    <h2 className="title is-2">
                      <span className="header-text">{this.props.store.CustomersStore.customer ? `Update ${this.props.store.CustomersStore.customer.firstName && this.props.store.CustomersStore.customer.firstName.length > 0 ? (this.props.store.CustomersStore.customer.firstName + " " + (this.props.store.CustomersStore.customer.lastName && this.props.store.CustomersStore.customer.lastName.length > 0 ? this.props.store.CustomersStore.customer.lastName : "")) : (this.props.store.CustomersStore.customer.email && this.props.store.CustomersStore.customer.email.length > 0 ? this.props.store.CustomersStore.customer.email : (this.props.store.CustomersStore.customer.phone && this.props.store.CustomersStore.customer.phone.length > 0 ? this.props.store.CustomersStore.customer.phone : ""))}` : `Add new customer`}</span>
                      <button className={`button rightAlign is-link ${this.updating ? ' is-loading' : ''}`} type="submit">{this.props.store.CustomersStore.customer ? "Update" : "Save"}</button>
                    </h2>
                  </div>
                  <div className="EventListing">
                    <div className="col-md-12 content">

                      <div className="eventPanel">
                        <div className="row">
                          <div className="col-md-12 field">
                            <label className="label">Email address</label>
                            <div className="control">
                              <input id="email" className="input" type="text" placeholder="E.g. example@binderpos.com" required defaultValue={this.props.store.CustomersStore.customer ?.email || ""} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 field">
                            <label className="label">First name</label>
                            <div className="control">
                              <input id="firstName" className="input" type="text" required defaultValue={this.props.store.CustomersStore.customer ?.firstName || ""} />
                            </div>
                          </div>
                          <div className="col-md-6 field">
                            <label className="label">Last name</label>
                            <div className="control">
                              <input id="lastName" className="input" type="text" required defaultValue={this.props.store.CustomersStore.customer ?.lastName || ""} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 field">
                            <label className="label">Phone number</label>
                            <div className="control">
                              <input id="phone" className="input" type="tel" placeholder="E.g. +1 123 456 7890" required defaultValue={this.props.store.CustomersStore.customer ?.phone || ""} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 field">
                            <label className="label">Customer notes</label>
                            <div className="control">
                              <textarea id="notes" className="input" placeholder="Customer notes" required defaultValue={this.props.store.CustomersStore.customer ?.notes || ""}></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className={`button is-link ${this.updating ? ' is-loading' : ''}`} type="submit">{this.props.store.CustomersStore.customer ? "Save and update customer" : "Save and add new customer"}</button>

                    </div>
                  </div>
                </form>
                {this.modalVisible ?
                  <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.modalTitle}</p>
                      </header>
                      <section className="modal-card-body" dangerouslySetInnerHTML={{ __html: this.modalText }}>
                      </section>
                      <footer className="modal-card-foot">
                        <React.Fragment>
                          {this.props.isPos ?
                            <React.Fragment>
                              <button type="button" className="button" onClick={() => this.closeParentModal(true)}>Close</button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <Link to="/customers" className="button">Ok</Link>
                            </React.Fragment>
                          }
                        </React.Fragment>
                      </footer>
                    </div>
                  </div>
                  :
                  null
                }

              </React.Fragment>
              :
              <Loader />
            }
          </React.Fragment>
          :
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        }
      </React.Fragment>
    );
  }
}
