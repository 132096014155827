import React from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { SettingsMenuStructure } from 'menuStructures';
import Quill from 'quill'
import showdown from 'showdown'
import xssFilter from 'showdown-xss-filter'
import "quill/dist/quill.snow.css"
import { Loader } from 'components'
import { ToastContainer } from "react-toastify";

@inject('store')
@observer
export default class StoreSettings extends React.Component {

  @observable updatingStoreSettings = false;
  @action setUpdatingStoreSettings(bool) {
    this.updatingStoreSettings = bool;
  }

  @observable storeSettings;
  @action setStoreSettings(storeSettings) {
    this.storeSettings = storeSettings;
  }

  @observable savingSettings;
  @action setSavingSettings(savingSettings) {
    this.savingSettings = this.savingSettings;
  }

  @observable buylistConfirmationText;
  @action setBuylistConfirmationText(value) {
    this.buylistConfirmationText = value;
  }

  @observable buylistConfirmationTextHTML;
  @action setBuylistConfirmationTextHTML(html) {
    this.buylistConfirmationTextHTML = html
  }

  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.buylistConfirmationTextEditor = React.createRef();
    this.buylistConfirmationTextRef = React.createRef();
    this.quillBuylistConfirmationText;
  }

  componentDidUpdate() {
    if (this.buylistConfirmationTextEditor.current && !this.quillBuylistConfirmationText) {
      this.initQuillEditor();
    }
  }

  componentDidMount() {
    document.title = 'Store Settings | BinderPOS';
    this.props.store.MenuStore.setSideMenuToDisplay(SettingsMenuStructure);
    this.props.store.SettingsStore.fetchCustomerSettingForType('buylistConfirmationText')
      .then(result => {
        console.log(result);
        this.setBuylistConfirmationText(result.settingValue);
      }).catch(error => {
        this.props.store.MainStore.setError(error);
      })
    this.refreshStoreSettings();
    this.props.store.SettingsStore.fetchSettings().then(result => {
      if (result) {
        this.props.store.SettingsStore.setSettings(result);
      }
    }).catch(error => {
      this.props.store.MainStore.setError(error);
    });
    if (this.buylistConfirmationTextEditor.current && !this.quillBuylistConfirmationText) {
      this.initQuillEditor();
    }
  }

  initQuillEditor() {
    var toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],

      ['clean']                                         // remove formatting button
    ];
    let markdownConverter = new showdown.Converter({ extensions: [xssFilter] });
    console.log(this.buylistConfirmationText);
    let html = markdownConverter.makeHtml(this.buylistConfirmationText && this.buylistConfirmationText.length > 0 ? this.buylistConfirmationText : "");

    this.setBuylistConfirmationTextHTML(html);
    this.buylistConfirmationTextEditor.current.innerHTML = html;

    this.quillBuylistConfirmationText = new Quill(this.buylistConfirmationTextEditor.current, {
      modules: { 'toolbar': toolbarOptions }, theme: 'snow'
    });
    this.quillBuylistConfirmationText.on('text-change', (delta, oldDelta, source) => {
      this.setBuylistConfirmationTextHTML(this.quillBuylistConfirmationText.root.innerHTML);
    });
  }

  refreshStoreSettings() {
    this.setStoreSettings(null);
    this.props.store.SettingsStore.fetchStoreSettings()
      .then((result) => {
        this.setStoreSettings(result);
      })
      .catch(error => {
        this.props.store.MainStore.setError(error);
      })
  }

  saveStoreSettings(e) {
    e.preventDefault()

    var storeSettingsToSave = {
      storeName: this.form.current.elements.storeName.value,
      addressLine1: this.form.current.elements.addressLine1.value,
      addressLine2: this.form.current.elements.addressLine2.value,
      city: this.form.current.elements.city.value,
      state: this.form.current.elements.state.value,
      country: this.form.current.elements.country.value,
      zipCode: this.form.current.elements.zipCode.value,
      phoneNumber: this.form.current.elements.phoneNumber.value
    }

    console.log(storeSettingsToSave);

    this.props.store.SettingsStore.updateStoreSettings(storeSettingsToSave)
      .then(() => {
        this.setUpdatingStoreSettings(false);
        this.refreshStoreSettings();
      }).catch((error) => {
        this.props.store.MainStore.setError(error);
      })

  }

  updateBuylistConfirmationText() {
    var setting = {
      settingName: "buylistConfirmationText",
      settingValue: `${this.buylistConfirmationTextHTML}`,
    }
    this.props.store.SettingsStore.updateSetting(setting)
      .then(() => {
        this.props.store.MainStore.toast("Buylist confirmation text updated!");
      }).catch((error) => {
        this.props.store.MainStore.setError(error);
        this.setSavingSettings(true);
      })
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        {this.props.store.SettingsStore.settings && this.storeSettings ?
          <React.Fragment>
            <div className="app-header">
              <h2 className="title is-2">
                <span className="app-icon purple"><i className="fas fa-users"></i></span>
                <span className="header-text">Store Settings</span>
              </h2>
            </div>
            <div className="EventListing">
              <div className="col-md-12 content">
                {this.updatingStoreSettings ?
                  <form ref={this.form} onSubmit={e => this.saveStoreSettings(e)} noValidate>
                    <button className="button settingsCancel is-link" type="button" onClick={() => this.setUpdatingStoreSettings(false)}>Cancel</button>
                    <div style={{ paddingLeft: "20px", paddingTop: "10px", paddingBottom: "20px" }}>
                      <div>
                        <div className="row">
                          <div className="col-md-6 field">
                            <label className="label">Store name</label>
                            <div className="control">
                              <input id="storeName" className="input" type="text" required placeholder="BinderPOS" defaultValue={this.storeSettings && this.storeSettings.storeName ? this.storeSettings.storeName : ""} />
                            </div>
                          </div>
                          <div className="col-md-6 field">
                            <label className="label">Phone Number</label>
                            <div className="control">
                              <input id="phoneNumber" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.phoneNumber ? this.storeSettings.phoneNumber : ""} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 field">
                            <label className="label">Address line 1</label>
                            <div className="control">
                              <input id="addressLine1" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.addressLine1 ? this.storeSettings.addressLine1 : ""} />
                            </div>
                          </div>
                          <div className="col-md-6 field">
                            <label className="label">Address line 2</label>
                            <div className="control">
                              <input id="addressLine2" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.addressLine2 ? this.storeSettings.addressLine2 : ""} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 field">
                            <label className="label">City</label>
                            <div className="control">
                              <input id="city" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.city ? this.storeSettings.city : ""} />
                            </div>
                          </div>
                          <div className="col-md-3 field">
                            <label className="label">State</label>
                            <div className="control">
                              <input id="state" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.state ? this.storeSettings.state : ""} />
                            </div>
                          </div>
                          <div className="col-md-3 field">
                            <label className="label">Country</label>
                            <div className="control">
                              <input id="country" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.country ? this.storeSettings.country : ""} />
                            </div>
                          </div>
                          <div className="col-md-3 field">
                            <label className="label">Zip Code / Post Code</label>
                            <div className="control">
                              <input id="zipCode" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.zipCode ? this.storeSettings.zipCode : ""} />
                            </div>
                          </div>
                        </div>
                        <button className="button is-link" type="submit" style={{ marginRight: "10px" }}>Save store settings</button>
                      </div>
                    </div>
                  </form>
                  :
                  <div className="notEditable">
                    <button className="button settingsUpdate is-link" onClick={() => this.setUpdatingStoreSettings(true)}>Update store settings</button>
                    <div className="row">
                      <div className="col-md-6 field">
                        <label className="label">Store name</label>
                        <div className="control">
                          <input disabled id="storeName" className="input" type="text" required placeholder="BinderPOS" defaultValue={this.storeSettings && this.storeSettings.storeName ? this.storeSettings.storeName : "Not setup yet"} />
                        </div>
                      </div>
                      <div className="col-md-6 field">
                        <label className="label">Phone Number</label>
                        <div className="control">
                          <input disabled id="phoneNumber" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.phoneNumber ? this.storeSettings.phoneNumber : "Not setup yet"} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 field">
                        <label className="label">Address line 1</label>
                        <div className="control">
                          <input disabled id="addressLine1" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.addressLine1 ? this.storeSettings.addressLine1 : "Not setup yet"} />
                        </div>
                      </div>
                      <div className="col-md-6 field">
                        <label className="label">Address line 2</label>
                        <div className="control">
                          <input disabled id="addressLine2" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.addressLine2 ? this.storeSettings.addressLine2 : ""} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 field">
                        <label className="label">City</label>
                        <div className="control">
                          <input disabled id="city" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.city ? this.storeSettings.city : "Not setup yet"} />
                        </div>
                      </div>
                      <div className="col-md-3 field">
                        <label className="label">State</label>
                        <div className="control">
                          <input disabled id="state" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.state ? this.storeSettings.state : "Not setup yet"} />
                        </div>
                      </div>
                      <div className="col-md-3 field">
                        <label className="label">Country</label>
                        <div className="control">
                          <input disabled id="country" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.country ? this.storeSettings.country : "Not setup yet"} />
                        </div>
                      </div>
                      <div className="col-md-3 field">
                        <label className="label">Zip Code / Post Code</label>
                        <div className="control">
                          <input disabled id="zipCode" className="input" type="text" defaultValue={this.storeSettings && this.storeSettings.zipCode ? this.storeSettings.zipCode : "Not setup yet"} />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="EventListing">
              <div className="col-md-12 content">
                <div className="field">
                  <label className="label">Buylist confirmation text</label>
                  <div className="control editor">
                    <div className="content" ref={this.buylistConfirmationTextEditor}></div>
                  </div>
                </div>
                <button className="button is-link" type="button" onClick={() => this.updateBuylistConfirmationText()} style={{ marginRight: "10px" }}>Update buylist confirmation text</button>
              </div>
            </div>
          </React.Fragment>
          :
          <Loader />
        }
      </React.Fragment>
    );
  }
}